import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { ShowBases } from '../../../interfaces/show-bases.interface';
import { Router } from '@angular/router';
import noInternet from 'no-internet';
import * as ValidationMessages from '../../../validators/validation-messages';
import { NgxUiLoaderService } from 'ngx-ui-loader';
export class companiesDriversList {
  company: string
  company_id: number
  drivers: []
}
@Component({
  selector: 'app-modal-alta-choferes-cliente',
  templateUrl: './modal-alta-choferes-cliente.component.html',
  styleUrls: ['./modal-alta-choferes-cliente.component.css']
})

export class ModalAltaChoferesClienteComponent implements OnInit {
  formAltaChoferes: FormGroup;
  bases;
  getCompaniesDrivers: companiesDriversList[];
  companiesDrivers: companiesDriversList[];
  user_errors: any = ValidationMessages.staffMessages.name_errors;
  number_driver_errors: any = ValidationMessages.staffMessages.name_errors;
  cliente_errors: any = ValidationMessages.staffMessages.name_errors;
  base_errors: any = ValidationMessages.staffMessages.name_errors;
  selectedCompany;
  isCompanySelected: boolean = false;
  companyBases= [];
  companiesId = [];
  company_id = JSON.parse(localStorage.getItem('AdminCliente')).companyId;
  serviceError:string;
  isFreemium: boolean;

  tabs = ['Nuevo Chofer', 'Carga Masiva'];
  tab_selected = new FormControl(0);
  DragAndDropFileOperation = 0; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc
  
  constructor(  public dialogRef: MatDialogRef<ModalAltaChoferesClienteComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private profileService: ProfileService,
                private utils: UtilsService,
                private spinner: NgxSpinnerService,
                private vehicleService: VehiclesService,
                private router: Router,
                private ngxService: NgxUiLoaderService
                ) {
    const userType =  Number(localStorage.getItem('userType'));
    if (userType === 3) {
      this.isFreemium = true;
    }  else {
      this.isFreemium = false;
    }
  }

  ngOnInit() {
    this.loadMembers();
    this.loadBases();
    this.loadCompanies();

    this.formAltaChoferes = this.fb.group({
      'name' : new FormControl('',
      [
            Validators.required,
            Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*')
        ]
      ),
      'last_name' : new FormControl('',
         [
            Validators.required,
            Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*')
          ]
      ),
      'driver_id' : new FormControl('',
      Validators.required
      ),
      // 'company_id' : new FormControl('',
      // Validators.required
      // ),
      'type' : new FormControl(8,
      Validators.required
      ),
    });
  }


  filterBases( selectedCompany ) {
    const bases: any = this.bases;
    bases.forEach(element => {
      if ( element.company_name === selectedCompany ) {
        this.companyBases.push(element);
      }
    });
    if (this.companyBases === undefined || this.companyBases.length == 0) {
      // array empty or does not exist
      this.isCompanySelected = true;
      this.formAltaChoferes.removeControl('base');
      this.formAltaChoferes.updateValueAndValidity();
    } else {
      this.formAltaChoferes.addControl('base', new FormControl('', Validators.required));
      this.isCompanySelected = true;
    }
    console.log(this.formAltaChoferes.value);
  }

  onNoClick( result? ): void {
    this.dialogRef.close( result );
  }

  public addMember( form ) {
    noInternet().then(offline => {
        if (offline) {
            this.utils.alertError('Verifica tu conexión');
        } else {
            this.ngxService.startLoader('loader-modal');
            if (form.valid) {
              let Data = {
                name: form.value.name,
                last_name: form.value.last_name,
                driver_number: form.value.driver_id,
                base_id: form.value.base,
                company_id: null
              }

              if (Data.base_id === undefined) {
                Data.base_id = null;
              }
              
              console.log(Data);
                this.profileService.addDriver( Data ).subscribe(
                    response => { 
                      this.ngxService.stopLoader('loader-modal');
                      if (response.success != 1) {
                        this.addMemberError( response.message ); 
                      } else {
                        this.addMemberSuccess( response ); 
                      }
                    },
                    error => { 
                      this.ngxService.stopLoader('loader-modal');
                      this.addMemberError( error ); 
                    }
                );
            } 
        }
    })
  }

  refresh() {
      window.location.reload();
  }
  
  private addMemberSuccess( response: any) {  
    this.onNoClick( 'true' );
    console.log(response);
  }
  
  private addMemberError( error: any ) {
    this.serviceError = error;
    console.error(error);
  }

  // #Get Bases
  public loadBases() {
    this.vehicleService.getBases().subscribe(
        response => { this.loadBasesSuccess( response );   
        },
        error => { this.loadBasesError( error );} 
    );
}
/*
    Load Bases Callbacks
*/
private loadBasesSuccess( response: any ) {
    let code = response.success;
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      this.bases = response.data;
      console.log(this.bases);
      this.filterBases( JSON.parse(localStorage.getItem('AdminCliente')).name);
    }
}

private loadBasesError( error: any ) {
    console.error( error );
}

   // #Get Clients
   public loadCompanies() {
      this.profileService.getCompaniesDrivers().subscribe(
          response => { 
            this.loadCompaniesSuccess( response );
          },
          error => { 
            this.loadCompaniesError( error );
          }
      );
  }
  /*
      Load Clients Callbacks
  */
  private loadCompaniesSuccess( response: any ) {
      let code = response.success;
      console.log( response );
      if (code != 1) {
          this.utils.errorManagement( response );
      } else {
        this.companiesDrivers = response.data;
        console.log(this.companiesDrivers);
      }
  }
  
  private loadCompaniesError( error: any ) {
      console.error( error );
  }

    // #Get users
    public loadMembers(startLoader?) {
      if (typeof startLoader === 'undefined' || startLoader === true){
        // this.ngxService.startLoader( this.masterLoader.loaderId )
      }
      this.profileService.getPremiumCompanies().subscribe(
          response => { 
            console.log(response);
            this.companiesId = response.data;
            // this.ngxService.stop();
          },
          error => {
            // this.ngxService.stop();
          }
      );
  }

}

import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import noInternet from 'no-internet';
import { UtilsService } from '../../../app.utils';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalSendNotificationIndComponent } from '../../../shared/modals/modal-send-notification-ind/modal-send-notification-ind.component';
import { ModalEliminarCompaniaComponent } from '../../modals/modal-eliminar-compania/modal-eliminar-compania.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { ModalEditarEquipoSoltekComponent } from '../../../shared/modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component'; 

// #Services
import { ProfileService } from '../../../services/users/profile.service';
import { SweetAlertService } from 'angular-sweetalert-service';
import { PaginatorService } from "../../../services/paginator.service";

@Component({
  selector: 'app-table-users-freemium',
  templateUrl: './table-users-freemium.component.html',
  styleUrls: ['./table-users-freemium.component.css']
})
export class TableUsersFreemiumComponent implements OnInit {
  @Input() clientType:number;
  @Input() displayedColumns: string[] = [
    'administrador', 
    'contacto', 
    'vehiculos', 
    'vehiculos_con_kit',    
    'vehiculosSupervisados', 
    'incidencia', 
    'status', 
    'acciones', 
    'upgrade', 
    'next',
    'number',
  ];
  @Input() members: [];
  @Input() showTableAlert:boolean;
  @Input() alertClass = true;
  @Output() memberSelected = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatPaginator) paginatorBottom: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort ;
  alertMessage =  {
    success: 'El usuario fue editado correctamente.',
    fail: 'Hubo un error al actualizar el usuario.'
  };
  dataSource = new MatTableDataSource(this.members);
  hasData: boolean;
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  route = this.router.url;
  paginatorId = 'pUf';
  paginatorChosenValue: number;
  private premium_options = { // Textos para modal de confirmar cambio de usuario Freemium a Premium
    title: '¿Estás seguro de cambiar este usuario a Premium?',
    text: 'Al cambiarlo a premium este usuario se borrará de la lista de Administradores Independientes y se pasará a la lista de Administradores clientes',
    confirmButtonText: 'Aceptar',
    cancelButtonText: '<span style="color:#ff6602">No, cancelar<span>',
    confirmButtonClass: 'btn-soltek btn-orange mr-1', 
    cancelButtonClass: 'btn-soltek btn-orange-inverse ml-1',
    buttonsStyling: false,
    width: 597,
    imageUrl: '../../../../assets/images/icn-modal-upgrade.png'
  };

  constructor(
    private profileService: ProfileService,
    private utils: UtilsService,
    private router: Router,
    private alertService: SweetAlertService,
    public dialog: MatDialog,
    private sharingService: SharingServiceService,
    private ngxService: NgxUiLoaderService,
    private paginatorService: PaginatorService
  ) { 
    this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
    this.loadMembers();
  }

  ngOnInit() {
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );
  }

  // Esta función se invoca un segundo después de procesar la información a mostrar
  applyDatasource(): void {
    setTimeout( (event) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }, 1000);
  }

  // Función para manjear el filtro por texto
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase(); 
    this.dataSource.filter = filterValue;
  }

  // Ruta para cambiar un usuario de freemium a premium
  altaCliente(idx: number){
    console.log(idx + 1);
    this.router.navigate(['/users/alta-cliente/',(idx + 1)]);
  }


  // Crea la tabla
  createTable( data ) {
    console.log(data);
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.email.toLowerCase().includes(filter) 
      || data.client.toLowerCase().includes(filter);
     };
    this.hasData = true;
    this.applyDatasource();
  }

  // Lleva a la vista de la información del usuario seleccionado
  goToDetalleCliente(element) {
    console.log(element);
    element = {
      companyId: element.companyId,
      userId: element.id,
      last_name: element.last_name, 
      name: element.name, 
      email: element.email,
    }
    localStorage.setItem('AdminCliente', JSON.stringify(element));
    this.sharingService.setData(element);
    localStorage.setItem('ViewingUserType', 'free')
    this.router.navigate(['/vehiculos-cliente']);
  }

  // Alerta para confirmar cambio de Freemium a Premium
  premiumAlert( idx: number, company) {
    console.log(company);
    localStorage.setItem('upgradeUser', JSON.stringify(company));
    this.alertService.confirm(this.premium_options)
    .then((result) => {
      this.altaCliente( idx );
        if (result.value) {
            noInternet().then(offline => {
                if (offline) {
                    this.utils.alertError('Verifica tu conexión');
                } else {
                    this.altaCliente( idx );
                }
            });
        } else if (result.dismiss === 'cancel') { }
  
    })
    .catch(() => console.log('canceled'))
  }

  // Esconde la barra de notificación
  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }


  // Obtiene todas las compaías Freemium registradas en el cms
  loadMembers(startLoader?) {
  if (typeof startLoader === 'undefined' || startLoader === true){
    this.ngxService.start();
  }
  this.profileService.getFreemiumCompanies().subscribe(
        response => { 
          console.log(response);
          this.ngxService.stop();
            this.loadMembersSuccess( response ); 
        },
        error => { 
          this.ngxService.stop();
        }
    );
  }

  // Clientes Freemium = Admin Independiente
  loadMembersSuccess( response: any ) {
    let code = Number(response.success);
    let data = response.data;
    let keys;
    let adminsInd = [];
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      keys = Object.keys(data);
      let i;
      for (i = 0; i < keys.length; i++) { 
        let key = keys[i];
        let Users = Object.keys(data[key][0].users);
        console.log( Users );
        let companyId =  data[key][0].id_company;
        let companyStatus = Number(data[key][0].active);
        let vehicleData = data[key][0].vehicles;
        const user = data[key][0].users;
        let userData;
        let toggleUserStatus:boolean;
        console.log(user);
        if (user) {
          let userNumber = Object.keys(user)[0];
          userData = user[userNumber][0];
          if (companyStatus === 1){
            toggleUserStatus = true;
          } 
          if (companyStatus === 0) {
            toggleUserStatus = false;
          }
          let client;
          if ( userData.last_name === 'null' || userData.last_name === null ) {
            client = userData.name;
          } else {
            client = userData.name + ' ' + userData.last_name;
          }
          let newUser = {
            created_at: userData.created_at,
            email: userData.email,
            id: userData.id,
            last_name: userData.last_name,
            name: userData.name,
            client: client,
            updated_at: userData.updated_at,
            user_status_description: userData.user_status_description,
            user_status_id: Number(userData.user_status_id),
            user_type: Number(userData.user_type),
            user_type_id: Number(userData.user_type_id),
            incidents: Number(vehicleData.incidents),
            total_vehicles: Number(vehicleData.total_vehicles),
            vehicles_with_kits: Number(vehicleData.vehicles_with_kits),
            vehicles_checked: Number(vehicleData.vehicles_checked),
            companyId: companyId,
            companyStatus: Number(userData.user_status_id),
            toggleUserStatus: toggleUserStatus
          }
          adminsInd.push( newUser ); 
        } else {
          console.log('Position num = ' + (i + 1));
          console.log( 'no hay usuario');
        }  
        if (adminsInd.length > 0) {
          this.createTable(adminsInd);
        }
        else {
          this.hasData = false;
        }
      }
    }
  }



  // Abre modal para borrar Compañia
  openDeleteDialog(company_id): void { 
    // console.log(company_id);
    const dialogRef = this.dialog.open(ModalEliminarCompaniaComponent,{
      panelClass: 'modal-send-notification',
      data: {
        company: company_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log( result );
      if (result === true) {
        this.openMessageDialog( true, 'El usuario fue borrado.' )
      } 
      if  (result === false) {
        this.openMessageDialog( false, 'Hubo un error al borrar al usuario.' )
      } 
    });
  }

  // Abre el modal para editar a un usuario
  openEditDialog(name, last_name, email, user_id, user_type_id): void { 
    const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
          name: name,
          last_name: last_name,
          email: email,
          user_id: user_id,
          user_type_id: user_type_id
        }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.showTableAlert = true;
        this.loadMembers();
      } else {
        this.showTableAlert = false;
      }
    });
  }

  // Modal usado para mostrar feedback al usuario sobre una operación realizada
  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      this.loadMembers();
    });
  }

  // Abre el modal para envíar notificación a usuario
  openSendNotificationInd(element): void {
    const dialogRef = this.dialog.open(ModalSendNotificationIndComponent,{
      panelClass: 'modal-send-notification',
      data : element
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != undefined){
        this.openNotificationStatusMessage( result )
      }
    });
  }

  // Muestra si una nitificación fue enviada o no
  openNotificationStatusMessage( success? ): void {
    let modal_success,
    modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
    }
    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }   
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      } 
    });
  }

  // Maneja status de usuarios (block, unblocks y borra)
  manageUser(user_id, status, name) {
    let new_status:number;
    if (status === 1) {
      new_status = 2;
    }
    if (status === 2)  {
      new_status = 1;
    }
    const data = {
      user_id: user_id,
      status: new_status
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-'+ name);
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageUser(data).subscribe(
      response => { 
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserError();
       }
    )
  }

  manageUserSuccess() {
    this.loadMembers(false);
  }
  
  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }
  
  // Sincroniza los paginadores superior e inferior
  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

}

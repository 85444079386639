import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalCancelarAltaClienteComponent } from '../../../shared/modals/modal-cancelar-alta-cliente/modal-cancelar-alta-cliente.component';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-form-alta-limites-vehiculos',
  templateUrl: './form-alta-limites-vehiculos.component.html',
  styleUrls: ['./form-alta-limites-vehiculos.component.css']
})
export class FormAltaLimitesVehiculosComponent implements OnInit {
  @Output() nextStep: EventEmitter<boolean>;
  @Output() goBack: EventEmitter<boolean>;

  success: boolean = null;
  message: string = null;
  vehicle_limits_errors: any = ValidationMessages.datosVehiculos.vehicle_limits;
  form: FormGroup;
  companyData: string;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private vehiclesService: VehiclesService,
    private ngxService: NgxUiLoaderService
  ) {
    this.nextStep = new EventEmitter();
    this.goBack = new EventEmitter();
    
   }

  ngOnInit() {
    this.companyData = JSON.parse(localStorage.getItem('NewCompany'));
    this.form = this.createForm( this.companyData );
    this.checkFormChanges();
  }


  createForm( data ) {
    return new FormGroup({
      fk_company : new FormControl( data.id_company ),
      vehicles_limit : new FormControl('', [
              Validators.required,
              CustomValidators.number,
              CustomValidators.digits,
              CustomValidators.gte(11)
      ])
    })
  }

  setVehicleLimit() {
    return new Promise((resolve, reject)=> {
      let data = this.form.value;
      this.ngxService.start();
      console.log(data);
      this.vehiclesService.updateVehicleLimit( data ).subscribe( 
        response => {
          this.ngxService.stop();
        // console.log(response);
          if (response.success === 0) {
            resolve('Limite de vehículos actualizado');
              this.success = false;
              this.message = response.message
          } else {
            this.success = true;
            this.message = response.message
            resolve('Limite de vehículos actualizado');
          }
           this.openDialog(this.success, this.message);
        }, 
        error => {
            this.ngxService.stop();
            this.success = false;
            this.message = 'El servicio no responde';
            this.openDialog(this.success, this.message);
            reject('Límite vehículos');
            // console.log(error); 
          })
    })
  }

  checkFormChanges() {
    this.form.statusChanges.subscribe( data => {
      console.log(data);   
    })
  }

  openDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.nextStep.emit( true );
      }
    });
  }

  openCancelDialog(): void {
    const companyId = JSON.parse(localStorage.getItem('NewCompany')).company_name;
    const dialogRef = this.dialog.open(ModalCancelarAltaClienteComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        user_name: companyId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.goBack.emit( true );
      }
    });
  }

}

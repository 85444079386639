import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { ShowBases } from '../../../interfaces/show-bases.interface';
import { Router } from '@angular/router';
import noInternet from 'no-internet';
import * as ValidationMessages from '../../../validators/validation-messages';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export class companiesDriversList {
    company: string
    company_id: number
    drivers: []
  }

@Component({
  selector: 'app-modal-editar-choferes',
  templateUrl: './modal-editar-choferes.component.html',
  styleUrls: ['./modal-editar-choferes.component.css']
})
export class ModalEditarChoferesComponent implements OnInit {
  changeCompanyOption : boolean = true;
  changeCompany : boolean = false;
  changeBase: boolean = false;
  formAltaChoferes: FormGroup;
  bases;
  showEditBaseOption = false;
  route;
  getCompaniesDrivers: companiesDriversList[];
  companiesDrivers:[];
  user_errors: any = ValidationMessages.staffMessages.name_errors;
  number_driver_errors: any = ValidationMessages.staffMessages.name_errors;
  cliente_errors: any = ValidationMessages.staffMessages.name_errors;
  base_errors: any = ValidationMessages.staffMessages.name_errors;
  selectedCompany;
  selected_company;
  isCompanySelected: boolean = false;
  companyBases= [];
  companiesId = [];
  serviceError:string;
  userCurrentBase;
  constructor(  public dialogRef: MatDialogRef<ModalEditarChoferesComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private profileService: ProfileService,
                private utils: UtilsService,
                private spinner: NgxSpinnerService,
                private vehicleService: VehiclesService,
                private router: Router,
                private ngxService: NgxUiLoaderService) { 
                  this.route = this.router.url
                  console.log(this.route);
                  this.setUpForm();
                  this.loadMembers();
                }

  ngOnInit() {
    Promise.all([ 
      this.loadBases(),
      this.loadCompanies()
        ])
        .then((value) => {
        if (value) {
          console.log(this.bases);
          // Se define si el usuario seleccionado tiene una base asignada o no
          let userBase;
          if (this.data.base) {
            userBase = this.data.base.base;
          } else {
            userBase = null;
          }
          const userCompany = this.data.company_name;
          let userCompanyBases = [];
          this.bases.forEach(element => {
            if (element.company_name === userCompany) {
              userCompanyBases.push(element);
            }
          });
          console.log(userCompanyBases);
          // Se ocupa el parámetro 'baseData' como temporal, eventualmente se pará el valor a la variable global 'userCurrentBase'
          let baseData;
          let i;
          // Si el usuario tiene una base asignada se usa el parámetro 'bases' para asignarle al formulario el id correcto de la base del usuario
          if (userBase && userCompanyBases.length > 0) {    
            for (i=0; i< userCompanyBases.length; i++){
              if (userCompanyBases[i].base_name === userBase && userCompanyBases[i].company_name === userCompany) {
                baseData = userCompanyBases[i]
                break;
              }
            }
            this.showEditBaseOption = true;
          } 
          // No tiene base asignada pero la compañía sí tiene bases registradas
          if (!userBase && userCompanyBases.length > 0) {
            this.showEditBaseOption = true;
          }
          // No tiene  base asignada y compaía No tiene bases registradas
          if (!userBase && userCompanyBases.length === 0) {
            this.showEditBaseOption = false;
          }
          console.log(baseData);
          this.userCurrentBase = baseData;
        }
    }, () => {
       console.log('stuff failed')
    });
  }

  setUpForm() {
    this.formAltaChoferes = this.fb.group({
      'name' : new FormControl( this.data.name,
          [
            Validators.required,
            // Validators.pattern('[a-zA-ZÀ-ÿ\u00f1\u00d1]*')
        ]
      ),
      'last_name' : new FormControl( this.data.last_name,
         [
            Validators.required,
            // Validators.pattern('[a-zA-ZÀ-ÿ\u00f1\u00d1]*')
          ]
      ),
      'driver_id' : new FormControl(this.data.driver_number,
      Validators.required
      ),
      'user_id' : new FormControl(this.data.id,
      Validators.required
      ),
      'company_id' : new FormControl(null,
      ),
      'type' : new FormControl(8,
      Validators.required
      ),
    });

    this.formAltaChoferes.controls['company_id'].valueChanges.subscribe(
      data =>{
        let company_data = data;
        console.log(company_data);
        var split = company_data.split("*company_id*");
        console.log(split);
        if (data != '') {
          console.log(split[0]);
          this.selectedCompany = split[0];
          this.companyBases = [];
          this.filterBases( this.selectedCompany);
        }  else {
          this.selectedCompany = false;
        }
      
      }
    )
  }

  moreFields(){
    setTimeout(() => {
      this.changeCompany = !this.changeCompany;
    }, 100);
  }

  filterBases( selectedCompany ) {
    const bases: any = this.bases;
    bases.forEach(element => {
      if ( element.company_name === selectedCompany ) {
        this.companyBases.push(element);
      }
    });
    if (this.companyBases === undefined || this.companyBases.length == 0) {
      // array empty or does not exist
      this.isCompanySelected = true;
      this.formAltaChoferes.removeControl('base');
      this.formAltaChoferes.updateValueAndValidity();
    } else {
      if (this.userCurrentBase) {
        this.formAltaChoferes.addControl('base', new FormControl(this.userCurrentBase.base_id, Validators.required));
      } else {
        this.formAltaChoferes.addControl('base', new FormControl('', Validators.required));
      }
      this.isCompanySelected = true;
    }
    console.log(this.formAltaChoferes.value);
    this.changeBase = true;
  }

  removeBaseFormControl() {
    this.changeBase = false;
    this.formAltaChoferes.removeControl('base');
    this.formAltaChoferes.updateValueAndValidity();
    this.companyBases = [];
  }

  onNoClick( result? ): void {
    this.dialogRef.close( result );
  }

  public editMember( form ) {
    noInternet().then(offline => {
        if (offline) {
            this.utils.alertError('Verifica tu conexión');
        } else {
            this.ngxService.startLoader('loader-modal');
            if (form.valid) {
              let id_company;
              if (form.value.company_id != null) {
                let company = form.value.company_id;
                let split_company = company.split("*company_id*");
                let i;
                let keys = Object.keys(this.companiesId)
                for (i=0; i <= keys.length ; i++) {
                  console.log(keys[i]);
                  if (keys[i] === split_company[0]) {
                      console.log('base win: ' + this.companiesId[keys[i]][0].id_company );
                      id_company = this.companiesId[keys[i]][0].id_company;
                      break;
                  }
                }
              }
              
              let data = {
                  name: form.value.name ,
                  last_name: form.value.last_name,
                  driver_id: form.value.driver_id,
                  id_company: id_company,
                  base_id: form.value.base,
                  user_id: form.value.user_id
              }
              if ( form.value.base === null || form.value.base === undefined ) {
                delete data.base_id;
              };
              if (form.value.company_id === null || form.value.company_id === undefined) {
                delete data.id_company
              }
              console.log(data);
                this.profileService.updateDriver( data ).subscribe(
                    response => { 
                      this.ngxService.stopLoader('loader-modal');
                      if (response.success != 1) {
                        this.editMemberError( response.message ); 
                      } else {
                        this.editMemberSuccess( response ); 
                      }
                    },
                    error => { 
                      this.ngxService.stopLoader('loader-modal');
                      this.editMemberError( error ); 
                    }
                );
            } 
        }
    })
  }

  refresh() {
      window.location.reload();
  }
  
  private editMemberSuccess( response: any) {  
    this.onNoClick( 'true' );
    console.log(response);
  }
  
  private editMemberError( error: any ) {
    this.serviceError = error;
    console.error(error);
  }

  // #Get Bases
  public loadBases() {
    return new Promise ((resolve, reject)=>{
      this.vehicleService.getBases().subscribe(
          response => { 
            let code = response.success;
            if (code != 1) {
                this.utils.errorManagement( response );
                reject(false);
            } else {
              this.bases = response.data;
              resolve(true);
            }
          },
          error => {  
            reject(false);
            console.error( error );
          }
      );
    })
  }


   // #Get Clients
   public loadCompanies() {
    return new Promise ((resolve, reject)=>{
      this.profileService.getCompaniesDrivers().subscribe(
          response => { 
            let code = response.success;
            console.log( response );
            if (code != 1) {
                this.utils.errorManagement( response );
                reject(false);
            } else {
              this.companiesDrivers = response.data;
              console.log(this.companiesDrivers);
              resolve(true);
            }
          },
          error => { 
            reject(false);
            console.error( error );
          }
      );
    })
  }


    // #Get users
    public loadMembers(startLoader?) {
      if (typeof startLoader === 'undefined' || startLoader === true){
        // this.ngxService.startLoader( this.masterLoader.loaderId )
      }
      this.profileService.getPremiumCompanies().subscribe(
          response => { 
            console.log(response);
            this.companiesId = response.data;
            // this.ngxService.stop();
          },
          error => {
            // this.ngxService.stop();
          }
      );
  }
}

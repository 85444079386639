import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, PageEvent} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalEliminarComponent } from '../../../shared/modals/modal-eliminar/modal-eliminar.component'; 
import { GetTeamSoltek } from '../../../interfaces/get-team-soltek';
import { ModalEditarEquipoSoltekComponent } from '../../modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalAltaInstaladorSupervisorComponent } from '../../../shared/modals/modal-alta-instalador-supervisor/modal-alta-instalador-supervisor.component'
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { FormControl } from '@angular/forms';
import { PaginatorService } from "../../../services/paginator.service";
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { ModalSendNotificationIndComponent } from '../../../shared/modals/modal-send-notification-ind/modal-send-notification-ind.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-cliente-edit-usuarios-table',
  templateUrl: './admin-cliente-edit-usuarios-table.component.html',
  styleUrls: ['./admin-cliente-edit-usuarios-table.component.css']
})
export class AdminClienteEditUsuariosTableComponent implements OnInit, OnChanges {
  @Input() clientData;
  @Input() addedNewUser;
  @Input() TypeOfUser;
  @Input() displayedColumns: string[] = ['name', 'email', 'user_status_id', 'acciones'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() toggleChange: EventEmitter<void> 
  @Input() checked: Boolean;
  @Input() idCompany: string;
  toggleUserStatus = new FormControl();
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  dataSource;
  Instalador:GetTeamSoltek[] = [];
  Supervisor:GetTeamSoltek[] = [];
  Administrador:GetTeamSoltek[] = [];
  Moderador:GetTeamSoltek[] = [];
  data;
  hasData: boolean = false;
  success: boolean;
  message: string;
  paginatorId = 'pAcEuT'
  paginatorChosenValue: number;

  constructor(public dialog: MatDialog,
              private profileService: ProfileService,
              private utils: UtilsService,
              private spinner: NgxSpinnerService,
              private ngxService: NgxUiLoaderService,
              private paginatorService: PaginatorService,
              private router: Router,
              ) { 
                this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
  }

  ngOnInit() {
    console.log(this.idCompany);
    this.spinner.show();
    this.addedNewUser = false;
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.Instalador = [];
    this.Supervisor = [];
    this.Administrador = [];
    this.Moderador = [];
    this.loadMembers();
    }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  openDeleteDialog(user_id): void { 
    console.log(user_id);
    const dialogRef = this.dialog.open(ModalEliminarComponent,{
      panelClass: 'modal-send-notification',
      data: {
        user_id: user_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      console.log( result );
                if (result[0] === true) {
                  this.openMessageDialog( true, result[1] )
                } 
                if  (result[0] === false) {
                  this.openMessageDialog( false, result[1] )
                } 
    });
  }

  openEditDialog(name, last_name, email, user_id): void { 
    const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
          name: name,
          last_name: last_name,
          email: email,
          user_id: user_id
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === true) {
        console.log( result );
        if (result) {
          this.message = 'El usuario fue editado correctamente.'
          this.success = true;
        } else {
          this.message = 'Hubo un error al editar el usuario.'
          this.success = false;
        }
        this.openMessageDialog(this.success, this.message);
      }
    });
  }

  // openSendNotification() Abre la imagen seleccionada en la tabla
  openSendNotificationInd(element): void {
    const dialogRef = this.dialog.open(ModalSendNotificationIndComponent,{
      panelClass: 'modal-send-notification',
      data : element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != undefined){
        this.openDialogMessage( result )
      }
    });
  }

  openDialogMessage( success? ): void {
    let modal_success,
    modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
    }
    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      } 
     

    });
  }


  // #Get Admins Ind
public loadMembers(startLoader?) {
  if (typeof startLoader === 'undefined' || startLoader === true){
    this.ngxService.start();
  }
  this.profileService.getCompanyMembers( this.idCompany ).subscribe(
    response => { 
      this.ngxService.stop();
      console.log(response);
      this.loadMembersSuccess( response ); 
    },
    error => { 
      this.ngxService.stop();
      console.log(error);
      this.loadMembersError( error ); 
    }
);
}
/*
  Load Members Callbacks
*/
private loadMembersSuccess( response: any ) {
  let code = Number(response.success);
  let data = response.data;
  let users = response.data.users;
  let headers;
  let keys = [];
  let supervisores = [];
  let instaladores = [];
  let administradores = [];
  let moderadores = [];
  let companyId = JSON.parse(localStorage.getItem('AdminCliente')).companyId;
  let companyName = JSON.parse(localStorage.getItem('AdminCliente')).name;
  // console.log( data );
  if (code != 1) {
    this.hasData = false;
    // this.utils.errorManagement( response );
  } else {
    headers = Object.keys(users);
    console.log(headers);
    users.forEach(user => {
      switch (Number(user.user_type_id)) {
          // user_type_id = 6 === Supervisor
          // user_type_id = 7 === Instalador
          // user_type_id = 2 === Administrador
          // user_type_id = 9 === Moderador
          case 6:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            supervisores.push(user);
            return; 
          case 7:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            instaladores.push(user);
            return; 
          case 2:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            administradores.push(user);
            return; 
          case 9:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            moderadores.push(user);
            return; 
          default:
            return;
      }
    });

    console.log(this.TypeOfUser);
    switch (this.TypeOfUser) {
      case 'Instalador':
        this.data = instaladores;
        break;
      case 'Supervisor':
        this.data = supervisores;
        break;
      case 'Administrador':
        this.data = administradores;
        break;
      case 'Moderador':
        this.data = moderadores;
        break;
     
      default:
        this.data = instaladores;
    }
 console.log(this.data);
  if (this.data.length <= 0 || this.data.length === null || this.data.length === undefined) {
    this.hasData = false;
  } else {
    this.hasData = true;
  }
  // This reverses the array so it shows the last users created first
  let reverseData = this.data.reverse();
  this.dataSource = new MatTableDataSource(reverseData);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  this.dataSource.filterPredicate = (data, filter: string) => {
    console.log(data.email);
    return data.email.toLowerCase().includes(filter) 
    || data.name.toLowerCase().includes(filter);
   };
  }
}

private loadMembersError( error: any ) {
  console.error( error );
}

refresh(){
  window.location.reload();
}

setUserStatusId(user_status_id) {
  // El retorno es el valor que se asigna a 'toggleUserStatus'
  switch (user_status_id) {
    case 1:
      return true;
    case 2:
      return false;
  }
};



// Manage User (block, unblocks and erases users)
public manageUser(user_id, status, name) {
  let new_status:number;
  if (status === 1) {
    new_status = 2;
  }
  if (status === 2) {
    new_status = 1;
  }
  const data = {
    user_id: user_id,
    status: new_status
  }
  console.log(data);
  this.ngxService.startLoader('loader-toggle-'+ name);
  // Status 1 = Active || 2 = Supended || 3 = Delete
  this.profileService.manageUser(data).subscribe(
    response => { 
      // this.ngxService.stopLoader('loader-toggle-'+ name);
      this.manageUserSuccess();
     },
    error => { 
      this.ngxService.stopLoader('loader-toggle-'+ name);
      this.manageUserError();
     }
  )
}

manageUserSuccess() {
  this.loadMembers(false);
}

manageUserError() {
  this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
}

  openDialogMember(): void {
    console.log();
    let id:number; 
    switch (this.TypeOfUser) {
      case 'Instalador':
        id = 7;
        break;
      case 'Supervisor':
        id = 4;
        break;
      case 'Administrador':
        id = 2;
        break;
      case 'Moderador':
        id = 9;
        break;
     
      default:
        id = 7;
    }
    let dialogRef = this.dialog.open(ModalAltaInstaladorSupervisorComponent,{
        data: {
          type : id,
          companyId: this.clientData.companyId
        }
      })

    dialogRef.afterClosed().subscribe(result => {
      console.log( result );
      if (result) {
        this.message = 'El usuario fue dado de alta correctamente.'
        this.success = true;
        this.openMessageDialog(this.success, this.message);
      } if (result === false) {
        this.message = 'Hubo un error al registrar el usuario.'
        this.success = false;
        this.openMessageDialog(this.success, this.message);
      } else if ( result === null) {

      }
     
    });
  } 

  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.loadMembers();
    });
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

}

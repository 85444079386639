import { Injectable } from '@angular/core';
let generator = require('generate-password-browser');

@Injectable({
  providedIn: 'root'
})
export class InputPasswordService {

  constructor() { }

  randomPassword(e){
    console.log( e )

    const password = generator.generate({
      length: 8,
      numbers: true,
      uppercase: true
    });
    e.controls.password.value = password;
    e.controls.password.setValue(password) ;

    e.controls.password.status = 'VALID';
    e.controls.password.touched = true;
    e.value.password = password;

    e.controls.password_confirmation.value = password;
    e.controls.password_confirmation.setValue(password) ;

    e.controls.password_confirmation.status = 'VALID';
    e.controls.password_confirmation.touched = true;

    e.value.password_confirmation = password;
    return true;
 } 

}  

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, InjectionToken, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule, MatSortModule, MatPaginatorModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatPaginator, MatPaginatorIntl, MatDialogModule, MatNativeDateModule, MatExpansionModule, MatSelectModule, MatDatepickerModule} from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatStepperModule, MatStepperIntl} from '@angular/material/stepper';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { CustomStepper } from './shared/tables/custom-stepper'

// Routes
import { APP_ROUTING } from './app.routes';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { LoginComponent } from './components/login/login.component';
import { RecoverpasswordComponent } from './components/recoverpassword/recoverpassword.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { GaugeComponent } from './components/dashboard/gauge.component';
import { AdminIndependienteComponent } from './components/admin-independiente/admin-independiente.component';
import { AdminTableComponent } from './shared/tables/admin-table/admin-table.component';
import { CustomPaginator } from './shared/tables/custom-paginator'
import { SectionsContainerComponent } from './shared/sections-container/sections-container.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FichaTecnicaTableComponent } from './shared/tables/ficha-tecnica-table/ficha-tecnica-table.component';


// Services
import { AuthService } from './services/auth.service';
import { LoginService } from './services/login.service';
import { RefreshTokenService } from './services/refresh-token.service';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from './app.utils';
import { SweetAlertService } from 'angular-sweetalert-service';
import { RecoverPasswordService } from './services/recover-password.service';
import { GaugeService } from './services/gauge.service';
import { ProfileService } from './services/users/profile.service';
import { SharingServiceService } from './services/sharing-service.service';


import { ToastrModule } from 'ngx-toastr';
import { AdminIndVehiculosRegistradosTableComponent } from './shared/tables/admin-ind-vehiculos-registrados-table/admin-ind-vehiculos-registrados-table.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ClipboardModule } from 'ngx-clipboard';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { AltaClientesComponent } from './components/alta-clientes/alta-clientes.component';
import { AltaNuevoClienteComponent } from './components/alta-nuevo-cliente/alta-nuevo-cliente.component';
import { EditarClienteComponent } from './components/editar-cliente/editar-cliente.component';


import { ShowPasswordDirective } from './directives/show-password.directive';
import { GeneratePasswordDirective } from './directives/generate-password.directive';
import { UserDataComponent } from './shared/forms/user-data/user-data.component';
import { ModalComponent, ExampleDialog } from './shared/modals/modal/modal.component';
import { DatosUsuarioDirective } from './directives/ficha-tecnica/datos-usuario.directive';
import { DetallePuntoRevisionComponent } from './components/detalle-punto-revision/detalle-punto-revision.component';
import { FullImageComponent } from './shared/modals/full-image/full-image.component';
import { DetallePuntoRevisionTableComponent } from './shared/tables/detalle-punto-revision-table/detalle-punto-revision-table.component';
import { TableAlertComponent } from './shared/tables/table-alert/table-alert.component';
import { ModalSendNotificationComponent } from './shared/modals/modal-send-notification/modal-send-notification.component';
import { NgDropFilesDirectiveDirective } from './directives/ng-drop-files/ng-drop-files-directive.directive';
import { AdminClienteComponent } from './components/admin-cliente/admin-cliente.component';
import { CatalogoVehiculosTableComponent } from './shared/tables/catalogo-vehiculos-table/catalogo-vehiculos-table.component';
import { ModelsTableComponent } from './shared/tables/catalogo-vehiculos-table/models-table/models-table.component';
import { ModalAltaMarcasModelosComponent } from './shared/modals/modal-alta-marcas-modelos/modal-alta-marcas-modelos.component';
import { ChoferesComponent } from './components/choferes/choferes.component';
import { DriverListTableComponent } from './shared/tables/driver-list-table/driver-list-table.component';
import { ModalAltaChoferesComponent } from './shared/modals/modal-alta-choferes/modal-alta-choferes.component';
import { ModalEditarChoferesComponent } from './shared/modals/modal-editar-choferes/modal-editar-choferes.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { NotificacionesTableComponent } from './shared/tables/notificaciones-table/notificaciones-table.component';
import { VehiculosComponent } from './components/vehiculos/vehiculos.component';
import { VehiculosTableComponent } from './shared/tables/vehiculos-table/vehiculos-table.component';
import { VehiculosDetalleTableComponent } from './shared/tables/vehiculos-detalle-table/vehiculos-detalle-table.component';
import { EquipoSoltekComponent } from './components/equipo-soltek/equipo-soltek.component';
import { MembersTableComponent } from './shared/tables/members-table/members-table.component';
import { ModalAltaEquipoSoltekComponent } from './shared/modals/modal-alta-equipo-soltek/modal-alta-equipo-soltek.component';
import { ModalEditarEquipoSoltekComponent } from './shared/modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component';
import { ModalEliminarComponent } from './shared/modals/modal-eliminar/modal-eliminar.component';
import { AdminClienteDetalleClienteComponent } from './components/admin-cliente-detalle-cliente/admin-cliente-detalle-cliente.component';
import { AdminClienteVehiculosRegistradosTableComponent } from './shared/tables/admin-cliente-vehiculos-registrados-table/admin-cliente-vehiculos-registrados-table.component';
import { AdminClienteUsuariosTableComponent } from './shared/tables/admin-cliente-usuarios-table/admin-cliente-usuarios-table.component';
import { AdminClienteEditUsuariosTableComponent } from './shared/tables/admin-cliente-edit-usuarios-table/admin-cliente-edit-usuarios-table.component';
import { ModalAltaVehiculoNewComponent } from './shared/modals/modal-alta-vehiculo-new/modal-alta-vehiculo-new.component';
import { ModalAltaVehiculoComponent } from './shared/modals/modal-alta-vehiculo/modal-alta-vehiculo.component';
import { ModalAltaInstaladorSupervisorComponent } from './shared/modals/modal-alta-instalador-supervisor/modal-alta-instalador-supervisor.component';
import { ModalCerrarSesionComponent } from './shared/modals/modal-cerrar-sesion/modal-cerrar-sesion.component';
import { ModalErroresFormularioComponent } from './shared/modals/modal-errores-formulario/modal-errores-formulario.component';
import { UpdateFormComponent } from './profile/update-form/update-form.component';
import { FormEditCompanyComponent } from './shared/forms/form-edit-company/form-edit-company.component';
import { FormEditVehicleLimitComponent } from './shared/forms/form-edit-vehicle-limit/form-edit-vehicle-limit.component';
import { FormEditBasesComponent } from './shared/forms/form-edit-bases/form-edit-bases.component';
import { ModalErroresEditarClientesComponent } from './shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderHttpModule } from  'ngx-ui-loader';
import { ModalEditarBaseComponent } from './shared/modals/modal-editar-base/modal-editar-base.component';
import { FormAltaNuevoClienteComponent } from './shared/forms/form-alta-nuevo-cliente/form-alta-nuevo-cliente.component';
import { FormAltaInstaladorSupervisorComponent } from './shared/forms/form-alta-instalador-supervisor/form-alta-instalador-supervisor.component';
import { FormAltaLimitesVehiculosComponent } from './shared/forms/form-alta-limites-vehiculos/form-alta-limites-vehiculos.component';
import { FormAltaBasesComponent } from './shared/forms/form-alta-bases/form-alta-bases.component';
import { ModalCancelarAltaClienteComponent } from './shared/modals/modal-cancelar-alta-cliente/modal-cancelar-alta-cliente.component';
import { ModalEliminarCompaniaComponent } from './shared/modals/modal-eliminar-compania/modal-eliminar-compania.component';
import { VehiculosClienteComponent } from './components/vehiculos-cliente/vehiculos-cliente.component';
import { VehiculosClienteTableComponent } from './shared/tables/vehiculos-cliente-table/vehiculos-cliente-table.component';
import { ChoferesClienteComponent } from './components/choferes-cliente/choferes-cliente.component';
import { ModalAltaChoferesClienteComponent } from './shared/modals/modal-alta-choferes-cliente/modal-alta-choferes-cliente.component';
import { EquipoClienteComponent } from './components/equipo-cliente/equipo-cliente.component';
import {NgxPrintModule} from 'ngx-print';
import {MatTableFilterModule} from 'mat-table-filter';
import { NumberDirective } from './directives//number.directive';
import { ModalEditarPerfilComponent } from './shared/modals/modal-editar-perfil/modal-editar-perfil.component';
import { FormUpgradeClienteComponent } from './shared/forms/form-upgrade-cliente/form-upgrade-cliente.component';
import { ModalSendNotificationClientesComponent } from './shared/modals/modal-send-notification-clientes/modal-send-notification-clientes.component';
import { ModalSendNotificationIndComponent } from './shared/modals/modal-send-notification-ind/modal-send-notification-ind.component';
import { FormSearchFoliosComponent } from './shared/forms/form-search-folios/form-search-folios.component';
import { DriverDamagedListComponent } from './components/driver-damaged-list/driver-damaged-list.component';
import { DriverDamagePointComponent } from './shared/tables/driver-damage-point/driver-damage-point.component';
import { ModalEliminarVehiculoComponent } from './shared/modals/modal-eliminar-vehiculo/modal-eliminar-vehiculo.component';
import { DriverDamagedSupervisionComponent } from './shared/tables/driver-damaged-supervision/driver-damaged-supervision.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';
import { ModalAltaMiEquipoComponent } from './shared/modals/modal-alta-mi-equipo/modal-alta-mi-equipo.component';

import { ModalDosImagenesComponent } from './shared/modals/modal-dos-imagenes/modal-dos-imagenes.component';
import { TableUsersPremiumComponent } from './shared/tables/table-users-premium/table-users-premium.component';
import { TableUsersFreemiumComponent } from './shared/tables/table-users-freemium/table-users-freemium.component';
import { DragAndDropComponent } from './shared/drag-and-drop/drag-and-drop.component';
import { TestingGroundsComponent } from './components/testing-grounds/testing-grounds.component';
import { ModalCargaPuntosRevisionComponent } from './shared/modals/modal-carga-puntos-revision/modal-carga-puntos-revision.component';
import { DetallePuntoRevisionTableV2Component } from './shared/tables/detalle-punto-revision-table-v2/detalle-punto-revision-table-v2.component';


// import * as Sentry from '@sentry/browser';

// Sentry.init({
//   dsn: "https://7a1825ea475d44019b80697d73153f5c@o73838.ingest.sentry.io/5250821"
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error) {
//     const eventId = Sentry.captureException(error.originalError || error);
//     Sentry.showReportDialog({ eventId });
//   }
// }


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'orange',
  fgsColor: '#ff6600',
  pbColor: '#ff6600',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.chasingDots, // background spinner type
  fgsType: SPINNER.ballSpin, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  hasProgressBar: false
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SettingsComponent,
    LoginComponent,
    RecoverpasswordComponent,
    DashboardComponent,
    NavbarComponent,
    GaugeComponent,
    AdminIndependienteComponent,
    AdminTableComponent,
    ResetPasswordComponent,
    ProfileComponent,
    AdminIndVehiculosRegistradosTableComponent,
    SectionsContainerComponent,
    AltaClientesComponent,
    AltaNuevoClienteComponent,
    EditarClienteComponent,
    ShowPasswordDirective,
    GeneratePasswordDirective,
    UserDataComponent,
    ModalComponent,
    ExampleDialog,
    FichaTecnicaTableComponent,
    DatosUsuarioDirective,
    DetallePuntoRevisionComponent,
    FullImageComponent,
    DetallePuntoRevisionTableComponent,
    TableAlertComponent,
    ModalSendNotificationComponent,
    NgDropFilesDirectiveDirective,
    AdminClienteComponent,
    CatalogoVehiculosTableComponent,
    ModelsTableComponent,
    ModalAltaMarcasModelosComponent,
    ChoferesComponent,
    DriverListTableComponent,
    ModalAltaChoferesComponent,
    ModalEditarChoferesComponent,
    NotificacionesComponent,
    NotificacionesTableComponent,
    VehiculosComponent,
    VehiculosTableComponent,
    VehiculosDetalleTableComponent,
    EquipoSoltekComponent,
    MembersTableComponent,
    ModalAltaEquipoSoltekComponent,
    ModalAltaVehiculoComponent,
    ModalEditarEquipoSoltekComponent,
    ModalEliminarComponent,
    AdminClienteDetalleClienteComponent,
    AdminClienteVehiculosRegistradosTableComponent,
    AdminClienteUsuariosTableComponent,
    AdminClienteEditUsuariosTableComponent,
    ModalAltaVehiculoNewComponent,
    ModalAltaInstaladorSupervisorComponent,
    ModalCerrarSesionComponent,
    ModalErroresFormularioComponent,
    UpdateFormComponent,
    FormEditCompanyComponent,
    FormEditVehicleLimitComponent,
    FormEditBasesComponent,
    ModalErroresEditarClientesComponent,
    ModalEditarBaseComponent,
    FormAltaNuevoClienteComponent,
    FormAltaInstaladorSupervisorComponent,
    FormAltaLimitesVehiculosComponent,
    FormAltaBasesComponent,
    ModalCancelarAltaClienteComponent,
    ModalEliminarCompaniaComponent,
    VehiculosClienteComponent,
    VehiculosClienteTableComponent,
    ChoferesClienteComponent,
    ModalAltaChoferesClienteComponent,
    EquipoClienteComponent,
    NumberDirective,
    ModalEditarPerfilComponent,
    FormUpgradeClienteComponent,
    ModalSendNotificationClientesComponent,
    ModalSendNotificationIndComponent,
    FormSearchFoliosComponent,
    DriverDamagedListComponent,
    DriverDamagePointComponent,
    ModalEliminarVehiculoComponent,
    DriverDamagedSupervisionComponent,
    AvisoPrivacidadComponent,
    ModalAltaMiEquipoComponent,
    ModalDosImagenesComponent,
    TableUsersPremiumComponent,
    TableUsersFreemiumComponent,
    DragAndDropComponent,
    TestingGroundsComponent,
    ModalCargaPuntosRevisionComponent,
    DetallePuntoRevisionTableV2Component,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    APP_ROUTING,
    ReactiveFormsModule,
    HttpModule,
    NgxErrorsModule,
    NoopAnimationsModule,
    ToastrModule.forRoot(),
    MatTableModule, 
    MatSortModule, 
    MatPaginatorModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatTabsModule,
    MatStepperModule,
    MatTooltipModule,
    NgxSpinnerModule, 
    ClipboardModule,
    FilterPipeModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxPrintModule,
    MatTableFilterModule
  ],
  providers: [
    AuthService,
    LoginService,
    RefreshTokenService,
    CookieService,
    UtilsService,
    SweetAlertService,
    RecoverPasswordService,
    GaugeService,  
    ProfileService,
    SharingServiceService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginator
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { 
        displayDefaultIndicatorType: true,
        showError: true
      }
    },
    { 
      provide: MatStepperIntl, 
      useClass: CustomStepper},
    // { 
    //   provide: ErrorHandler, 
    //   useClass: SentryErrorHandler 
    // }

  ],
  bootstrap: [
    AppComponent,
  ],
  entryComponents: [
    ExampleDialog,
    FullImageComponent,
    ModalComponent,
    ModalSendNotificationComponent,
    ModalAltaMarcasModelosComponent,
    ModalAltaChoferesComponent,
    ModalEditarChoferesComponent,
    ModalAltaEquipoSoltekComponent,
    ModalEditarEquipoSoltekComponent,
    ModalEliminarComponent,
    ModalAltaVehiculoNewComponent,
    ModalAltaInstaladorSupervisorComponent,
    ModalCerrarSesionComponent,
    ModalErroresFormularioComponent,
    ModalErroresEditarClientesComponent,
    ModalEditarBaseComponent,
    ModalCancelarAltaClienteComponent,
    ModalEliminarCompaniaComponent,
    ModalAltaChoferesClienteComponent,
    ModalEditarPerfilComponent,
    ModalSendNotificationClientesComponent,
    ModalSendNotificationIndComponent,
    ModalEliminarVehiculoComponent,
    ModalAltaMiEquipoComponent,
    ModalDosImagenesComponent,
    ModalAltaVehiculoComponent,
    ModalCargaPuntosRevisionComponent
  ]
})
export class AppModule { }

import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef} from '@angular/material';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CatalogVehicles } from '../../../../interfaces/catalog-vehicles.interface';

  // Sorting on this table is not working. Some issue about having two tables on the same view

@Component({
  selector: 'app-models-table',
  templateUrl: './models-table.component.html',
  styleUrls: ['./models-table.component.css']
})
export class ModelsTableComponent implements OnInit, OnChanges {
  @Input() addedNewModels: boolean = false;
  @Input() vehicles: CatalogVehicles[];
  @Input() displayedColumns: string[] = ['models'];
  alertMessage = {
    success: 'Se agregó exitosamente el modelo.',
    fail: 'Hubo un error al agregar el modelo.'
  }
  alertClass = true;
  showTableAlert: boolean = false;
  modelsDataSource;


  constructor() {
    this.showTableAlert = false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    console.log('a ver ' + this.addedNewModels)
    this.showTableAlert = this.addedNewModels;
    this.generateTable(this.vehicles);
  }

  generateTable(data){
    console.log(data);
    console.log(Array.prototype.reverse.apply(data));
    this.modelsDataSource = new MatTableDataSource(data);
    if (this.showTableAlert) {
      !this.showTableAlert
    }
  }

  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }


}

import { Component, OnInit, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.css']
})
export class VehiculosComponent implements OnInit {

  constructor () { 
  }

  ngOnInit() {
  }
}
 
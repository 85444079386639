import { Injectable } from '@angular/core';
import { Http, Headers, Response, CookieXSRFStrategy } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from '../global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private access_token: string = this.cookieService.get('access_token');
  private token_type: string = this.cookieService.get('token_type');

  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  uploadVehicles(data): any {
    const headers = new HttpHeaders({
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/staff/vehicle/upload_excel`;
    return this.httpClient.post( url, data,  {headers}  ).map( (response: Response) => response );
  }

  uploadBases(data): any {
    const headers = new HttpHeaders({
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/staff/upload_excel_bases`;
    return this.httpClient.post( url, data,  {headers}  ).map( (response: Response) => response );
  }

  uploadDrivers(data): any {
    const headers = new HttpHeaders({
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/staff/upload_excel_drivers`;
    return this.httpClient.post( url, data,  {headers}  ).map( (response: Response) => response );
  }

  uploadUsers(data): any {
    const headers = new HttpHeaders({
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/staff/upload_excel_users`;
    return this.httpClient.post( url, data,  {headers}  ).map( (response: Response) => response );
  }

  uploadPoints(data): any {
    const headers = new HttpHeaders({
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/install/create-install-others-excel`;
    return this.httpClient.post( url, data,  {headers}  ).map( (response: Response) => response );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RecoverPasswordService } from '../../services/recover-password.service';
import { SweetAlertService } from 'angular-sweetalert-service';
import { UtilsService } from '../../app.utils';
import * as ValidationMessages from '../../validators/validation-messages';
import noInternet from 'no-internet';

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.css']
})
export class RecoverpasswordComponent implements OnInit {
    
    recoverForm: FormGroup;
    restoreResponse: any;
    formHidden: boolean = false;
    email_errors: any = ValidationMessages.recoverPassMessages.email_errors;
    
    constructor( 
        private recoverPasswordService: RecoverPasswordService,
        private alertService: SweetAlertService, 
        private utils: UtilsService 
    ) {
    this.buildForm();
    }

    ngOnInit() {}

    buildForm() {
        this.recoverForm = new FormGroup({
            'email': new FormControl('', [
                Validators.required,
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
            ])
        });
    }
    
    public recoverPass( user: any ) {
        if (this.recoverForm.valid) {
            this.recoverPasswordService.recoverPassword( user ).subscribe(
                response => { this.recoverPassSuccess( response ); },
                error => { this.recoverPassError( error ); }
            );
        }
    }
    /*
        Recover Password Callbacks
    */
    private recoverPassSuccess( response: any ) {
        noInternet().then(offline => {
            if (offline) {
                this.utils.alertError('Verifica tu conexión');
            } else {
                this.restoreResponse = response.message;
                this.formHidden = true;
                let code = response.success;
                if (code != 1) {
                    this.utils.errorManagement( response );
                }
            }
        });
    }
    private recoverPassError( error: any ) {
        console.error( error );
    }

}

import { Component, OnInit, ElementRef, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { NgForm, FormsModule, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../app.utils';
import { ProfileService } from '../../services/users/profile.service';
import { VehiclesService } from '../../services/vehicles/vehicles.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


import { InputPasswordService } from '../../services/input-password.service';


import noInternet from 'no-internet';

import * as ValidationMessages from '../../validators/validation-messages';


import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import { ModalErroresFormularioComponent } from '../../shared/modals/modal-errores-formulario/modal-errores-formulario.component';


// #Validators
import { CustomValidators } from 'ng4-validators';
import { companiesDriversList } from '../../shared/modals/modal-alta-choferes/modal-alta-choferes.component';
import { not } from '@angular/compiler/src/output/output_ast';
import { DialogData } from '../../shared/modals/modal/modal.component';

@Component({
  selector: 'app-alta-clientes',
  templateUrl: './alta-clientes.component.html',
  styleUrls: ['./alta-clientes.component.css']
})

// Este flujo aún está pendiente, sigue en desarrollo 

export class AltaClientesComponent implements OnInit, OnChanges, OnDestroy {

  public form: FormGroup;
  public clientData: FormArray;
  // public customerData: FormArray;
  public adminData: FormArray;
  public modData: FormArray;
  public baseData: FormArray;
  public vehicleData: FormArray;
  public instData: FormArray;
  public superData: FormArray;
  public data;
  user: TablaAdminIndependiente [];
  companiesDrivers: companiesDriversList[];
  company_name;
  public memberForm: FormGroup;
  public member: any;
  public generatedPass: string = '';
  public _id;
  public moreData: boolean = false;
  userData: any;
  formErrors:boolean= false;
  formErrorsList = {
    bases : false,
    usuarios : false,
    vehiculos : false
  }


    /* Error Messages */
    name_errors: any = ValidationMessages.staffMessages.name_errors;
    email_errors: any = ValidationMessages.staffMessages.email_errors;
    pass_errors: any = ValidationMessages.staffMessages.pass_errors;
    passc_errors: any = ValidationMessages.staffMessages.passc_errors;
    type_errors: any = ValidationMessages.staffMessages.type_errors;
    base_errors: any = ValidationMessages.commonMessages.generic_errors;
    // createCustomerData(): FormGroup {
    //   return this.fb.group({
    //     company: [null, Validators.compose([Validators.required])],
    //   });
    // }

    createClientData(): FormGroup {
      return this.fb.group({
        name: [this.userData.name, Validators.compose([Validators.required])],
        id_user: [this.userData.id]
      });
    }

    createVehicleData(): FormGroup {
      return this.fb.group({
        vehicle_num: ['', Validators.compose([Validators.required])],
      });
    }



  // createAdminData(): FormGroup {
  //   return this.fb.group({
  //     email: [null, Validators.compose([
  //         Validators.required, 
  //         Validators.email
  //     ])],
  //     name: [null, Validators.compose([Validators.required])],
  //     last_name: ['Perez', Validators.compose([Validators.required])],
  //     password: [null, Validators.compose([
  //         Validators.required, 
  //         Validators.minLength[6]
  //     ])],
  //     password_confirmation: [null, Validators.compose([
  //         Validators.required, 
  //         Validators.minLength[6],
  //     ])],
  //     type: ['3', Validators.compose([Validators.required])],
  //   });
  // }


  createBaseData(): FormGroup {
    return this.fb.group({
      baseName: ['',[Validators.required]]
    });
  }

  createFormData( userType: number ){
    let password = new FormControl('', [Validators.required ] );
    let password_confirmation = new FormControl('', [ Validators.required,
                                                      CustomValidators.equalTo(password)
                                                    ]);
    return new FormGroup({

      'name' : new FormControl('', [Validators.required]),

      // 'last_name' : new FormControl('.'),

      'email' : new FormControl('', [
               Validators.required, 
                 Validators.email
            ]),

      'password' : password, 

      'password_confirmation' : password_confirmation,

      'type' : new FormControl(userType),
    })
  }

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private utils: UtilsService,
              private profileService: ProfileService,
              private router: Router,
              private readonly route: ActivatedRoute,
              private inputPasswordService: InputPasswordService,
              private cdr: ChangeDetectorRef,
              private vehiclesService: VehiclesService,
              public dialog: MatDialog,
             ) {

            this.userData = JSON.parse(localStorage.getItem('upgradeUser'));
            console.log(this.userData);
  }

  ngOnInit() {
      this.company_name = this.route.snapshot.paramMap.get("name");
      this.form = this.fb.group({        
        clientData: this.fb.array([this.createClientData()]),
        // customerData: this.fb.array([this.createCustomerData()]),
        vehicleData: this.fb.array([this.createVehicleData()]),
        adminData: this.fb.array([this.createFormData(2)]),
        modData: this.fb.array([this.createFormData(9)]),
        baseData: this.fb.array([this.createBaseData()]),
        // instData: this.fb.array([this.createFormData(7)]),
        // superData: this.fb.array([this.createFormData(6)])
      });
  
      this.clientData = this.form.get('clientData') as FormArray;
      // this.customerData = this.form.get('customerData') as FormArray;
      this.vehicleData = this.form.get('vehicleData') as FormArray;
      this.adminData = this.form.get('adminData') as FormArray;
      this.modData = this.form.get('modData') as FormArray;
      this.baseData = this.form.get('baseData') as FormArray;
      // this.instData = this.form.get('instData') as FormArray;
      // this.superData = this.form.get('superData') as FormArray;
  
      this.loadCompanies();
  
      // Esta función checa cada que hay un cambio en el form
      
      this.checkFormChanges();
  
//      console.log(this.form);
    }

    ngOnChanges() {
    }

    ngOnDestroy() {
      // Se asegura de quitar al usuario seleccionado al salir de la vista 
      this.userData = undefined;
      localStorage.removeItem('upgradeUser');
    }

    public randomPassword( e ) {
//      console.log(e.controls.password);
      this.inputPasswordService.randomPassword( e ); 
    }


// Ads input group
addData(index, $event) {
  $event.preventDefault();

  if (index === 'clientData'){
    this.modData.push(this.createClientData());
  }
  // if (index === 'customerData'){
  //   this.modData.push(this.createCustomerData());
  // }

  if (index === 'vehicleData'){
    this.vehicleData.push(this.createVehicleData());
  }

  if (index === 'modData'){
    this.modData.push(this.createFormData(9));
  }
  if (index === 'adminData'){
    this.adminData.push(this.createFormData(2));
  }

  if (index === 'baseData'){
    this.baseData.push(this.createBaseData());
  }

  if (index === 'instData'){
    this.instData.push(this.createFormData(7));
  }

  if (index === 'superData'){
    this.superData.push(this.createFormData(6));
  }
}


// remove input group
removeInput(index, position) {
  if (index === 'modData'){
    this.modData.removeAt(position);
  }
  if (index === 'adminData'){
    this.adminData.removeAt(position);
  }

  if (index === 'baseData'){
    this.baseData.removeAt(position);
  }

  if (index === 'instData'){
    this.instData.removeAt(position);
  }

  if (index === 'superData'){
    this.superData.removeAt(position);
  }
  
}

get clientDataFormGroup() {
  return this.form.get('clientData') as FormArray;
}

// get customerDataFormGroup() {
//   return this.form.get('customerData') as FormArray;
// }

get vehicleDataFormGroup() {
  return this.form.get('vehicleData') as FormArray;
}
  get adminDataFormGroup() {
    return this.form.get('adminData') as FormArray;
  }

  get modDataFormGroup() {
    return this.form.get('modData') as FormArray;
  }

  get baseDataFormGroup() {
    return this.form.get('baseData') as FormArray;
  }

  get instDataFormGroup() {
    return this.form.get('instData') as FormArray;
  }

  get superDataFormGroup() {
    return this.form.get('superData') as FormArray;
  }
 
// Obtiene posición del arreglo del usuario seleccionado
getIndex(idx: number){
//  console.log(idx + 1);
  this.router.navigate(['/users/alta-cliente',idx + 1]);
}

public changeUserType( id$: string ){
  this.profileService.changeTypeUser( id$ ).subscribe(
    response => { this.changeTypeSuccess(response); },
    error => { this.changeTypeError(error); }
  );
}

private changeTypeSuccess( response: any ){
  let code = response.success;
  if ( code != 1 ) {
    this.utils.errorManagement( response );
  } else {
    this.utils.toasterSuccess( response );
  }
}

public moreFields( value: boolean ){
    if (value) {

      this.moreData = true;

      this.form.addControl(
        'instData', this.fb.array([this.createFormData(7)]),
      )

      this.form.addControl(
        'superData', this.fb.array([this.createFormData(6)]),
      )

      this.instData = this.form.get('instData') as FormArray;
      this.superData = this.form.get('superData') as FormArray;
  

    } else if ( !value ) {
      this.form.removeControl('instData');
      this.form.removeControl('superData');
      this.moreData = false;
    }
//    console.log(this.moreData);
}

private changeTypeError( error: any){
  console.log( error );
}

// #Get Clients
public loadCompanies() {
  this.profileService.getCompaniesDrivers().subscribe(
      response => { this.loadCompaniesSuccess( response ); },
      error => { this.loadCompaniesError( error ); } 
  );
}

/*
  Load Clients Callbacks
*/
private loadCompaniesSuccess( response: any ) {
  let code = response.success;
  if (code != 1) {
      this.utils.errorManagement( response );
  } else {
    this.companiesDrivers = response.data;
  }
}

private loadCompaniesError( error: any ) {
  console.error( error );
}

// Esta es la función que se usa para cambiar un admin independiente a admin cliente
public addMember( form ) {
  noInternet().then(offline => {
      if (offline) {
          this.utils.alertError('Verifica tu conexión');
      } else {
        // Checks if all emails and bases are valid and aren't dulpicated
        Promise.all([ 
                      this.checkEmails(),
                      this.checkBases(),
                    ])
                    .then((values) => {
          !this.formErrors ;
          console.log('all loaded YO', values);
          
          // Converts the Admin ind to Admin Cliente before anything else
          this.convertUser().then(( value ) => {
            console.log(value)
            // If conversion is a success, then simultaneously 
            // register users, update vehicle limit and create bases
            Promise.all([
              this.createBases(),
              this.createUsers(),
              this.setVehicleLimit()
            ]).then((values) => {
              console.log(values)
              console.log(this.formErrorsList);
              this.openDialog( this.formErrorsList, 'Algún error', 'Bases, Users o vehículos', true );
            }, function( error ){
              console.log(error);
              alert('Hubo un error en uno de los servicios utilizados.')
            });
            

          }, function ( error ) {
            // alert('Hubo un error en uno de los servicios utilizados.')
          });

        }, function() {
        });
      }
  })
}

private addMemberSuccess( response: any) {  

  console.log(response);

  let code = response.success;
  if (code != 1) {
      this.utils.errorManagement( response );
  } else {
      this.utils.toasterSuccess( response );
      this.form.reset();
      // this.router.navigate(['/users']);
  }
}

private addMemberError( error: any ) {
  console.error(error);
}

// FOR TESTING service: /api/staff/convert-user
  convertUser() {
    return new Promise((resolve, reject)=> {
    // let user = this.form.value.clientData[0];
      let user = {
        user_id: this.form.value.clientData[0].id_user,
        name_company: this.form.value.clientData[0].name
      }
      console.log(user);
      this.profileService.convertUser( user ).subscribe(
        response => { 
          console.log(response);     
          if (response.success === 1) {
            resolve(response.success);
          } else {
            this.openDialog( response.message, 'No fue posible cambiar este usuario a Cliente Premium, inténtalo de nuevo más tarde.', 'Convert User', false );
            reject(response.message);
          }
          
          // this.spinner.hide();    
        },
        error => { 
          this.openDialog( 'Hubo un problema, intenta de nuevo más tarde', 'Servicio no funcionando', 'Convert User', false  );
          reject('Hubo un error al consultar el servicio');
          console.log(error); 
          // this.loadMembersError( error ); 
          // this.spinner.hide(); 
        }
      );
    }
    )}

  checkEmails() {
    return new Promise((resolve, reject)=> {
        const controls = this.form.controls;
        let thisOne;
        let emailList = [];
        let seenDuplicate = false;
        let testObject = {};
        for (const name in controls ) {
          // console.log(name);
          let i;
          for ( i = 0; i < this.form.controls[name].value.length; i++ ) {
            // console.log(name);
            let emailValue = this.form.controls[name].value[i].email
            if ( emailValue != undefined && emailValue != ''){
                thisOne = {
                    id : name ,
                    number : i,
                    value : emailValue
                  }
                emailList.push(thisOne)
              }
            } 
        }
        //console.log(emailList);
          // test for duplicate emails
        emailList.map(function(item) {
          let itemPropertyName = item['value'];    
          if (itemPropertyName in testObject) {
            testObject[itemPropertyName].duplicate = true;
            item.duplicate = true;
            seenDuplicate = true;
            // console.log(item); 
          }
          else {
            testObject[itemPropertyName] = item;
            delete item.duplicate;
           
          }
        });    
    
        // console.log(seenDuplicate); 
        let duplicates = [];
        let i;
        for (i = 0; emailList[i] ; i++) {
          if ( emailList[i].duplicate === true ) {
              duplicates.push(  emailList[i]  );
          }

        }
        // console.log(duplicates);
        if ( seenDuplicate ) {
          //console.log(duplicates);          
          let i;
          for (i = 0; duplicates[i] ; i++) { 
            // this[duplicates[i].id]['controls'][duplicates[i].number]['controls'].email.setValue(duplicates[i].value );
            this[duplicates[i].id]['controls'][duplicates[i].number]['controls'].email.setErrors({'duplicate': true} );
          }
          reject('Hay correos duplicados');
          this.formErrors = true;

        } else {
          //console.log(emailList);
          let i;
          for (i = 0; emailList[i] ; i++) {
            if ( emailList[i].duplicate != true ) {
              this[emailList[i].id]['controls'][emailList[i].number]['controls'].email.setErrors(null);
            }
          }
          this.formErrors = false;
          resolve(true);
        }
    })
  }

  checkBases() {
    return new Promise((resolve, reject)=> {
      const bases = this.baseData.value;
      let baseList = [];
      let thisOne;
      let testObject = {};
      let seenDuplicate = false;
      console.log(bases);
      let i;
      for ( i=0; bases[i]; i++ ) {
      // console.log(bases[i]);
        thisOne = {
          baseNum: i,
          value: bases[i].baseName
        }
        baseList.push(thisOne)
      }
      console.log(baseList);
      baseList.map(function(item) {
        let itemPropertyName = item['value'];    
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
          // console.log(item); 
        }
        else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      });   
      let duplicates = [];
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate === true ) {
              duplicates.push(  baseList[i]  );
          }
        }
      if ( seenDuplicate ) {
        console.log(duplicates);
    //    console.log(this.baseData['controls'][0]['controls'].baseName);
        let i;
        for (i = 0; duplicates[i] ; i++) { 
          console.log(duplicates[i]);
          this.baseData['controls'][duplicates[i].baseNum]['controls']['baseName'].setErrors({'duplicate': true});
          // this.baseData[i]['controls'].baseName.setErrors({'duplicate': true} );
        }
        this.formErrors = true;
        reject('Hay bases duplicadas');
      } else {
        console.log(baseList);
        let i;
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate != true ) {
            this.baseData['controls'][baseList[i].baseNum]['controls']['baseName'].setErrors(null);
          }
        }
        this.formErrors = false;
        console.log(this.baseData);
        resolve(true);
        
      }
    })
  }

  createBases() {
    return new Promise((resolve, reject)=> {
      let baseData = this.baseData.value;
      baseData.forEach(element => {
  //      console.log(element.baseName);
        const data = {
          base : element.baseName,
          company_id: this.userData.companyId
        }

        this.vehiclesService.createBases( data ).subscribe(
          response => { 
  //          console.log(response);  
            // If base already exists or some service related error
            if (response.success === 0) {
              const data = {
                error : response.message,
                baseName : element.baseName,
                id: 'base'
              }
              this.formErrorsList.bases = true;
            } 
            // this.spinner.hide();    
            resolve('Servicio Crear Bases alcanzado');
          },
          error => { 
            // if service can't be reached
            const data = {
              error : 'El servicio no responde',
              baseName : element.baseName
            }
            this.formErrorsList.bases = true;
            reject('Bases');
  //          console.log(error); 
            // this.spinner.hide(); 
          }
        );

      });
    })
  }

  createUsers() {
    return new Promise((resolve, reject)=> {
      let data = [];
      this.adminData.value.forEach(element => {
        element.company_id = this.userData.companyId;
        data.push(element);
      });
      
      this.modData.value.forEach(element => {
        element.company_id = this.userData.companyId;
        data.push(element);
      });

      // if moreData === True it adds intalador and supervisor data to the object
      if (this.moreData) {
        this.instData.value.forEach(element => {
          element.company_id = this.userData.companyId;
          data.push(element);
        });
        this.superData.value.forEach(element => {
          element.company_id = this.userData.companyId;
          data.push(element);
        });
      }
       console.log(data);
      // 
      this.profileService.addMember( data ).subscribe( 
        response => {
    //        console.log(response);  
          if (response.success === 1){
            resolve('Crear usuarios success');
          } else {
            this.formErrorsList.usuarios = true;
            resolve('Crear usuarios: ' + response.message);
          }
        }, 
        error => {
          this.formErrorsList.usuarios = true;
          reject('Usuarios');
     //     console.log(error);
        } )

    })
  }

  setVehicleLimit() {
    return new Promise((resolve, reject)=> {
      let data = {
        fk_company :  this.userData.companyId,
        vehicles_limit : this.vehicleData.value[0].vehicle_num
      }
  //    console.log(data);
      this.vehiclesService.updateVehicleLimit( data ).subscribe( 
        response => {
  //        console.log(response);
          if (response.success === 0) {
            const data = {
                error : response.message,
              }
              this.formErrorsList.vehiculos = true;
            } 
   //         console.log(this.formErrorsList);
            // this.spinner.hide();    
            resolve('Limite de vehículos actualizado');
        }, 
        error => {
          const data = {
              error : 'El servicio no responde',
            }
            this.formErrorsList.vehiculos = true;
            reject('Límite vehículos');
   //         console.log(error); 
            // this.spinner.hide(); 
          })
    })
  }

  checkFormChanges() {
    this.form.valueChanges.subscribe( data => {
 //     console.log(data);
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
          if (controls.baseData.invalid){
            console.log('baseData invalid')
          }
      }
      console.log(invalid);

      Promise.all([ 
        this.checkEmails(),
        this.checkBases(),
                  ])
                  .then((values) => {

        !this.formErrors ;
        console.log('all loaded YO', values);
      }, function() {
        console.log('stuff failed')
      });

      if(this.form.valid){
//        console.log('form valid');
        this.formErrors = false;
      } 

     
    })
  }

  openDialog( message, typeOfError, step, stayOnForm ): void {
    const dialogRef = this.dialog.open(ModalErroresFormularioComponent,{
      panelClass: 'modal-send-notification',
      data : {
        message: message,
        typeOfError : typeOfError,
        step: step,
        stayOnForm: stayOnForm
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.goBack( result );
 //   console.log('The dialog was closed');
    });
  }

  goBack( goToScreen ) {
    localStorage.removeItem('upgradeUser');
    if (goToScreen === 'adminInd') {
      this.router.navigate(['/users']);
    }
    if ( goToScreen === 'adminCliente' ) {
      this.router.navigate(['/usersClientes']);
    }
  }


}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-modal-dos-imagenes',
  templateUrl: './modal-dos-imagenes.component.html',
  styleUrls: ['./modal-dos-imagenes.component.css']
})
export class ModalDosImagenesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalDosImagenesComponent>){
    }

  ngOnInit(){
    console.log(this.data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  openNewWindow(imageUrl): void {
    window.open(imageUrl, "_blank");
  }


}

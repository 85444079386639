import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FullImageComponent } from '../../../shared/modals/full-image/full-image.component'

@Component({
  selector: 'app-notificaciones-table',
  templateUrl: './notificaciones-table.component.html',
  styleUrls: ['./notificaciones-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class NotificacionesTableComponent implements OnInit {
  @Input() notifications;
  showTableAlert; // Used to display table alert. Pass boolean.
  alertClass = true; // Used to style table alert colors; true = green, false = red.
  alertMessage = {
    success: 'Se agregó exitosamente la notificación.',
    fail: 'Hubo un error al agregar la notificación.'
  }
  columnsToDisplay = ['title', 'sent_to', 'date', 'time'];
  expandedElement;
  dataSource;
  hasData: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(public dialog: MatDialog) { 
  }

  ngOnInit() {
    console.log(this.notifications);
    this.dataSource = new MatTableDataSource(this.notifications);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.hasData = true;
  }

    // openImage() Abre la imagen seleccionada en la tabla
    openImage(data): void {
      let imgLink = data;
      // console.log(imgLink);
      const dialogRef = this.dialog.open(FullImageComponent,{
        data: imgLink,
        panelClass: 'modal-full-image'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
      });
    }
    checkColumn(element) {
      // console.log(element);
      return element;
    }

  // Closes Alert Table
  closeTableAlert(data){
    this.showTableAlert = data;
  }


  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

}
import { Component, OnInit, } from '@angular/core';
import { GetTeamSoltek } from '../../interfaces/get-team-soltek';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalAltaMiEquipoComponent } from '../../shared/modals/modal-alta-mi-equipo/modal-alta-mi-equipo.component'; 
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ProfileService } from '../../services/users/profile.service';
import { arrayLength } from 'ng4-validators/src/app/array-length/validator';
import {FormControl} from '@angular/forms';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import * as moment from 'moment';
import { isFormattedError } from '@angular/compiler';


@Component({
  selector: 'app-equipo-cliente',
  templateUrl: './equipo-cliente.component.html',
  styleUrls: ['./equipo-cliente.component.css']
})
export class EquipoClienteComponent implements OnInit {
  selected = new FormControl(0);
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  SuperAdmin = [];
  Instalador = [];
  Supervisor = [];
  Moderador = [];
  company_id: string = JSON.parse(localStorage.getItem('AdminCliente')).companyId;
  dataHasLoaded: boolean = false;
  modal_success: boolean;
  modal_message: string;
  loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private ngxService: NgxUiLoaderService   
  ) { }

  ngOnInit() {
    this.loadMembers();
    // El valor de 'EquipoSoltek-TabLocation' se usa para mostrar el último tab que se seleccionó (en caso de que se recargue la página)
    if (localStorage.getItem('EquipoSoltek-TabLocation')) {
      let index = localStorage.getItem('EquipoSoltek-TabLocation') 
      this.selected.setValue(index); 
    }
  }

    // Obtiene la lista de usuarios
    public loadMembers( reload? ) {
      console.log(reload);
      if (typeof reload === 'undefined' || reload.toggleUserStatus === false || reload.toggleUserStatus === undefined){
        this.ngxService.start();
      }
      this.profileService.getCompanyMembers(this.company_id).subscribe(
          response => { 
            this.ngxService.stop();
            this.loadMembersSuccess( response ); 
          },
          error => { 
            this.ngxService.stop();
            this.loadMembersError( error ); 
          }
      );
    }
  
    /*
   loadMembers() Callbacks
  */
  // Se filtran el resto de las empresas usando el nombre de la compañía para solo mostrar los datos de la compañía autentificada
  private loadMembersSuccess( response: any ) {
    let code = response.success;
    const company_name = JSON.parse(localStorage.getItem('AdminCliente')).name;
    let data = response.data;
    console.log( data );
    if (code != 1) { } else {
      this.SuperAdmin = [];
      this.Instalador = [];
      this.Supervisor = [];
      this.Moderador = [];
      const users = data.users;
      users.forEach(user => {
        user.user_status_id = Number(user.user_status_id);
        user.is_principal =  Number(user.is_principal);
        user.user_type_id = Number(user.user_type_id);
        if (user.user_status_id === 1){
          user.toggleUserStatus = true;
        } 
        if (user.user_status_id === 2) {
          user.toggleUserStatus = false;
        }
        if (user.email === this.loggedUser.email) {
          user.is_principal = 1;
        } else {
          user.is_principal = 0;
        }
         // Dependiendo del 'user_type_id' se filtran los usuarios
         switch (user.user_type_id) {
          case 2:
            this.SuperAdmin.push(user)
            break;
          case 7:
              this.Instalador.push(user)
            break;
          case 6:
              this.Supervisor.push(user)
            break;
          case 9:
              this.Moderador.push(user)
            break;
          default:
            break;
        }
      });
      if (this.SuperAdmin.length > 1) {
        this.getFirstAdmin(this.SuperAdmin);
      }
      this.dataHasLoaded = true;
    }
  }

  // Para definir cual usuario es el principal, se realiza una comparación de la fecha de creación de usuarios
  // Se asume que el usuario principal es el usuario que se creó junto con la compañía, lo cual lo haría el usuario
  // fecha de creación más antigua
  getFirstAdmin(SuperAdminList) {
    console.log(SuperAdminList);
    const dates = [];
    SuperAdminList.forEach(element => {
      const split_date = element.created_at.split(' ')
      dates.push(split_date);
    });
    console.log(dates);
    let i;
    let isFirst;
    let beforeDate = [];
    for (i=0; i < dates.length; i++) {
      if ( dates[0][0] === dates[i][0]) { // Si la fecha es la misma, compara las horas
        const test = moment(dates[0][1]).isBefore(dates[i][1]);
        if (test) {
          isFirst = 0; // Es despues
        } else {
          isFirst = i; // Es antes
        }
      } else { // Si la fecha NO es la misma, compara las fechas
        const test = moment(dates[0][0]).isBefore(dates[i][0]);
        let iteration = i;
        if (test) {
          beforeDate.push(iteration);
        }
      }
    
    }
    // Se asigna el valor 1 a 'is_principal' al usuario que cumpla con las validaciones
    if (beforeDate.length > 0) {
      this.SuperAdmin[beforeDate[0]].is_principal = 1;
    } else {
      this.SuperAdmin[0].is_principal = 1;
    }
    // this.SuperAdmin[isFirst].is_principal = 1;
    console.log(this.SuperAdmin);
  }
  
  private loadMembersError( error: any ) {
    console.log(error);
  }
  
    openDialog(): void {
      const dialogRef = this.dialog.open(ModalAltaMiEquipoComponent,{
        panelClass: 'modal-send-notification',
        data: {
          company_id : this.company_id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!result) {
        }
        else {
          this.openDialogMessage( true );
        }
      });
    }
  
    // Se guarda el estado de actual del mat-tab para usarse si la vista es actualizada
    changeButton(a){ 
      console.log(a);
      localStorage.setItem('EquipoSoltek-TabLocation', a.index);
    }
  
    // Modal de alerta
    openDialogMessage( success ): void {
      if ( success || success === undefined ) {
        this.modal_success = true;
        this.modal_message = 'El usuario fue dado de alta correctamente'
      } else {
        this.modal_success = false;
        this.modal_message = 'Hubo un error al crear el usuario'
      }
      const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
        panelClass: 'modal-send-notification',
        disableClose: true,
        data : {
          message: this.modal_message,
          success: this.modal_success
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.loadMembers();
        }
      });
    }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChange } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectModule, MatFormFieldModule} from '@angular/material';
import { FullImageComponent } from '../../../shared/modals/full-image/full-image.component';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { NgxUiLoaderService} from 'ngx-ui-loader';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { MatTableFilter } from 'mat-table-filter';
import { LogoService } from '../../../services/logo.service';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

export class filterDate {
  created_at: string = '';
}

export class filterMovement {
  movement: string = '';
}

export class filterStatus {
  status: number = null;
}

export class filterFolios {
  foliate_stamp: [string] = [''];
}

export class tableRow {
  status: filterStatus;
  created_at: filterDate;
  movement: filterMovement;
  foliate_stamp: filterFolios
}

declare global {
  interface Window { chrome: any; }
}

@Component({
  selector: 'app-detalle-punto-revision-table-v2',
  templateUrl: './detalle-punto-revision-table-v2.component.html',
  styleUrls: ['./detalle-punto-revision-table-v2.component.css']
})
export class DetallePuntoRevisionTableV2Component implements OnInit {
  @Input() data;
  @Input() vehicle;
  @Input() pointDetail;
  @Output() reload: EventEmitter<object>;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['general', 'movimiento', 'fallaJustificada', 'ultimaFoto', 'status', 'foliate_stamp', 'fechaHora', 'comentarios'];
  
  filterEntity: tableRow;
  filterType: MatTableFilter;
  filterPlaceholder = 'Buscar por folios';
  filteredData = [];
  hasData: boolean = false;
  hasFilteredData: boolean = true;
  calendarFilter;
   // Variables que se usan para filtrado por fechas
  now = moment().startOf('month').format('YYYY-MM');
  oneMonth = moment().startOf('month').subtract(1, 'months').format('YYYY-MM');
  twoMonths = moment().startOf('month').subtract(2, 'months').format('YYYY-MM');
  current_month = moment().locale('es').format('MMMM').charAt(0).toUpperCase() + moment().locale('es').format('MMMM').slice(1);
  // Si isChrome = true se esconde el botón de 'imprimir' de vista, ya que no es compatible con el navegador
  isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  imagesProcessed = 0;
  addImageCount = 0;
  constructor(
      public dialog: MatDialog,
      private ngxService: NgxUiLoaderService,
      public vehiclesService: VehiclesService,
      private LogoService: LogoService,
  ) {
    this.reload = new EventEmitter();
  }

  ngOnInit() {
    console.log(this.data);
  }
  
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['data']; 
    console.log(change);
    this.createTable(change.currentValue);
  }


  createTable(data) {
    const preparedData = [];
    let elementPosition = 0;
    data.forEach((element) => {
      let data_split;
      if (element=== undefined || element === null) { } else {
        let date;
        let time;
        if ( element.updated_at !== null) { 
          data_split = element.updated_at.split(' '); 
        } 
        else { 
          data_split = element.created_at.split(' '); 
         }
         date = moment(data_split[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '');
         time = data_split[1].slice(0,5)
        if (element.movement === 'Instalación'){
          element.movement = "Registro"
        }
        // La columna de COMENTARIOS se agregaba antes solamente a la tabla que lo necesitaba, 
        // pero ahora que todo está en una sola tabla deberá de añadirse siempre
        // if (element.movement === 'Supervisión' && element.supervisor.comment !== null) {
        //   this.displayedColumns.push('comentarios');
        // }
        element.date = date;
        element.time = time;
        element.position = elementPosition + 1;
        elementPosition = elementPosition + 1
        preparedData.push(element);
      }
    });
    this.hasData = true;
    this.dataSource = new MatTableDataSource(preparedData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filterEntity = new tableRow();
    this.filterType = MatTableFilter.ANYWHERE;
    const filterData = JSON.parse(sessionStorage.getItem('dataSource-filter-detalle-punto'));
    if (filterData) {
      this.filterEntity = filterData;
      this.filterPlaceholder = 'Filtro activado: "' + filterData.folios;
    }
  }

  // openDialog() Abre la imagen seleccionada en la tabla
    openDialog(data): void {
      let imgLink = data;
      console.log(imgLink);
      const dialogRef = this.dialog.open(FullImageComponent,{
        data: imgLink,
        panelClass: 'modal-full-image'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
   // Se usa para marcar un punto con supervisión y status dañado
  // EL administrador determina si la falla es o no justificada, por default todas las fallas son No justificadas
  changeJustifiedStatus( id ) {
    console.log(id);
    const data = {
      installation_point_id: id
    }
    this.ngxService.startLoader('loader-toggle-' + id);
    this.vehiclesService.justifiedFail(data).subscribe(
      response => {
        console.log(response);
        this.ngxService.stopLoader('loader-toggle-' + id);
        if ( response.success !== 1 ) {
          this.changeJustifiedStatusError( response );

        } else {
          this.changeJustifiedStatusSuccess();
        }
      },
      error => {
        console.log(error);
        this.ngxService.stopLoader('loader-toggle-' + id);
        this.changeJustifiedStatusError( error );
      }
    )
  }

  // Recarga la vista para reflejar los cambios realizados
  changeJustifiedStatusSuccess() {
    this.reload.emit( {
      reload: true,
      toggleUserStatus: true
    });
  }
  
  // Abre un alerta en un modal
  changeJustifiedStatusError( data ) {
    this.openMessageDialog( false, data.message );
  }
  
  // Cambia el status de un punto entre sin daño y con daño
  // 0 sin daño, 1 con daño
   changeStatus(status: number, id: number): void {
    const data = {
      id,
      status,
    }
    console.log(data);
      this.ngxService.startLoader('loader-toggle-status' + id);
      this.vehiclesService.changeOnePointInstallation(data).subscribe(
        response => {
         this.changeJustifiedStatusSuccess();
         this.ngxService.stopLoader('loader-toggle-status' + id);
        },
        error => {
        this.ngxService.stopLoader('loader-toggle-status' + id);
        this.changeJustifiedStatusError( error );
        }
      );
  }

    openMessageDialog( success, message ): void {
    let data = {
      message : message,
      success : success
    }
    console.log(data);
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  // Si no hay datos cuando se aplican los filtros se mostrará un mensaje
  checkDataSource(e?) {
    console.log(e);
    console.log(this.dataSource);
    console.log(this.filterEntity.foliate_stamp);
    sessionStorage.setItem('dataSource-filter-detalle-punto', JSON.stringify(this.dataSource.filter));
    setTimeout(() => {
      this.filteredData = this.dataSource.filteredData;
      if (this.filteredData.length <= 0) {
        this.hasFilteredData = false;
      } else {
        this.hasFilteredData = true;
      }
    }, 500);
  }

    // Esta función se usa para generar un pdf con la información de esta pantalla
  // El valor 'print' es un booleano que indica si será un archivo descargable (false) o imprimible (true)
  pdf(print) {
    if (!this.hasData) { } else {
      this.ngxService.start();
      let data;
      if (this.filteredData.length > 0) {
        data = this.filteredData;
      } else {
        data = this.data;
      }
      console.log(data);
      let col = [
        "Último movimiento",
        "Supervisor",
        "Estatus",
        "Folios",
        "Fecha",
        "Hora",
        "Comentarios",
        "Foto",
      ];
      let rows = [];
      let count = 0;
      data.forEach(element => {
        if (element.is_damaged === 1) {
          element.statusString = 'Dañado'
        } else {
          element.statusString = 'Sin daño'
        }

        if (element.foliate_stamp === null || element.foliate_stamp === undefined) {
          element.foliate_stampString = ['Sin datos']
        } else {
          console.log(element.foliate_stampString);
          element.foliate_stampString = element.foliate_stampString.replace(/<br>/g, ", ")
        }

        let imgUrl;
        if (element.image != null) {
          imgUrl = element.image;
        }

        if (element.image === null) {
          imgUrl = './assets/images/no-image.png'
        }
        if (element.supervisor === null) {
          element.supervisor = 'Sin datos'
        } else {
          if (element.movement !== "Supervisión") {
            element.comment = 'Sin datos'
          } else {
            if (element.supervisor.comment === null) {
              element.comment = 'Sin datos'
            } else {
              element.comment = element.supervisor.comment
            }
          }
        }

        this.getBase64ImageFromURL(imgUrl).subscribe(base64data => {
          let img64 = base64data;
          let temp = [
            element.movement,
            element.supervisor.supervisor_email,
            element.statusString,
            element.foliate_stampString,
            element.date,
            element.time,
            element.comment,
            img64,
          ];
          rows.push(temp);
          count++;

          if (count === data.length) {
            // console.log(rows); 
            this.createPdf(col, rows, print);
          }
        });

      });
    }

  }

  // Se usa la liga de la imagen para generar una imagen en base64 con el propósito de adjuntarla en el pdf
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = '*';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          // console.log(img);
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });

  }

  // Se genera un canvas, a 1/3 del tamaño de la imagen original para ahorrar tiempo de generación del pdf
  // y reducir el peso del documento a generar
  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width / 3;
    canvas.height = img.height / 3;
    var ctx = canvas.getContext("2d");
    this.roundedImage(ctx, 0, 0, canvas.width, canvas.height, 20);
    ctx.clip();
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  // Redondea las imagenes para mostrar en el pdf
  roundedImage(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }

  // Una vez concluido el proceso, se procede a usar el plugin 'jsPDF' para generar el pdf
  createPdf(col, rows, print?) {
    let pdfData = {
      vehicleBrand: this.vehicle.vehicle_brand_description,
      vehicleModel: this.vehicle.model_vehicle_description,
      vehicleSerialNumber: this.vehicle.serial_number,
      vehicleTanks: this.vehicle.tanks,
      vehicleEconomic: this.vehicle.economic_number,
      pointDetail: this.pointDetail.revision_point,
      pointGroup: this.pointDetail.revision_group,
      logoSoltek: this.LogoService.getLogoBlack()
    }
    let pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [712, 792],
    });
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    pdf.setFillColor(245, 245, 245);
    pdf.rect(0, 0, width, height, 'B');

    pdf.setFontSize(10);
    pdf.autoTable({
      margin: { top: 104 },
      styles: {
        cellWidth: 'wrap',
        minCellWidth: 70,
        minCellHeight: 100,
        fontSize: 7,
        cellPadding: [10, 10, 10, 10],
        valign: 'center',
        halign: 'center',
        fillColor: [255, 255, 255],
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: [255, 102, 0],
        minCellHeight: 32,
        halign: 'center',
        valign: 'middle',
        fontSize: 7,
        overflow: 'hidden',
        lineColor: [255, 102, 0],
        lineWidth: 0
      },
      columnStyles: {
        7: {
          textColor: [255, 255, 255],
          fontSize: 1,
          cellWidth: 110,
        },
        6: {
          overflow: 'linebreak',
          cellWidth: 100,
          valign: 'center',
          halign: 'left',

        },
      },

      head: [col],
      body: rows,
      didDrawCell: columnStyles => {
        if (columnStyles.section === 'head' && columnStyles.column.index === 0) {
          pdf.addImage(this.LogoService.getTableBorderRigth(), 'png', columnStyles.cell.x, (columnStyles.cell.y - .9), 30, 30, '', 'NONE', 0);
        }
        if (columnStyles.section === 'head' && columnStyles.column.index === 7) {
          console.log(columnStyles);
          pdf.addImage(this.LogoService.getTableBorderLeft(), 'png', (columnStyles.cell.x + columnStyles.column.width - 30), (columnStyles.cell.y - .9), 30, 30, '', 'NONE', 0);
        }
        if (columnStyles.section === 'body' && columnStyles.column.index === 7) {
          let img = columnStyles.row.cells[7].raw;
          pdf.addImage(img, 'jpg', columnStyles.cell.x + 10, columnStyles.cell.y + 10.05, 100, 70);
          this.addImageCount++;
          if (this.addImageCount === columnStyles.table.body.length) {
            var docName = 'Ficha_tecnica_SN=' + this.vehicle.serial_number + 'fecha-' + (moment()) + '.pdf';
            if (print) {
              setTimeout(() => {
                pdf.autoPrint();
                pdf.output('dataurlnewwindow');
                this.ngxService.stop();
              }, 1000);
            } else {
              setTimeout(() => {
                pdf.save(docName);
                this.ngxService.stop();
                this.addImageCount = 0;
                this.imagesProcessed = 0;
              }, 1000);
            }
          }
        }
        pdf.setDrawColor(245, 245, 245);
        if (columnStyles.section === 'body') {
          let cellWidth = columnStyles.cell.width;
          let cellHeigth = columnStyles.cell.height;
          let cellX = columnStyles.cell.x;
          let cellY = columnStyles.cell.y;
          pdf.setLineWidth(2);
          pdf.line(cellX, (cellY + cellHeigth), (cellX + cellWidth), (cellY + cellHeigth));
        }
      },
      didParseCell: columnStyles => {
        if (columnStyles.section === 'body' && columnStyles.column.index === 7) {
          columnStyles.cell.text = ''
        }
      },
      didDrawPage: () => {
        pdf.addImage(pdfData.logoSoltek, 'png', 594, 30, 80, 21, '', 'FAST', 0);
        pdf.setTextColor(244, 113, 22);
        pdf.setFontSize(14);
        pdf.text(('Detalle de ' + pdfData.pointDetail + ' en ' + pdfData.pointGroup), 38, 30);
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(8);
        pdf.text(('Marca: ' + pdfData.vehicleBrand + ', Modelo: ' + pdfData.vehicleModel + ', No. de Tanques: ' + pdfData.vehicleTanks), 38, 51);
        pdf.text(('No. Económico: ' + pdfData.vehicleEconomic), 38, 64);
        pdf.text(('No. de serie: ' + pdfData.vehicleSerialNumber), 38, 77);
        pdf.text(('Página ' + pdf.internal.getNumberOfPages()), 38, 772);

      },
      willDrawCell: (data) => {
        var rows = data.table.body;
        pdf.setFillColor(255, 255, 255);
        if (data.section === 'head') {
          pdf.setFillColor(255, 102, 0);
        }
        if (data.section === 'body') {
          pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(8);
        }
      }
    });
  }


}

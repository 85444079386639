import { Component, OnInit, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { GetTeamSoltek } from '../../interfaces/get-team-soltek';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalAltaVehiculoNewComponent } from '../../shared/modals/modal-alta-vehiculo-new/modal-alta-vehiculo-new.component'
import { ModalAltaInstaladorSupervisorComponent } from '../../shared/modals/modal-alta-instalador-supervisor/modal-alta-instalador-supervisor.component'
import { ModalAltaVehiculoComponent } from '../../shared/modals/modal-alta-vehiculo/modal-alta-vehiculo.component';
import { ModalCargaPuntosRevisionComponent } from '../../shared/modals/modal-carga-puntos-revision/modal-carga-puntos-revision.component';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import {FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../services/sharing-service.service";
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';


@Component({
  selector: 'app-admin-cliente-detalle-cliente',
  templateUrl: './admin-cliente-detalle-cliente.component.html',
  styleUrls: ['./admin-cliente-detalle-cliente.component.css']
})
export class AdminClienteDetalleClienteComponent implements OnInit, OnDestroy, OnChanges {
  selected = new FormControl(0);
  tabs = ['Vehículos registrados', 'Instaladores', 'Supervisores'];
  modalButton:boolean = false;
  detailClient: GetTeamSoltek[];
  id;
  user;
  companyId;
  TypeOfUser;
  addUser = false;
  reloadVehicleTable = false;
  data;
  showTableAlert:boolean;
  alertClass:boolean = true;
  addSuccessMessage: 'El usuario fue agregado correctamente.'
  alertMessage:any = {
    success: '',
    fail: 'Hubo un error al agregar el usuario.'
  }


  changeButton(a){ 
    console.log(a);
    this.addUser = false;
    console.log(this.addUser);
    
    localStorage.setItem('AdminClienteDetalleCliente-TabLocation', a.index);

    if (a.index === 2) {
      this.modalButton = true;
      this.id = 6;
    }
    if (a.index === 1) {
      this.modalButton = true;
      this.id = 7;
    }
    if (a.index === 0) {
      this.modalButton = false;
    }

  }

  setButton(){
      const buttonId = localStorage.getItem('AdminClienteDetalleCliente-TabLocation');
      if (buttonId === '2') {
        this.modalButton = true
        this.id = 6
      }
      if (buttonId === '1') {
        this.modalButton = true
        this.id = 7
      }
      if (buttonId === '0' || !buttonId ) {
        this.modalButton = false
      }
    }
  
  constructor(public dialog: MatDialog,
              private router: Router,
              private sharingService:SharingServiceService,
              ) { 
    this.data = this.sharingService.getData();
    console.log(this.data);
    this.setButton();
    // gets selected user data for use
    this.user = JSON.parse(localStorage.getItem('AdminCliente'));
    console.log(this.user);
    this.companyId = this.user.companyId;
    sessionStorage.removeItem('dataSource-filter-ficha-tecnica');
  }
  

  ngOnInit() {
    this.TypeOfUser = Number(localStorage.getItem('userType'));
    if (localStorage.getItem('AdminClienteDetalleCliente-TabLocation')) {
      let index = localStorage.getItem('AdminClienteDetalleCliente-TabLocation') // get stored number or zero if there is nothing stored
      this.selected.setValue(index); 
    }
  }
  ngOnChanges() {}

  ngOnDestroy() {
    localStorage.removeItem('AdminClienteDetalleCliente-TabLocation');
  }

  openDialogVehicle(): void {
    let dialogRef = this.dialog.open(ModalAltaVehiculoComponent,{
      data: {
        company_id: this.companyId,
        company_name: this.user.name
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log( 'Vehiculo dado de alta ' + result )
      console.log('The dialog was closed');
      this.reloadVehicleTable = result;
      console.log(this.reloadVehicleTable);
      setTimeout(() => {
        this.reloadVehicleTable = false;
      }, 300);
    });
  }

  openDialogPuntos(): void {
    let dialogRef = this.dialog.open(ModalCargaPuntosRevisionComponent,{
      data: {
        company_id: this.companyId,
        company_name: this.user.name
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.reloadVehicleTable = result;
      setTimeout(() => {
        this.reloadVehicleTable = false;
      }, 300);
    });
  }

  openDialogMember(id): void {
    console.log(id);
    let dialogRef = this.dialog.open(ModalAltaInstaladorSupervisorComponent,{
        data: {
          type : id,
          companyId: this.user.companyId
        }
      })
    this.addUser = false;

    dialogRef.afterClosed().subscribe(result => {
      console.log( result );
      this.router.navigate(['/detalle-cliente']);
      if (result) {
        this.openAlertModal( result );
      }
    });
  } 

  goToAdminCliente() {
    localStorage.removeItem('AdminCliente');
    this.router.navigate(['/usersClientes']);
  }

  openAlertModal( success ): void {
    let modal_success, modal_message;
    if ( success || success === undefined ) {
      modal_success = true;
      modal_message = 'El usuario fue dado de alta correctamente'
    } else {
      modal_success = false;
      modal_message = 'Hubo un error al crear el usuario'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.addUser = true;
      }
    });
  }


 
}


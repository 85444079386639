import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { UtilsService } from '../../../app.utils';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-eliminar-vehiculo',
  templateUrl: './modal-eliminar-vehiculo.component.html',
  styleUrls: ['./modal-eliminar-vehiculo.component.css']
})
export class ModalEliminarVehiculoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalEliminarVehiculoComponent>,
    private vehiclesService: VehiclesService,
    private utils: UtilsService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  public deleteVehicle() {
    const data = {
      vehicle_id: this.data,
      status: 3
    }
    this.ngxService.startLoader('loader-toggle-delete-modal');
    this.vehiclesService.deleteVehicle( data ).subscribe(
      response => {
        const data: any = response;
        if (data.success === 1){
          this.manageVehiclesSuccess();
        } else {
          this.manageVehiclesError();
        }
      },
      error => {
        this.manageVehiclesError();
      }
    )
  }

  onNoClick( success? ): void {
    this.dialogRef.close( success );
  }

  manageVehiclesSuccess() {
    this.dialogRef.close( true );
  }

  manageVehiclesError() {
    this.dialogRef.close( false );
  }
}

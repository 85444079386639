import { Injectable } from '@angular/core';
import { Http, Headers, Response, CookieXSRFStrategy } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from '../global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { VehicleAll } from '../../interfaces/vehicle-all.interface';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  private access_token: string = this.cookieService.get('access_token');
  private token_type: string = this.cookieService.get('token_type');

  constructor(private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService) { }

    // #Add Vehicle 
  addVehicle( member: VehicleAll ) {
    const body = JSON.stringify( member );
    const headers = new Headers({
        'Content-Type': 'application/json',
        // 'lang': 'es_mx',
        'Authorization': `${ this.token_type } ${ this.access_token }`
    });
  
    const url = `${ GLOBAL_URL }/api/staff/vehicle`;
  
    return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }

    // #Edit Vehicle 
    editVehicle( member ) {
      const body = JSON.stringify( member );
      const headers = new Headers({
          'Content-Type': 'application/json',
          'lang': 'es_mx',
          'Authorization': `${ this.token_type } ${ this.access_token }`
      });
      const url = `${ GLOBAL_URL }/api/staff/manage_vehicle`;
      return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
    }

    // #Get Vehicles List
 getVehiclesAll(): any {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/vehicle/all/all/all`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

  // #Get Vehicles List
 getVehicles( data ): any {
  console.log(data);
  if(typeof data === 'string') {
    data = JSON.parse(data);
  }

  let clientId = data.companyId;
  // const clientId = (JSON.parse(localStorage.getItem('AdminCliente')));
  console.log(clientId);
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/vehicle/` + clientId + `/all/all`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

  getVehiclesByFolio(data) {
    const body = data;
    const headers = new Headers({
        'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    console.log(body);
    const url = `${ GLOBAL_URL }/api/staff/search-foliate`;
    return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }

 // #Get Vehicles List
 getVehicleDetail( id_vehicle ): any {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/install/show-full-installation/`+ id_vehicle;
  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

// #Get Vehicles List
getPointDetail( id ): any {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/install/show-point-installation/`+ id;
  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

addBrandsModels( bm ) {
  const body = JSON.stringify(bm);
  const headers = new Headers({
      'Content-Type': 'application/json',
      // 'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  console.log(body);

  const url = `${ GLOBAL_URL }/api/staff/create-model-vehicle`;

  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
}

getModel(): any {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/catalog-vehicles`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );

}

getBases(): any {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/get-base`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );

}

// #Justified Fail
justifiedFail( id ) {
  const body = id;
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  console.log(body);
  const url = `${ GLOBAL_URL }/api/install/justify-installation-point`;
  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
}

 // #Add Bases 
 createBases( base ) {
    const body = base ;
    const headers = new Headers({
        'Authorization': `${ this.token_type } ${ this.access_token }`
    });
    const url = `${ GLOBAL_URL }/api/staff/create-base`;
    return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }

 // #Add Bases 
 editBase( base ) {
  const body = base ;
  const headers = new Headers({
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/staff/update-base`;
  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
}

// #Updates company vehicle limit
 updateVehicleLimit( vehicleLimit ) {
    const body = vehicleLimit ;
    const headers = new Headers({
          'Authorization': `${ this.token_type } ${ this.access_token }`
      });
      const url = `${ GLOBAL_URL }/api/staff/update-subscription-limit`;
      return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
    }
    
// # Change status point full installation
 changeStatusPointInstallation( data ) {
    const body = data ;
    const headers = new Headers({
          'Authorization': `${ this.token_type } ${ this.access_token }`
      });
      const url = `${ GLOBAL_URL }/api/install/change-status-point-full-installation`;
      return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
    }
// # Change status point full installation
 changeOnePointInstallation( data ) {
    const body = data ;
    const headers = new Headers({
          'Authorization': `${ this.token_type } ${ this.access_token }`
      });
      const url = `${ GLOBAL_URL }/api/install/change-point-installation`;
      return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
    }

  // # Shows vehicles general data
  showVehicleStatistics( ) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
  
    const url = `${ GLOBAL_URL }/api/staff/dashboard-vehicles`;
  
    return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
  }
  
  // # Gets the vehicle catalog
  getVehicleCatalog() {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
    });
  
    const url = `${ GLOBAL_URL }/api/staff/catalog-vehicles`;
  
    return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
  }
  
  getVehicleByDriver( id ) {
    const body = id ;
  const headers = new Headers({
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/staff/get-damage-vehicles`;
  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }

  deleteVehicle( vehicle_id ) {
    const body = vehicle_id ;
  const headers = new Headers({
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/staff/manage_vehicle`;
  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }

  changeImageOptions( values ) {
    const body = values ;
    const headers = new Headers({
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  console.log(body);
  const url = `${ GLOBAL_URL }/api/install/update-photo-point`;
  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
  }
  
}




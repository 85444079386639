import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';


@Component({
  selector: 'app-modal-cancelar-alta-cliente',
  templateUrl: './modal-cancelar-alta-cliente.component.html',
  styleUrls: ['./modal-cancelar-alta-cliente.component.css']
})
export class ModalCancelarAltaClienteComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService,
    private router: Router,
    public dialogRef: MatDialogRef<ModalCancelarAltaClienteComponent>
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  onNoClick( data ): void {
    this.dialogRef.close( data );
  }

}
 
import { Router } from '@angular/router';
import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import * as ValidationMessages from '../../../validators/validation-messages';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { UtilsService } from '../../../app.utils';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { MatAccordion } from '@angular/material';

@Component({
  selector: 'app-form-search-folios',
  templateUrl: './form-search-folios.component.html',
  styleUrls: ['./form-search-folios.component.css']
})
export class FormSearchFoliosComponent implements OnInit {
  form: FormGroup;
  form_errors: any = ValidationMessages.commonMessages.generic_errors;
  hasErrors = false;
  errorMessage: string;
  @Output() vehiclesList: EventEmitter<any>;
  @Output() removeFolioFilter: EventEmitter<Boolean>;
  @Input() activeFolioFilter;
  isExpanded = false;
  folioPlaceholder = 'Ej: 0001';
  constructor(
    private vehicleService: VehiclesService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) { 
    this.vehiclesList = new EventEmitter();
    this.removeFolioFilter = new EventEmitter();
    
  }

  ngOnInit() {
    if ( Number(localStorage.getItem('userType')) === 1) {
      this.form = this.createFormFolios(JSON.parse(localStorage.getItem('AdminCliente')).userId);
    } else {
      this.form = this.createFormFolios(localStorage.getItem('userId'));
    }
    console.log(this.activeFolioFilter);
    if (this.activeFolioFilter) {
     
      const folioData = JSON.parse(this.activeFolioFilter);
      this.folioPlaceholder = 'Filtro activado: "' + folioData.foliate_stamp +'"';
      this.isExpanded = true;
      this.getVehiclesByFolio(folioData);
    
    }
  }

  private createFormFolios( user_id ) {
    console.log(user_id)
    return new FormGroup({
      user_id: new FormControl( user_id ),
      foliate_stamp: new FormControl('',[
        // Validators.required
      ])
    })
  }
  
  public getVehiclesByFolio(data) {
    console.log(data);
    this.ngxService.startLoader('loader-toggle-folios');
    this.vehicleService.getVehiclesByFolio(data).subscribe(
      response => {
        this.ngxService.stopLoader('loader-toggle-folios');
        this.ngxService.stopAll();
        console.log(response);
        if (response.success !== 1) {
          this.hasErrors = true;
          this.errorMessage = response.message;
          this.hideErrorMessage();
        } else {
          if (response.data.length <= 0) {
            console.log(response.data.length);
            this.hasErrors = true;
            this.errorMessage = 'No hay vehículos con el folio <b>' + this.form.value.foliate_stamp + '</b>';
            this.hideErrorMessage();
          } else {
            this.vehiclesList.emit( [true, response.data] );
            this.hasErrors = false;
            if (this.form.value.foliate_stamp !== '') {
              sessionStorage.setItem('folio-' + this.router.url, JSON.stringify(this.form.value));
            }
          }
        } 
      },
      error => {
        this.ngxService.stopLoader('loader-toggle-folios');
        console.log(error);
        this.hasErrors = true;
        this.errorMessage = error.message;
        this.hideErrorMessage();
      }
    )
  }

  public removeFilter() {
    this.folioPlaceholder = 'Ej: 0001';
    this.removeFolioFilter.emit( true );
  }

  public hideErrorMessage() {
    setTimeout(() => {
      this.hasErrors = false;
      this.errorMessage = '';
    }, 3000);
  }

}

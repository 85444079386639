import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Gauge } from 'gaugeJS';
import { GaugeService } from '../../services/gauge.service';


@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css'],
})
export class GaugeComponent implements OnInit, OnChanges {
  @Input() userType;
  @Input() gaugeData;

  constructor( private _gaugeService:GaugeService ) {   
}

  ngOnInit() {}

  ngOnChanges() {
    console.log(this.gaugeData);
    setTimeout(() => {
      this._gaugeService.setupGauge( this.gaugeData );
    }, 100);
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgForm, FormsModule, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { InputPasswordService } from '../../../services/input-password.service';
import { ValidateEmptySpaces } from '../../../validators/additional_validators';
import { CustomValidators } from 'ng4-validators';
import { ProfileService } from '../../../services/users/profile.service';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalCancelarAltaClienteComponent } from '../../../shared/modals/modal-cancelar-alta-cliente/modal-cancelar-alta-cliente.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-form-alta-instalador-supervisor',
  templateUrl: './form-alta-instalador-supervisor.component.html',
  styleUrls: ['./form-alta-instalador-supervisor.component.css']
})
export class FormAltaInstaladorSupervisorComponent implements OnInit {

  @Output() nextStep: EventEmitter<boolean>;
  success: boolean = null;
  message: string = null;
  formDataCompany: FormGroup;
  name_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.staffMessages.email_errors;
  pass_errors: any = ValidationMessages.staffMessages.pass_errors;
  passc_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
  password = new FormControl('',  [      
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12)
  ]);
  password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.password)
  ]);
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  
  @Input() userTypeGroup1: number;
  @Input() userTypeGroup2: number;
  // If stepNum === 2 means is the instalador/supervisor step
  // If stepNum === 3 means is the admin/moderador step
  @Input() stepNum: number;
  @Output() goBack: EventEmitter<boolean>;
  public dataGroup1: FormArray;
  public dataGroup2: FormArray;
  public form: FormGroup;
  formErrors:boolean= false;
  moreData: boolean;


  constructor( 
    private profileService: ProfileService,
    private inputPasswordService: InputPasswordService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {
    this.nextStep = new EventEmitter();
    this.goBack = new EventEmitter();
   }

  ngOnInit() {
    console.log(this.stepNum);
    if ( this.stepNum == 2 ) {
      this.moreData = false;
    }
    if ( this.stepNum == 3 ) {
      this.moreData = true;
    }

    this.form = this.fb.group({
      dataGroup1: this.fb.array([this.createFormData(this.userTypeGroup1)]),
      dataGroup2: this.fb.array([this.createFormData(this.userTypeGroup2)])
    });

    this.dataGroup1 = this.form.get('dataGroup1') as FormArray;
    this.dataGroup2 = this.form.get('dataGroup2') as FormArray;
    this.checkFormChanges();
    // console.log(this.userTypeGroup1);
  }


// Ads input group
addData(index, $event) {
  $event.preventDefault();
  if (index === 'dataGroup1'){
    this.dataGroup1.push(this.createFormData(this.userTypeGroup1));
  }

  if (index === 'dataGroup2'){
    this.dataGroup2.push(this.createFormData(this.userTypeGroup2));
  }
}

// remove input group
removeInput(index, position) {
  if (index === 'dataGroup1'){
    this.dataGroup1.removeAt(position);
  }
  if (index === 'dataGroup2'){
    this.dataGroup2.removeAt(position);
  }
}

get dataGroup1FormGroup() {
  return this.form.get('dataGroup1') as FormArray;
}

get dataGroup2FormGroup() {
  return this.form.get('dataGroup2') as FormArray;
}

createFormData( userType: number ){
  let password = new FormControl('',  [     
    Validators.required, 
    Validators.pattern(this.regex),
    Validators.minLength(6),
    Validators.maxLength(12)
  ]);
  let password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(password)
  ]);
  return new FormGroup({
    'name' : new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*'),
      ValidateEmptySpaces
    ]),
    'email' : new FormControl('', [
              Validators.required, 
                Validators.email,
                CustomValidators.email
          ]),
    'password' : password, 
    'password_confirmation' : password_confirmation,
    'type' : new FormControl(userType),
  })
}

  checkFormChanges() {
    this.form.valueChanges.subscribe( data => {
  //     console.log(data);
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      console.log(invalid);
  
      Promise.all([ 
        this.checkEmails(),
      ]).then((values) => {
        !this.formErrors ;
        console.log('all loaded YO', values);
      }, function() {
        console.log('stuff failed')
      });
  
      if(this.form.valid){
  //        console.log('form valid');
        this.formErrors = false;
      } 
  
      
    })
  }

  public moreFields( value: boolean ){
    if (value) {

      this.moreData = true;

      this.form.addControl(
        'dataGroup1', this.fb.array([this.createFormData(7)]),
      )

      this.form.addControl(
        'dataGroup2', this.fb.array([this.createFormData(6)]),
      )

      this.dataGroup1 = this.form.get('dataGroup1') as FormArray;
      this.dataGroup2 = this.form.get('dataGroup2') as FormArray;
  

    } else if ( !value ) {
      this.form.removeControl('dataGroup1');
      this.form.removeControl('dataGroup2');
      this.moreData = false;
    }
//    console.log(this.moreData);
}

  randomPassword( e ) {
    //  console.log(e.controls.password);
      this.inputPasswordService.randomPassword( e ); 
  }
  
  checkEmails() {
    return new Promise((resolve, reject)=> {
        const controls = this.form.controls;
        let thisOne;
        let emailList = [];
        let seenDuplicate = false;
        let testObject = {};
        for (const name in controls ) {
          // console.log(name);
          let i;
          for ( i = 0; i < this.form.controls[name].value.length; i++ ) {
            // console.log(name);
            let emailValue = this.form.controls[name].value[i].email
            if ( emailValue != undefined && emailValue != ''){
                thisOne = {
                    id : name ,
                    number : i,
                    value : emailValue
                  }
                emailList.push(thisOne)
              }
            } 
        }
        //console.log(emailList);
          // test for duplicate emails
        emailList.map(function(item) {
          let itemPropertyName = item['value'];    
          if (itemPropertyName in testObject) {
            testObject[itemPropertyName].duplicate = true;
            item.duplicate = true;
            seenDuplicate = true;
            // console.log(item); 
          }
          else {
            testObject[itemPropertyName] = item;
            delete item.duplicate;
           
          }
        });    
    
        // console.log(seenDuplicate); 
        let duplicates = [];
        let i;
        for (i = 0; emailList[i] ; i++) {
          if ( emailList[i].duplicate === true ) {
              duplicates.push(  emailList[i]  );
          }

        }
        // console.log(duplicates);
        if ( seenDuplicate ) {
          //console.log(duplicates);          
          let i;
          for (i = 0; duplicates[i] ; i++) { 
            this[duplicates[i].id]['controls'][duplicates[i].number]['controls'].email.setErrors({'duplicate': true} );
          }
          reject('Hay correos duplicados');
          this.formErrors = true;

        } else {
          //console.log(emailList);
          let i;
          for (i = 0; emailList[i] ; i++) {
            if ( emailList[i].duplicate != true ) {
              this[emailList[i].id]['controls'][emailList[i].number]['controls'].email.setErrors(null);
            }
          }
          this.formErrors = false;
          resolve(true);
        }
    })
  }

  createUsers() {
    return new Promise((resolve, reject)=> {
      const companyId = JSON.parse(localStorage.getItem('NewCompany')).id_company;
      let data = [];
      // if moreData === True it adds intalador and supervisor data to the object
      if (this.moreData) {
        this.dataGroup1.value.forEach(element => {
          element.company_id = companyId;
          data.push(element);
        });
        this.dataGroup2.value.forEach(element => {
          element.company_id = companyId;
          data.push(element);
        });
      }
       console.log(data);
      // 
      this.profileService.addMember( data ).subscribe( 
        response => {
        this.ngxService.stop(); 
        resolve(response);
        }, 
        error => {
          this.ngxService.stop(); 
          reject(error);
           // console.log(error);
        } )

    })
  }

  submitForm() {
    console.log();
    if ( !this.moreData ) {
      this.nextStep.emit( true );
    } else {
      this.ngxService.start();
      this.createUsers().then((response:any) => {
        console.log(response);  
        this.success = true;
        this.ngxService.stop();
        if (response.status === 0) {
          this.success = false;
          if (response.message === "" || response.message === null) {
            this.message = "Hubo un error al dar de alta este usuario";
          } else {
            this.message = response.message;
          }
        } else {
  
          if (response.message === "" || response.message === null) {
            this.message = "El usuario fue dado de alta correctamente";
          } else {
            this.message = response.message;
          }
  
        }
        this.openDialog(this.success, this.message);
      }, function( error ){
        console.log(error); 
        this.ngxService.stop(); 
        this.success = false;
        if (error.message === "" || error.message === null) {
          this.message = "Hubo un error al dar de alta este usuario";
        } else {
          this.message = error.message;
        }
        this.openDialog(this.success, this.message);
      })
    }
  }

  openDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.nextStep.emit( true );
      }
    });
  }

  openCancelDialog(): void {
    const companyId = JSON.parse(localStorage.getItem('NewCompany')).company_name;
    const dialogRef = this.dialog.open(ModalCancelarAltaClienteComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        user_name: companyId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.goBack.emit( true );
      }
    });
  }
  
}

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';


@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

  private _token: string = this.cookieService.get('access_token');

  constructor(private http: Http, private cookieService: CookieService) { }

   // #Recover Password
   recoverPassword( email: any) {
    const body = JSON.stringify( email );
    const headers = new Headers({
        'Content-Type': 'application/json',
        'lang': 'es_mx'
    });

    const url = `${ GLOBAL_URL }/api/restore_password`;

    return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
}

}

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';


@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  constructor( private http: Http, private httpClient: HttpClient, private cookieService: CookieService ) { }

    refreshToken( refresh_token: any ) {
        const body = JSON.stringify(refresh_token);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const url = `${ GLOBAL_URL }/api/refresh`;
        return this.httpClient.post( url, body, { headers } ).map( (response: Response) => response );
    }
}

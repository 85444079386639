import { Component, OnChanges, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { companiesDriversList } from 'src/app/components/choferes/choferes.component';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { ModalEliminarComponent } from '../../modals/modal-eliminar/modal-eliminar.component';
import { ModalEditarChoferesComponent } from '../../../shared/modals/modal-editar-choferes/modal-editar-choferes.component'; 
import { FormControl } from '@angular/forms';
import { ModalEditarEquipoSoltekComponent } from '../../modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component';
import { eventNames } from 'cluster';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../../services/sharing-service.service";

@Component({
  selector: 'app-driver-list-table',
  templateUrl: './driver-list-table.component.html',
  styleUrls: ['./driver-list-table.component.css']
})

export class DriverListTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort) sort: MatSort ;
  @Input() driversCompanies;
  driversDataSource;
  hasData: boolean;
  @Input() displayedColumns: string[] = ['number', 'name', 'driver_id', 'base', 'status_description', 'grade', 'status', 'actions'];
  @Output() editedDriver: EventEmitter < Boolean >;
  @Output() deletedDriver: EventEmitter < Boolean >;
  @Output() toggleChange: EventEmitter<void> 
  @Input() checked: Boolean;
  applyFilter(filterValue: string) {
    this.driversDataSource.filter = filterValue.trim().toLowerCase();
  }
  toggleUserStatus = new FormControl();
  constructor(
    private profileService: ProfileService,
    private ngxService: NgxUiLoaderService,
    private utils: UtilsService,
    public dialog: MatDialog,
    public router: Router,
    public sharingService: SharingServiceService
  ) {
      this.editedDriver = new EventEmitter;
      this.deletedDriver = new EventEmitter;
      console.log(this.driversCompanies);
  }
  
  ngOnInit(){
    console.log(this.driversCompanies);
    // Usa los datos enviados del componente padre para mostrar los datos de los choferes.
    this.driversCompanies.forEach(element => {
      element.status = Number(element.status);
      // Redondea la calificación de los choferes
      if (element.score !== null && element.score < 100 && element.score > 0) {
        element.score = element.score.toFixed(1);
      }
      console.log(element);
      // 'toggleUserStatus' se usa para el switch de estatus de usuario
      if (element.status === 1){
        element.toggleUserStatus = true;
      } 
      if (element.status === 2) {
        element.toggleUserStatus = false;
      }
    });              
    if (this.driversCompanies.length > 0) {
      this.hasData = true;
      this.driversDataSource = new MatTableDataSource(this.driversCompanies);
      this.driversDataSource.filterPredicate = (data, filter: string) => {
        // Si alguno de los valores es null o undefined se debe cambiar a un string vacío o el filtro deja de funcionar
        const emptyvalues = Object.keys(data).filter(key => data[key] === null || data[key] === undefined || data[key] === '');
        for (const iterator of emptyvalues) {
          console.log(iterator);
          data[iterator] = '';
        }
        console.log(data);
        return data.name.toLowerCase().includes(filter)
        || data.driver_number.toLowerCase().includes(filter)
      };
      this.applyDatasource();
    } else {
      this.hasData = false;
    }
  }

  ngOnChanges(){
    if ( this.driversCompanies != null){
      this.driversCompanies.forEach(element => {
        element.status = Number(element.status);
        if (element.status === 1){
          element.toggleUserStatus = true;
        } 
        if (element.status === 2) {
          element.toggleUserStatus = false;
        }
      });
      if (this.driversCompanies.length > 0) {
        this.hasData = true;
        this.driversDataSource = new MatTableDataSource(this.driversCompanies);
        this.applyDatasource();
      } else {
        this.hasData = false;
      }
    }
  }

  ngOnDestroy(){
    console.log('destroy!');
    this.driversDataSource = '';
    this.driversCompanies = null;
  }


  applyDatasource(): void {
    setTimeout( (event) => {
      this.driversDataSource.sort = this.sort;
    }, 500);
  }
  
  ngAfterViewInit() {
    this.applyDatasource();
}

openEditDialog(element): void {
  console.log(element);
  const dialogRef = this.dialog.open(ModalEditarChoferesComponent,{
    panelClass: 'modal-send-notification',
    data: element
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    if (result) {
      this.editedDriver.emit(true);
    }
  });
}
openDeleteDialog(user_id): void { 
  console.log(user_id);
  const dialogRef = this.dialog.open(ModalEliminarComponent,{
    panelClass: 'modal-send-notification',
    data: {
      user_id: user_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    
    console.log( result );
    if (result[0] === true) {
      this.openMessageDialog( true, result[1] )
    } 
    if  (result[0] === false) {
      this.openMessageDialog( false, result[1] )
    } 
  });
}

refresh(){
  window.location.reload();
}

// Bloquea, desbloquea o borra usuarios
public manageUser(user_id, status, name,) {
  let new_status:number;
  if (status === 1) {
    new_status = 2;
  }
  if (status === 2) {
    new_status = 1;
  }
  const data = {
    user_id: user_id,
    status: new_status
  }
  console.log(data);
  this.ngxService.startLoader('loader-toggle-'+ name + user_id);
  // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageUser(data).subscribe(
      response => { 
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name + user_id);
        console.log(error);
        this.manageUserError();
       }
    )
  }
  
  manageUserSuccess() {
    this.editedDriver.emit(true);
  }
  
  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }

  goToDamagedVehicles( id ) {
    this.sharingService.setData( id );
    localStorage.setItem('driverId', id);
    this.router.navigate(['/vehiculos-danados-choferes'])
  }

  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.editedDriver.emit(true);
      }
    });
  }
}

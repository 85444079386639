import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-damaged-supervision',
  templateUrl: './driver-damaged-supervision.component.html',
  styleUrls: ['./driver-damaged-supervision.component.css']
})
export class DriverDamagedSupervisionComponent implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {
    console.log(this.data);
    if (this.data === undefined || this.data === null) { } else {
      this.data.forEach(element => {
        console.log(element);
      });
    }
  }

}

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private access_token: string = this.cookieService.get('access_token');
  private token_type: string = this.cookieService.get('token_type');

  getNewTokens() {
    this.access_token = this.cookieService.get('access_token');
    this.token_type = this.cookieService.get('token_type');
  }

  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  // #Get All sent notifications 
  getNotifications(): any {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${this.token_type} ${this.access_token}`
    });

    const url = `${GLOBAL_URL}/api/staff/get-push`;

    return this.http.get(url, { headers }).map((response: Response) => response.json());
  }

  // #Send notification
  sendNotification(form) {
    console.log('update profile')
    let formData = new FormData();
    formData.append('message', form.message);
    formData.append('user_type', `[` + form.user_type.toString() + `]`);
    formData.append('title', form.title);
    if (form.image !== "") {
      formData.append('image', form.image);
    }
    console.log(formData);
    const headers = ({
      'Authorization': `${this.token_type} ${this.access_token}`,
    });
    const url = `${GLOBAL_URL}/api/staff/send-push`;

    return this.httpClient.post(url, formData, { headers }).map((response: Response) => response);
  }

  // #Send notification
  sendNotificationClients(form) {
    console.log('update profile')
    let formData = new FormData();
    var userString = "[\"" + form.users.join("\",\"") + "\"]";
    console.log(userString);
    formData.append('message', form.message);
    formData.append('users', userString);
    formData.append('title', form.title);
    if (form.image !== "") {
      formData.append('image', form.image);
    }
    console.log(formData);
    const headers = ({
      'Authorization': `${this.token_type} ${this.access_token}`,
    });
    const url = `${GLOBAL_URL}/api/staff/send-push`;

    return this.httpClient.post(url, formData, { headers }).map((response: Response) => response);
  }

}

import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Inject, ChangeDetectorRef,ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PushService } from '../../../services/push.service';
import { FileItem } from '../../../directives/ng-drop-files/file-item';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../../validators/validation-messages';
import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-send-notification-ind',
  templateUrl: './modal-send-notification-ind.component.html',
  styleUrls: ['./modal-send-notification-ind.component.css']
})
export class ModalSendNotificationIndComponent implements OnInit {
  mouseOverDropzone = false;
  files: FileItem[] = [];
  hasImage = false;
  form: FormGroup;
  message_errors: any = ValidationMessages.contentMessages.body_errors;
  radio_errors: any = ValidationMessages.radios.radio_errors;
  route = this.router.url;
  @ViewChild('fileInput') el: ElementRef;
  editFile: boolean = true;
  imageUrl: any;
  uploadImage: boolean = false;
  userFormGroup = new FormGroup({
    'user_admin_cliente' :  new FormControl(null),
    'user_sup_cliente' : new FormControl(null),
    'user_inst_cliente' : new FormControl(null),
    'is_touched' : new FormControl(false, [Validators.required, Validators.requiredTrue])
  })

  constructor(
    public dialogRef: MatDialogRef<ModalSendNotificationIndComponent>,
    private cd: ChangeDetectorRef,
    private pushService: PushService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data);
    this.form = new FormGroup({
      'user' : new FormControl( this.data , Validators.required),
      'title' : new FormControl('',
                    Validators.required
      ),
      'message' : new FormControl('',
                    Validators.required
      ),
      'image': new FormControl('', []),
    })
   }

   uploadImages( files: FileItem[] ){ 
    console.log( files );
  }

  ngOnInit() {
   
  }

  onNoClick( result? ): void {
    console.log(result);
    if (result != undefined){
      this.dialogRef.close( result );
    } else {
      this.dialogRef.close();
    }
  }

  hasImages(event){
    console.log(event);
    this.hasImage = event;
  }


   // Function to upload a file uploaded file
   uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    console.log(file);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = (event:any) => {
        // console.log(reader.result);
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
        // console.log(reader.result);
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();   
  
      this.form.patchValue({
        image: file,
      })    
    }
    this.hasImage = true;
    console.log(this.form.value);
  }

  droppedFile(file) {
    console.log(file.file);
    let reader = new FileReader();
    reader.readAsDataURL(file.file);
      reader.onload = (event:any) => {
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
      }
      this.cd.markForCheck();   
  
      this.form.patchValue({
        image: file.file,
      })  
  }

  // Function to remove uploaded file
  removeUploadedFile() {
    this.form.patchValue({
      image: [null]
    });
    this.imageUrl = null;
    this.uploadImage = false;
    this.hasImage = false;

  }

  sendNotification(){
    this.ngxService.start();
    console.log(this.form.valid);
    if ( !this.form.valid ) {
  
    } else {
        console.log(this.form.value); 
        let data = {
          'image' : this.form.value.image,
          'message': this.form.value.message,
          'title': this.form.value.title,
          'users': [this.form.value.user]
        };
        console.log(data);
        this.pushService.sendNotificationClients( data ).subscribe(
          response => { 
            let callback:any = response;
            this.ngxService.stop();
            if (callback.success != 1){
              this.onNoClick( false );
  
            } else {
              this.onNoClick( true );
            }
          },
          error => { 
            this.onNoClick( false );
  
            this.ngxService.stop();
          }
      );
    }
  }

}

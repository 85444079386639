import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Inject } from '@angular/core';
import { ProfileService } from '../../../services/users/profile.service';
import { InputPasswordService } from '../../../services/input-password.service';

import { UtilsService } from '../../../app.utils';
import { Router } from '@angular/router';
import { TablaAdminIndependiente } from '../../../interfaces/user.interface';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-editar-equipo-soltek',
  templateUrl: './modal-editar-equipo-soltek.component.html',
  styleUrls: ['./modal-editar-equipo-soltek.component.css']
})
export class ModalEditarEquipoSoltekComponent implements OnInit {
  formEditarEquipoSoltek: FormGroup;
  public _id;
  public member: TablaAdminIndependiente;
  changePassword;

  user_errors: any = ValidationMessages.staffMessages.name_errors;
  last_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.loginMessages.user_errors;;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalEditarEquipoSoltekComponent>,
    private profileService: ProfileService,
    private utils: UtilsService,
    private router: Router,
    private inputPasswordService: InputPasswordService,
    private ngxService: NgxUiLoaderService){
      console.log(this.email_errors);
      const regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
      let password = new FormControl('',  [      
          Validators.pattern(regex),
          Validators.minLength(6),
          Validators.maxLength(12)
      ]);
      let password_confirmation = new FormControl('', [ 
        CustomValidators.equalTo(password)
      ]);
               
      this.formEditarEquipoSoltek = new FormGroup({
 
        'name' : new FormControl(''),
  
        'last_name' : new FormControl(''),
  
        'email' : new FormControl('', Validators.compose([
          Validators.email,
          CustomValidators.email
        ])),

        'password' : password, 

        'password_confirmation' : password_confirmation,

        'id' : new FormControl(''),
      })
      
      this.formEditarEquipoSoltek.controls['name'].statusChanges.subscribe(
        data =>{
          console.log(data);
        }
      )

      this.formEditarEquipoSoltek.valueChanges
  } 

  ngOnInit() {
    // Al iniciar la vista se pasan los valores existentes al formaulario
    this.formEditarEquipoSoltek.controls['name'].setValue(this.data.name);
    this.formEditarEquipoSoltek.controls['last_name'].setValue(this.data.last_name);
    this.formEditarEquipoSoltek.controls['email'].setValue(this.data.email);
    this.formEditarEquipoSoltek.controls['id'].setValue(this.data.user_id);

  }

  public randomPassword( e ) {
    this.inputPasswordService.randomPassword( e );
  }

  onNoClick( editUser? ): void {
    this.dialogRef.close( editUser );
  }

  refresh(){
    window.location.reload();
  }

  public updateMember( form ) {
    event.stopPropagation();
    // Si un campo se deja vacio, se manda el dato que se recibe al abrir el formulario
    if ( this.formEditarEquipoSoltek.valid ) {
      var dataToSendAsData = new FormData();
      if (form.value.name !== "") {
          dataToSendAsData.set("name", form.value.name);
      }
      if (form.value.last_name !== "") {
        dataToSendAsData.set("last_name", form.value.last_name);
      }
      if (form.value.last_name === null || form.value.last_name === "") {
          dataToSendAsData.set("last_name", '');
      }
      if (form.value.email !== "") {
          dataToSendAsData.set("email", form.value.email);
      }
      if (this.changePassword) {
          dataToSendAsData.set("password", form.value.password);
          dataToSendAsData.set("password_confirmation", form.value.password_confirmation)
      }
      
      dataToSendAsData.set("id", form.value.id)
      
      var object:any = {};
      dataToSendAsData.forEach((value, key) => {object[key] = value});
      console.log(object);
      if( object.last_name === ''){
        delete object.last_name
      }
      console.log(object);
      // Service Call
      this.ngxService.startLoader('loader-modal');
      this.profileService.updateMember( object ).subscribe(
              response => { 
                this.updateOnSuccess(response);
                this.ngxService.stopLoader('loader-modal');
              },
              error => { 
                this.updateOnError(error);
                this.ngxService.stopLoader('loader-modal');
              }
          );
    }  
}

private updateOnSuccess( response: any) {
  console.log(response);
    let code = response.success;
    if (code != 1) {
      this.onNoClick( false );
    } else {
        // this.utils.toasterSuccess( response );
      this.onNoClick( true );
    }
}

  private updateOnError( error: any) {
    this.onNoClick( false );
  }

}

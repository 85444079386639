import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { ValidateEmptySpaces } from '../../../validators/additional_validators';
import noInternet from 'no-internet';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalEditarBaseComponent } from '../../../shared/modals/modal-editar-base/modal-editar-base.component';
import {Location} from '@angular/common';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-form-edit-bases',
  templateUrl: './form-edit-bases.component.html',
  styleUrls: ['./form-edit-bases.component.css']
})
export class FormEditBasesComponent implements OnInit, OnChanges {
  @Input() clientData;
  form: FormGroup;
  success: boolean;
  message: string;
  @Output() reload: EventEmitter< boolean >;
  reset:boolean;
  public baseData: FormArray;
  base_errors: any = ValidationMessages.commonMessages.generic_errors;
  @Input() displayedColumns: string[] = ['name', 'acciones'];
  dataSource;
  bases = [];
  hasData: boolean = true;

  // Las siguientes variables son necesarias para usar Drag and Drop
  massiveUpload = false;
  DragAndDropFileOperation = 2; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc
  company_id = JSON.parse(localStorage.getItem('AdminCliente')).companyId;


  constructor(
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) { 
    this.form = this.fb.group({
      baseData: this.fb.array([this.createForm()])
    }) 
    this.reload = new EventEmitter();
  }

  ngOnInit() {
    console.log(this.clientData);
    this.baseData = this.form.get('baseData') as FormArray;
    // Esta función checa cada que hay un cambio en el form
    this.checkFormChanges();
  }

  ngOnChanges( changes: SimpleChanges) { 
    const clientData: SimpleChange = changes.clientData;
    console.log('prev value: ', clientData.previousValue);
    console.log('got name: ', clientData.currentValue);
    this.getBaseList();
    this.checkFormChanges();
  }

  createForm(): FormGroup {
    return this.fb.group ({
      // fk_company : new FormControl( clientData.companyId ),
      'baseName': new FormControl('' ,[
        Validators.required,
        ValidateEmptySpaces
      ])
    })
  }
  

  get baseDataFormGroup() {
    return this.form.get('baseData') as FormArray;
  }

  removeInput(position) {
      this.baseData.removeAt(position);
  }

  addData(index, $event) {
    $event.preventDefault();
    this.form.statusChanges;
    this.baseData.push(this.createForm());
  }

  checkBases() {
    return new Promise((resolve, reject)=> {
      const bases = this.baseData.value;
      let baseList = [];
      let thisOne;
      let testObject = {};
      let seenDuplicate = false;
      console.log(bases);
      let i;
      for ( i=0; bases[i]; i++ ) {
      // console.log(bases[i]);
        thisOne = {
          baseNum: i,
          value: bases[i].baseName
        }
        if (thisOne.value === "") {
          console.log('vacio');
        } else {
          baseList.push(thisOne)
        }
      }
      console.log(baseList);
      baseList.map(function(item) {
        let itemPropertyName = item['value'];    
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
          // console.log(item); 
        }
        else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      });   
      let duplicates = [];
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate === true ) {
              duplicates.push(  baseList[i]  );
          }
        }
      if ( seenDuplicate ) {
        console.log(duplicates);
    //    console.log(this.baseData['controls'][0]['controls'].baseName);
        let i;
        for (i = 0; duplicates[i] ; i++) { 
          console.log(duplicates[i]);
          this.baseData['controls'][duplicates[i].baseNum]['controls']['baseName'].setErrors({'duplicate': true});
          // this.baseData[i]['controls'].baseName.setErrors({'duplicate': true} );
        }
        reject('Hay bases duplicadas');
      } else {
        console.log(baseList);
        let i;
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate != true ) {
            this.baseData['controls'][baseList[i].baseNum]['controls']['baseName'].setErrors(null);
          }
        }
        console.log(this.baseData);
        resolve(true);
        
      }
    })
  }

  checkFormChanges() {
    this.form.valueChanges.subscribe( data => {
     console.log(data);
      console.log(this.form);
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
          if (controls.baseData.invalid){
            console.log('baseData invalid')
          }
      }
      console.log(invalid);

      Promise.all([ 
        this.checkBases(),
                  ])
                  .then((values) => {

        //  console.log('all loaded YO', values);
      }, function() {
        //  console.log('stuff failed')
      });

      if(this.form.valid){
        //  console.log('form valid');
      } 

     
    })
  }

  createBasesAll() {
    let responseValues;
    noInternet().then(offline => {
      if (offline) {
          alert('Verifica tu conexión');
      } else {
        this.ngxService.start();
        let baseData = this.baseData.value;
        let promises = [];
        const companyId = this.clientData.companyId;
        let vehicles = this.vehiclesService;

        baseData.forEach(element => {
          // console.log(element);
          promises.push( {
            baseName: element.baseName,
            companyId: companyId,
            vehicles: vehicles
          })
        })
        console.log(promises);
        Promise.all(promises.map(this.createBases))
          .then(response => {
            // console.log(response);
            let successList = [];
            let successString:string;

            let failList = [];
            let failString:string;
            response.forEach(element => {
              let data: any = element;
              if ( data.success ) {
                successList.push(data.baseName)
                this.success = false;
              } else {
                failList.push(data.baseName)
              }
            });

            if (successList.length >= 1) {
              successString = successList.toString().replace(/,/g, ", ");
            }
            if (failList.length >= 1) {
              failString = failList.toString().replace(/,/g, ", ");
            }

            if (successList.length  >= 1 && (failList === undefined || failList.length === 0 ||  failList === null)){
              this.message = `Las bases: <br><b>` + successList + `</b><br> fueron creadas correctamente.`;
              this.success = true;
            }

            if (successList.length  >= 1 &&  failList.length > 0 ){
              this.message = `Las bases: <br><b>` + successList + `</b><br> fueron creadas correctamente.<br><br> Hubo un problema al crear las siguientes bases: <br><b>` + failString +  `</b>. <br> Revisa que no existan bases con ese nombre.`;
              this.success = false;
            }

            if (( successList === undefined || successList.length === 0 ||  successList === null) &&  failList.length > 0 ){
              this.message =  
              `Hubo un problema al crear las siguientes bases: <br> <b>`
                + failString + 
              `</b>. <br> Revisa que no existan  bases con ese nombre.`;
              this.success = false;
            }
            this.ngxService.stop();
            this.form.reset();
            this.form = this.fb.group({
              baseData: this.fb.array([this.createForm()])
            }) 
            this.openDialog(this.success, this.message);
            // console.log(successList);
            // console.log(failString);
        })
       
      }
    })
  }
 
  createBases( baseData ) {

    return new Promise((resolve, reject)=> {
      // console.log(element.baseName);
        const data = {
          base : baseData.baseName,
          company_id: baseData.companyId
        }
        baseData.vehicles.createBases( data ).subscribe(
          response => { 
            let responseData;
          // console.log(response);  
          // If base already exists or some service related error
            if (response.success === 0) {
              responseData = {
                success: false,
                message : response.message,
                baseName : baseData.baseName,
              }
              // this.success = false;
              // this.message = response.message
            } else {
              responseData = {
                success: true,
                message : response.message,
                baseName : baseData.baseName,
              }
              
              // this.success = true;

            }
            resolve(responseData);
            // this.spinner.hide();    
          },
          error => { 
            // if service can't be reached
            let responseData = {
              success: false,
              message : 'Hubo un error con el servicio',
              baseName : baseData.baseName,
            }
            reject(responseData);
            // console.log(error); 
            // this.spinner.hide(); 
          }
        );
    })
  }

  getBaseList() {
    this.ngxService.start();
    this.vehiclesService.getBases().subscribe(
      response => {
        this.ngxService.stop();
        console.log(response.data);
        const data = response.data;
        let baseList: any = [];
        data.forEach(element => {
            if (element.company_name === this.clientData.companyName) {
              baseList.push(element);
              this.bases.push( element.base_name ); 
            }
        });
        console.log(baseList);
        if (baseList.length <= 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
        this.dataSource = new MatTableDataSource(baseList);

      }, error => {
        this.ngxService.stop();
        alert('Hubo un problema al cargar la lista de bases. Intenta de nuevo más tarde.')
      }
    )
  }

  openDialog( success, message ): void {
    if ( success || success === undefined ) {
      success = true;
      message = 'La(s) base(s) fueron dadas de alta correctamente'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.reset = result;
      this.checkForReload(this.reset);
      this.form.reset();
      this.getBaseList();

      // location.reload();
    });
  }

  openEditBaseDialog(base_id, name): void {
    
    const dialogRef = this.dialog.open(ModalEditarBaseComponent,{
      panelClass: 'modal-send-notification',
      // disableClose: true,
      data : {
        base_id: base_id,
        name: name,
        bases_list: this.bases
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.reset = result;
      if (this.reset == true) {
        this.bases = [];
        this.getBaseList();
      }
    });
  }

  checkForReload( reset ) {
    this.success= undefined;
    this.message= undefined;
    this.form.reset();
    this.baseData = this.form.get('baseData') as FormArray;
    this.reset = null;
  }

  cancelMassiveUpload(value) {
      if (value) {
        this.getBaseList();
      } 
      this.massiveUpload = false;
  }

  massiveUploadToggle() {
    this.massiveUpload = !this.massiveUpload;
    if (this.massiveUpload) {
      const element = document.getElementById('dragAndDrop');    
      setTimeout(() => {        
        element.scrollIntoView(false)
      }, 100);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { Router, Routes } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SweetAlertService } from 'angular-sweetalert-service';
import * as ValidationMessages from '../../validators/validation-messages';
import { UtilsService } from '../../app.utils';
import { CustomValidators } from 'ng4-validators';
import { ResetPasswordService } from '../../services/reset-password.service';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  member: any = {
    password: '',
    confirmpassword: ''
  };
  token:string;
  pass_errors: any = ValidationMessages.staffMessages.pass_errors;
  passc_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
  password = new FormControl('',  [      
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12)
  ]);
  password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.password)
  ]);
  constructor(
               private authService: AuthService,
               private resetPasswordService: ResetPasswordService,
               private utils: UtilsService,
               private router: Router,
               private alertService: SweetAlertService,
               private ngxService: NgxUiLoaderService,
               public dialog: MatDialog,
               private cookieService: CookieService,
    ) { 
      this.resetForm = this.createResetForm();  
      console.log(this.resetForm.value);
  }

  ngOnInit() {
    this.cookieService.deleteAll();
  }

  createResetForm() {
    const token =  this.router.url.replace('/reset-password/', '');
    return new FormGroup({
      'password': this.password,
      'password_confirmation': this.password_confirmation,
      'token' : new FormControl(token, [])
    })
  }

  resetPassword(value: any){
    if(this.resetForm.valid) {
    this.ngxService.startLoader('resetPasswordLoader');
    this.resetPasswordService.resetpassword(value)
    .subscribe(
      response => {
        this.ngxService.stopLoader('resetPasswordLoader');
        this.accessSuccess( response );
      }, 
      error => { 
        this.ngxService.stopLoader('resetPasswordLoader');
        this.accessError ( error ); 
      }
    );
    }
  }

  private accessSuccess( response: any ) {
        let code = response.success;
        if (code != 1) {
            this.openMessageDialog(false, response.message)
        } else {
            this.openMessageDialog(true, 'La contraseña se cambió correctamente.')
        }
}

private accessError( error: any ) {
  this.openMessageDialog(false, 'Hubo un error en el servicio. Intenta de nuevo más tarde.')
}

  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (success === true) {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { Http, Headers, Response, CookieXSRFStrategy } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from '../global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import { arrayLength } from 'ng4-validators/src/app/array-length/validator';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private access_token: string = this.cookieService.get('access_token');
  private token_type: string = this.cookieService.get('token_type');

  getNewTokens() {
    this.access_token = this.cookieService.get('access_token');
    this.token_type = this.cookieService.get('token_type');
  }

  constructor(private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService) { }

  // #GET PROFILE
  resetpassword( member: any) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'lang': 'es_mx',
        'Authorization': `${ this.token_type } ${ this.access_token }`
    });

    const url = `${ GLOBAL_URL }/api/profile`;

    return this.http.post( url, { headers }).map( (response: Response) => response.json() );
}

 // #Get Admins 
 getMembers(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/show-users`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

 // #Get Admins 
 getAdmins(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/dashboard-clients`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

 // Gets Freemium Companies 
 getFreemiumCompanies(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/staff/dashboard-freemium`;
  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

 // Gets Premium Companies 
 getPremiumCompanies(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  const url = `${ GLOBAL_URL }/api/staff/dashboard-premium`;
  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

// Get companies members
getCompanyMembers( idCompany ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`,
  });
  const url = `${ GLOBAL_URL }/api/staff/users_company`;
  const CompanyId = {
    company_id: idCompany
  }
  return this.http.post( url, CompanyId, { headers }).map( (response: Response) => response.json() );
}

// Get Soltek members
getSoltekMembers() {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${ this.token_type } ${ this.access_token }`,
  });
  const url = `${ GLOBAL_URL }/api/dashboard-soltek`;
  return this.http.get( url, { headers }).map( (response: Response) => response.json() );
}



// #Perfil
getProfile(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  console.log( headers);
  const url = `${ GLOBAL_URL }/api/profile`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

// #Add Staff Member
addMember( member ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`,
      'Cache-Control':  'no-cache',
      'Pragma': 'no-cache',
  });

  var obj_parse = JSON.parse(JSON.stringify(member));
  
  const url = `${ GLOBAL_URL }/api/staff/register`;
  console.log(obj_parse);
  return this.http.post( url, obj_parse, { headers }).map( (response: Response) => response.json() );
}

// #Add Driver Member
addDriver( member ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`,
      'Cache-Control':  'no-cache',
      'Pragma': 'no-cache',
  });

  
  const url = `${ GLOBAL_URL }/api/create_driver`;
  console.log(member);
  return this.http.post( url, member, { headers }).map( (response: Response) => response.json() );
}

// #Add New Freemium Company
addNewCompany( member ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  var member = member;
  const url = `${ GLOBAL_URL }/api/register`;
  return this.http.post( url, member, { headers }).map( (response: Response) => response.json() );
}

// # Premium Company
addNewPremiumCompany( member ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });
  var member = member;
  const url = `${ GLOBAL_URL }/api/register_premium`;
  return this.http.post( url, member, { headers }).map( (response: Response) => response.json() );
}

// #Update Profile
updateCompany( form ) {
  const body =  form ;
  console.log(body);
  const headers = new Headers({
      'Accept': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/update-company`;

  return this.http.post( url, body, { headers } ).map( (response: Response) => response.json() );
}

// #Update Profile
updateProfile( form ) {
  console.log('update profile')
  let formData = new FormData();
  const keys = Object.keys(form);
  console.log(keys);
  keys.forEach(element => {
    let data = form[element];
    console.log(data);
    if( data != "" ) {
      formData.append(element, form[element]);
    }
  });
  
  //formData.append('image1', form.image1);
 
  const headers = ({
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/update`;

  return this.httpClient.post( url, formData, { headers } ).map( (response: Response) => response );
}

 // #Update Staff Member
 updateMember( form ) {
  const body = JSON.stringify( form );
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/update-user`;

  return this.http.post( url, body, { headers } ).map( (response: Response) => response.json() );
}

// #Update Staff Member
updateDriver( form ) {
  const body = JSON.stringify( form );

  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/update/driver`;

  return this.http.post( url, body, { headers } ).map( (response: Response) => response.json() );
}

// GET Companies and Drivers
getCompaniesDrivers(): any {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/catalog-companies-drivers`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

// Change type user Admin ind - Adn cli
changeTypeUser( id$: string ){
  const headers = new Headers({
    'content-Type': 'application/json',
    'lang': 'es_mx',
    'Authorization': `${ this.token_type } ${ this.access_token }`
  })

  const url = `${ GLOBAL_URL }/api/staff/convert-user/${ id$ }`;

  return this.http.get( url, { headers } ).map( (response: Response) => response.json() );
}

// #Activate Account
activateAccount( id$: any ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
  });

  const url = `${ GLOBAL_URL }api/activate_account/${ id$ }`;

  return this.http.post( url, { headers }).map( (response: Response) => response.json() );
} 
 
// #Delete/Suspend Account
deleteUser( id$ ) {
  const body = id$.id;
  console.log(body);
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/disable/user/${ body }`;

  return this.httpClient.delete( url, { headers } ).map( (response: Response) => response );
}

// // #Delete/Suspend Account
// deleteAdminIndUser( data: string ) {
//   const body = data;
//   console.log(body);
//   // const headers = new HttpHeaders({
//   //     'Content-Type': 'application/json',
//   //     'lang': 'es_mx',
//   //     'Authorization': `${ this.token_type } ${ this.access_token }`
//   // });

//   // const url = `${ GLOBAL_URL }/api/staff/activate-company`;

//   // return this.httpClient.post( url,  body, { headers } ).map( (response: Response) => response );
// }

manageUser( data ) {
  const body = data;
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/manage_user`;
  return this.httpClient.post( url, body, { headers } ).map( (response: Response) => response );
}

manageCompany( data ) {
  const body = data;
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/manage_company`;
  return this.httpClient.post( url, body, { headers } ).map( (response: Response) => response );
}

disableUser( id$: string ) {
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/disable/${ id$ }`;

  return this.httpClient.delete( url, { headers } ).map( (response: Response) => response );
}

enableUser( id$: string ) {
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/activate-company/${ id$ }`;

  return this.http.get( url, { headers } ).map( (response: Response) => response);
}

// #Convierte Admin Ind a Admin Cliente
convertUser( data ) {
  const body = JSON.stringify(data);
  console.log(body);
  const headers = new Headers({
      'Content-Type': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${ this.token_type } ${ this.access_token }`
  });

  const url = `${ GLOBAL_URL }/api/staff/convert-user`;

  return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
} 

}

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalCerrarSesionComponent } from '../../shared/modals/modal-cerrar-sesion/modal-cerrar-sesion.component'
import { NgxUiLoaderService } from 'ngx-ui-loader';


import * as $ from 'jquery';
import { ProfileService } from '../../services/users/profile.service';
import { UtilsService } from '../../app.utils';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

  user: TablaAdminIndependiente [];
  user_type: number;
  user_company: string;
    // Menu List
    MenuItemList = [
      {
        id: 1,
        name: 'Admin Cliente'
      },
      {
        id: 2,
        name: 'Admin Independiente'
      },
      {
        id: 3,
        name: 'Vehículos'
      },
      {
        id: 4,
        name: 'Catálogo Vehículos'
      },
      {
        id: 5,
        name: 'Choferes'
      },
      {
        id: 6,
        name: 'Notificaciones'
      },
      {
        id: 7,
        name: 'Equipo Soltek'
      }
    ];

    // Dropdown menu
    DropdownItems = [
      {
        id: 1,
        name: 'Mi cuenta'
      }
    ];

  constructor(private cookieService: CookieService,
              private router: Router,
              private profileService: ProfileService,
              private utils: UtilsService,
              public dialog: MatDialog,
              private ngxService: NgxUiLoaderService,
              ) { }

  ngOnInit() {
    this.profileService.getNewTokens();
    this.loadProfile();
    // Change class if Menu Items are less than 7
    if ( this.MenuItemList.length < 7) {
      $('.navbar-menu-lg').removeClass('justify-content-around').addClass('justify-content-end');
      $('.logo-soltek-box').css('position', 'relative');
    }

  }
    
  openDialog(data?): void {
    this.ngxService.stop();
    const dialogRef = this.dialog.open(ModalCerrarSesionComponent, {
      disableClose: true,
      data: data
    });
  }

// #Get Profile
public loadProfile() {
  this.profileService.getProfile().subscribe(
      response => { this.loadProfileSuccess( response );
      },
      error => { 
        this.loadProfileError( error );
      } 
  );
}
/*
  Load Profile Callbacks
*/
private loadProfileSuccess( response: any ) {
  let code = response.success;
  if (code != 1) {
      this.utils.errorManagement( response );
  } else {
    this.user = response.data;  
    console.log(this.user);
    localStorage.setItem('userId', JSON.stringify(this.user[0].id ));
    this.user_company = response.data[0].name;  
    this.user_type = Number(this.user[0].user_type);
    console.log(this.user_company);
    if (response.data[0].image === null){
      response.data[0].image = './assets/images/icn-user-profile.png'
    }
    if (response.data[0].is_principal === 1) {
      let data = {
        email: response.data[0].email,
        is_principal: response.data[0].is_principal
      }
      localStorage.setItem('loggedUser', JSON.stringify( data ))
    } else {
      let data = {
        email: response.data[0].email,
        is_principal: 0
      }
      localStorage.setItem('loggedUser', JSON.stringify(data))
    }
  }
}

private loadProfileError( error: any ) {
  console.error( error );
  this.openDialog(true);
}

}
 
import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[showPassword]'
})
export class ShowPasswordDirective {

  private _shown = false;

  constructor(private el: ElementRef) {
    this.setupShowPassword();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = `<img class="img-fluid" src="../../assets/images/icn-eye-closed.png">`;
    } else  {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = `<img class="img-fluid" src="../../assets/images/icn-eye-open.png">`;
    }
  }

  setupShowPassword() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.className = 'input-show-password';
    span.innerHTML = `<img class="img-fluid" src="../../assets/images/icn-eye-open.png">`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}

import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectModule, MatFormFieldModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Location } from '@angular/common';
import { Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FullImageComponent } from '../../../shared/modals/full-image/full-image.component';
import { ModalDosImagenesComponent } from '../../../shared/modals/modal-dos-imagenes/modal-dos-imagenes.component';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { LogoService } from '../../../services/logo.service';
import { MatTableFilter } from 'mat-table-filter';
import { arrayLength } from 'ng4-validators/src/app/array-length/validator';
import { timeout } from 'q';
import { filter } from 'rxjs-compat/operator/filter';

interface fichaTecnica {
  last_mov: string,
  status: string,
  last_pic: string,
  revision_point: string,
  group_rev: object,
  folios: object,
  kit_seguridad: string,
  date: string,
  hour: string,
  random_image?: number
}

export class filterStatus {
  status: number = null;
}

export class filterMov {
  last_movement: string = '';
}

export class filterRevPoint {
  revision_point: string = '';
}

export class filterRevGroup {
  revision_group: string = '';
}

export class filterDate {
  created_at: string = '';
}

export class filterSecurity {
  security_kit_id: number = null;
}

export class filterFolios {
  folios: [string] = [''];
}

export class tableRow {
  security_kit_id: filterSecurity;
  created_at: filterDate;
  last_movement: filterMov;
  revision_group: filterRevGroup;
  revision_point: filterRevPoint;
  status: filterStatus;
  folios: filterFolios
}

@Component({
  selector: 'app-ficha-tecnica-table',
  templateUrl: './ficha-tecnica-table.component.html',
  styleUrls: ['./ficha-tecnica-table.component.css']
})



export class FichaTecnicaTableComponent implements OnInit, OnChanges {
  filterEntity: tableRow;
  filterType: MatTableFilter;
  userType;
  vehicle;
  user;
  displayedColumns;
  dataSource;
  moreFilters = false;
  calendarFilter;
  hasData: boolean = false;
  hasFilteredData: boolean = true;
  tableData = [];
  filteredData = [];
  filterPlaceholder = 'Buscar por folios';
  imagesProcessed = 0;
  addImageCount = 0;

  // Variables que se usan para filtrado por fechas
  now = moment().startOf('month').format('YYYY-MM');
  oneMonth = moment().startOf('month').subtract(1, 'months').format('YYYY-MM');
  twoMonths = moment().startOf('month').subtract(2, 'months').format('YYYY-MM');
  current_month = moment().locale('es').format('MMMM').charAt(0).toUpperCase() + moment().locale('es').format('MMMM').slice(1);
  // Si isChrome = true se esconde el botón de 'imprimir' de vista, ya que no es compatible con el navegador
  isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  // Previene varias llamadas al servicio /api/install/update-photo-point
  isDisabled = null;
  ViewingUserType = localStorage.getItem('ViewingUserType');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);

  }
  applyFilterCache(filterValue: string) {
    console.log(this.dataSource);
    console.log(this.dataSource.filter);

    this.dataSource.filter = {
      folios: filterValue
    }
    console.log(this.dataSource.filter);
  }


  constructor(
    public dialog: MatDialog,
    private vehicleService: VehiclesService,
    private ngxService: NgxUiLoaderService,
    private _location: Location,
    private router: Router,
    private sharingService: SharingServiceService,
    private LogoService: LogoService,
  ) {
    // Se obtiene los datos del vehículo de localStorage
    this.vehicle = JSON.parse(localStorage.getItem('SelectedVehicle'));
    // Se obtiene los datos del cliente de localStorage
    this.user = JSON.parse(localStorage.getItem('AdminCliente'));
    // Se obtiene el tipo de usuario autenticado de localStorage
    this.userType = Number(localStorage.getItem('userType'));
    this.loadVehicleDetail(this.vehicle.id_vehicle);
    this.displayedColumns = this.displayColumns()
    sessionStorage.removeItem('dataSource-filter-detalle-punto');
  }

  displayColumns() {
    // Para mostrar la columna 'randomImage' se usa la variable 'ViewingUserType', 
    // la cual se define cuando se entra desde la vista 'usersClientes' o 'users'
    if (this.userType === 1 && this.ViewingUserType === 'premium' || this.userType === 2 && this.ViewingUserType === 'premium') {
      return [
        'number',
        'ultimoMovimiento',
        'registerImage',
        'supervisionImage',
        'status',
        'randomImage',
        'puntoRev',
        'grupoRev',
        'folios',
        'fecha',
        'hora',
        'accion'
      ]
    } else {
      return [
        'number',
        'ultimoMovimiento',
        'registerImage',
        'supervisionImage',
        'status',
        'puntoRev',
        'grupoRev',
        'folios',
        'fecha',
        'hora',
        'accion'
      ]
    }
  }

  // openDialog() Abre la imagen seleccionada en la tabla
  openDialog(data): void {
    let imgLink = data;
    console.log(imgLink);
    const dialogRef = this.dialog.open(FullImageComponent, {
      data: imgLink,
      panelClass: 'modal-full-image'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // openDialog() Abre la imagen seleccionada en la tabla
  openDialogBothImages(data): void {
    let imgLink = data;
    console.log(imgLink);
    const dialogRef = this.dialog.open(ModalDosImagenesComponent, {
      data: imgLink,
      panelClass: 'modal-two-images'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  // Usa el servicio 'show-full-installation' para obtener la información de la tabla
  loadVehicleDetail(id_vehicle) {
    this.ngxService.start();
    this.vehicleService.getVehicleDetail(id_vehicle).subscribe(
      response => {
        this.loadVehiclesDetailsSuccess(response);
        this.ngxService.stop();
      },
      error => {
        this.loadVehiclesDetailsError(error);
        this.ngxService.stop();
      }
    );
  }

  loadVehiclesDetailsSuccess(response: any) {
    let data = response.data.installation_latest_groups;
    if (data.length === 0) {
      this.hasData = false;
    } else {
      const length = data.length;
      let iterationCount = 0;
      data.forEach(element => {
        // console.log(element);
        let changedDamagedStatus: number;
        // Un problema en angular no acepta el uso del valor '0', por lo que el valor '0' se reemplaza por '9'
        if (element.installation_points[0].status === 0) {
          changedDamagedStatus = 9;
        }
        if (element.installation_points[0].status === 1) {
          changedDamagedStatus = 1;
        }
        if (element.installation_points[0].status === null) {
          changedDamagedStatus = 2;
        }
        let folios;
        let folioObject = element.installation_points[0].foliate_stamp;
        if (folioObject != null) {
          folios = folioObject.join('<br>')
        }
        let data_split;
        let array;
        if (element.installation_points[0].movement === 'Instalación') {
          element.installation_points[0].movement = 'Registro'
        }
        if (element.installation_points[0].other != null) {
          element.name = element.installation_points[0].other
        }
        if (element.installation_points[0].created_at != null
          || element.installation_points[0].created_at != undefined) {
          data_split = element.installation_points[0].created_at.split(' ');
          let registerImageDateSplit;
          let supervisionImageDateplit;
          if (element.installation_points[0].data_register_img.date !== null && element.installation_points[0].data_supervision_img.date !== null) {
            registerImageDateSplit = element.installation_points[0].data_register_img.date.split(' ');
            registerImageDateSplit = moment(registerImageDateSplit[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '')
            supervisionImageDateplit = element.installation_points[0].data_supervision_img.date.split(' ');
            supervisionImageDateplit = moment(supervisionImageDateplit[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '')
          } else {
            registerImageDateSplit = null;
            supervisionImageDateplit = null;
          }

          array = {
            last_movement: element.installation_points[0].movement,
            status: changedDamagedStatus,
            revision_group: element.name,
            revision_point: element.installation_points[0].description,
            image: element.installation_points[0].image,
            supervisionImage: element.installation_points[0].data_supervision_img.image,
            supervisionImageDate: supervisionImageDateplit,
            registerImage: element.installation_points[0].data_register_img.image,
            registerImageDate: registerImageDateSplit,
            security_kit_id: element.installation_points[0].security_kit_id,
            folios: folios,
            created_at: element.installation_points[0].created_at,
            date: moment(data_split[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', ''),
            time: data_split[1].slice(0, 5),
            id: element.installation_points[0].id,
            order: this.orderPointsRevision(element.installation_points[0].description),
            random_image: Number(element.installation_points[0].random_image)
          }
        } else {
          array = {
            last_movement: element.installation_points[0].movement,
            status: changedDamagedStatus,
            revision_group: element.name,
            revision_point: element.installation_points[0].description,
            image: element.installation_points[0].image,
            supervisionImage: element.installation_points[0].data_supervision_img.image,
            supervisionImageDate: element.installation_points[0].data_supervision_img.date,
            registerImage: element.installation_points[0].data_register_img.image,
            registerImageDate: element.installation_points[0].data_register_img.date,
            security_kit_id: element.installation_points[0].security_kit_id,
            folios: folios,
            created_at: 'Sin datos',
            date: 'Sin datos',
            time: 'Sin datos',
            id: element.installation_points[0].id,
            order: this.orderPointsRevision(element.installation_points[0].description),
            random_image: Number(element.installation_points[0].random_image)
          }
        }
        this.tableData.push(array);
        iterationCount++;
      });
      if (iterationCount === length) {
        this.tableData = this.orderGroupsTable(this.tableData);
        console.log(this.tableData);
        this.hasData = true;
        this.createTable();
      }


    }
  }

  createTable() {
    this.filterEntity = new tableRow();
    this.filterType = MatTableFilter.ANYWHERE;
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const filterData = JSON.parse(sessionStorage.getItem('dataSource-filter-ficha-tecnica'));
    // console.log(filterData.folios);
    if (filterData) {
      this.filterEntity = filterData;
    }
  }

  checkDataSource(e) {
    console.log(e);
    console.log(this.dataSource);
    console.log(this.filterEntity.folios);
    setTimeout(() => {
      this.filteredData = this.dataSource.filteredData
      console.log(this.filteredData);
      if (this.filteredData.length <= 0) {
        this.hasFilteredData = false;
      } else {
        this.hasFilteredData = true;
      }
    }, 500);
  }

  consoleEvent(e) {
    console.log(e);
  }

  loadVehiclesDetailsError(error: any) {
    console.log(error);
  }

  goback() {
    this._location.back();
    this.sharingService.setData(undefined);
  }

  goToDetail(element) {
    console.log(element.id);
    this.sharingService.setData(element);
    this.router.navigate(['/detalle-punto-revision']);
    sessionStorage.setItem('dataSource-filter-ficha-tecnica', JSON.stringify(this.dataSource.filter));
  }

  // Ordena la información de acuerdo a requerimientos de cliente para ser mostrada en vista
  orderGroupsTable(data) {
    let tanquePiloto = [],
      tanqueAuxPiloto = [],
      filtroDiesel = [],
      filtroDieselSec = [],
      motor = [],
      tanqueCopiloto = [],
      tanqueAuxCopiloto = [],
      tanqueInterconexion = [],
      otros = [];

    data.forEach(element => {
      switch (element.revision_group) {
        case 'Tanque piloto':
          tanquePiloto.push(element);
          break;

        case 'Tanque auxiliar piloto':
          tanqueAuxPiloto.push(element);
          break;

        case 'Tanque copiloto':
          tanqueCopiloto.push(element);
          break;

        case 'Tanque auxiliar copiloto':
          tanqueAuxCopiloto.push(element);
          break;
        case 'Filtro diesel principal':
          filtroDiesel.push(element);
          break;

        case 'Filtro diesel secundario':
          filtroDieselSec.push(element);
          break;

        case 'T interconexion tanques':
          tanqueInterconexion.push(element);
          break;

        case 'Motor':
          motor.push(element);
          break;

        default:
          otros.push(element);
          break;
      }
    });

    tanquePiloto = tanquePiloto.sort((a, b) => {
      return a.order - b.order;
    });
    tanqueAuxPiloto = tanqueAuxPiloto.sort((a, b) => {
      return a.order - b.order;
    });
    filtroDiesel = filtroDiesel.sort((a, b) => {
      return a.order - b.order;
    });
    filtroDieselSec = filtroDieselSec.sort((a, b) => {
      return a.order - b.order;
    });
    motor = motor.sort((a, b) => {
      return a.order - b.order;
    });
    tanqueCopiloto = tanqueCopiloto.sort((a, b) => {
      return a.order - b.order;
    });
    tanqueAuxCopiloto = tanqueAuxCopiloto.sort((a, b) => {
      return a.order - b.order;
    });
    tanqueInterconexion = tanqueInterconexion.sort((a, b) => {
      return a.order - b.order;
    });
    otros = otros.sort((a, b) => {
      return a.order - b.order;
    });
    console.log(tanquePiloto,
      tanqueAuxPiloto,
      filtroDiesel,
      filtroDieselSec,
      motor,
      tanqueCopiloto,
      tanqueAuxCopiloto,
      tanqueInterconexion,
      otros
    );
    const newArray = [
      ...tanquePiloto,
      ...tanqueAuxPiloto,
      ...filtroDiesel,
      ...filtroDieselSec,
      ...motor,
      ...tanqueCopiloto,
      ...tanqueAuxCopiloto,
      ...tanqueInterconexion,
      ...otros
    ];
    console.log(newArray);
    return newArray;
  }

  // Asigna el valor 'order' para hacer el listado de la información
  orderPointsRevision(data) {
    switch (data) {
      case 'Antisifon':
        return 1
      case 'Respiradero':
        return 2
      case 'Manguera inyeccion':
        return 3
      case 'Manguera retorno':
        return 4
      case 'Flotador':
        return 5
      case 'Filtro':
        return 7
      case 'Drenes':
        return 8
      case 'Mangueras':
        return 9
      default:
        return 6
    };
  }

  // Sorts array(s) using the 'order' param from orederPOintsRevision()
  sortArray(array) {
    array.sort((a, b) => {
      return a.order - b.order;
    });
  }

  // Si es usuario tipo 1 o 2 la opción de actualizar la foto requerida será mostrada
  // Esta función se usa para cambiar de modo individual cada punto
  updateRandomPhoto(point_id, new_value) {
    this.isDisabled = true;
    this.ngxService.startLoader('loader-toggle-' + point_id);
    console.log(point_id, new_value);
    const value = [{
      id_point: Number(point_id),
      status: Number(new_value)
    }];
    this.vehicleService.changeImageOptions(value).subscribe(
      response => {
        console.log(response);
        this.tableData = [];
        this.vehicleService.getVehicleDetail(this.vehicle.id_vehicle).subscribe(
          response => {
            this.loadVehiclesDetailsSuccess(response);
            this.ngxService.stopLoader('loader-toggle-' + point_id);
            this.isDisabled = null;
          },
          error => {
            this.loadVehiclesDetailsError(error);
            this.ngxService.stopLoader('loader-toggle-' + point_id);
            this.isDisabled = null;
          }
        );
      },
      error => {
        this.loadVehiclesDetailsError(error);
        this.ngxService.stopLoader('loader-toggle-' + point_id);
        this.isDisabled = null;
      }
    );
  }

  // Se usa para decidir cual check de la columna 'randomImage' será puesto como activo al momento de generar la vista
  checkCurrentRandomPhotoValue(input_value, current_point_value) {
    if (Number(input_value) === Number(current_point_value)) {
      return true;
    } else {
      return false
    }
  }

  // Si es usuario tipo 1 o 2 la opción de actualizar la foto requerida será mostrada
  // Esta función se usa para cambiar todos los puntos en un solo moviemiento
  updateAllPointsPhotos(new_value) {
    this.ngxService.start();
    const points = [];
    this.tableData.forEach(element => {
      points.push(
        {
          id_point: Number(element.id),
          status: Number(new_value)
        }
      )
    });
    console.log(new_value);
    console.log(points);
    this.vehicleService.changeImageOptions(points).subscribe(
      response => {
        this.vehicleService.getVehicleDetail(this.vehicle.id_vehicle).subscribe(
          response => {
            this.tableData = [];
            this.hasData = false;
            this.loadVehiclesDetailsSuccess(response);
          },
          error => {

            this.loadVehiclesDetailsError(error);
          }
        );
        this.ngxService.stop();
      },
      error => {
        this.loadVehiclesDetailsError(error);
        this.ngxService.stop();
      }
    );
  }

  changeStatus(status: number, id: number): void {
    const data = {
      id,
      status,
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-status' + id);
    this.vehicleService.changeStatusPointInstallation(data).subscribe(
      response => {
        this.vehicleService.getVehicleDetail(this.vehicle.id_vehicle).subscribe(
          response => {
            this.tableData = [];
            this.hasData = false;
            this.loadVehiclesDetailsSuccess(response);
            this.ngxService.stopLoader('loader-toggle-status' + id);
          },
          error => {
            this.loadVehiclesDetailsError(error);
            this.ngxService.stopLoader('loader-toggle-status' + id);
          }
        );
      },
      error => {
        this.loadVehiclesDetailsError(error);
        this.ngxService.stopLoader('loader-toggle-status' + id);
      }
    );
  }


  // Esta función se usa para generar un pdf con la información de esta pantalla
  // El valor 'print' es un booleano que indica si será un archivo descargable (false) o imprimible (true)
  pdf(print) {
    if (!this.hasData) { } else {
      this.ngxService.start();
      let data;
      if (this.filteredData.length > 0) {
        data = this.filteredData;
      } else {
        data = this.tableData;
      }
      console.log(data);
      let col = [
        //"Último Movimiento",
        "Estatus",
        //"Grupo de rev.",
        "Punto de rev.",
        // "Kit de Seguridad", 
        "Folios",
        "Fecha",
        //"Hora",
        "Foto",
      ];
      let rows = [];
      let count = 0;
      data.forEach(element => {
        if (element.status === 9 || element.status === 2) {
          element.statusString = 'Sin daño'
        } else {
          element.statusString = 'Dañado'
        }

        if (element.folios === undefined || element.folios === null) {
          element.folioString = ['Sin datos'];
        } else {
          console.log(element.folios);
          element.folioString = element.folios.replace(/<br>/g, ", ")
        }

        let imgUrl;
        if (element.image && element.image.length > 0) {
          imgUrl = element.image;
        } else {
          imgUrl = './assets/images/no-image.png'
        }

        this.getBase64ImageFromURL(imgUrl).subscribe(base64data => {
          let img64 = base64data;
          let temp = [
            //element.last_movement,
            element.statusString,
            //element.revision_group,
            element.revision_point,
            element.folioString,
            element.date,
            //element.time,
            img64
          ];
          rows.push(temp);
          count++;

          if (count === data.length) {
            //console.log(rows); 
            this.createPdf(col, rows, print);
          }
        });

      });
    }
  }


  // Se usa la liga de la imagen para generar una imagen en base64 con el propósito de adjuntarla en el pdf
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer) => {
      // let newUrl = url.replace('%25C3%25B3', 'ó').replace('%25C3%25AD', 'í').replace('%25C3%25A1', 'á');
      // create an image object
      let img = new Image();
      img.crossOrigin = 'anonymous';
      const src = new URL(url);
      src.searchParams.append('date', Date());
      img.src = src.toString();
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          img.setAttribute('src', './assets/images/no-image.png');
          //observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });

  }

  // Se genera un canvas, a 1/3 del tamaño de la imagen original para ahorrar tiempo de generación del pdf
  // y reducir el peso del documento a generar
  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width / 3;
    canvas.height = img.height / 3;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL("image/jpg");
    return dataURL;
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  // Una vez concluido el proceso, se procede a usar el plugin 'jsPDF' para generar el pdf
  createPdf(col, rows, print?) {
    let pdfData = {
      vehicleBrand: this.vehicle.vehicle_brand_description,
      vehicleModel: this.vehicle.model_vehicle_description,
      vehicleSerialNumber: this.vehicle.serial_number,
      vehicleTanks: this.vehicle.tanks,
      vehicleEconomic: this.vehicle.economic_number,
      logoSoltek: this.LogoService.getLogoBlack()
    }
    let pdf = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4'
    });
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    pdf.setFillColor(255, 255, 255);
    //pdf.rect(0, 0, width, height, 'B');
    pdf.setFontSize(10);
    pdf.autoTable({
      margin: { top: 110 },
      styles: {
        cellWidth: 'auto',
        minCellWidth: 90,
        minCellHeight: 40,
        fontSize: 8,
        cellPadding: [40, 10, 40, 10],
        valign: 'center',
        halign: 'center',
        fillColor: [255, 255, 255],
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: [74, 74, 74],
        minCellHeight: 32,
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
        overflow: 'hidden',
        lineColor: [74, 74, 74],
        lineWidth: 0,
        cellPadding: [10, 10, 10, 10],
      },
      columnStyles: {
        0: {
          fillColor: [255, 255, 255],
        },
        1: {
          fillColor: [255, 255, 255],
        },
        2: {
          fillColor: [255, 255, 255],
        },
        3: {
          fillColor: [255, 255, 255],
        },
        4: {
          textColor: [255, 255, 255],
          fontSize: 1,
          cellWidth: 'auto',
          valign: 'center',
          halign: 'center',
          fillColor: [255, 255, 255],
        },
      },

      head: [col],
      body: rows,
      didDrawCell: columnStyles => {
        /*if (columnStyles.section === 'head' && columnStyles.column.index === 0) {
          pdf.addImage(this.LogoService.getTableBorderRigth(), 'jpg', columnStyles.cell.x, (columnStyles.cell.y - .9), 30, 30, '', 'NONE', 0);
        }
        if (columnStyles.section === 'head' && columnStyles.column.index === 4) {
          console.log(columnStyles);
          pdf.addImage(this.LogoService.getTableBorderLeft(), 'jpg', (columnStyles.cell.x + columnStyles.column.width - 30), (columnStyles.cell.y - .9), 30, 30, '', 'NONE', 0);
        }*/
        if (columnStyles.section === 'body' && columnStyles.column.index === 4) {
          let img = columnStyles.row.cells[4].raw;
          pdf.addImage(img, 'jpg', columnStyles.cell.x + 50, columnStyles.cell.y + 10 + .05, 100, 70, '', 'FAST');
          this.addImageCount++;
          if (this.addImageCount === columnStyles.table.body.length) {
            var docName = 'Ficha_tecnica_SN=' + this.vehicle.serial_number + 'fecha-' + (moment()) + '.pdf';
            if (print) {
              setTimeout(() => {
                window.open(pdf.output('bloburl'));
                this.addImageCount = 0;
                this.imagesProcessed = 0;
                this.ngxService.stop();
              }, 1000);

            } else {
              setTimeout(() => {
                pdf.save(docName);
                this.ngxService.stop();
                this.addImageCount = 0;
                this.imagesProcessed = 0;
              }, 1000);
            }


          }
        }
        if (columnStyles.section === 'body') {
          // console.log(columnStyles);
          let cellWidth = columnStyles.cell.width;
          let cellHeigth = columnStyles.cell.height;
          let cellX = columnStyles.cell.x;
          let cellY = columnStyles.cell.y;
          //console.log(cellWidth, cellHeigth, cellX, cellY);
          pdf.setLineWidth(1);
          pdf.line(cellX, (cellY + cellHeigth), (cellX + cellWidth), (cellY + cellHeigth));
        }
      },
      didParseCell: columnStyles => {
        245
        if (columnStyles.section === 'body' && columnStyles.column.index === 4) {
          console.log("columnStyles", columnStyles);
          columnStyles.cell.height = 100;
          columnStyles.cell.text = ''
        }
      },
      didDrawPage: function () {
        //El formato debe ser jpg
        //pdf.addImage(pdfData.logoSoltek, 'jpg', 904, 30, 80, 21, '', 'FAST', 0);

        pdf.setDrawColor(75, 75, 75)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(40, 26, 520, 70, 5, 5, 'S')

        pdf.setDrawColor(75, 75, 75)
        pdf.setFillColor(74, 74, 75)
        pdf.roundedRect(40, 26, 140, 70, 5, 0, 'F')

        //pdf.roundedRect(40, 26, width - 80, 120, 5, 5, 'S');

        pdf.setTextColor(244, 113, 22);
        pdf.setFontSize(35);
        pdf.setFont(undefined, 'bold');
        pdf.text(('Soltek'), 60, 65);

        pdf.setTextColor(255, 255, 255);
        pdf.setFontSize(10);
        pdf.setFont(undefined, 'bold');
        pdf.text(('FICHA TÉCNICA'), 75, 80);


        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont(undefined, 'bold');
        pdf.text(('MARCA: ' + pdfData.vehicleBrand + ' \t MODELO: ' + pdfData.vehicleModel + ' \t NO TANQUES: ' + pdfData.vehicleTanks), 200, 50);
        pdf.text(('NO. ECONÓMICO: ' + pdfData.vehicleEconomic + ' \t No. SERIE: ' + pdfData.vehicleSerialNumber), 200, 80);

        pdf.text(('Página ' + pdf.internal.getNumberOfPages()), 40, 748);
      }
    });
  }

}



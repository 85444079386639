import { Component, OnInit, OnDestroy, OnChanges, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../services/sharing-service.service";
import { VehiclesService } from "../../services/vehicles/vehicles.service";
import {Location} from '@angular/common';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-driver-damaged-list',
  templateUrl: './driver-damaged-list.component.html',
  styleUrls: ['./driver-damaged-list.component.css']
})
export class DriverDamagedListComponent implements OnInit {
  driverId = localStorage.getItem('driverId');
  dataHasLoaded: boolean = undefined;
  vehiclesData = [];
  @ViewChild(MatSort) sort: MatSort ;
  @Input() displayedColumns: string[] = ['column'];
  dataSource;
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(
    private router: Router,
    private sharingService:SharingServiceService,
    private vehiclesService: VehiclesService,
    private _location: Location,
    private ngxService: NgxUiLoaderService   
  ) { }

  ngOnInit() {
    console.log( this.driverId );
    this.getVehicles();
  }

  // Obtiene los vehículos con daño de un chofer
  getVehicles() {
    this.ngxService.start();
    const data = {
      driver_id : this.driverId
    }
    this.vehiclesService.getVehicleByDriver(data).subscribe(
      response => {
        if (response.success === 1 && response.data.length > 0) {
          this.getVehiclesSuccess( response.data );
        } else if ( response.success === 1 && response.data.length === 0 ) {
          this.getVehiclesError( "No hay datos para mostrar" );
        }

        else {
          this.getVehiclesError( response.message );
        }
      },
      error => {
        console.log(error);
        this.getVehiclesError( error.message );
      }
    )
  }

  goBack(){
    this._location.back();   
  }

  getVehiclesSuccess( data ) {
    this.ngxService.stop();
    console.log(data);
    this.dataHasLoaded = true;
    this.vehiclesData = data;
    console.log(this.vehiclesData);
    this.dataSource = new MatTableDataSource(this.vehiclesData);
    this.dataSource.filterPredicate = (data, filter: string) => {
      // Si alguno de los valores es null o undefined se debe cambiar a un strin vacío o el filtro deja de funcionar
      const emptyvalues = Object.keys(data).filter(key => data[key] === null || data[key] === undefined || data[key] === '');
      for (const iterator of emptyvalues) {
        console.log(iterator);
        data[iterator] = '';
      }
      console.log(data);
      return data.vehicle_detail.economic_number.toLowerCase().includes(filter)
      || data.vehicle_detail.serial_number.toLowerCase().includes(filter)
    };
    this.applyDatasource();
  }

  applyDatasource(): void {
    setTimeout( (event) => {
      this.dataSource.sort = this.sort;
    }, 500);
  }
  

  getVehiclesError( message ) {
    this.ngxService.stop();
    console.log(message);
    this.dataHasLoaded = false;
  }

}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Inject } from '@angular/core';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-eliminar',
  templateUrl: './modal-eliminar.component.html',
  styleUrls: ['./modal-eliminar.component.css']
})
export class ModalEliminarComponent implements OnInit {
 
  id: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModalEliminarComponent>,
              private profileService: ProfileService,
              private utils: UtilsService,
              private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    console.log(this.data);
  }


  onNoClick( success? ): void {
    this.dialogRef.close( success );
  }
 
   // Manage User (block, unblocks and erases users)
   public manageUser() {
    
    console.log(this.data);
    let body = {
      user_id: this.data.user_id,
      status: 3
    }

    this.ngxService.startLoader('loader-toggle-delete-modal');
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageUser(body).subscribe(
      response => { 
        const data : any = response;
        if (data.success === 1){
          this.manageUserSuccess( true, data.message );
        } else  {
          this.manageUserError( false, data.message );
        }
        // this.ngxService.stopLoader('loader-toggle-delete-modal');
       },
      error => { 
        // this.ngxService.stopLoader('loader-toggle-delete-modal');
        const data : any = error;
        this.manageUserError( false, data.message );
       }
    )
  }

  manageUserSuccess( success:boolean, message:string ) {
    const data = [true, message]
    this.dialogRef.close( data );
  }

  manageUserError( success:boolean, message:string ) {
    const data = [false, message]
    this.dialogRef.close( data );
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {

  constructor() { }
  // Construye el paginador de acuerdo a la última selección hecha por el usuario
  // o asigna default 10 si no se ha realizado ninguna selección
  setPaginator( paginatorId) {
    console.log('paginator set');
    const paginatorChosenValue = Number(localStorage.getItem(paginatorId));
    if (paginatorChosenValue === null || paginatorChosenValue === undefined || paginatorChosenValue <= 0) {
      return 10;
    } else {
      return paginatorChosenValue;
    }
  }

  // Guarda en localStorage la selección del usuario y el id del paginador
  savePaginator( paginator, paginatorId ){
    console.log('paginator save');
    paginator.page.subscribe(
      x => {
        console.log(x.pageSize); 
        localStorage.setItem(paginatorId, x.pageSize);
      });
  }
}

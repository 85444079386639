import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import noInternet from 'no-internet';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';

@Component({
  selector: 'app-form-edit-vehicle-limit',
  templateUrl: './form-edit-vehicle-limit.component.html',
  styleUrls: ['./form-edit-vehicle-limit.component.css']
})
export class FormEditVehicleLimitComponent implements OnInit {
  @Input() clientData;
  form: FormGroup;
  success: boolean;
  message: string;
  @Output() reload: EventEmitter< boolean >;
  reset:boolean;
  vehicle_limits_errors: any = ValidationMessages.datosVehiculos.vehicle_limits;
  constructor(
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
  ) {
    this.reload = new EventEmitter();
   }

  ngOnInit() {
    console.log(this.clientData);
    this.form = this.createForm( this.clientData );
    this.checkFormChanges();
  }

  ngOnChanges( changes: SimpleChanges) { 
    const clientData: SimpleChange = changes.clientData;
    console.log('prev value: ', clientData.previousValue);
    console.log('got name: ', clientData.currentValue);
    this.form = this.createForm( this.clientData );
    console.log(this.form);
  }

  createForm( data ) {
    return new FormGroup({
      fk_company : new FormControl( data.companyId ),
      vehicles_limit : new FormControl('', [
              Validators.required,
              CustomValidators.number,
              CustomValidators.digits,
              CustomValidators.gte((Number(data.vehicles_limit) +1))
      ])
    })
  }

  setVehicleLimit( value ) {
    return new Promise((resolve, reject)=> {
      let data = {
        fk_company :  value.fk_company,
        vehicles_limit : value.vehicles_limit
      }
      console.log(data);
      this.vehiclesService.updateVehicleLimit( data ).subscribe( 
        response => {
        // console.log(response);
          if (response.success === 0) {
              const data = {
                error : response.message,
              }
              this.success = false;
              this.message = response.message
          } else {
            this.success = true;
            resolve('Limite de vehículos actualizado');
          }
            // this.spinner.hide();    
           this.openDialog(this.success, this.message);
        }, 
        error => {
          const data = {
              error : 'El servicio no responde',
            }
            reject('Límite vehículos');
            // console.log(error); 
            // this.spinner.hide(); 
          })
    })
  }


  checkFormChanges() {
    this.form.statusChanges.subscribe( data => {
      console.log(data);   
    })
  }

  openDialog( success, message ): void {
    if ( success || success === undefined ) {
      success = true;
      message = 'El límite de vehículos fue actualizado correctamente correctamente'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.reset = result;
      this.checkForReload(this.reset);
    });
  }

  checkForReload( reset ) {
    this.success= undefined;
    this.message= undefined;
    if (reset === true) {
      this.form.reset();
      this.reload.emit( true );
      this.reset = null;
    }
    if ( reset === false ) {
      this.reset = null;
    } else {
      this.reset = null;
    }
  }

}

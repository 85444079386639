import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SoltekCMS';
  resetPassword = false;

  constructor ( private authService: AuthService,
                private router: Router ) {
    let active = this.isActive();
    if (this.router.url.indexOf('/reset-password')) {
      console.log('reset-password');
    } else {
      if (!active){
      this.router.navigate(['/login']);
    } 
      else {
      console.log('sesion activa');
      }
    }
  }

  isActive() {
    return this.authService.isAuthenticated();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserCategoryService {

  constructor() { }

  getUserCategory(id: number) {
    switch (id) {
      case 1:
        //   console.log("Super Admin");
          return 'Super Admin';
          break;
      case 2:
        //   console.log("Admin Cliente");
          return 'Admin Cliente';
          break;
      case 3:
        //   console.log("Admin Ind");
          return 'Admin Ind';
          break;
      case 4:
        //   console.log("Supervisor Soltek");
          return 'Supervisor Soltek';
          break;
      case 5:
        //   console.log("Instalador Soltek");
          return 'Instalador Soltek';
          break;
      case 6:
        //   console.log("Supervisor");
          return 'Supervisor';
          break;
      case 7:
        //   console.log("Instalador");
          return 'Instalador';
          break;
      case 8:
        //   console.log("Chofer");
          return 'Chofer';
          break;
      case 9:
        //   console.log("Moderador");
          return 'Moderador';
          break;
      case 10:
        //   console.log("Moderador Soltek");
          return 'Moderador Soltek';
          break;
      case 11:
        //   console.log("Vigilante");
          return 'Vigilante';
          break;
      default:
        //   console.log('No hay datos');
          return 'Sin datos';
          break;
    }
  }

 
}

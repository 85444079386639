import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectModule, MatFormFieldModule} from '@angular/material';
import { FullImageComponent } from '../../../shared/modals/full-image/full-image.component';
import * as moment from 'moment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-driver-damage-point',
  templateUrl: './driver-damage-point.component.html',
  styleUrls: ['./driver-damage-point.component.css']
})
export class DriverDamagePointComponent implements OnInit {
  @Input() data;
  @Input() displayedColumns: string[] = [
    'nombre_punto',
    'ultimaFoto',
    'folios',
    'fecha',
    'hora', 
    // 'boton'
  ];
  dataSource;

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    // console.log(this.data);
    let data_split;
    if (this.data === undefined || this.data === null) { } else {
      this.data.forEach(element => {
        console.log(element.foliate_stamp);
        let date;
        let time;
        data_split = element.created_at.split(' ');
        date = moment(data_split[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '');
        time = data_split[1].slice(0,5);
        element.date = date;
        element.time = time;
        element.folios = null;
        // console.log(element.foliate_stamp);
        if (element.foliate_stamp !== null, element.foliate_stamp !== "null") {
            let temporalObject = JSON.parse(element.foliate_stamp);
            console.log(temporalObject);
            console.log(temporalObject.length);
            if (temporalObject.length >= 1) {
              element.folios  = temporalObject.join('<br>');
            } else {
              element.folios  = "Sin datos"
            }
            // console.log(element.folios);
        }
        if (element.foliate_stamp === "null" ||element.foliate_stamp === null ) {
          element.folios = "Sin datos"
        }
      });
    }

    this.dataSource = new MatTableDataSource(this.data);

  }

   // openDialog() Abre la imagen seleccionada en la tabla
   openDialog(data): void {
    let imgLink = data;
    console.log(imgLink);
    const dialogRef = this.dialog.open(FullImageComponent,{
      data: imgLink,
      panelClass: 'modal-full-image'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  goToDetail( element ) {
    console.log(element);
    // Lleva a la vista clásica de punto de revisión
    this.router.navigate(['/detalle-punto-revision']);
} 

  

}

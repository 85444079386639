import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import * as ValidationMessages from '../../../validators/validation-messages';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { UtilsService } from '../../../app.utils';
import { VehicleAll } from '../../../interfaces/vehicle-all.interface';
import noInternet from 'no-internet';
import { Router } from '@angular/router';
import { CatalogVehicles } from '../../../interfaces/catalog-vehicles.interface';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { CustomValidators } from 'ng4-validators';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-alta-vehiculo',
    templateUrl: './modal-alta-vehiculo.component.html',
  })
  export class ModalAltaVehiculoComponent implements OnInit  {
    route;
    vehicle: VehicleAll;
    bm: CatalogVehicles
    brand: CatalogVehicles;
    selectedBrand;
    showBases: boolean;
    formAltaVehiculo: FormGroup;
    formAltaMarcaModelo: FormGroup;
    required_errors: any = ValidationMessages.commonMessages.generic_errors;
    alta_vehiculo_errors: any = ValidationMessages.altaVehiculo;
    datos_vehiculo_errors: any = ValidationMessages.datosVehiculos;
    responseString:string;
    serviceError: boolean = false;
    bases = [];
    isFreemium: boolean;

    // Las siguientes variables son necesarias para usar Drag and Drop
    tabs = ['Nuevo Vehículo', 'Carga Masiva'];
    tab_selected = new FormControl(0);
    DragAndDropFileOperation = 1; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
    DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc
    company_id = JSON.parse(localStorage.getItem('AdminCliente')).companyId;

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<ModalAltaVehiculoComponent>, 
      private utils: UtilsService,
      private vehicleService: VehiclesService,
      private router: Router,
      private ngxService: NgxUiLoaderService,
      private fb: FormBuilder,
      ){
        const userType =  Number(localStorage.getItem('userType'));
        if (userType === 3) {
          this.isFreemium = true;
        }  else {
          this.isFreemium = false;
        }
        this.route = this.router.url;
      } 
  
      ngOnInit() {
        this.loadBrands();
        this.getBases();
        this.formAltaVehiculo = new FormGroup({
          fk_vehicle_model : new FormControl('',
          [
            Validators.required,
          ]
          ),
          brand : new FormControl('',   
            [
              Validators.required,
              Validators.minLength(1)
            ]
          ),
          tanks : new FormControl('',
            Validators.required
          ),
          vehicle_economic : new FormControl('',[
            Validators.minLength(3),
            Validators.maxLength(8),
            Validators.pattern('[a-zA-ZÀ-ÿ0-9]*')

          ]),
          vehicle_id : new FormControl('',
            [ 
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(20),
              Validators.pattern('[a-zA-ZÀ-ÿ0-9]*')
            ]
          ),
          company_id :  new FormControl(this.company_id),
          // Este se ocupa cuando se da de alta siendo Admin Cliente
        });
        const userType =  Number(localStorage.getItem('userType'));
        console.log(this.route);
        if ( this.route === '/vehiculos-cliente' || (this.route === '/vehiculos-clientes' && userType  === 3 )) {
          this.showBases = false;
        } 
          else if (this.route === '/detalle-cliente' || this.route === '/vehiculos-clientes'){
            this.showBases = true;
            this.formAltaVehiculo.addControl('base_id' ,new FormControl('', [
              Validators.required,
            ]))
        };
          //Listens for value changes on fk_vehicle_model select
          this.formAltaVehiculo.controls['brand'].valueChanges.subscribe(
            data =>{
              if (data != '') {
                console.log(data);
                this.selectedBrand = this.brand;
                this.selectedBrand = this.selectedBrand.find(obj => obj.brand_id == data);
                console.log(this.selectedBrand);
              }  else {
                this.selectedBrand = false;
              }
            
            }
        );
      }
  
      ngOnChanges() {
      }
  
      onSelected(brand: CatalogVehicles): void {
        this.selectedBrand = brand;
      }
  
      onNoClick(): void {
        this.dialogRef.close( false );
        this.formAltaVehiculo.reset();
      }
  
    enviar(){
      if(this.formAltaVehiculo.valid) {
        console.log( this.formAltaVehiculo.value );
        //this.dialogRef.close();
        //this.formAltaVehiculo.reset();
  
      } else {
  
        Object.keys(this.formAltaVehiculo.controls).forEach(field => {
          const control = this.formAltaVehiculo.get(field);  
          console.log(control);         
          control.markAsDirty({ onlySelf: true });     
        });
  
      }
    }
  
    // #Get Brands
    public loadBrands() {
      this.vehicleService.getModel().subscribe(
          response => { this.loadBrandsSuccess( response );   
          },
          error => { this.loadBrandsError( error );} 
      );
  }
  /*
      Load Brands Callbacks
  */
  private loadBrandsSuccess( response: any ) {
      let code = response.success;
      if (code != 1) {
        //  this.utils.errorManagement( response );
      } else {
        this.brand = response.data;
      }
  }
  
  private loadBrandsError( error: any ) {
      console.error( error );
  }
  
  refresh(){
    window.location.reload();
  }
  
  private getBases(){
    this.vehicleService.getBases().subscribe(
      response => { 
        // console.log( response.data ); 
        this.getBasesSuccess( response.data );
      },
      error => { 
        console.log( error ); 
      }
    )
  }
  
  private getBasesSuccess( data ) {
    const company_name = JSON.parse(localStorage.getItem('AdminCliente')).name;
    
    console.log( data ); 
    console.log( company_name );
    data.forEach(element => {
      if (element.company_name === company_name) {
        let base = {
          base_name: element.base_name,
          base_id: element.base_id
        }
        this.bases.push(base);
      }
    });
    console.log(this.bases);
  }
  
    newVehicle ( vehicle ) {
      console.log(vehicle);
      noInternet().then(offline => {
        if (offline) {
          this.utils.alertError('Verifica tu conexión');
        }
        else {
          if (this.formAltaVehiculo.valid) {
            let property = "brand";
            delete vehicle[property];
            if ( vehicle.vehicle_economic === '' ) {
              delete vehicle.vehicle_economic;
            }
            this.ngxService.startLoader('loader-modal');
            this.vehicleService.addVehicle(vehicle).subscribe(
              response => { 
                this.ngxService.stopLoader('loader-modal');
                this.newVehicleSuccess( response ); 
              },
              error => { 
                this.ngxService.stopLoader('loader-modal');
                this.newVehicleError( error ); 
              }
            )
          } 
        }
      })
    }
  
    private newVehicleSuccess( response: any ) {
      let code = response.success;
      if (code != 1) {
        console.log(response);
        // this.utils.errorManagement( response );
        this.responseString = response.message;
        // console.log('%c' + response.message , 'color:red; font-size:15px;');
        this.serviceError = true;
        // this.formAltaVehiculo.controls.vehicle_id.setErrors({'duplicate': true} );
      } else {
        // this.utils.toasterError( response );
        this.formAltaVehiculo.reset();
        this.dialogRef.close( true );
        //this.router.navigate(['/vehiculos']);
      }
    }
  
    private newVehicleError( error : any) {
      console.error(error);
    }
  } 
  
import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../../validators/validation-messages';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { UtilsService } from '../../../app.utils';
import { VehicleAll } from '../../../interfaces/vehicle-all.interface';
import noInternet from 'no-internet';
import { Router } from '@angular/router';
import { CatalogVehicles } from '../../../interfaces/catalog-vehicles.interface';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { PaginatorService } from "../../../services/paginator.service";
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { ModalEliminarVehiculoComponent } from '../../../shared/modals/modal-eliminar-vehiculo/modal-eliminar-vehiculo.component';
import * as moment from 'moment';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: 'app-vehiculos-cliente-table',
  templateUrl: './vehiculos-cliente-table.component.html',
  styleUrls: ['./vehiculos-cliente-table.component.css']
})
export class VehiculosClienteTableComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  user;
  data;
  vehiclesData;
  hasData: boolean = false;
  showTableAlert: boolean = false;
  alertClass = true;
  alertMessage = {
    success: 'Se agregó exitosamente el vehículo.',
    fail: 'Hubo un error al agregar el vehículo.'
  }
  userType;
  @Input() vehicles: any[];
  @Input() reloadTable;
  @Output() vehicleSelected: EventEmitter<VehicleAll> = new EventEmitter();
  selection = new SelectionModel<VehicleAll>(allowMultiSelect, initialSelection);
  displayedColumns: string[] = ['marca', 'modelos', 'base', 'incidencia', 'tanques', 'num_economico', 'num_serie', 'last_supervision', 'last_supervision_time', 'next'];
  dataSource = new MatTableDataSource([]);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  activeSort = null;
  paginatorId = 'paginatorVehiclesDetailTable'
  paginatorChosenValue: number;
  filterPlaceholder = "¿Qué quieres buscar?";


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim();
    console.log(this.dataSource);
    if (this.dataSource.filter === '') {
      this.filterPlaceholder = "¿Qué quieres buscar?";
    }
  }
  activeFolioFilter = sessionStorage.getItem('folio-/vehiculos-clientes');


  constructor(
    private vehicleService: VehiclesService,
    private utils: UtilsService,
    private router: Router,
    public dialog: MatDialog,
    private sharingService: SharingServiceService,
    private ngxService: NgxUiLoaderService,
    private paginatorService: PaginatorService
  ) {
    // 'paginatorChosenValue' se usa para mantener la opción que el usuario escogío en el paginador cuando se recarga la vista
    this.paginatorChosenValue = this.paginatorService.setPaginator(this.paginatorId);
    this.userType = Number(localStorage.getItem('userType'));
    console.log(this.userType);
    // 
    this.data = this.sharingService.getData();
    if (this.data === undefined) {
      this.data = localStorage.getItem('AdminCliente');
      this.user = localStorage.getItem('AdminCliente');
    } else {
      this.user = this.data;
    }

    const isSortActive = sessionStorage.getItem('sort-detalle-cliente');
    if (isSortActive) {
      this.activeSort = JSON.parse(isSortActive);
    } else {
      this.activeSort = {
        active: 'marca',
        direction: 'asc'
      }
    }
  }

  ngOnInit() {
    this.loadVehicles();
    this.paginatorService.savePaginator(this.paginator, this.paginatorId);
    this.dataSource.sort = this.sort;

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.reloadTable.currentValue)
    if (this.reloadTable === true) {
      this.loadVehicles();
      this.showTableAlert = true;
    }
  }

  // Obtener todos los vehículos de un usuario
  public loadVehicles() {
    this.ngxService.start();
    this.vehicleService.getVehiclesAll().subscribe(
      response => {
        this.ngxService.stop();
        this.loadVehiclesSuccess(response);
      },
      error => {
        this.ngxService.stop();
        this.loadVehiclesError(error);
      }
    );
  }
  /*
      loadVehicles() Callbacks
  */
  private loadVehiclesSuccess(response: any) {
    let data = response.data;
    console.log(data);
    let header;
    let code = response.success;
    if (code != 1) {

    } else {
      header = Object.keys(data);
      if (data[header] === undefined || data[header] === null || data.length === 0) {
        this.hasData = false;
        this.vehiclesData = null;
      } else {
        console.log(data[header]);
        this.vehiclesData = data[header];
        // Se cuentan las incidencias por vehículo de acuerdo a si existen, y si su valor 'failed' es diferente a null
        this.vehiclesData.forEach(element => {
          // console.log(element);
          if (element.date_last_supervised === null) {
            // console.log(element.date_last_supervised);
          } else {
            let date_last_supervised_split = element.date_last_supervised.split(' ');
            date_last_supervised_split = date_last_supervised_split[0].split('-');
            //Take the time
            element.last_supervision_time = element.date_last_supervised.split(' ', 2)[1];
            // console.log(element.last_supervision_time);
            // This one is used for the sorting header
            element.date_for_sorting = date_last_supervised_split[0] + '-' + date_last_supervised_split[1] + '-' + date_last_supervised_split[2];
            // This one is used for the table view
            element.date_last_supervised = moment(element.date_for_sorting).locale('Es').format('YYYY-MMM-DD').replace('.', '').toLocaleUpperCase();
          }
          if (element.base === null) {
            element.base = 'Sin datos'
            element.base_id = null;
            element.base_description = 'Sin datos';
          } else {
            element.base_id = element.base.base_id;
            element.base_description = element.base.base_description;
          }
          if (element.installed_kits.length > 0) {
            element.hasKits = true;
          } else {
            element.hasKits = false;
            element.incidents = -1;
          }

          const visitedVehiclesList = sessionStorage.getItem('visitedVehicleList');
          if (visitedVehiclesList) {
            element.hasBeenVisited = this.hasBeenVisited(element.serial_number, visitedVehiclesList);
          }

          if (element.incidents > 0 && element.failed_kits.length > 0) {
            // console.log(element.failed_kits);
            // Esta sección añade un tooltip cuando el usuario hace hover sobre la columna 'failed kits'
            // si hay incidencias en ese vehículo
            let tooltipKits: any = []
            element.failed_kits.forEach(element => {
              switch (element.description) {
                case 'Candado Respiradero BreatherSafe':
                  if (!tooltipKits.includes('Respiradero')) {
                    tooltipKits.push('Respiradero');
                  }
                  break;
                case 'Kit Protección Mangueras':
                  if (!tooltipKits.includes('Periféricos')) {
                    tooltipKits.push('Periféricos');
                  }
                  break;
                case 'Antisifon Tanksafe':
                  if (!tooltipKits.includes('Antisifón')) {
                    tooltipKits.push('Antisifón');
                  }
                  break;
                case 'Otros':
                  if (!tooltipKits.includes('Otros')) {
                    tooltipKits.push('Otros');
                  }
                  break;

                default: ''
                  break;
              }
            });
            element.tooltipKits = tooltipKits.toString().replace(',', ', ');
            // console.log(element);
          }

        });
        // console.log(data[header]);
        // Si es un usuario freemium (usertype = 3) la columna 'bases' no se muestra
        if (this.userType === 3) {
          this.displayedColumns.splice(2, 1);
        }
        this.hasData = true;
        this.setTable(this.vehiclesData);
      }
    }
  }

  private loadVehiclesError(error: any) {
    console.error(error);
  }

  setTable(data) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // Esta parte limita el filtrado a solo los parámetros que se deseen
    this.dataSource.filterPredicate = (data, filter: string) => {
      // Si alguno de los valores es null o undefined se debe cambiar a un string vacío o el filtro deja de funcionar
      const emptyvalues = Object.keys(data).filter(key => !data[key] || data[key] === '');
      for (const iterator of emptyvalues) {
        console.log(iterator);
        if (iterator !== 'incidents') {
          data[iterator] = '';
        }
      }
      // console.log(data);
      return data.base_description.toLowerCase().includes(filter)
        || data.vehicle_brand_description.toLowerCase().includes(filter)
        || data.model_vehicle_description.toLowerCase().includes(filter)
        || data.incidents.toString().includes(filter)
        || data.tanks.toString().includes(filter)
        || data.economic_number.toString().includes(filter)
        || data.serial_number.toString().includes(filter)
        || data.date_last_supervised.toLowerCase().includes(filter)
    };
    if (sessionStorage.getItem('dataSource-filter-detalle-cliente')) {
      this.dataSource.filter = sessionStorage.getItem('dataSource-filter-detalle-cliente');
      this.filterPlaceholder = 'Filtro activado: "' + this.dataSource.filter + '"';
    }
    this.applyDatasource();
  }


  applyDatasource(): void {
    setTimeout((event) => {
      this.dataSource.sort = this.sort;
    }, 500);
  }

  goDetalleVehiculo(selectedVehicle) {
    console.log(selectedVehicle);
    localStorage.setItem('SelectedVehicle', JSON.stringify(selectedVehicle));
    this.router.navigate(['/ficha-tecnica']);
    const visitedVehicleList = sessionStorage.getItem('visitedVehicleList');
    // Guarda el serial_number de cada vehículo visitado para aplicar clase .visited si aparece en la lista
    if (visitedVehicleList) {
      const addToList = JSON.parse(visitedVehicleList);
      console.log(addToList);
      addToList.push(selectedVehicle.serial_number);
      sessionStorage.setItem('visitedVehicleList', JSON.stringify(addToList));
    } else {
      sessionStorage.setItem('visitedVehicleList', JSON.stringify([selectedVehicle.serial_number]));
    }
    // Guarda el último Sort activo para reconstruir la pantalla
    if (this.activeSort !== null) {
      sessionStorage.setItem('sort-detalle-cliente', JSON.stringify(this.activeSort));
    }
    // Guarda el último Filtro de búsqueda aplicado para reconstruir la pantalla
    if (this.dataSource.filter !== '') {
      sessionStorage.setItem('dataSource-filter-detalle-cliente', this.dataSource.filter);
    }
  }

  // Se usa despues de aplicar el filtro de Buscar por folios para mostrar unicamente esas coincidencias
  showFilteredVehicles(data) {
    if (data[0] === true) {
      const keys = Object.keys(data[1]);
      const vehiclesList = data[1][keys[0]];
      console.log(vehiclesList);
      vehiclesList.forEach(element => {
        console.log(element);
        if (element.base === null) {
          element.base = 'Sin datos'
          element.base_id = null;
          element.base_description = 'Sin datos';
        } else {
          element.base_id = element.base.base_id;
          element.base_description = element.base.base_description;
        }
        if (element.installed_kits.length > 0) {
          element.hasKits = true;
        } else {
          element.hasKits = false;
          element.incidents = -1;
        }
        if (!element.date_last_supervised) {
          element.date_last_supervised = '';
          // console.log(element.date_last_supervised);
        } else {
          let date_last_supervised_split = element.date_last_supervised.split(' ');
          date_last_supervised_split = date_last_supervised_split[0].split('-');
          //Take the time
          element.last_supervision_time = element.date_last_supervised.split(' ', 2)[1];
          // console.log(element.last_supervision_time);
          // This one is used for the sorting header
          element.date_for_sorting = date_last_supervised_split[0] + '-' + date_last_supervised_split[1] + '-' + date_last_supervised_split[2];
          // This one is used for the table view
          element.date_last_supervised = moment(element.date_for_sorting).locale('Es').format('YYYY-MMM-DD').replace('.', '').toLocaleUpperCase();
        }
        const visitedVehiclesList = sessionStorage.getItem('visitedVehicleList');
        if (visitedVehiclesList) {
          element.hasBeenVisited = this.hasBeenVisited(element.serial_number, visitedVehiclesList);
        }
        // if ( element.incidents > 0 && element.failed_kits) {
        //   console.log(element.failed_kits);
        //   // Esta sección añade un tooltip cuando el usuario hace hover sobre la columna 'failed kits'
        //   // si hay incidencias en ese vehículo
        //   let tooltipKits: any = []
        //   element.failed_kits.forEach(element => {
        //     switch (element.description) {
        //       case 'Candado Respiradero BreatherSafe':  
        //         if (!tooltipKits.includes('Respiradero')){
        //           tooltipKits.push ('Respiradero');
        //         }
        //         break;
        //       case 'Kit Protección Mangueras':
        //         if (!tooltipKits.includes('Periféricos')){
        //           tooltipKits.push ('Periféricos');
        //         }
        //         break;
        //       case 'Antisifon Tanksafe':
        //         if (!tooltipKits.includes('Antisifón')){
        //           tooltipKits.push ('Antisifón');
        //         }
        //         break;
        //       case 'Otros':
        //         if (!tooltipKits.includes('Otros')){
        //           tooltipKits.push ('Otros');
        //         }
        //         break;

        //       default: ''
        //         break;
        //     }
        //   });
        //   element.tooltipKits = tooltipKits.toString().replace(',', ', ');
        //   console.log(element);
        // }
        // console.log(vehiclesList);
        // this.setTable(vehiclesList);
        setTimeout(() => {
          // console.log(vehiclesList);
          this.dataSource = new MatTableDataSource(vehiclesList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 2000);
      });
      // this.loadVehiclesSuccess(response) 
    }
  }

  hideTableAlert(e) {
    console.log(e);
    if (e == true) {
      this.showTableAlert = false;
      this.reloadTable = false;
    }
  }

  removeFolioFilter() {
    sessionStorage.removeItem('folio-detalle-cliente');
    this.dataSource = new MatTableDataSource(this.vehiclesData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDeleteModal(vehicle_id) {
    console.log(vehicle_id);
    const dialogRef = this.dialog.open(ModalEliminarVehiculoComponent, {
      panelClass: 'modal-send-notification',
      data: vehicle_id
    });

    this.alertMessage = {
      success: 'Se eliminó exitosamente el vehículo.',
      fail: 'Hubo un error al eliminar el vehículo.'
    }

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.showTableAlert = false;
      }
      else {
        this.loadVehicles();
        this.showTableAlert = true;
      }
    });
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  // Checks if serial number is inside the list
  hasBeenVisited(serialNumber, visitedVehicleList) {
    const List = JSON.parse(visitedVehicleList);
    const match = List.filter(s => s.includes(serialNumber));
    if (match.length > 0) {
      return true
    } else {
      return false
    }
  }

  sortData(event) {
    console.log(event);
    this.activeSort = event;
  }

  sortActive(event) {
    console.log(event);
  }

  removeAllFilters() {
    sessionStorage.clear();
    this.activeFolioFilter = null;
    this.filterPlaceholder = '¿Qué quieres buscar?';
    this.activeSort = {
      active: 'marca',
      direction: 'asc'
    };
    this.setTable(this.vehiclesData);
  }


}

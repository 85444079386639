import { Component, OnInit, Input } from '@angular/core';
import { Gauge } from 'gaugeJS';
import { GaugeService } from '../../services/gauge.service';
import { ProfileService } from '../../services/users/profile.service';
import { UtilsService } from '../../app.utils';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  today = moment().endOf('month').format('YYYY-MM-DD');
  now = moment().startOf('month').format('YYYY-MM-DD');
  oneMonth = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD');
  allTime = moment().startOf('month').subtract(100, 'years').format('YYYY-MM-DD');
  current_month = moment().locale('es').format('MMMM').charAt(0).toUpperCase() + moment().locale('es').format('MMMM').slice(1);
  userType = Number(localStorage.getItem('userType'));
  user: TablaAdminIndependiente[];
  gaugeData;

  opts: any = {};
  // Se harán tres consultas al servicio {{url}}/api/staff/dashboard
  // Mes actual, mes anterior e histórico
  // Poner siempre el ultimo dia del mes en curso
  period_current: any = {
    from: this.now,
    to: this.today
  };

  period_lastMonth: any = {
    from: this.oneMonth,
    to: this.now
  }

  period_historic: any = {
    from: '2000-01-01',
    to: this.today
  }

  data: any;
  data_historic: any;
  showingData = 'now';

  constructor(
    private _gaugeService: GaugeService,
    private profileService: ProfileService,
    private utils: UtilsService,
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog,
  ) {
    this.ngxService.start();
    Promise.all([
      // this.getDashboardData(this.period_current, 'current'),
      // this.getDashboardData(this.period_lastMonth, 'lastMonth'),
      // this.getDashboardData(this.period_historic, 'allTime'),
      this.getDashboardDataNew(),
    ]).then(values => {
      const value: any = values[0];
      const historic = value.historic;
      const actual_month = value.actual_month;
      const last_month = value.last_month;
      console.log(historic);
      console.log(actual_month);
      console.log(last_month);
      const hasCustomers = () => {
        if (historic.customers) {
          return true;
        }
        return false;
      };

      this.data = [
        {
          customers: {
            customers_hired: actual_month.customers[0].customers_premium,
            total: actual_month.customers[0].customers_total,
            total_new: actual_month.customers[0].customers_total_new,
            total_period: actual_month.customers[0].customers_total_period,
            percentage: this._gaugeService.getPercentage(actual_month.customers[0].customers_premium, actual_month.customers[0].customers_total_new),
          },
          vehicles: {
            install_total: actual_month.register_vehicles[0].total_vehicles_at_least_one_kit_period,
            total: actual_month.register_vehicles[0].total_vehicles_period,
            percentage: this._gaugeService.getPercentage(actual_month.register_vehicles[0].total_vehicles_at_least_one_kit_period, actual_month.register_vehicles[0].total_vehicles_period),
          },
          supervision: {
            damage: actual_month.supervision[0].supervisions_made_with_damege,
            checked: actual_month.supervision[0].supervisions_made,
            percentage: this._gaugeService.getPercentage(actual_month.supervision[0].supervisions_made_with_damege, actual_month.supervision[0].supervisions_made)
          },
          total_supervised: {
            total_supervised: actual_month.vehicles_with_damage[0].total_supervised,
            supervised_with_damage: actual_month.vehicles_with_damage[0].supervised_with_damage,
            percentage: this._gaugeService.getPercentage((actual_month.vehicles_with_damage[0].supervised_with_damage), (actual_month.vehicles_with_damage[0].total_supervised))
          },
        },
        {
          customers: {
            customers_hired: last_month.customers[0].customers_premium,
            total: last_month.customers[0].customers_total,
            total_new: last_month.customers[0].customers_total_new,
            total_period: last_month.customers[0].customers_total_period,
            percentage: this._gaugeService.getPercentage(last_month.customers[0].customers_premium, last_month.customers[0].customers_total_new),
          },
          vehicles: {
            install_total: last_month.register_vehicles[0].total_vehicles_at_least_one_kit_period,
            total: last_month.register_vehicles[0].total_vehicles_period,
            percentage: this._gaugeService.getPercentage(last_month.register_vehicles[0].total_vehicles_at_least_one_kit_period, last_month.register_vehicles[0].total_vehicles_period),
          },
          supervision: {
            damage: last_month.supervision[0].supervisions_made_with_damege,
            checked: last_month.supervision[0].supervisions_made,
            percentage: this._gaugeService.getPercentage(last_month.supervision[0].supervisions_made_with_damege, last_month.supervision[0].supervisions_made)
          },
          total_supervised: {
            total_supervised: last_month.vehicles_with_damage[0].total_supervised,
            supervised_with_damage: last_month.vehicles_with_damage[0].supervised_with_damage,
            percentage: this._gaugeService.getPercentage((last_month.vehicles_with_damage[0].supervised_with_damage), (last_month.vehicles_with_damage[0].total_supervised))
          },
        },
        {
          customers: {
            customers_hired: historic.customers[0].customers_premium,
            total: historic.customers[0].customers_total,
            total_new: historic.customers[0].customers_total_new,
            total_period: historic.customers[0].customers_total_period,
            percentage: this._gaugeService.getPercentage(historic.customers[0].customers_premium, historic.customers[0].customers_total),
          },
          vehicles: {
            install_total: historic.register_vehicles[0].total_vehicles_at_least_one_kit_period,
            total: historic.register_vehicles[0].total_vehicles_period,
            percentage: this._gaugeService.getPercentage(historic.register_vehicles[0].total_vehicles_at_least_one_kit_period, historic.register_vehicles[0].total_vehicles_period),
          },
          supervision: {
            damage: historic.supervision[0].supervisions_made_with_damege,
            checked: historic.supervision[0].supervisions_made,
            percentage: this._gaugeService.getPercentage(historic.supervision[0].supervisions_made_with_damege, historic.supervision[0].supervisions_made)
          },
          total_supervised: {
            total_supervised: historic.vehicles_with_damage[0].total_supervised,
            supervised_with_damage: historic.vehicles_with_damage[0].supervised_with_damage,
            percentage: this._gaugeService.getPercentage((historic.vehicles_with_damage[0].supervised_with_damage), (historic.vehicles_with_damage[0].total_supervised))
          },
        },
      ];
      console.log(this.data);

      // data[0].forEach(element => {
      //   console.log(element);
      //   if (element.period != 'allTime') {
      //     if (element.customers) {
      //       element.customers.percentage = this._gaugeService.getPercentage(element.customers.customers_hired, element.customers.total);
      //     }
      //     element.vehicles.percentage = this._gaugeService.getPercentage(element.vehicles.install_new, element.vehicles.total_new);
      //     element.supervision.percentage = this._gaugeService.getPercentage(element.supervision.damage, element.supervision.checked);
      //     element.total_supervised.percentage = this._gaugeService.getPercentage(element.total_supervised.supervised_with_damage, element.total_supervised.total_supervised);
      //   }
      //   if (element.period === 'allTime') {
      //     if (element.customers) {
      //       element.customers.percentage = this._gaugeService.getPercentage(element.customers.customers_hired, element.customers.total_new);
      //     }
      //     element.vehicles.percentage = this._gaugeService.getPercentage(element.vehicles.install_total, element.vehicles.total);
      //     element.supervision.percentage = this._gaugeService.getPercentage(element.supervision.damage, element.supervision.checked);
      //     element.total_supervised.percentage = this._gaugeService.getPercentage(element.total_supervised.supervised_with_damage, element.total_supervised.total_supervised);
      //   }
      // });
      // console.log(this.data);
      this.ngxService.stop();
      this.changeDashboardData('allTime');
    }).catch(error => {
      this.gaugeData = null;
      this.ngxService.stop();
    })

  }

  ngOnInit() {
    this.loadProfile();
  }

  // #Get Admins Ind
  // Gets user data (name, etc)
  public loadProfile() {
    this.profileService.getProfile().subscribe(
      response => {
        this.loadProfileSuccess(response)
      },
      error => {
        this.loadProfileError(error);
      }
    );
  }
  /*
      Load Members Callbacks
  */
  private loadProfileSuccess(response: any) {
    let code = response.success;
    if (code != 1) {
      this.utils.errorManagement(response);
    } else {
      this.user = response.data;
    }
  }

  private loadProfileError(error: any) {
    //  console.error( error );
  }

  changeDashboardData(period) {
    if (period === "now") {
      this.showingData = 'now'
      this.gaugeData = this.data[0]
    }
    if (period === "oneMonth") {
      this.showingData = 'oneMonth'
      this.gaugeData = this.data[1]
    }
    if (period === "allTime") {
      this.showingData = 'allTime'
      this.gaugeData = this.data[2]
    }
    console.log(this.gaugeData);
  }

  getDashboardData(period, period_id) {
    return new Promise((resolve, reject) => {
      this._gaugeService.getDashboard(period).subscribe(
        response => {
          console.log(response);
          let data: any;
          if (response.success != 1) {
            reject(response.message);
          } else {
            data = response.data;
            data.period = period_id;
            resolve(data)
          }
        }, error => {
          reject(error);
          console.log(error);
        }
      );
    })
  }

  getDashboardDataNew() {
    return new Promise((resolve, reject) => {
      this._gaugeService.getDashboardNew().subscribe(
        response => {
          console.log(response);
          let data: any;
          if (response.success != 1) {
            reject(response.message);
          } else {
            data = response.data;
            resolve(data);
          }
        }, error => {
          reject(error);
        }
      );
    })
  }

  openMessageDialog(success, message): void {
    let data = {
      message: message,
      success: success
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent, {
      panelClass: 'modal-send-notification',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }

}

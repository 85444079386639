import { Component, OnInit, ElementRef } from '@angular/core';
import { UtilsService } from '../../app.utils';
import noInternet from 'no-internet';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-alta-nuevo-cliente',
  templateUrl: './alta-nuevo-cliente.component.html',
  styleUrls: ['./alta-nuevo-cliente.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {
      displayDefaultIndicatorType: false,
      showError: true
    }
  }]
})

//Este flujo aún está pendiente, sigue en desarrollo 

export class AltaNuevoClienteComponent implements OnInit {   
  initSteps : boolean = false;
  currentUrl: string = this.router.url;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _location: Location,
    ) {
      console.log(this.currentUrl);
    }

  ngOnInit() {
  }

  nextStep( stepper: MatStepper ) {
    stepper.next();
    this.initSteps = true;
  }

  lastStep() {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: 'El cliente ha sido dado de alta de forma exitosa.',
        success: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      localStorage.removeItem('NewCompany');
      this.router.navigate(['/usersClientes']);
    });
  }

  back(){
    this._location.back();
  }
}

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public userProfile: any;

    constructor( private http: Http,
                 private httpClient: HttpClient,
                 private cookieService: CookieService ) { }

    // #LOGIN
    login( member: any) {
        const body = JSON.stringify( member );
        const headers = new Headers({
            'Content-Type': 'application/json',
            'lang': 'es_mx'
        });

        const url = `${ GLOBAL_URL }/api/login`;
        return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
    }

    // #LOGOUT
    logout() {
        const access_token: string = this.cookieService.get('access_token');
        const token_type: string = this.cookieService.get('token_type');

        const headers = new HttpHeaders({
            'lang': 'es_mx',
            'Authorization': `${ token_type } ${ access_token }`
        });

        const url = `${ GLOBAL_URL }/api/logout`;

        return this.httpClient.post( url, { headers } ).map( (response: Response) => response );

    }

}

// Content
export const contentMessages = {
    title_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'maxlength', text: 'Máximo 60 caracteres', rules: ['dirty'] }
    ],
    body_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'notEqualTo', text: 'El nombre es el mismo', rules: ['dirty'] },
        { name: 'nameExists', text: 'Ya existe una base con ese nombre', rules: ['dirty'] },
        { name: 'maxlength', text: 'Máximo 45 caracteres', rules: ['dirty'] }

    ],
    image_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ]
};

export const contactMessages = {
    title_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ],
    bodyUrl_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'url', text: 'Ingrese una URL válida', rules: ['touched'] }
    ],
    bodyEmail_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'email', text: 'Ingrese una dirección de correo válida', rules: ['dirty'] }
    ],
    bodyPhone_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'number', text: 'Introduzca unicamente dígitos', rules: ['touched', 'dirty'] },
        { name: 'minlength', text: 'Mínimo 10 dígitos', rules: ['dirty'] }
    ],
    detail_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ]
};
// FAQs
export const faqsMessages = {
    question_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ],
    answer_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] }
    ]
};
// Featured
export const featuredMessages = {
    featured_title_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'maxlength', text: 'Máximo 60 caracteres', rules: ['dirty'] }
    ],
    body_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'url', text: 'Ingrese una URL válida', rules: ['dirty'] }
    ],
    image_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ]
};
// Legals
export const legalsMessages = {
    title_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'maxlength', text: 'Máximo 60 caracteres', rules: ['dirty'] }
    ],
    body_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] }
    ]
};
// Login
export const loginMessages = {
    user_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'email', text: 'Ingrese una dirección de correo válida', rules: ['dirty'] }
    ],
    // Password Error Messages
    pass_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ]
};
// Recover Password
export const recoverPassMessages = {
    email_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'pattern', text: 'Ingrese una dirección de correo válida', rules: ['dirty'] }
    ]
};
// Staff
export const staffMessages = {
    name_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'pattern', text: 'Ingresa únicamente letras', rules: ['touched', 'dirty'] },
        { name: 'whitespace', text: 'Campo requerido', rules: ['touched', 'dirty'] },
    ],
    last_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ],
    email_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'pattern', text: 'Ingrese una dirección de correo válida', rules: ['dirty'] },
        { name: 'duplicated',  text: 'El correo está duplicado', rules: ['dirty'] },
        { name: 'email', text: 'Ingrese una dirección de correo válida', rules: ['touched', 'dirty'] },
    ],
    pass_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'minlength', text: 'Utilice al menos 6 caracteres', rules: ['touched', 'dirty'] },
    ],
    passc_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'minlength', text: 'Utilice al menos 6 caracteres', rules: ['touched', 'dirty'] },
        { name: 'equalTo', text: 'Las contraseñas no coinciden', rules: ['touched', 'dirty'] }
    ],
    type_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] }
    ],
    dirver_number_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'number', text: 'Ingresa únicamente números' },
        { name: 'digits', text: 'Ingresa únicamente números' },
    ]
};

export const messageMessages = {
    type_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] }
    ],
    title_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] }
    ],
    url_image_errors: [
        { name: 'url', text: 'Ingrese una URL válida', rules: ['touched', 'dirty'] }
    ],
    desc_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] }
    ],
    btnAction_errors: [
        { name: 'required', text: 'Campo requerido', rules: ['touched', 'dirty'] },
        { name: 'url', text: 'Ingrese una URL válida', rules: ['dirty'] }
    ]
};

export const commonMessages = {
    generic_errors : [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'minLength', text: 'Debes seleccionar un modelo', rules: ['invalid'] },
        { name: 'duplicate', text: 'Hay bases repetidas', rules: ['invalid'] },
        { name: 'whitespace', text: 'Campo requerido', rules: ['dirty'] },
    ]
}

export const passwordMessages = {
    generic_errors : [
        { name: 'required', text: 'Campo requerido', rules: ['invalid', 'touched'] },
        { name: 'minlength', text: 'Utilice al menos 6 caracteres', rules: ['touched'] },
        { name: 'equalTo', text: 'La contraseña debe ser la misma', rules: ['invalid', 'touched'] },
        { name: 'pattern', text: 'La contraseña debe contener al menos una letra mayúscula y no tener espacios', rules: ['invalid', 'touched'] },
        { name: 'maxlength', text: 'Utilice hasta 12 caracteres', rules: ['touched'] },
    ]
}

export const altaVehiculo = {
    generic_errors : [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
    ],
    dropdown_errors : [
        { name: 'minLength', text: 'Debes seleccionar una opcion', rules: ['invalid'] },
        { name: 'required', text: 'Campo requerido', rules: ['invalid', 'touched'] },
    ]
}

export const datosVehiculos = {
    serial_num : [
        { name: 'required', text: 'Campo requerido', rules: ['touched'] },
        { name: 'minlength', text: 'Utilice al menos 5 caracteres', rules: ['dirty', 'touched'] },
        { name: 'maxlength', text: 'Utilice hasta 20 caracteres', rules: ['dirty', 'touched'] },
        { name: 'duplicate', text: 'Ese número de serie ya está registrado', rules: ['dirty', 'touched'] },
        { name: 'pattern', text: 'Ingresa únicamente letras y números', rules: ['dirty', 'touched'] },

    ],
    sello_foliado : [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'minlength', text: 'Utilice 4 caracteres', rules: ['dirty', 'touched'] },
        { name: 'maxlength', text: 'Utilice hasta 4 caracteres', rules: ['dirty', 'touched'] },
        { name: 'pattern', text: 'Ingresa únicamente letras y números', rules: ['dirty', 'touched'] },
    ],
    econ_num : [
        { name: 'minlength', text: 'Utilice mínimo 3 caracteres', rules: ['dirty'] },
        { name: 'maxlength', text: 'Utilice hasta 8 caracteres', rules: ['dirty'] },
        { name: 'pattern', text: 'Ingresa únicamente letras y números', rules: ['dirty', 'touched'] },

    ],
    vehicle_limits : [
        { name: 'required', text: 'Campo requerido' },
        { name: 'number', text: 'Ingresa únicamente números' },
        { name: 'lesserThan', text: 'El número no puede ser menor o igual al actual' }

    ]

}

export const companyName = {
    name_errors : [
        { name: 'required', text: 'Campo requerido', rules: ['touched'] },
        { name: 'minLength', text: 'Utilice al menos 3 caracteres', rules: ['dirty', 'touched'] },
        { name: 'notUnique', text: 'Nombre de compañia ya registrado', rules: ['dirty', 'touched'] },
        
    ]
}

export const altaMarcasModelos = {
    brand_errors : [
        { name: 'required', text: 'Campo requerido', rules: ['dirty'] },
        { name: 'nameExists', text: 'Ya existe una marca con ese nombre', rules: ['dirty'] },
        { name: 'modelExists', text: 'Ya existe un modelo con ese nombre', rules: ['dirty'] }
    ]
}

export const radios = {
    radio_errors : [
        { name: 'required', text: 'Debes seleccionar al menos uno', rules: ['dirty'] },
        { name: 'requiredTrue', text: 'No hay usuarios con las opciones seleccionadas', rules: ['dirty'] },
    ]
}
import { Component, OnInit, OnChanges, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import noInternet from 'no-internet';
import { Router } from '@angular/router';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { InputPasswordService } from '../../../services/input-password.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-alta-equipo-soltek',
  templateUrl: './modal-alta-equipo-soltek.component.html',
  styleUrls: ['./modal-alta-equipo-soltek.component.css']
})
export class ModalAltaEquipoSoltekComponent implements OnInit {
  userType = Number(localStorage.getItem('userType'));
    // 9 = moderador
    // 5 = instalador soltek
    // 4 = supervisor soltek
    // 2 = admin cliente
    // 1 = super admin -> Admin sistema
  formAltaEquipoSoltek: FormGroup;
  user_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.staffMessages.email_errors;
  pass_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  serviceError:string;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
  password = new FormControl('',  [      
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12)
  ]);
  password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.password)
  ]);

  tabs = ['Nuevo Usuario', 'Carga Masiva'];
  tab_selected = new FormControl(0);
  DragAndDropFileOperation = 3; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,
               public dialogRef: MatDialogRef<ModalAltaEquipoSoltekComponent>,
               private profileService: ProfileService,
               private utils: UtilsService,
               private router: Router,
               private spinner: NgxSpinnerService,
               private inputPasswordService: InputPasswordService,
               private ngxService: NgxUiLoaderService) {
                 console.log(data);
                 this.formAltaEquipoSoltek = this.createFormData(data)
               }

  ngOnInit() { 
  }
  refresh(){
    window.location.reload();
  }

  onNoClick( value? ): void {
    this.dialogRef.close( value );
  }

  public addMember(  ) {
    noInternet().then(offline => {
        if (offline) {
            this.utils.alertError('Verifica tu conexión');
        } else {
          console.log(this.formAltaEquipoSoltek.value);
          let data = this.formAltaEquipoSoltek.value;
          console.log(data);
          this.ngxService.startLoader('loader-modal');
          this.profileService.addMember( [data] ).subscribe(
            response => { 
              this.ngxService.stopLoader('loader-modal');
              this.addMemberSuccess( response ); 
            },
            error => { 
              this.ngxService.stopLoader('loader-modal');
              this.addMemberError( error ); 
            }
          );
        } 
    })
  }
 
 
  
  private addMemberSuccess( response: any) {  
    console.log(response);
    let code = response.success;
    if (code != 1) {
        this.serviceError = response.message;
    } else {
        this.onNoClick(true);
    }
  }
  
  private addMemberError( error: any ) {
    console.log(error);
    this.onNoClick(false);
  }

  createFormData( data ){
   
    return new FormGroup({
      'name' : new FormControl('', [Validators.required]),
      // 'last_name' : new FormControl('', [Validators.required]),
      'email' : new FormControl('', [
              Validators.required, 
              Validators.email,
              CustomValidators.email

            ]),
      'password' : this.password, 
      'password_confirmation' : this.password_confirmation,
      'type' : new FormControl('', [Validators.required]),
      'company_id' : new FormControl(data.company_id)
    })
  }

  public randomPassword( e ) {
    //      console.log(e.controls.password);
      this.inputPasswordService.randomPassword( e ); 
    }

}

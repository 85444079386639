import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Inject, ChangeDetectorRef,ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PushService } from '../../../services/push.service';
import { FileItem } from '../../../directives/ng-drop-files/file-item';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../../validators/validation-messages';
import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Router } from '@angular/router';
import { emit } from 'cluster';

@Component({
  selector: 'app-modal-send-notification-clientes',
  templateUrl: './modal-send-notification-clientes.component.html',
  styleUrls: ['./modal-send-notification-clientes.component.css']
})
export class ModalSendNotificationClientesComponent implements OnInit {
  mouseOverDropzone = false;
  files: FileItem[] = [];
  hasImage = false;
  form: FormGroup;
  message_errors: any = ValidationMessages.contentMessages.body_errors;
  radio_errors: any = ValidationMessages.radios.radio_errors;
  route = this.router.url;

  @ViewChild('fileInput') el: ElementRef;
  editFile: boolean = true;
  imageUrl: any;
  uploadImage: boolean = false;
  userFormGroup = new FormGroup({
    'user_admin_cliente' :  new FormControl(null),
    'user_sup_cliente' : new FormControl(null),
    'user_inst_cliente' : new FormControl(null),
    'is_touched' : new FormControl(false, [Validators.required, Validators.requiredTrue])
  })
 
  constructor(
    public dialogRef: MatDialogRef<ModalSendNotificationClientesComponent>,
    private cd: ChangeDetectorRef,
    private pushService: PushService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    console.log(data);
    this.form = new FormGroup({
      'users' : new FormArray( [this.userFormGroup], Validators.required),
      'title' : new FormControl('',
                    Validators.required
      ),
      'message' : new FormControl('',
                    Validators.required
      ),
      'image': new FormControl('', []),
    })
  }

  uploadImages( files: FileItem[] ){ 
    console.log( files );
  }

  ngOnInit() {
  }

  onNoClick( result? ): void {
    console.log(result);
    if (result != undefined){
      this.dialogRef.close( result );
    } else {
      this.dialogRef.close();
    }
  }

  hasImages(event){
    console.log(event);
    this.hasImage = event;
  }

  inputChange(){
    console.log('change');
    this.checkRadioSelected();
  }

  radioToggle(event){
    console.log(event.target.name);
    let clickedRadio =   this.userFormGroup.controls[event.target.name];
    console.log(clickedRadio);
    if (clickedRadio.touched && clickedRadio.value != null) {
      clickedRadio.setValue(null);
    }
  }

  checkRadioSelected() {
    let isSelected = [];
    let radios = [
      'user_sup_cliente',
      'user_admin_cliente',
      'user_inst_cliente'
    ]
    for (let i = 0; i < radios.length; i++) {
      if (this.userFormGroup.controls[radios[i]].value != null ){
        isSelected.push(radios[i])
      }
      if (this.userFormGroup.controls[radios[i]].value === null){
        isSelected.splice(i ,1)
      }
      
    }

    if (isSelected.length < 1) {
      this.userFormGroup.controls.is_touched.reset();
      this.userFormGroup.controls.is_touched.updateValueAndValidity({emitEvent : false})
      return false;
    }

    if (isSelected.length >= 1) {
      this.userFormGroup.controls.is_touched.setValue(true);
      this.userFormGroup.controls.is_touched.updateValueAndValidity({emitEvent : true})
      return true;
    }
  }

   // Function to upload a file uploaded file
   uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    console.log(file);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = (event:any) => {
        // console.log(reader.result);
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
        // console.log(reader.result);
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();   
  
      this.form.patchValue({
        image: file,
      })    
    }
    this.hasImage = true;
    console.log(this.form.value);
  }

  droppedFile(file) {
    console.log(file.file);
    let reader = new FileReader();
    reader.readAsDataURL(file.file);
      reader.onload = (event:any) => {
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
      }
      this.cd.markForCheck();   
  
      this.form.patchValue({
        image: file.file,
      })  
      console.log(this.form);
  }

  // Function to remove uploaded file
  removeUploadedFile() {
    this.form.patchValue({
      image: [null]
    });
    this.imageUrl = null;
    this.uploadImage = false;
    this.hasImage = false;

  }

  sendNotification(){
    this.ngxService.start();
    this.checkRadioSelected();
    console.log(this.form.valid);
    if ( !this.form.valid ) {
  
    } else {
      console.log(this.form.value); 
      let user_list = this.data;
      let send_to_users = [];
      console.log(user_list);
      let selected_users = this.getUsersValues();
      console.log(selected_users);
      let keys = Object.keys(user_list);
      let i;
      for (i=0; i < keys.length; i++){
        let user = user_list[keys[i]][0];
        console.log(user);
        let user_type_selected : Boolean;
        selected_users.forEach(element => {
          if (Number(user.user_type_id) === element) {
            send_to_users.push(user.id);
          }
        });
      }
      console.log(send_to_users);
      if (send_to_users.length > 0){
        let data = {
          'image' : this.form.value.image,
          'message': this.form.value.message,
          'title': this.form.value.title,
          'users': send_to_users
        };
        console.log(data);
        this.pushService.sendNotificationClients( data ).subscribe(
          response => { 
            let callback:any = response;
            this.ngxService.stop();
            if (callback.success != 1){
              this.onNoClick( false );
  
            } else {
              this.onNoClick( true );
            }
          },
          error => { 
            this.onNoClick( false );
  
            this.ngxService.stop();
          }
      );
      } else {
       this.userFormGroup.controls.is_touched.setErrors(
         {requiredTrue: false}
       );
       this.userFormGroup.controls.is_touched.markAsTouched();
       // this.userFormGroup.controls.is_touched.updateValueAndValidity();

       console.log(this.userFormGroup.controls.is_touched.valid);
       this.ngxService.stop();
      }
    }
  }

  getUsersValues(){
    let selected_users = [];
    if (this.userFormGroup.value.user_admin_cliente != null){
      selected_users.push(Number(this.userFormGroup.value.user_admin_cliente));
    }
    if (this.userFormGroup.value.user_sup_cliente != null){
      selected_users.push(Number(this.userFormGroup.value.user_sup_cliente));
    }
    if (this.userFormGroup.value.user_inst_cliente != null){
      selected_users.push(Number(this.userFormGroup.value.user_inst_cliente));
    }
    

    return selected_users;
    
  }

}

import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { CustomValidators } from 'ng4-validators';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-editar-base',
  templateUrl: './modal-editar-base.component.html',
  styleUrls: ['./modal-editar-base.component.css']
})
export class ModalEditarBaseComponent implements OnInit {
  message_errors: any = ValidationMessages.contentMessages.body_errors;
  formEditBase: FormGroup;
  serviceError: string;

  constructor(
                public dialogRef: MatDialogRef<ModalEditarBaseComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private vehiclesService: VehiclesService,
                private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    console.log(this.data);
    let currentName = new FormControl(this.data.name);
    let name = new FormControl(this.data.name, [ 
        Validators.required,
        CustomValidators.notEqualTo(currentName)
    ]);
 
    this.formEditBase = this.fb.group({
      'name' : name,
      'base_id' : new FormControl( this.data.base_id,
      ),
    });
    this.checkFormChanges();
  }

  onNoClick( value ): void {
    this.data = null;
    this.dialogRef.close( value);
  }

  editBase(){
    console.log(this.formEditBase.value);
    this.ngxService.startLoader('loader-modal');
    this.vehiclesService.editBase( this.formEditBase.value ).subscribe(
      response => { 
        this.ngxService.stopLoader('loader-modal');

        console.log(response);  
        if (response.success === 1){
          this.onNoClick( true );
        } else {
          this.serviceError = response.message;
        }
        
      },
      error => { 
        this.ngxService.stopLoader('loader-modal');
        console.log(error); 
        this.serviceError = error;
        // this.spinner.hide(); 
      }
    );
  }

  checkFormChanges() {
    this.formEditBase.valueChanges.subscribe( data => {
      console.log(this.formEditBase);
      // const invalid = [];
      const controls = this.formEditBase.controls;
      const nameData = this.formEditBase.value.name;
      // console.log(nameData);
      if (this.checkIfNameExists(nameData) === true) {
          controls.name.setErrors({ 
            nameExists : true
          });
      } 
      // for (const name in controls) {
      //   if (controls[name].invalid) {
      //       invalid.push(name);
      //   }
      // }
      // console.log(invalid);
    })
  }

  private checkIfNameExists(nameData) {
    return this.data.bases_list.includes(nameData);
  }

}

import { Component, OnInit, Input, Output, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { FileItem } from '../../directives/ng-drop-files/file-item';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { UploadService } from '../../services/upload/upload.service';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css']
})
export class DragAndDropComponent implements OnInit {
  @Input() CompanyId: string;
  @Input() FileType: any; // 'image', 'xlsx', etc
  @Input() FileOperation: any; // 0 = 'choferes', 1= 'vehiculos', 2 ='bases', 3 = 'equipo soltek', 4 = 'puntos de revisión'
  @Output() Response: EventEmitter<boolean> = new EventEmitter();
  Config = [
    {
      text_string: 'choferes',
      endpoint: 'uploadDrivers',
      file: 'drivers.xlsx',
    },
    {
      text_string: 'vehículos',
      endpoint: 'uploadVehicles',
      file: 'vehicles.xlsx',
    },
    {
      text_string: 'bases',
      endpoint: 'uploadBases',
      file: 'bases.xlsx',
    },
    {
      text_string: 'usuarios',
      endpoint: 'uploadUsers',
      file: 'users.xlsx',
    },
    {
      text_string: 'puntos',
      endpoint: 'uploadPoints',
      file: 'points.xlsx',
    },
  ];

  // Dropzone config variables
  file: FileItem[] = [];
  fileName: string;
  form: FormGroup;
  hasBeenSent: boolean = false;
  hasErrors: boolean = false;
  hasFile = false;
  mouseOverDropzone = false;
  responseMessage: string;
  @ViewChild('fileInput') el: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    public uploadService: UploadService,
    private ngxService: NgxUiLoaderService
  ) {
    this.form = new FormGroup({
      excel: new FormControl('',
      Validators.required),
      company_id:  new FormControl(this.CompanyId),
    });
   }

  ngOnInit() {
    console.log(this.FileType);
    console.log(this.Config[this.FileOperation]);
  }
  
  arrayBuffer;
  // Función para subir archivos arrastrando el archivo
  droppedFile(file) {
    console.log(file.file);
    let reader = new FileReader();
    reader.readAsArrayBuffer(file.file);
    reader.onload = (event:any) => {
      // this.imageUrl = event.target.result;
      // this.editFile = false;
      // this.uploadImage = true
      this.arrayBuffer = reader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});     
      const filelist = [];    
      console.log(filelist);    
    }
    this.cd.markForCheck();   
    this.form.patchValue({
      excel: file.file,
    });
    this.hasFile = true;
    this.fileName = file.file.name;
    console.log(this.form.value);
  }

  // Función para subir archivos seleccionando
  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    console.log(file);
    if (file) {
      reader.readAsDataURL(file);
      this.cd.markForCheck();     
      this.form.patchValue({
        excel: file,
      });
      this.hasFile = true;
      this.fileName = file.name;
      console.log(this.form.value);
    } else {
      this.hasFile = false;
    }
   
  }

  removeFile() {
    this.form.patchValue({
      excel: null,
    });
    this.hasFile = false;
    this.fileName = null;
    this.cd.markForCheck();   
    console.log(this.form);
  }

  closeDialog( value?: boolean ) {
    this.Response.emit( value );
  }

  errorPoints(response) {
    console.log(response);
    const keys = Object.keys(response);
    const errors = [];
    for (let i = 0; i < keys.length; i++) {
      const element = response[keys[i]];
      const subKeys = Object.keys(element);
      console.log(subKeys);
      for (let j = 0; j < subKeys.length; j++) { 
        const subElement = element[subKeys[j]];
        errors.push(`${subElement} <br>`);
        this.hasErrors = true;
      }
    }
    this.responseMessage = errors.toString().replace(/,/g, '');
    console.log(errors);
  }

  onSubmit() {
    this.ngxService.start();
    const formData: FormData = new FormData();
    if (this.CompanyId){
      formData.append('company_id', this.CompanyId);
    }
    formData.append('excel', this.form.value.excel, this.fileName )
    console.log(formData);
    let endpoint: string = this.Config[this.FileOperation].endpoint;
    this.uploadService[endpoint](formData).subscribe(
      response => {
        this.hasBeenSent = true;
        console.log(response);
        if (response.success === 1) {
          if (response.data) {
            if (this.FileOperation !== 4) {
              const errors = [];
              errors.push(`<h5>`+ response.message + '</h5> <br/> <p>Sin embargo, algunos errores fueron reportados:</p>');
              response.data.forEach(element => {
                const text = `<p class="text-left">Error en la fila <b>` + element.row + `</b>, columna <b>` + element.attr + `</b> con mensaje: "<b>` + element.errors[0] + `</b>"</p>`;
                errors.push(text);
              });
              this.responseMessage = errors.toString().replace(/,/g, '');
              this.hasErrors = true;
            } else {
              this.errorPoints(response.data[0]);
            }
            
          } else {
            this.responseMessage = response.message;
            this.hasErrors = false;
          }
        }
        if (response.success === 0) {
          const errors = [];
            errors.push(`<h5>`+ response.message + '</h5> <br/>');
            if (response.data) {
              errors.push(`<p>Sin embargo, algunos errores fueron reportados:</p>`);
              response.data.forEach(element => {
                const text = `<p class="text-left">Error en la fila <b>` + element.row + `</b>, columna <b>` + element.attr + `</b> con mensaje: "<b>` + element.errors[0] + `</b>"</p>`;
                errors.push(text);
              });
            }
            this.responseMessage = errors.toString().replace(/,/g, '');
            this.hasErrors = true;
        } 
        this.ngxService.stop();
        this.removeFile();
      },
      error => {
        this.responseMessage = error.message;
        this.hasBeenSent = true;
        this.hasErrors = true;
        console.log(error);
        this.ngxService.stop();
        this.removeFile();
      }
    );
  }

}

import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { ShowBases } from '../../../interfaces/show-bases.interface';
import { Router } from '@angular/router';
import noInternet from 'no-internet';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export class companiesDriversList {
    company: string
    company_id: number
    drivers: []
  }

@Component({
  selector: 'app-modal-alta-choferes',
  templateUrl: './modal-alta-choferes.component.html',
  styleUrls: ['./modal-alta-choferes.component.css']
})
export class ModalAltaChoferesComponent implements OnInit {
  bases;
  base_errors: any = ValidationMessages.staffMessages.name_errors;
  companyBases= [];
  companiesId = [];
  companiesDriversPremium: any = [];
  companiesDriversFreemium: any = [];
  companiesDriversAll: any = [];
  cliente_errors: any = ValidationMessages.staffMessages.name_errors;
  formAltaChoferes: FormGroup;
  formDragAndDrop: FormGroup;
  getCompaniesDrivers: companiesDriversList[];
  user_errors: any = ValidationMessages.staffMessages.name_errors;
  number_driver_errors: any = ValidationMessages.staffMessages.dirver_number_errors;
  selectedCompany;
  isCompanySelected: boolean = false;
  serviceError:string;
  tabs = ['Nuevo Chofer', 'Carga Masiva'];
  hasSelectedCompany: boolean = false;
  tab_selected = new FormControl(0);
  DragAndDropFileOperation = 0; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc

  constructor(  public dialogRef: MatDialogRef<ModalAltaChoferesComponent>,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private profileService: ProfileService,
                private utils: UtilsService,
                private vehicleService: VehiclesService,
                private router: Router,
                private ngxService: NgxUiLoaderService) { 
                  console.log(this.tabs[0]);
                }

  ngOnInit() {
    this.ngxService.startLoader('loader-toggle-choferes');
    this.loadBases();
    this.loadCompanies();

    this.formAltaChoferes = this.fb.group({
      'name' : new FormControl('',
          [
            Validators.required,
            Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*')
        ]
      ),
      'last_name' : new FormControl('',
         [
            Validators.required,
            Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*')
          ]
      ),
      'driver_id' : new FormControl('',
      [
        Validators.required,
        CustomValidators.number,
        CustomValidators.digits
      ]
      ),
      'company_id' : new FormControl('',
      Validators.required
      ),
      'type' : new FormControl(8,
      Validators.required
      ),
    });

    this.formDragAndDrop = this.fb.group({
      company_id : new FormControl(null),
    });

    //Listens for value changes on fk_vehicle_model select
    this.formAltaChoferes.controls['company_id'].valueChanges.subscribe(
      data =>{
        let company_data = data;
        console.log(company_data);
        var split = company_data.split("*company_id*");
        console.log(split);
        if (data != '') {
          console.log(split[0]);
          this.selectedCompany = split[0];
          // console.log(this.selectedCompany);
          this.companyBases = [];
          this.filterBases( this.selectedCompany);
          console.log(this.companyBases);
        }  else {
          this.selectedCompany = false;
        }
      
      }
    )

    this.formDragAndDrop.controls['company_id'].valueChanges.subscribe(
      data => {
        console.log(data);
        switch (data) {
          case null:
            this.hasSelectedCompany = false;
            break;
          case 'null':
            this.hasSelectedCompany = false;
            break;
        
          default:
            this.hasSelectedCompany = true;
            break;
        }
      }
    )
  }

  filterBases( selectedCompany ) {
    const bases: any = this.bases;
    bases.forEach(element => {
      if ( element.company_name === selectedCompany ) {
        this.companyBases.push(element);
      }
    });
    if (this.companyBases === undefined || this.companyBases.length == 0) {
      this.isCompanySelected = true;
      this.formAltaChoferes.removeControl('base');
      this.formAltaChoferes.updateValueAndValidity();
    } else {
      this.formAltaChoferes.addControl('base', new FormControl('', Validators.required));
      this.isCompanySelected = true;
    }
    console.log(this.formAltaChoferes.value);
  }

  onNoClick( result?, data? ): void {
    let response = {
      result: result,
      company: data
    }
    this.dialogRef.close( response );
  }

  public addMember( form ) {
    noInternet().then(offline => {
        if (offline) {
            this.utils.alertError('Verifica tu conexión');
        } else {
            this.ngxService.startLoader('loader-toggle-choferes');
            if (form.valid) {
              let company = form.value.company_id;
              let split_company = company.split("*company_id*");
              let id_company = split_company[1];
              let i;
              let Data = {
                name: form.value.name,
                last_name: form.value.last_name,
                driver_number: form.value.driver_id,
                base_id: form.value.base,
                company_id: id_company,
              }

              console.log(Data);
                this.profileService.addDriver( Data ).subscribe(
                    response => { 
                      this.ngxService.stopLoader('loader-toggle-choferes');
                      if (response.success != 1) {
                        this.addMemberError( response.message ); 
                      } else {
                        this.addMemberSuccess( response, this.selectedCompany ); 
                      }
                    },
                    error => { 
                      this.ngxService.stopLoader('loader-toggle-choferes');
                      this.addMemberError( error ); 
                    }
                );
            } 
        }
    })
  }

  refresh() {
      window.location.reload();
  }
  
  private addMemberSuccess( response: any, company) {  
    this.onNoClick( 'true', company );
    console.log(response);
  }
  
  private addMemberError( error: any ) {
    this.serviceError = error;
    console.error(error);
  }

  // Carga la lista de bases
  public loadBases() {
    this.vehicleService.getBases().subscribe(
        response => { this.loadBasesSuccess( response );   
        },
        error => { this.loadBasesError( error );} 
    );
}

// loadBases() Callbacks
private loadBasesSuccess( response: any ) {
    let code = response.success;
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      this.bases = response.data;
      console.log(this.bases);
    }
}

private loadBasesError( error: any ) {
    console.error( error );
}

// Obtiene la lista de compañías existentes
   public loadCompanies() {
     Promise.all([
       this.loadPremiumCompanies(),
       this.loadFreemiumCompanies()
     ]).then((values) => {
      this.ngxService.stopLoader('loader-toggle-choferes');
     }, (error) => {
      this.ngxService.stopLoader('loader-toggle-choferes');
     });
  }

  loadPremiumCompanies() {
    return new Promise ((resolve, reject) => {
      this.profileService.getPremiumCompanies().subscribe(
        response => { 
          console.log(response.data);
          const keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            const element = response.data[keys[i]];
            const companyData = {
              company: keys[i],
              company_id: element[0].id_company
            }
            this.companiesDriversPremium.push(companyData);
            this.companiesDriversAll.push(companyData);
          }
          resolve (true);
        },
        error => { 
          reject (false);
        }
        );
      })
  }

  loadFreemiumCompanies() {
    return new Promise ((resolve, reject) => {
      this.profileService.getFreemiumCompanies().subscribe(
        response => { 
          console.log(response.data);
          const keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            const element = response.data[keys[i]];
            const companyData = {
              company: keys[i],
              company_id: element[0].id_company
            }
            this.companiesDriversFreemium.push(companyData);
            this.companiesDriversAll.push(companyData);
          }
          resolve (true);
        },
        error => { 
          reject (false);
        }
        );
      })
  }
  
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm, FormsModule, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { InputPasswordService } from '../../../services/input-password.service';
import { CustomValidators } from 'ng4-validators';
import { ProfileService } from '../../../services/users/profile.service';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { resolve } from 'url';
import { SharingServiceService } from "../../../services/sharing-service.service";


@Component({
  selector: 'app-form-upgrade-cliente',
  templateUrl: './form-upgrade-cliente.component.html',
  styleUrls: ['./form-upgrade-cliente.component.css']
})
export class FormUpgradeClienteComponent implements OnInit {
  @Output() nextStep: EventEmitter<boolean>;
  @Output() goBack: EventEmitter<boolean>;
  success: boolean = null;
  message: string = null;
  formDataCompany: FormGroup;
  name_errors: any = ValidationMessages.companyName.name_errors;
  companyData = JSON.parse(localStorage.getItem('upgradeUser'));
  companiesList;
  constructor(
    private profileService: ProfileService,
    private inputPasswordService: InputPasswordService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private sharingService: SharingServiceService,    
  ) {
    this.nextStep = new EventEmitter();
    this.goBack = new EventEmitter();

   }

  ngOnInit() {
    this.ngxService.start();
    if (this.companyData === undefined || this.companyData === null) {
      this.goBack.emit( true );
    } else {
      this.formDataCompany = this.createFormCompany();
      console.log( this.formDataCompany);
    }
    this.loadCompaniesNames().then(resolve => {
      console.log(resolve);
      this.companiesList = resolve;
      this.ngxService.stop();
    }, reject => {
      this.ngxService.stop();
    })
  }

  createFormCompany() {
    return new FormGroup({
      'name_company' : new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      'user_id' : new FormControl(this.companyData.id, [Validators.required]),
    })
  }

  submitForm() {
    const newCompanyName = this.formDataCompany.value.name_company;
    let notUnique: boolean = false;
    this.companiesList.forEach(element => {
      if ( element.toLowerCase() === newCompanyName.toLowerCase()) {
        notUnique = true;
        this.formDataCompany.controls.name_company.setErrors( {
          notUnique : true
        });
      }
    });

    if (!notUnique) {
      this.ngxService.start();
      Promise.all([ 
           this.serviceUpdateUser()
        ]).then((value) => {
          this.ngxService.stop();
          let response: any = value[0];
          if(response.success === 1){
            this.loadMembers().then(resolve => {
              this.createCustomerSuccess( response );
            }, reject => {
              this.createCustomerError(response);
            })
            console.log(value[0]);
          }
          if (response.success != 1){
            this.createCustomerError(response);
            console.log('status not 1');
          }
        }, function(error) {
          this.ngxService.stop();
          this.createCustomerError(error);
          console.log('stuff failed');
      })
    }
  }
  createCustomerSuccess( response ) {
    console.log( response );
    this.success = true;
    if (response.message === "" || response.message === null) {
      this.message = "La compañía fue dada de alta correctamente";
    } else {
      this.message = response.message;
    }
    this.openDialog(this.success, this.message);
  }
  
  createCustomerError( response ) {
    console.log( response );
    this.success = false;
    if (response.message === "" || response.message === null) {
      this.message = "Hubo un error al dar de alta esta compañía";
    } else {
      this.message = response.message;
    }
    this.openDialog(this.success, this.message);
  }

  loadMembers() {
    return new Promise((resolve, reject)=> {
      this.profileService.getPremiumCompanies().subscribe(
          response => { 
            console.log(response);
            let code = response.success;
            let data = response.data;
            const companyName = this.formDataCompany.value.name_company;
            console.log(data);
            if (code != 1) {
              console.log( response );
              reject( response )
            } else {
              let userNum = Number(Object.keys(data[companyName][0].users)[0]);
              console.log(data[companyName][0].users[userNum][0].id);
                

              let companyData = {
                id_company : data[companyName][0].id_company,
                company_name : companyName,
                id_user : data[companyName][0].users[userNum][0].id,
                vehicle_limit : data[companyName][0].limits.vehicles_limit
              }
              
              localStorage.setItem('NewCompany', JSON.stringify(companyData));
              localStorage.removeItem('upgradeUser');
              resolve( response );
            }
          },
          error => { 
            console.log( error );
            reject( error );
          }
      );
    })
}

loadCompaniesNames() {
  return new Promise((resolve, reject)=> {
    this.profileService.getPremiumCompanies().subscribe(
        response => { 
          console.log(response);
          let code = response.success;
          let data = response.data;
          const companiesList = Object.keys(data);
          resolve( companiesList );
        },
        error => { 
          console.log( error );
          reject( false );
        }
    );
  })
}


serviceUpdateUser() {
  return new Promise ((resolve, reject) => {
    console.log(this.formDataCompany.value)
    this.profileService.convertUser( this.formDataCompany.value ).subscribe(
      response => { 
        resolve(response)
        // console.log(response);
    },
        error => { 
        reject(error);
        // console.log(error);
    });
  })
}

openDialog( success, message ): void {
  const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
    panelClass: 'modal-send-notification',
    disableClose: true,
    data : {
      message: message,
      success: success
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    if (result) {
      this.nextStep.emit( true );
    }
  });
}

back(){
    this.goBack.emit( true );
}



}

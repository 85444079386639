import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UtilsService } from 'src/app/app.utils';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import noInternet from 'no-internet';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as ValidationMessages from '../../../validators/validation-messages';

export class Vehiculo {
  brand: string
  models: []
}



@Component({
  selector: 'app-modal-alta-marcas-modelos',
  templateUrl: './modal-alta-marcas-modelos.component.html',
  styleUrls: ['./modal-alta-marcas-modelos.component.css']
})
export class ModalAltaMarcasModelosComponent implements OnInit {
  formAltaMarcasModelos: FormGroup;
  modelsData : FormArray;
  modal_errors: any = ValidationMessages.altaMarcasModelos.brand_errors;
  existingBrands = [];
  existingModels = [];
  error_message: string;
  constructor(
    public dialogRef: MatDialogRef<ModalAltaMarcasModelosComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private utils: UtilsService,
    private vehicleService: VehiclesService,
    private router: Router,
    private ngxService: NgxUiLoaderService
  ) {
  }

  ngOnInit() {
      // EL parámetro 'data' continene los datos que fueron enviados al momento de abrir el modal:
      // hasBrand, brand, existingModels, modelsList
      this.data.existingModels.forEach(element => {
        this.existingBrands.push(element.brand.toLowerCase());
      });
      // Si se va a dar de alta un modelo a una marca existente se genera este formulario
      if (this.data.hasBrand) {
        console.log(this.data.modelsList);
        this.data.modelsList.forEach(element => {
          this.existingModels.push(element.description.toLowerCase());
        });
        console.log(this.existingModels);
        this.formAltaMarcasModelos = this.fb.group({
          'brand_id' : new FormControl(this.data.brand[1],
              Validators.required
          ),
          'models': this.fb.array([this.createModelData()])
        });
        this.checkFormModelChanges();  

      } else {
      // Si se va a dar de alta una marca nueva, con sus modelos, se usará esta configuración del formulario
        this.formAltaMarcasModelos = this.fb.group({
          'name_brand' : new FormControl(this.data.brand[0],
              Validators.required
          ),
          'models': this.fb.array([this.createModelData()])
        })
        this.checkFormBrandChanges();  

      }  
      console.log(this.data.hasBrand);
      this.modelsData = this.formAltaMarcasModelos.get('models') as FormArray;
  }

  get modelsGet() {
    return this.formAltaMarcasModelos.get('models') as FormArray;
  }

  createModelData(): FormGroup {
    return this.fb.group({
      model:[null, Validators.compose([Validators.required])]
    });
  }

  addData() {
    this.modelsData.push(this.createModelData());
    console.log(this.formAltaMarcasModelos);
    console.log(this.modelsData);
  }

  removeInput(index) {
    this.modelsData.removeAt(index);
  }

  onNoClick( result ): void {
    this.dialogRef.close( result );
  }

  refresh(){
    window.location.reload();
  }

  // Envía el formulario para dar de alta marcas y/o modelos
  newBM ( form ) {
    noInternet().then(offline => {
      if (offline) {
        this.utils.alertError('Verifica tu conexión');
      }
      else {
        if (form.valid) {          
          var models = form.value.models;
          var brand = form.value.name_brand;
          var brand_id = form.value.brand_id;
          var modelsArray = [];
          for(var prop in models) {
            modelsArray.push(models[prop]["model"]);
          }
          var modelos_str = "[\"" + modelsArray.join("\",\"") + "\"]";
          if(this.data.hasBrand) {
            var formData = new FormData();
            formData.set("model", modelos_str);
            formData.set("brand_id", brand_id);
            var object = {};
            formData.forEach((value, key) => {object[key] = value});
          } else {
            var formData = new FormData();
            formData.set("model", modelos_str);
            formData.set("name_brand", brand);
            var object = {};
            formData.forEach((value, key) => {object[key] = value});
          }
          this.ngxService.startLoader('loader-modal');
          this.vehicleService.addBrandsModels( object ).subscribe(
            response => { 
              this.newBMSuccess( response ); 
              this.ngxService.stopLoader('loader-modal');
            },
            error => { 
              this.newBMError( error ); 
              this.ngxService.stopLoader('loader-modal');
            }
          )
        } 
      }
    })
  }

  private newBMSuccess( response: any ) {
    console.log(response);
    let code = response.success;
    if (code != 1) {
      this.error_message = response.message
    } else {
      this.error_message = '';
      this.formAltaMarcasModelos.reset();
      this.onNoClick( true );
    }
  }

  private newBMError( error : any) {
    console.error(error);
    this.onNoClick( false );
  } 

  checkFormBrandChanges() {
    this.formAltaMarcasModelos.valueChanges.subscribe( data => {
      console.log(this.formAltaMarcasModelos);
      const controls = this.formAltaMarcasModelos.controls;
      const nameData = this.formAltaMarcasModelos.value.name_brand.toLowerCase().trim();
      if (this.checkIfBrandExists(nameData) === true) {
          controls.name_brand.setErrors({ 
            nameExists : true
          });
      }
    })
  }




  checkFormModelChanges() {
    this.formAltaMarcasModelos.valueChanges.subscribe( data => {
      console.log(this.formAltaMarcasModelos);
      const controls = this.formAltaMarcasModelos.controls.models['controls'];
      console.log(controls);
      controls.forEach(element => {
        let nameData = element.value.model
        if (nameData != null) {
          nameData = nameData.toLowerCase();
        }
        if (this.checkIfModelExists(nameData) === true) {
          element.controls.model.setErrors({ 
            modelExists : true
          });
      } 
      });
    })
  }

  // Estas funciones comparan el nombre de la marca o modelo a dar de alta contra 
  // el parámetro existingModels o existingBrands regresando un boolean.
  // true = marca/modelo existe, por lo que no se permite dar de alta nuevos
  // false = marca/modelo no existe, se puede dar de alta
  private checkIfModelExists(nameData) {
    return this.existingModels.includes(nameData);
  }
  private checkIfBrandExists(nameData) {
    return this.existingBrands.includes(nameData);
  }


}



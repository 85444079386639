import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterDeleteService {

  constructor() { }

  public deleteFilters(array) {   
    array.forEach(element => {
      sessionStorage.removeItem(element);
    });

  }
}

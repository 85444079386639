import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-sections-container',
  templateUrl: './sections-container.component.html',
  styleUrls: ['./sections-container.component.css']
}) 
export class SectionsContainerComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  isActive(){ return this.authService.isAuthenticated(); }

}

import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../../validators/validation-messages';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { UtilsService } from '../../../app.utils';
import { VehicleAll } from '../../../interfaces/vehicle-all.interface';
import noInternet from 'no-internet';
import { Router } from '@angular/router';
import { CatalogVehicles } from '../../../interfaces/catalog-vehicles.interface';

@Component({
  selector: 'app-modal-alta-vehiculo-new',
  templateUrl: './modal-alta-vehiculo-new.component.html',
  styleUrls: ['./modal-alta-vehiculo-new.component.css']
})
export class ModalAltaVehiculoNewComponent implements OnInit {

  vehicle: VehicleAll;
  bm: CatalogVehicles
  brand: CatalogVehicles;
  selectedBrand;
  formAltaVehiculo: FormGroup;
  formAltaMarcaModelo: FormGroup;
  required_errors: any = ValidationMessages.commonMessages.generic_errors;


  constructor(
    public dialogRef: MatDialogRef<ModalAltaVehiculoNewComponent>, 
    private utils: UtilsService,
    private vehicleService: VehiclesService,
    private router: Router){
      
      this.formAltaVehiculo = new FormGroup({
          'fk_vehicle_model' : new FormControl('',
                    Validators.required
                    ),
          'brand' : new FormControl('',   
          ),
          'tanks' : new FormControl('',
                    Validators.required
          ),
          'vehicle_economic' : new FormControl(''),
          'vehicle_id' : new FormControl('',
                    Validators.required
          ),
          // Este se ocupa cuando se da de alta siendo Admin Cliente
          // 'base' : new FormControl('',
          //           Validators.required
          // ),
      }),

      this.formAltaMarcaModelo = new FormGroup({
        'model' : new FormControl('',
                  Validators.required),
        'brand_id' : new FormControl('',
                     Validators.required),
        'name_brand' : new FormControl('',
                       Validators.required)
      })

      //Listens for value changes on fk_vehicle_model select
      this.formAltaVehiculo.controls['fk_vehicle_model'].valueChanges.subscribe(
        data =>{
        
          if (data != '') {
            console.log(data);
            this.selectedBrand = this.brand;
            this.selectedBrand = this.selectedBrand.find(obj => obj.brand_id == data);
            console.log(this.selectedBrand.models);
          }  else {
            this.selectedBrand = false;
          }
        
        }
      )

    } 

    ngOnInit() {
      this.loadBrands();
    }

    ngOnChanges() {
    }

    onSelected(brand: CatalogVehicles): void {
      this.selectedBrand = brand;
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.formAltaVehiculo.reset();
  }

  enviar(){
    if(this.formAltaVehiculo.valid) {
      console.log( this.formAltaVehiculo.value );
      //this.dialogRef.close();
      //this.formAltaVehiculo.reset();

    } else {

      Object.keys(this.formAltaVehiculo.controls).forEach(field => {
        const control = this.formAltaVehiculo.get(field);  
        console.log(control);         
        control.markAsDirty({ onlySelf: true });     
      });

    }
  }

  // #Get Brands
  public loadBrands() {
    this.vehicleService.getModel().subscribe(
        response => { this.loadBrandsSuccess( response );   
        },
        error => { this.loadBrandsError( error );} 
    );
}
/*
    Load Brands Callbacks
*/
private loadBrandsSuccess( response: any ) {
    let code = response.success;
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      this.brand = response.data;
    }
}

private loadBrandsError( error: any ) {
    console.error( error );
}

  newVehicle ( vehicle: VehicleAll ) {
    noInternet().then(offline => {
      if (offline) {
        this.utils.alertError('Verifica tu conexión');
      }
      
      else {
        if (this.formAltaVehiculo.valid) {
          this.vehicle = vehicle;
          this.vehicleService.addVehicle(this.vehicle).subscribe(
            response => { this.newVehicleSuccess( response ); },
            error => { this.newVehicleError( error ); }
          )
        } 
      }
    })
  }

  private newVehicleSuccess( response: any ) {
    let code = response.success;
    if (code != 1) {
      this.utils.errorManagement( response );
    } else {
      this.utils.toasterError( response );
      this.formAltaVehiculo.reset();
      this.dialogRef.close();
      this.router.navigate(['/vehiculos']);
    }
  }

  private newVehicleError( error : any) {
    console.error(error);
  }

 
}
 
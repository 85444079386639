import { Component, OnInit, } from '@angular/core';
import { GetTeamSoltek } from '../../interfaces/get-team-soltek';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalAltaEquipoSoltekComponent } from '../../shared/modals/modal-alta-equipo-soltek/modal-alta-equipo-soltek.component'; 
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ProfileService } from '../../services/users/profile.service';
import { arrayLength } from 'ng4-validators/src/app/array-length/validator';
import {FormControl} from '@angular/forms';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-equipo-soltek',
  templateUrl: './equipo-soltek.component.html',
  styleUrls: ['./equipo-soltek.component.css']
  
})

export class EquipoSoltekComponent implements OnInit {
  selected = new FormControl(0);
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  SuperAdmin = [];
  Moderador = [];
  Instalador = [];
  Supervisor = [];
  company_id: string;
  dataHasLoaded: boolean = false;
  modal_success: boolean;
  modal_message: string;
  loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.loadMembers();
    // El valor de 'EquipoSoltek-TabLocation' permite conservar la vista del mat-tab-group 
    // si se recarga la página al guardar en localStorage el index cada que se cambia de tab
    if (localStorage.getItem('EquipoSoltek-TabLocation')) {
      let index = localStorage.getItem('EquipoSoltek-TabLocation') 
      this.selected.setValue(index); 
    }
  }

  public loadMembers( reload? ) {
    if (typeof reload === 'undefined' || reload.toggleUserStatus === false || reload.toggleUserStatus === undefined){
      this.ngxService.start();
    }
    this.profileService.getSoltekMembers().subscribe(
        response => { 
          this.ngxService.stop();
          console.log(response);
          this.loadMembersSuccess( response ); 
        },
        error => { 
          this.ngxService.stop();
          console.log(error);
          this.loadMembersError( error ); 
        }
    );
  }

  /*
  Load Members Callbacks
*/
private loadMembersSuccess( response: any ) {
  let code = response.success;
  let data = response.data.Soltek;
  console.log(data);
  if (code != 1) {
    //  this.utils.errorManagement( response );
  } else {
    this.SuperAdmin = [];
    this.Moderador = [];
    this.Instalador = [];
    this.Supervisor = [];
    this.company_id = data[0].id_company;
    const users = data[0].users;
    const keys = Object.keys(users);
    const users_length = Object.keys(users).length;
    let i;
    for (i=0; i < users_length; i++){
      let user = users[keys[i]][0];
      user.is_principal = Number(user.is_principal);
      user.user_status_id = Number(user.user_status_id);
      user.user_type_id = Number(user.user_type_id);
      if (user.user_status_id === 1){
        user.toggleUserStatus = true;
      } 
      if (user.user_status_id === 2) {
        user.toggleUserStatus = false;
      }
      if (i === 0) {
        user.is_principal = 1;
      } else {
        user.is_principal = 0;
      }
      // Se segmentan los usuarios de acuerdo al usr_type_id
      switch (user.user_type_id) {
        case 1:
          this.SuperAdmin.push(user)
        
          break;
        case 10:
            this.Moderador.push(user)
        
          break;
        case 5:
            this.Instalador.push(user)
        
          break;
        case 4:
            this.Supervisor.push(user)
          break;

        default:
          // Nothing
          break;
      }
    }
    console.log(this.SuperAdmin);
    this.dataHasLoaded = true;

  }
}

private loadMembersError( error: any ) {
  console.log(error);
}

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAltaEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
        company_id : this.company_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === false) {
        this.openDialogMessage( false );
      }
      
      if (result === true) {
        this.openDialogMessage( true );
      }

      if (result === undefined || result === null) {

      }
    });
  }

  // Guarda en localStorage el index cada que se cambia de tab
  changeButton(a){ 
    console.log(a);
    localStorage.setItem('EquipoSoltek-TabLocation', a.index);
  }

  openDialogMessage( success? ): void {
    if ( success ) {
      this.modal_success = true;
      this.modal_message = 'El usuario fue dado de alta correctamente'
    } 
    if (!success) {
      this.modal_success = false;
      this.modal_message = 'Hubo un error al crear el usuario. Revisa que el correo no esté duplicado.'
    }
    if (success === undefined) {
      this.modal_success = false;
      this.modal_message = 'Hubo un error al crear el usuario'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: this.modal_message,
        success: this.modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.loadMembers();
      } 
    });
  }

}
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, Optional  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { timeout } from 'q';

@Component({
  selector: 'app-modal-cerrar-sesion',
  templateUrl: './modal-cerrar-sesion.component.html',
  styleUrls: ['./modal-cerrar-sesion.component.css']
})
export class ModalCerrarSesionComponent implements OnInit {
  title: string;
  body: string;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService,
    private router: Router,
    public dialogRef: MatDialogRef<ModalCerrarSesionComponent>,
    private ngxService: NgxUiLoaderService){ 
      dialogRef.disableClose = true;
    }

  ngOnInit() {
    console.log(this.data);
    if (this.data === null || this.data === undefined) {
      this.title= 'Cerrar sesión';
      this.body= '¿Estás seguro de cerrar tu sesión?';
    } else {
      this.title= 'Sesión Expirada';
      this.body= 'Tu sesión ha expirado, vuelve a iniciar sesión.';
      this.ngxService.stop();
      setTimeout(() => {
        this.logOutOnSuccess()
      }, 4700);
    }
    this.ngxService.stop()
  }

  onNoClick(): void {
    this.dialogRef.close(); 
  }

  logOutOnSuccess() {
        localStorage.removeItem('AdminCliente');
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        // this.onNoClick();
        setTimeout(() => {
          location.reload();
        }, 100);
  }
}

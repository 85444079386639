import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';


@Component({
  selector: 'app-modal-errores-formulario',
  templateUrl: './modal-errores-formulario.component.html',
  styleUrls: ['./modal-errores-formulario.component.css']
})
export class ModalErroresFormularioComponent implements OnInit {
  public stayOnForm;
  public messageIsString : boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService,
    private router: Router,
    public dialogRef: MatDialogRef<ModalErroresFormularioComponent>
  ) {
   
   }

  ngOnInit() {
    console.log(this.data.message);
    console.log(typeof this.data.message);
    if ( typeof this.data.message === 'object' ){
      this.messageIsString = false;
    } else {
      this.messageIsString = true;
    }
    this.stayOnForm = this.data.stayOnForm
  }

  onNoClick( action ): void {
    this.dialogRef.close( action );
  }
}
  
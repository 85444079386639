import { Component, OnInit, ViewChild, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { FichaTecnicaInterface } from '../../interfaces/ficha-tecnica-interface'
import { SharingServiceService } from "../../services/sharing-service.service";
import { VehiclesService } from '../../services/vehicles/vehicles.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { LogoService } from '../../services/logo.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { Observable } from 'rxjs';
import { MatTableFilter } from 'mat-table-filter';
import { SourceListMap } from 'source-list-map';

export class filterDate {
  created_at: string = '';
}

export class filterMovement {
  movement: string = '';
}

export class filterStatus {
  status: number = null;
}

export class filterFolios {
  folios: [string] = [''];
}

export class tableRow {
  status: filterStatus;
  created_at: filterDate;
  movement: filterMovement;
  folios: filterFolios
}

declare global {
  interface Window { chrome: any; }
}


@Component({
  selector: 'app-detalle-punto-revision',
  templateUrl: './detalle-punto-revision.component.html',
  styleUrls: ['./detalle-punto-revision.component.css']
})

export class DetallePuntoRevisionComponent implements OnInit {
  filterEntity: tableRow;
  filterType: MatTableFilter;
  userType;
  calendarFilter;
  pointId;
  pointDetail;
  data;
  vehicle;
  user;
  hasData: boolean = false;

  constructor(
    public dialog: MatDialog,
    private sharingService: SharingServiceService,
    private vehicleService: VehiclesService,
    private router: Router,
    private _location: Location,
    private ngxService: NgxUiLoaderService,
    private LogoService: LogoService
  ) {
    this.ngxService.start();
    // Se obtiene los datos del vehículo de localStorage
    this.vehicle = JSON.parse(localStorage.getItem('SelectedVehicle'));
    // Se obtiene los datos del cliente de localStorage
    this.user = JSON.parse(localStorage.getItem('AdminCliente'));
    // Se obtiene el tipo de usuario autenticado de localStorage
    this.userType = Number(localStorage.getItem('userType'));
    // Si el servicio 'sharingService' no obtiene información, se busca en 'localStorage'
    this.pointDetail = this.sharingService.getData();
    if (this.pointDetail === undefined || this.pointDetail === null) {
      this.pointDetail = JSON.parse(localStorage.getItem('pointDetail'));
    } else {
      localStorage.setItem('pointDetail', JSON.stringify(this.pointDetail));
    }
    // Si no hay datos en 'pointDetail.id' no se puede cargar la vista,
    // por lo que se regresa al usuario a la última vista que visitó
    this.pointId = Number(this.pointDetail.id);
    if (this.pointId === undefined || this.pointId == null) {
      this._location.back();
    }
    console.log(this.pointId);
    this.loadPointData();
    // Resetea la informacion almacenada en 'sharingService'
    this.sharingService.resetData();
  }

  ngOnInit() {
  }

  goBack() {
    this._location.back();
  }

  createTable() {
    this.hasData = true;
  }

  // Obtiene la información para llenar la vista
  loadPointData() {
    let placeholderData;
    this.vehicleService.getPointDetail(this.pointId).subscribe(
      response => {
        placeholderData = response.data;
        // Si 'data' no tiene información, la tabla no se genera
        if (placeholderData.length === 0) {
          this.hasData = false;
        } else {
          let i;
          for (i = 0; i < placeholderData.length; i++) {
            // Este parámetro status se utiliza únicamente para el filtro de status
            let status: number;
            if (placeholderData[i].is_damaged === 0) {
              status = 9
            } else {
              status = 1
            }
            placeholderData[i].status = status;
            if (placeholderData[i].justified_fail !== null) {
              placeholderData[i].justified_fail = Number(placeholderData[i].justified_fail)
            }
            let folios;
            let folioObject = placeholderData[i].foliate_stamp;
            if (folioObject != null) {
              folios = folioObject.join('<br>')
              placeholderData[i].foliate_stampString = folios
            }
          }
          const Moment = require('moment')
          const sortedArray = placeholderData.sort((a, b) => new Moment(a.updated_at).format() - new Moment(b.updated_at).format());
          let instalacion;
          for (i = 0; i < sortedArray.length; i++) {
            if (sortedArray[i].movement === 'Instalación') {
              instalacion = sortedArray[i];
              sortedArray.splice(i, 1)
              break;
            }

          }
          placeholderData = sortedArray.reverse();
          if (instalacion != undefined || instalacion != null) {
            placeholderData.push(instalacion);
          }
          this.data = placeholderData;
          this.createTable();
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        alert(error);
      }
    );
  };

  reloadScreen() {
    console.log('reload');
    this.loadPointData();
  }


}


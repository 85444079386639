import { Component, OnInit, OnDestroy, ViewChild, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, PageEvent} from '@angular/material';
import { VehicleAll } from '../../../interfaces/vehicle-all.interface';
import {FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { PaginatorService } from "../../../services/paginator.service";
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
 
@Component({
  selector: 'app-vehiculos-detalle-table',
  templateUrl: './vehiculos-detalle-table.component.html',
  styleUrls: ['./vehiculos-detalle-table.component.css']
})

export class VehiculosDetalleTableComponent implements OnInit {
  selectedModel;
  filteredVehicles = [];
  displayedColumns: string[] = ['number', 'base','is_registered', 'cliente', 'incidencia', 'num_economico', 'num_serie', 'status', 'next'];
  dataSource;
  @Input() vehicleCatalogData;
  toggleUserStatus = new FormControl();
  hasData: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  activeSort = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatPaginator) paginatorBottom: MatPaginator;  
  paginatorId = 'paginatorVehiclesDetailTable'
  paginatorChosenValue: number;
  filterPlaceholder = '¿Qué quieres buscar?';

  constructor(
    private vehiclesService: VehiclesService,    
    private router: Router,
    private sharingService:SharingServiceService,
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog,
    private paginatorService: PaginatorService
    ) { 
      this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
      console.log(this.sharingService.getData());
      if (this.sharingService.getData() != undefined ){
        localStorage.setItem('selectedVehicleModel', JSON.stringify(this.sharingService.getData()));
      }
      this.selectedModel = JSON.parse(localStorage.getItem('selectedVehicleModel'));
      if ( this.selectedModel === undefined ||  this.selectedModel == null ) {
        this.router.navigate(['/vehiculos']);    
      }
    
      console.log(this.selectedModel);
    }

  ngOnInit() {
    const isSortActive = sessionStorage.getItem('sort-detalle-vehiculos');
     if (isSortActive) {
       this.activeSort = JSON.parse(isSortActive);
     } else {
       this.activeSort = {
         active : 'base',
         direction : 'asc'
       }
     }
    this.loadData();
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );
  }

  applyFilter(filterValue: string) {
    console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filter === '') {
      this.filterPlaceholder = '¿Qué quieres buscar?';
    }
  }

  loadData(startLoader?){
    if (typeof startLoader === 'undefined' || startLoader === true){
      this.ngxService.start();
    }
    this.filteredVehicles = [];
    this.getVehicles().then( (data) => {
      this.sortVehicles(data);
    }, function ( error ) {
      this.getVehiclesError( error );      
    })
  }

  getVehicles() {
  
    return new Promise((resolve, reject) => {
      this.vehiclesService.getVehiclesAll().subscribe(
        response => {
          resolve(response.data);
          this.ngxService.stop();
        },
        error => {
          reject(error);
          this.ngxService.stop();
        }
      );
    })
   
  }

  sortVehicles(data) {
      console.log(data);
      const vehiclesList = data;
      const keys = Object.keys(data);
      let allVehicles = [];
      let i;
      let position = 0;
      for (i=0; i < keys.length; i++) {
        let vehicles = vehiclesList[keys[i]];
       
        vehicles.forEach(element => {
          if (element.model_vehicle_description === this.selectedModel.model_description) {   
            if (element.economic_number != null) {
              element.economic_number = element.economic_number.replace(/(.{22})/g, "$1<br>");            
            }
            if (element.serial_number != null) {
              element.serial_number = element.serial_number.replace(/(.{22})/g, "$1<br>");            
            }
            if (element.vehicle_status_id === 1){
              element.toggleUserStatus = true;
            } 
            if (element.vehicle_status_id === 2) {
              element.toggleUserStatus = false;
            }
            if (element.base != undefined || element.base != null) {
              element.base = element.base.base_description
            }
            element.loaderId =  this.setRandomLoaderId(element);
            element.position = position;
            position ++;
            this.filteredVehicles.push(element);
          }
          
        });
        // console.log(vehiclesList[keys[i]].length);
      }
      if ( i = keys.length) {
        if (this.filteredVehicles.length === 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
        this.setUpTable();
      }
  }

  getVehiclesError( error ){
    console.log(error);
  }

  setUpTable(){
    console.log(this.filteredVehicles);
    this.dataSource = new MatTableDataSource(this.filteredVehicles);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const vehicleFilter = sessionStorage.getItem('dataSource-filter-detalle-vehiculos');
    if (vehicleFilter) {
      this.dataSource.filter = JSON.parse(vehicleFilter);
      this.filterPlaceholder = 'Filtro activado: "' + this.dataSource.filter +'"';
    }
  }


    // Manage User (block, unblocks and erases users)
    public manageVehicle(vehicle_id, status, serial_number, loaderId, position) {
      let new_status:number;
      if (status === 1) {
        new_status = 2;
      }
      if (status === 2) {
        new_status = 1;
      }
      const data = {
        vehicle_id: vehicle_id,
        status: new_status,
        position: position
      }
      console.log(data);
      this.ngxService.startLoader(loaderId);
      // Status 1 = Active || 2 = Supended
      this.vehiclesService.editVehicle(data).subscribe(
        response => { 
          this.ngxService.stopLoader(loaderId);
          this.manageVehicleSuccess(data);
         },
        error => { 
          this.ngxService.stopLoader(loaderId);
          this.manageVehicleError();
         }
      )
    }
    
    // Se usa el id que se mandó en manageVehicle() y se busca dentro de filtered vehicles
    // Se cambia el parámetro 'vehicle_status_id' y 'toggleUserStatus'
    manageVehicleSuccess(data) {
      const managed = data.position;
      console.log(managed);
      this.filteredVehicles[managed].vehicle_status_id = data.status;
      if (this.filteredVehicles[managed].vehicle_status_id === 1){
        this.filteredVehicles[managed].toggleUserStatus = true;
      } else if (this.filteredVehicles[managed].vehicle_status_id === 2) {
        this.filteredVehicles[managed].toggleUserStatus = false;
      }
     
    }
  
    manageVehicleError() {
      this.openMessageDialog( false, 'Ocurrió un error al actualizar el vehículo, intente de nuevo más tarde.' );
    }

    goDetalleVehiculo( selectedVehicle ) {
      console.log(selectedVehicle);
      let user = {
        companyId: selectedVehicle.company_id,
        email: selectedVehicle.email,
        last_name: selectedVehicle.last_name,
        name: selectedVehicle.company_name,
      }
      localStorage.setItem( 'AdminCliente', JSON.stringify(user) );
      localStorage.setItem( 'SelectedVehicle', JSON.stringify(selectedVehicle) );
      sessionStorage.setItem('dataSource-filter-detalle-vehiculos', JSON.stringify(this.dataSource.filter));
      this.router.navigate(['/ficha-tecnica']);
    }

    openMessageDialog( success, message ): void {
      const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
        panelClass: 'modal-send-notification',
        disableClose: true,
        data : {
          message: message,
          success: success
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        // this.loadMembers();
      });
    }

    setRandomLoaderId(element) {
      //console.log(element);
      return 'loader-toggle-' + element.serial_number + String(Math.floor((Math.random() * 10000) + (Math.random() * Math.random())));
    }

    syncPrimaryPaginator(event: PageEvent) {
      this.paginator.pageIndex = event.pageIndex;
      this.paginator.pageSize = event.pageSize;
      this.paginator.page.emit(event);
    }

    sortData(event) {
      console.log(event);
      this.activeSort = event;
      sessionStorage.setItem('sort-detalle-vehiculos', JSON.stringify(event));
    }
  
    sortActive(event) {
      console.log(event);
    }
}
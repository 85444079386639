import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { InputPasswordService } from '../../../services/input-password.service';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import noInternet from 'no-internet';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';


@Component({
  selector: 'app-form-edit-company',
  templateUrl: './form-edit-company.component.html',
  styleUrls: ['./form-edit-company.component.css']
})
export class FormEditCompanyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clientData;
  form: FormGroup;
  name_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.staffMessages.email_errors;
  pass_errors: any = ValidationMessages.staffMessages.pass_errors;
  passc_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  changePassword;
  success: boolean;
  message: string;
  reset:boolean;
  @Output() reload: EventEmitter< boolean >;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
  password = new FormControl('',  [      
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12)
  ]);
  password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.password)
  ]);

  constructor( 
    private profileService: ProfileService,
    private inputPasswordService: InputPasswordService,
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService
  ) {
    this.reload = new EventEmitter();
  }

  ngOnInit() {
    console.log(this.clientData);
    this.form = this.createForm( this.clientData );
    console.log(this.form);
    this.checkForReload( this.reset );
  }

  ngOnChanges( changes: SimpleChanges) { 
    // const clientData: SimpleChange = changes.clientData;
    // console.log('prev value: ', clientData.previousValue);
    // console.log('got name: ', clientData.currentValue);
    this.form = this.createForm( this.clientData );
  }

  ngOnDestroy() {
    this.success = undefined;
    this.message = undefined;
  }
  
  createForm( data ) {
    return new FormGroup({
      'name' : new FormControl(data.companyName, [Validators.required]),
      'email' : new FormControl(data.email, [
              Validators.required, 
              Validators.email,
              CustomValidators.email
      ]),
      'userId' : new FormControl(data.userId),
      'companyId' : new FormControl(data.companyId),
    })
  }

  updateMember( data ) {
    console.log(data);
    this.ngxService.start();
    let updateCompanyData = {
      company_id: data.companyId,
      company_name: data.name
    };

    let updateUserData;
    
    // If changePassword is true adds password fields to submit data
    if (!this.changePassword) {
      updateUserData = {
        id: data.userId,
        email: data.email
      }
    } else {
      updateUserData = {
        password: data.password,
        password_confirmation: data.password_confirmation,
        id: data.userId,
        email: data.email
      }
    }

    if (updateCompanyData.company_name != this.clientData.companyName) {
        console.log('Nombre de compañía diferente')
        console.log(updateCompanyData);
        Promise.all([
          this.serviceUpdateUser( updateUserData ),
          this.serviceUpdateCompany( updateCompanyData )
        ]).then((values) => {

          values.forEach(element => {
            let response:any = element
            if (response.success === 0) {
              this.success = false;
              this.message = response.message
              // console.log(message);
            }
          });

          this.openDialog(this.success, this.message);
        }, function (error){
          this.ngxService.stop();
          this.success = false;
          this.message = 'Hubo un error en uno de los servicios utilizados. Inténtalo de nuevo.';
          this.openDialog(this.success, this.message);
          // alert('Hubo un error en uno de los servicios utilizados. Inténtalo de nuevo.')
        })
    }

    if (updateCompanyData.company_name === this.clientData.companyName) {
        console.log('Nombre de compañía Igual')
        Promise.all([
          this.serviceUpdateUser( updateUserData ),
        ]).then((values) => {
          values.forEach(element => {
            let response:any = element
            if (response.success === 0) {
              this.success = false;
              this.message = response.message
              // console.log(message);
            }
          });
          this.openDialog(this.success, this.message);
        }, function (error){
          this.success = false;
          this.message = 'Hubo un error en uno de los servicios utilizados. Inténtalo de nuevo.';
          this.openDialog(this.success, this.message);
          // alert('Hubo un error en uno de los servicios utilizados. Inténtalo de nuevo.')
        })
    }
    this.ngxService.stop();
    
  }

  randomPassword( e ) {
  //  console.log(e.controls.password);
    this.inputPasswordService.randomPassword( e ); 
  }

  // Adds or removes password and password_confirmation controls using the changePassword variable as flag
  addPasswordControls() {
    this.changePassword = !this.changePassword
    if ( this.changePassword ) {
      this.form.addControl('password', this.password);
      this.form.addControl('password_confirmation', this.password_confirmation);
    } else {
      this.form.removeControl('password');
      this.form.removeControl('password_confirmation');
    }
  }

  serviceUpdateCompany( data ) {
    return new Promise((resolve, reject) => {
      this.profileService.updateCompany( data ).subscribe(
        response => { 
          // console.log(response);
          if (response.success === 1) {
            localStorage.setItem('newCompanyName', data.company_name);
          }
          resolve(response);
      },
          error => { 
          // console.log(error);
          reject('Hubo un error con el servicio para actualizar el nombre de la compañía');
      });
    })
  }

  serviceUpdateUser( data ) {
    return new Promise((resolve, reject) => {
      this.profileService.updateMember( data ).subscribe(
        response => { 
          // console.log(response);
          resolve(response);
      },
          error => { 
          // console.log(error);
          reject('Hubo un error con el servicio para actualizar usuarios');
      });
    })
    
  }

  openDialog( success, message ): void {
    if ( success || success === undefined ) {
      success = true;
      message = 'Los cambios fueron guardados correctamente'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.reset = result;
      this.checkForReload(this.reset);
    });
  }
  
  checkForReload( reset ) {
    this.success= undefined;
    this.message= undefined;
    if (reset === true) {
      this.reload.emit( true );
      this.reset = null;
    }
    if ( reset === false ) {
      this.reset = null;
    } else {
      this.reset = null;
    }

  }

}

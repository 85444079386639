import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, PageEvent } from '@angular/material';
import { VehicleAll } from '../../../interfaces/vehicle-all.interface';
import { SelectionModel } from '@angular/cdk/collections'; // Usado para el table select
import { UtilsService } from '../../../app.utils';
import noInternet from 'no-internet';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { PaginatorService } from "../../../services/paginator.service";

@Component({
  selector: 'app-vehiculos-table',
  templateUrl: './vehiculos-table.component.html',
  styleUrls: ['./vehiculos-table.component.css']
})

export class VehiculosTableComponent implements OnInit {
  @Input() displayedColumns: string[] = ['number', 'brand', 'model_vehicle', 'fleet_total', 'total_vehicles', 'damaged_percentage', 'next'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("filterVehicles") input: ElementRef;
  filterPlaceholder = "¿Qué quieres buscar?";
  vehicleCatalog = [];
  tableData: any = [];
  paginatorId = 'pVt'
  paginatorChosenValue: number;
  hasData: boolean = false;
  constructor(
    private vehiclesService: VehiclesService,
    private utils: UtilsService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private sharingService: SharingServiceService,
    private paginatorService: PaginatorService
  ) {
    this.paginatorChosenValue = this.paginatorService.setPaginator(this.paginatorId);
    // Para armar esta vista primero se llama el servicio 'catalog-vehicles' para obtener los id de marcas y modelos
    // Posteriormente se llama dashboard-vehicles para terminar de obtener la información necesaria para la vista
    this.loadCatalogVehicles().then(response => {
      this.loadVehicleStatistics();
    }, function (error) {
      console.log(error);
    });
    sessionStorage.clear();
  }

  ngOnInit() {
    this.paginatorService.savePaginator(this.paginator, this.paginatorId);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filter === '') {
      this.filterPlaceholder = "¿Qué quieres buscar?";
    }
  }

  // Obtiene datos de vehículos por marca --> modelo
  public loadVehicleStatistics() {
    let tableData = []
    this.ngxService.start();
    noInternet().then(offline => {
      if (offline) {
        this.utils.alertError('Verifica tu conexión');
        this.ngxService.stop();
      } else {
        this.vehiclesService.showVehicleStatistics().subscribe(
          response => {
            console.log(response);
            this.ngxService.stop();
            this.loadVehicleStatisticsSuccess(response.data);
          },
          error => {
            this.ngxService.stop();
          }
        );
      }

    });
  }

  loadVehicleStatisticsSuccess(data) {
    console.log(data)
    let keys = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      let allBrandvehicles = data[keys[index]];
      allBrandvehicles.forEach(element => {
        console.log(element);
        element.brand_name = keys[index];
        // brand_id y model_id vienen del servicio '/api/staff/catalog-vehicles' via loadCatalogVehicles()
        element.brand_id = this.vehicleCatalog[keys[index]].brand_id;
        element.model_id = this.vehicleCatalog[keys[index]].models[element.model_description].element.id
        if (element.total_register <= 0 || element.total_register === null || element.total_register === undefined) {
          element.damage_percentage = null
        } else {
          const percentage = (element.total_failed * 100) / element.total_register;
          if (percentage === NaN) {
            element.damage_percentage = null;
          }
          if (percentage === 0) {
            element.damage_percentage = 'Sin daños';
          }
          else {
            let percentage_formatted = percentage.toString();
            if (percentage_formatted.length > 5) {
              element.damage_percentage = percentage_formatted.slice(0, 5) + '%';
            } else {
              element.damage_percentage = percentage + '%';
            }
          }

        }
        this.tableData.push(element)
      });
    }
    console.log(this.tableData);
    this.setUpTable(this.tableData);
  }

  setUpTable(tableData) {
    this.dataSource = new MatTableDataSource(tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      // Si el valor es null o undefined se debe cambiar por un string vacio o el filtro fallará
      const emptyvalues = Object.keys(data).filter(key => data[key] === null || data[key] === undefined || data[key] === '');
      for (const iterator of emptyvalues) {
        console.log(iterator);
        data[iterator] = '';
      }
      console.log(data);
      return data.brand_name.toLowerCase().includes(filter)
        || data.model_description.toLowerCase().includes(filter)
        || data.total_register.toString().toLowerCase().includes(filter)
        || data.total_install.toString().toLowerCase().includes(filter)
        || data.damage_percentage.toString().toLowerCase().includes(filter)
    };
    this.hasData = true;
  }

  loadCatalogVehicles() {
    return new Promise((resolve, reject) => {
      this.ngxService.start();
      noInternet().then(offline => {
        if (offline) {
          this.ngxService.stop();
          this.utils.alertError('Verifica tu conexión');
        } else {
          this.vehiclesService.getVehicleCatalog().subscribe(
            response => {
              console.log(response.data)
              let placeholder = response.data;
              for (let i = 0; i < placeholder.length; i++) {
                let brand = placeholder[i].brand;
                let models = [];
                placeholder[i].models.forEach(element => {
                  models[element.description] = {
                    element
                  }
                });
                this.vehicleCatalog[brand] = {
                  brand: placeholder[i].brand,
                  brand_id: placeholder[i].brand_id,
                  models: models
                };
              }
              resolve('Ready');
            },
            error => {
              reject('Fail');
            }
          );
        }

      });

    })
  }

  // LLeva a la siguiente vista
  goToDetail(element) {
    console.log(element);
    this.sharingService.setData(element);
    this.router.navigate(['/vehiculos/detalle-vehiculo']);
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  removeFilter() {
    this.setUpTable(this.tableData);
    console.log(this.input.nativeElement);
    this.input.nativeElement.value = '';
    // document.getElementById('filterVehicles').value = '';
  }
}
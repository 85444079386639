import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-modal-errores-editar-clientes',
  templateUrl: './modal-errores-editar-clientes.component.html',
  styleUrls: ['./modal-errores-editar-clientes.component.css']
})
export class ModalErroresEditarClientesComponent implements OnInit {
  
  reset:boolean; // Returns true if page to be reset (including form) or false if just closes the modal

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService,
    private router: Router,
    public dialogRef: MatDialogRef<ModalErroresEditarClientesComponent>
  ) { }

  ngOnInit() {
    console.log(this.data);
      if (this.data.success === true) {
        this.reset = true;
      } else {
        this.reset = false;
      }
  }

  onNoClick(): void {
    this.dialogRef.close( this.reset );
  }
}

import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import noInternet from 'no-internet';
import { UtilsService } from '../../../app.utils';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalSendNotificationClientesComponent } from '../../../shared/modals/modal-send-notification-clientes/modal-send-notification-clientes.component';
import { ModalEliminarCompaniaComponent } from '../../modals/modal-eliminar-compania/modal-eliminar-compania.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { ModalEditarEquipoSoltekComponent } from '../../../shared/modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component'; 
// #Services
import { ProfileService } from '../../../services/users/profile.service';
import { SweetAlertService } from 'angular-sweetalert-service';
import { PaginatorService } from "../../../services/paginator.service";

@Component({
  selector: 'app-table-users-premium',
  templateUrl: './table-users-premium.component.html',
  styleUrls: ['./table-users-premium.component.css']
})
export class TableUsersPremiumComponent implements OnInit {
  displayColumns = [
    'administrador', 
    'contacto', 
    'vehiculos', 
    'vehiculos_con_kit',
    'vehiculosSupervisados', 
    'incidencia', 
    'status', 
    'acciones', 
    'next'
  ];
  @Input() members: [];
  @Input() showTableAlert:boolean;
  @Input() alertClass = true;
  @Output() memberSelected = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatPaginator) paginatorBottom: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort ;
  alertMessage =  {
    success: 'El usuario fue editado correctamente.',
    fail: 'Hubo un error al actualizar el usuario.'
  };
  dataSource = new MatTableDataSource(this.members);
  hasData: boolean;
  route = this.router.url;
  paginatorId = 'pUp';
  paginatorChosenValue: number;

  constructor(
    private profileService: ProfileService,
    private utils: UtilsService,
    private router: Router,
    private alertService: SweetAlertService,
    public dialog: MatDialog,
    private sharingService: SharingServiceService,
    private ngxService: NgxUiLoaderService,
    private paginatorService: PaginatorService
  ) {
    this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
    this.loadMembers();
   }

  ngOnInit() {
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );
  }

  // Esta función se invoca un segundo después de procesar la información a mostrar
  applyDatasource(): void {
    setTimeout( (event) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }, 1000);
  }

  // Función para manjear el filtro por texto
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase(); 
    this.dataSource.filter = filterValue;
  }

  // Crea la tabla
  createTable( data ) {
    console.log(data);
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.email.toLowerCase().includes(filter) 
      || data.client.toLowerCase().includes(filter);
      };
    this.hasData = true;
    this.applyDatasource();
  }

  // Ir a detalle de cliente
  goToDetalleCliente(element) {
    console.log(element);
    element = {
      companyId: element.companyId,
      userId: element.id,
      last_name: element.last_name, 
      name: element.name, 
      email: element.email,
    }
    this.sharingService.setData(element);
    localStorage.setItem('AdminCliente', JSON.stringify(element));
    localStorage.setItem('ViewingUserType', 'premium')
    this.router.navigate(['/detalle-cliente']);
  }

  // Obtiene los datos del cliente seleccionado y lleva a pantalla de edición
  editarCliente(idx: number, element){
    console.log(element);
    let users = element.users;
    let headers;
    console.log(users);
    headers = Object.keys(users);
    element = {
      companyId: element.companyId,
      name: element.name, 
      email: element.email,
      userId: users[headers[0]][0].id,
      vehicles_limit: element.vehicles_limit
    }
    localStorage.setItem('AdminCliente', JSON.stringify(element));
    this.router.navigate(['/usersClientes/editar-cliente/',( idx + 1)]);
  }
  
  // Esconde la barra de notificación
  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }

  // Obtiene todas las compaías Freemium registradas en el cms
  loadMembers(startLoader?) {
    if (typeof startLoader === 'undefined' || startLoader === true){
      this.ngxService.start();
    }
    this.profileService.getPremiumCompanies().subscribe(
          response => { 
            console.log(response);
            this.ngxService.stop();
              this.loadMembersSuccess( response ); 
          },
          error => { 
            this.ngxService.stop();
          }
      );
  }

  // Clientes Freemium = Admin Independiente
  loadMembersSuccess( response: any ) {
    let code = Number(response.success);
    let data = response.data;
    let keys;
    let adminsClientes = [];
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      keys = Object.keys(data);
      console.log(keys);
      let i;
      for (i = 0; i < keys.length; i++) { 
        let key = keys[i];
        // let Users = Object.keys(data[key][0].users);
        const companyName = keys[i];
        const companyId =  data[key][0].id_company;
        const companyStatus = Number(data[key][0].active);
        const vehicleData = data[key][0].vehicles;
        const limits = data[key][0].limits;
        const user = data[key][0].users;
        let userData;
        let toggleUserStatus:boolean;
        // Las compañias deben tener un usuario mínimo (con tipo de usuario = 2) o no se mostrará en tabla
        if (user) {
          // Se extrae el número identificador del usuario para poder acceder a su información
          let userNumber = Object.keys(user)[0];
          userData = user[userNumber][0];
          if (companyStatus === 1){
            toggleUserStatus = true;
          } 
          if (companyStatus === 0) {
            toggleUserStatus = false;
          }
          const newUser = {
            created_at: userData.created_at,
            email: userData.email,
            id: userData.id,
            last_name: '',
            name: companyName,
            client: companyName,
            updated_at: userData.updated_at,
            user_status_description: userData.user_status_description,
            user_status_id:  Number(userData.user_status_id),
            user_type: userData.user_type,
            user_type_id: Number(userData.user_type_id),
            incidents: Number(vehicleData.incidents),
            total_vehicles: vehicleData.total_vehicles,
            vehicles_checked: Number(vehicleData.vehicles_checked),
            companyId: companyId,
            companyStatus: Number(companyStatus),
            users_limit: Number(limits.users_limit),
            vehicles_limit: Number(limits.vehicles_limit),
            vehicles_with_kits: Number(vehicleData.vehicles_with_kits),
            users: data[key][0].users,
            toggleUserStatus: toggleUserStatus,
          }
          console.log(newUser);
          // 'user_status_id' === 3 significa un usuario con status 'borrado' que no debe salir en vista
          if (newUser.user_status_id != 3) {
            adminsClientes.push( newUser );
          }
        } else {
          console.log('Position num = ' + (i + 1));
          console.log( 'no hay usuario');
        }
      }
      console.log(adminsClientes);
      if (adminsClientes.length >= 1) {
        // console.log(adminsClientes);
        this.createTable(adminsClientes);
      } else {
        this.hasData = false;
      } 
    }
  }

  openDeleteDialog(company_id): void { 
    console.log(company_id);
    const dialogRef = this.dialog.open(ModalEliminarCompaniaComponent,{
      panelClass: 'modal-send-notification',
      data: {
        company: company_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      console.log( result );
      if (result === true) {
        this.openMessageDialog( true, 'El usuario fue borrado.' )
      } 
      if  (result === false) {
        this.openMessageDialog( false, 'Hubo un error al borrar al usuario.' )
      } 
    });
  }

  openDialogMessage( success? ): void {
    let modal_success,
    modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
    }
    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      } 
    });
  }

   // Abre el modal para editar a un usuario
   openEditDialog(name, last_name, email, user_id, user_type_id): void { 
    const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
          name: name,
          last_name: last_name,
          email: email,
          user_id: user_id,
          user_type_id: user_type_id
        }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.showTableAlert = true;
        this.loadMembers();
      } else {
        this.showTableAlert = false;
      }
    });
  }

  // Modal usado para mostrar feedback al usuario sobre una operación realizada
  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      this.loadMembers();
    });
  }

  // Abre el modal para envíar notificación a usuario
  openSendNotification(element): void {
    const dialogRef = this.dialog.open(ModalSendNotificationClientesComponent,{
      panelClass: 'modal-send-notification',
      data : element
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != undefined){
        this.openDialogMessage( result )
      }
    });
  }

  // Muestra si una nitificación fue enviada o no
  openNotificationStatusMessage( success? ): void {
    let modal_success,
    modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
    }
    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }   
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      } 
    });
  }

  // Maneja status de compañias (block, unblocks y borra)
  manageCompany(company_id, status, name) {
    let new_status:number;
    if (status === 1) {
      new_status = 0;
    }
    if (status === 0)  {
      new_status = 1;
    }
    const data = {
      company: company_id,
      status: new_status
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-'+ name);
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageCompany(data).subscribe(
      response => { 
        // this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserError();
       }
    )
  }

  manageUserSuccess() {
    this.loadMembers(false);
  }
  
  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }
  
  // Sincroniza los paginadores superior e inferior
  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  


}

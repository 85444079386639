import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[generatePassword]'
})
export class GeneratePasswordDirective {

  constructor(private el: ElementRef) {
    this.setupGeneratePassword();
   }

   randomPassword(element: HTMLElement){
      const password = Math.random().toString(36).slice(-8);
      const inputPassword = <HTMLInputElement>element.previousElementSibling.previousSibling;
      const confirmPassword = (<HTMLInputElement>element.parentElement.nextSibling).getElementsByTagName('input')[0];
      inputPassword.value = password;
      confirmPassword.value = password;
      //console.log(confirmPassword);
   } 


  setupGeneratePassword() {
    const parent = this.el.nativeElement.parentNode;
    const element = document.createElement('div');
    element.className = 'input-generate-password';
    element.innerHTML = 'Ó genera una contraseña automática';
    element.addEventListener('click', (event) => {
      this.randomPassword(element);
    })
    parent.appendChild(element);

  }

}

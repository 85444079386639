import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Inject, ChangeDetectorRef,ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import { PushService } from '../../../services/push.service';
import { FileItem } from '../../../directives/ng-drop-files/file-item';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../../validators/validation-messages';
import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-send-notification',
  templateUrl: './modal-send-notification.component.html',
  styleUrls: ['./modal-send-notification.component.css']
})
export class ModalSendNotificationComponent implements OnInit {
  mouseOverDropzone = false;
  files: FileItem[] = [];
  hasImage = false;
  form: FormGroup;
  message_errors: any = ValidationMessages.contentMessages.body_errors;
  radio_errors: any = ValidationMessages.radios.radio_errors;
  route = this.router.url;

  @ViewChild('fileInput') el: ElementRef;
  editFile: boolean = true;
  imageUrl: any;
  uploadImage: boolean = false;
  userTypeFormGroup = new FormGroup({
    'user_admin_ind' : new FormControl(null),
    'user_admin_cliente' :  new FormControl(null),
    'user_sup_cliente' : new FormControl(null),
    'user_inst_cliente' : new FormControl(null),
    'is_touched' : new FormControl(false, Validators.required)
  })
 
  constructor(
    public dialogRef: MatDialogRef<ModalSendNotificationComponent>,
    private cd: ChangeDetectorRef,
    private pushService: PushService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    ) { 
    this.form = new FormGroup({
      'user_type' : new FormArray( [this.userTypeFormGroup], Validators.required),
      'title' : new FormControl('',
                    [
                      Validators.required,
                      Validators.maxLength(45)
                    ]
      ),
      'message' : new FormControl('',
                    Validators.required
      ),
      'image': new FormControl('', []),
    })
  }

  uploadImages( files: FileItem[] ){ 
    console.log( files );
  }

  ngOnInit() {
  }
  
  onNoClick( result? ): void {
    console.log(result);
    if (result != undefined){
      this.dialogRef.close( result );
    } else {
      this.dialogRef.close();
    }
  }

  hasImages(event){
    console.log(event);
    this.hasImage = event;
  }

  inputChange(){
    console.log('change');
    this.checkRadioSelected();
  }

  radioToggle(event){
    console.log(event.target.name);
    let clickedRadio =   this.userTypeFormGroup.controls[event.target.name];
    console.log(clickedRadio);
    if (clickedRadio.touched && clickedRadio.value != null) {
      clickedRadio.setValue(null);
    }
  }

  // Esta función se asegura que al menos una de las opciones de los radios ha sido
  // seleccionada. En caso de que ninguna se haya seleccionado la form será inválida.
  checkRadioSelected() {
    let isSelected = [];
    let radios = [
      'user_admin_ind',
      'user_sup_cliente',
      'user_admin_cliente',
      'user_inst_cliente'
    ]
    for (let i = 0; i < radios.length; i++) {
      if (this.userTypeFormGroup.controls[radios[i]].value != null ){
        isSelected.push(radios[i])
      }
      if (this.userTypeFormGroup.controls[radios[i]].value === null){
        isSelected.splice(i ,1)
      }
    }

    if (isSelected.length < 1) {
      this.userTypeFormGroup.controls.is_touched.reset();
      this.userTypeFormGroup.controls.is_touched.updateValueAndValidity({emitEvent : false})
      return false;
    }

    if (isSelected.length >= 1) {
      this.userTypeFormGroup.controls.is_touched.setValue(true);
      this.userTypeFormGroup.controls.is_touched.updateValueAndValidity({emitEvent : true})
      return true;
    }
  }

   // Función para subir archivos seleccionando
   uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    console.log(file);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = (event:any) => {
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
      }
      this.cd.markForCheck();     
      this.form.patchValue({
        image: file,
      })    
    }
    this.hasImage = true;
    console.log(this.form.value);
  }

  // Función para subir archivos arrastrando el archivo
  droppedFile(file) {
    console.log(file.file);
    let reader = new FileReader();
    reader.readAsDataURL(file.file);
      reader.onload = (event:any) => {
        this.imageUrl = event.target.result;
        this.editFile = false;
        this.uploadImage = true
      }
      this.cd.markForCheck();   
      this.form.patchValue({
        image: file.file,
      })  
  }

  // Función para remover el archivo a enviar
  removeUploadedFile() {
    this.form.patchValue({
      image: [null]
    });
    this.imageUrl = null;
    this.uploadImage = false;
    this.hasImage = false;

  }

  sendNotification(){
    this.checkRadioSelected();
    console.log(this.form.valid);
    if ( !this.form.valid ) {

    } else {
      this.ngxService.start();
      console.log(this.form.value);
      let data = {
        'image' : this.form.value.image,
        'message': this.form.value.message,
        'title': this.form.value.title,
        'user_type': []
      };
      let user_type_list = this.userTypeFormGroup.value;
      console.log(user_type_list);
      let user_type_values = [
        user_type_list.user_admin_ind,
        user_type_list.user_admin_cliente,
        user_type_list.user_sup_cliente,
        user_type_list.user_inst_cliente
      ];
      
      user_type_values.forEach(element => {
        if (element != null){
          data.user_type.push(Number(element));
        }
      });
      console.log(data);
      this.pushService.sendNotification( data ).subscribe(
        response => { 
          let callback:any = response;
          this.ngxService.stop();
          if (callback.success != 1){
            this.onNoClick( false );
          } else {
            this.onNoClick( true );
          }
        },
        error => { 
          this.onNoClick( false );
          this.ngxService.stop();
        }
    );
    }
  }
}

import { Component, OnInit} from '@angular/core';
import { FilterDeleteService } from '../../services/filter-delete.service';

@Component({
  selector: 'app-admin-independiente',
  templateUrl: './admin-independiente.component.html',
  styleUrls: ['./admin-independiente.component.css']
})
export class AdminIndependienteComponent {
  
  displayColumns = [
    // 'select', 
    'number',
    'administrador', 
    'contacto', 
    'vehiculos', 
    'vehiculos_con_kit',
    'incidencia', 
    'status', 
    'acciones', 
    'upgrade', 
    'next'
  ];
  clientType = 3;

  constructor(public FilterDelete: FilterDeleteService) { }

  ngOnInit() {
    this.FilterDelete.deleteFilters([
      'sort-vehiculos-cliente', 'folio-/vehiculos-cliente',  'dataSource-filter-vehiculos-cliente'
    ]);
  }



}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-alert',
  templateUrl: './table-alert.component.html',
  styleUrls: ['./table-alert.component.css']
})
export class TableAlertComponent implements OnInit {
  @Input() showTableAlert: boolean;
  @Input() alertClass: boolean;
  @Input() message: {
    success: string,
    fail: string
  };
  @Output() closeTableAlert: EventEmitter<boolean>;
  constructor() {
      this.closeTableAlert =  new EventEmitter(); 
   }

  ngOnInit() {
    console.log(this.message);
  }

  closeAlert(){
    $('.table-alert-box').addClass('fadeOutRight faster');
    setTimeout(() => {  
      this.showTableAlert = false;
      this.closeTableAlert.emit( true );
    }, 350);
    console.log('cerrando table alert');
  }

}

import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild  } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from "@angular/forms";
import { ProfileService } from '../../services/users/profile.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.css']
})
export class UpdateFormComponent implements OnInit {
  registrationForm:FormGroup = this.fb.group({
    image1: [null],
    name: "Hardcoded",
    last_name: 'Oso',
    email: '',
    password: '',
    password_confirmation: ''
  }) ;
  image;

  constructor(public fb: FormBuilder, 
    private cd: ChangeDetectorRef, 
    private profileService: ProfileService,
    private httpClient: HttpClient,) { }

  ngOnInit() {
  }

@ViewChild('fileInput') el: ElementRef;
imageUrl: any = '/assets/dummy-user.jpg';
editFile: boolean = true;
removeUpload: boolean = false;

/*########################## File Upload ########################*/
  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      this.registrationForm.patchValue({
        image1: file,
      })    
    }
  }


// Function to remove uploaded file
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '/assets/dummy-user.jpg';
    this.editFile = true;
    this.removeUpload = false;
    this.registrationForm.patchValue({
      file: [null]
      });
    }


  // This works!!!!!!
// onSubmit() {
//   console.log(this.registrationForm.value);
//   const formData = new FormData();
//   formData.append('image1', this.registrationForm.get('image1').value);
//   formData.append('name', this.registrationForm.get('name').value);
//   const headers = ({
//       'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjdhYzAzNTA2NGZiYjlhNGE3YWE1MjEyNDhiMWI2MjdiNDZjZDc3NTBiYTMyZDU2NDg0MGIxYjkyMmZjNjAzMjM3OWJkZWE3NGRhNWMyZmMwIn0.eyJhdWQiOiIyIiwianRpIjoiN2FjMDM1MDY0ZmJiOWE0YTdhYTUyMTI0OGIxYjYyN2I0NmNkNzc1MGJhMzJkNTY0ODQwYjFiOTIyZmM2MDMyMzc5YmRlYTc0ZGE1YzJmYzAiLCJpYXQiOjE1NjE0OTQ2MzEsIm5iZiI6MTU2MTQ5NDYzMSwiZXhwIjoxNTYyMDk5NDMxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.mtV_cLA9Pyhi3NIXlE63V_oJKmf7IddcBNkWHrinUy1u_1hVMeASgPWKlJ0bs4TrdzoWxbAF4Ne5uJ7r1Wpj_Zu4NEL_RT9UlzwO48mExeCJx8S8oy0q61Eo1JDqbf_novH540kL7t4evQbM4Nq56udmzdKPA5kBR5w3T3_kY1w4Y8U1MPfCCx9sQC_VU-qsTLZbRDqagUftu-lDfGoV3rleeB7wo8XHH34cAk4O6wlteQgYEDMyRQojDnozR-qcWeAOJyBKkx5Ic_pjCEelXEiOxnIBP7zoLV0ZdFvqLJJbrMCQgRTHlZXOEkwUm4J1EdsPHXHpNGtH8ukXcD-_i2Zotgc7mLlQncYJnBQcsM3aDLdBxx8xgaSkyQq-9zrF339FrFWNK5wcyMSivx_tEzBhoob25Z7u-7EG1xSFyzTnJnm_sL8vf6aiH4VjfUEn3kQwyg1jMp2NF92vNd6Zq5OZ-_P5Z1IDdvWRPPUVY7VYxrPUCuQxMH9b7Uq5SXloNYOQcLHkqv-whNk6fGgZRFDYmIh5o7juZc83cgAl4d-248kuVIpsWmux0-6CclCdI3WDY1AO739lK34zE8zxCe-D3yqJHq3z_Fm_mAyNX0qFVfcVfL1hgxbEm6stPjjcYyEpLkbozdc0LwJoYPlU1ulymH0tKDPErXiFUs3K4T4`
//   });
//   this.httpClient.post<any>(this.SERVER_URL, formData, {headers}).subscribe(
//     (res) => console.log(res),
//     (err) => console.log(err)
//   );
// }

// This works too!!!!!!
onSubmit() {
  console.log(this.registrationForm.value);
  this.profileService.updateProfile(this.registrationForm.value).subscribe(
    response => { 
      console.log(response)
    },
    error => { 
      console.log(error)
  } 
  );
}

}

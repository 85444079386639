import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections'; // Usado para el table select
import { UtilsService } from '../../../app.utils';
import noInternet from 'no-internet';
import { CatalogVehicles } from '../../../interfaces/catalog-vehicles.interface';
import { ModalAltaMarcasModelosComponent } from '../../../shared/modals/modal-alta-marcas-modelos/modal-alta-marcas-modelos.component';
import { VehiclesService } from 'src/app/services/vehicles/vehicles.service';
import { TablaAdminIndependiente } from '../../../interfaces/user.interface';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: 'app-catalogo-vehiculos-table',
  templateUrl: './catalogo-vehiculos-table.component.html',
  styleUrls: ['./catalogo-vehiculos-table.component.css']
})
export class CatalogoVehiculosTableComponent implements OnInit, OnChanges {
  showTableAlert:boolean ;
  alertClass = true; // Se usa para estilizar los colores de la alerta; true = green, false = red
  alertMessage = {
    success: 'Se agregó exitosamente la marca.',
    fail: 'Hubo un error al agregar la marca'
  }
  brandsModels: CatalogVehicles[];
  vehicles: CatalogVehicles[];
  displayModels = false;
  modelsTableData; 
  brandSelected;
  addedBrandModel = false;
  addedNewModels = false;
  hasData: boolean = false;
  selection = new SelectionModel<CatalogVehicles>(allowMultiSelect, initialSelection);
  @ViewChild('brandSort') brandSort: MatSort;
  @Input() displayedColumns: string[] = ['marca'];
  dataSource = new MatTableDataSource(this.vehicles);
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    public dialog: MatDialog,
    public vehiclesService: VehiclesService,
    public utils: UtilsService,
    private ngxService: NgxUiLoaderService
  ) {
    this.showTableAlert = false;
  }

  ngOnInit() {
    this.dataSource.sort = this.brandSort;
    this.loadBrands();
  }

  ngOnChanges() {
  }

  // Muestra la tabla de modelos cuando se selecciona una marca
  showModelTable(brandId, brand?, addedNewModels?) {
    $('.mat-cell').removeClass('rowActive');
    $('.' + brandId).parent().addClass('rowActive');
    this.addedNewModels = addedNewModels;
    this.brandSelected = [brand, brandId];
    this.displayModels = true;

    for(let i = 0; i < this.brandsModels.length ; i++ ) {
      if (this.brandsModels[i].brand_id === brandId) {
        this.modelsTableData = this.brandsModels[i].models;
      }
    }
  }

  // Abre el modal para dar de alta marca y modelos
  openDialog(hasBrand, brandName, brandsModels, modelsList): void {
    const dialogRef = this.dialog.open(ModalAltaMarcasModelosComponent,{
      panelClass: 'modal-send-notification',
      data: { 
        hasBrand : hasBrand,
        brand: brandName,
        existingModels: brandsModels,
        modelsList: modelsList}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.addedBrandModel = result;
      // Si 'addedBrandModel' === true significa que se dió de alta de forma exitosa una marca y/o modelo
      // y se mostrará una alerta al cerrar el modal. Si el parámetro es false, null o undefined, no sucede nada
      if (this.addedBrandModel === true) {
        this.ngxService.start();
        this.vehiclesService.getModel().subscribe(
          response => { 
            this.ngxService.stop();
            this.loadBrandsSuccess( response ); 
            if (this.addedBrandModel && hasBrand) {
              console.log('added model');
              this.addedNewModels = true;
              this.showModelTable(brandName[1], brandName[0], this.addedNewModels);
            }
            if (this.addedBrandModel && !hasBrand) {
              console.log('added brand');
              this.showTableAlert = true;
            }
            
          },
          error => {
            this.ngxService.stop(); 
            this.loadBrandsError( error );
          } 
        );
      }
    });
  }

  // Removes orange background from brands row on models table close.
  noBrandSelected(){
    $('.mat-cell').removeClass('rowActive');
  }
  

  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }

  // Obtiene un listado con todos las marcas y  modelos registrados
  public loadBrands() {
    this.ngxService.start();
    this.vehiclesService.getModel().subscribe(
        response => { 
          this.ngxService.stop();
          this.loadBrandsSuccess( response ); console.log(response);
        },
        error => { 
          this.ngxService.stop();
          this.loadBrandsError( error );
        } 
    );
}

/*
    Load Brands Callbacks
*/
private loadBrandsSuccess( response: any ) {
    let code = response.success;
    if (code != 1) {
        this.utils.errorManagement( response );
    } else {
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.sort = this.brandSort;
      this.brandsModels = response.data;
      this.hasData = true;
    }
}

private loadBrandsError( error: any ) {
    console.error( error );
}

}

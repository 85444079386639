import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ChangeDetectorRef} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, PageEvent} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalEditarEquipoSoltekComponent } from '../../../shared/modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component'; 
import { ModalEliminarComponent } from '../../../shared/modals/modal-eliminar/modal-eliminar.component'; 
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from 'src/app/app.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { TablaAdminIndependiente } from '../../../interfaces/user.interface';
import { GetTeamSoltek } from '../../../interfaces/get-team-soltek';
import { element } from 'protractor';
import { FormControl } from '@angular/forms';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { PaginatorService } from "../../../services/paginator.service";

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.css']
})
export class MembersTableComponent implements OnInit, OnChanges {
  @Input() Users;
  @Input() displayedColumns: string[] = ['name', 'email', 'user_status_id', 'acciones'];
  dataSource;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  paginatorId = 'pMt'
  paginatorChosenValue: number;
  clientType:number;
  @Input()getTeamSoltek: TablaAdminIndependiente[];
  data;
  hasData: boolean;
  @Output() reload: EventEmitter< object >;
  @Output() toggleChange: EventEmitter<void> 
  @Input() checked: Boolean;
  toggleUserStatus = new FormControl();
  showTableAlert:boolean ; // Used to display table alert. Pass a boolean.
  alertClass = true; // Used to style table alert colors; true = green, false = red.
  alertMessage = {
    success: 'Se editó exitosamente el usuario.',
    fail: 'Hubo un error al editar al usuario'
  }
  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private utils: UtilsService, 
    private spinner: NgxSpinnerService,
    private ngxService: NgxUiLoaderService,
    private paginatorService: PaginatorService
  ) { 
    this.reload = new EventEmitter();
    this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
  }

  ngOnInit() {
    if(this.Users.length <= 0){
      this.hasData = false;
    } else {
      this.hasData = true
    }
  }

  ngOnChanges( changes: SimpleChanges ) {
    const Users: SimpleChange = changes.Users;
    console.log('prev value: ', Users.previousValue);
    console.log('got name: ', Users.currentValue);
    this.Users = Users.currentValue;
    console.log(this.Users);
    if(this.Users.length <= 0){
      this.hasData = false;
    } else {
      this.hasData = true
    }
    this.dataSource = new MatTableDataSource(Users.currentValue);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );

  }

refresh(){
  window.location.reload();
}



applyDatasource(): void {
  setTimeout( (event) => {
  }, 500);
}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openEditDialog(name, last_name, email, user_id, user_type_id): void { 
    const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
          name: name,
          last_name: last_name,
          email: email,
          user_id: user_id,
          user_type_id: Number(user_type_id)
        }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        console.log('editar bien!');
        this.showTableAlert = true;
        this.reload.emit( {
          reload: true,
          toggleUserStatus: false
        } );
      }
    });
  }

  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }

  openDeleteDialog(user_id): void { 
    console.log(user_id);
    const dialogRef = this.dialog.open(ModalEliminarComponent,{
      panelClass: 'modal-send-notification',
      data: {
        user_id: user_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      console.log( result );
      if (result[0] === true) {
        this.openMessageDialog( true, result[1] )
      } 
      if  (result[0] === false) {
        this.openMessageDialog( false, result[1] )
      } 
    });
  }


  // Bloquea, desbloquea, borra usuarios
  public manageUser(user_id, status, name) {
    let new_status:number;
    if (status === 1) {
      new_status = 2;
    }
    if (status === 2) {
      new_status = 1;
    }
    const data = {
      user_id: user_id,
      status: new_status
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-'+ name);
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageUser(data).subscribe(
      response => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserError();
       }
    )
  }

  // Envía al componente padre la indicación de recargar la vista
  manageUserSuccess() {
    this.reload.emit( {
      reload: true,
      toggleUserStatus: true
    });
  }
  
  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }

  openMessageDialog( success, message ): void {
    let data = {
      message : message,
      success : success
    }
    console.log(data);
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (data.message === 'Usuario manejado correctamente') {
        this.reload.emit( {
          reload: true,
          toggleUserStatus: undefined
        });
      }
    });
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

}
import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MatExpansionModule, MatIcon} from '@angular/material';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ModalAltaChoferesComponent } from '../../shared/modals/modal-alta-choferes/modal-alta-choferes.component'; 
import { ProfileService } from '../../services/users/profile.service'
import { UtilsService } from '../../app.utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-testing-grounds',
  templateUrl: './testing-grounds.component.html',
  styleUrls: ['./testing-grounds.component.css']
})
export class TestingGroundsComponent implements OnInit {
  hasSelectedCompany: boolean = false;
  DragAndDropFileOperation = 0; // 0 = 'choferes', 1= 'vehiculos' y 2 ='bases'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc

  constructor(
    public dialog: MatDialog,
              private profileService: ProfileService,
              private utils: UtilsService,
              private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAltaChoferesComponent,{
      panelClass: 'modal-send-notification',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log( result );
    });
  };

}

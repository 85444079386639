import { Component, OnInit, ViewChild, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Inject } from '@angular/core';
import { UtilsService } from '../../../app.utils';
import { Router } from '@angular/router';
import noInternet from 'no-internet';
import { ProfileService } from '../../../services/users/profile.service';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { InputPasswordService } from '../../../services/input-password.service';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-alta-instalador-supervisor',
  templateUrl: './modal-alta-instalador-supervisor.component.html',
  styleUrls: ['./modal-alta-instalador-supervisor.component.css']
})
export class ModalAltaInstaladorSupervisorComponent implements OnInit {
  formEditarEquipoSoltek: FormGroup;
  serviceError:string;
  user_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.staffMessages.email_errors;
  pass_errors: any = ValidationMessages.staffMessages.pass_errors;
  passc_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");

  tabs = ['Nuevo Usuario', 'Carga Masiva'];
  tab_selected = new FormControl(0);
  DragAndDropFileOperation = 3; // 0 = 'choferes', 1= 'vehiculos', 2 ='bases', 3 = 'usuarios'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<ModalAltaInstaladorSupervisorComponent>,
      private utils: UtilsService,
      private router: Router,
      private profileService: ProfileService,
      private fb: FormBuilder,
      private inputPasswordService: InputPasswordService,
      private ngxService: NgxUiLoaderService){
      this.formEditarEquipoSoltek = this.createFormData(data)
      
      this.formEditarEquipoSoltek.controls['name'].statusChanges.subscribe(
        data =>{
          console.log(data);
        }
      )
      console.log(data);
  }

  ngOnInit() {
  }

  onNoClick( result? ): void {
    this.dialogRef.close( result );
  }

  refresh(){
    window.location.reload();
  }

  public addMember( form ) {
    this.ngxService.startLoader('loader-modal');
    noInternet().then(offline => {
        if (offline) {
            this.utils.alertError('Verifica tu conexión');
            this.ngxService.stopLoader('loader-modal');
        } else {
            if (form.valid) {
              console.log(form.value);
              this.profileService.addMember( [form.value] ).subscribe(
                      response => { 
                        this.addMemberSuccess( response ); 
                        this.ngxService.stopLoader('loader-modal');
                      },
                      error => { 
                        this.addMemberError( error ); 
                        this.ngxService.stopLoader('loader-modal');
                      }
              );
              
            }
        }
    })
  }
  
  private addMemberSuccess( response: any) {  
  
    console.log(response);
  
    let code = response.success;
    if (code != 1) {
        // this.utils.errorManagement( response );
        this.serviceError = response.message;
    } else {
        // this.utils.toasterSuccess( response );
        this.formEditarEquipoSoltek.reset();
        this.onNoClick( 'true' );
        this.serviceError === null;
    } 
  }
   
  private addMemberError( error: any ) {
    // console.error(error);
    this.serviceError = 'No pudo realizarse la petición. Hubo un error en el servicio.'
  }

  createFormData( data ){
    let password = new FormControl('', 
      [Validators.required,
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12) ] 
      );
    let password_confirmation = new FormControl('', [ Validators.required,
                                                      CustomValidators.equalTo(password)
                                                    ]);
    return new FormGroup({

      'name' : new FormControl('', [Validators.required]),

      // 'last_name' : new FormControl('.'),

      'email' : new FormControl('', [
               Validators.required, 
               CustomValidators.email
            ]),

      'password' : password, 

      'password_confirmation' : password_confirmation,

      'type' : new FormControl(data.type),
      'company_id' : new FormControl(data.companyId)
    })
  }

  public randomPassword( e ) {
    //      console.log(e.controls.password);
          this.inputPasswordService.randomPassword( e ); 
  }

}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private cookieService: CookieService ) {}

    public isAuthenticated(): boolean {
        const cookieExists: boolean = this.cookieService.check('access_token');
        return cookieExists;
    }

    public isUserType(): number {
      const cookieExists: number = Number(this.cookieService.get('user_type'));
      return cookieExists;
  }

}

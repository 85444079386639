import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalSendNotificationComponent } from '../../shared/modals/modal-send-notification/modal-send-notification.component'
import { PushService } from '../../services/push.service';
import { UserCategoryService } from '../../services/user-category.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { number } from 'ng4-validators/src/app/number/validator';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {
  notifications;
  hasData: boolean = false;
  constructor(
    public dialog: MatDialog,
    private pushService: PushService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private userCat: UserCategoryService
  ) {
    this.getNotifications();
  }

  ngOnInit() {
  }

  getNotifications() {
    this.ngxService.start();
    this.pushService.getNotifications().subscribe(
      response => {
        let code = response.success;
        let data = response.data;
        console.log(data);
        if (code != 1) {
          // error
          this.ngxService.stop();
          this.openDialogMessage(false);
        } else {
          // success
          this.ngxService.stop();
          this.getNotificationsSuccess(data);
        }
      },
      error => {
        this.ngxService.stop();
        this.openDialogMessage(false);
      }
    );
  }

  getNotificationsSuccess(data) {
    let keys = [];
    let notifications = [];
    let position = -1;
    data.forEach(element => {
      if (element.push_id !== null) {
        // El servicio envía todas las notificaciones que han sido enviadas por usuario.
        // Para mostrar las notificaciones que fueron enviadas en grupo, se toma el parámetro 'push_id',
        // Si ese parámetro se repite en más de una ocasión, significa que la notificación fue enviada en grupo
        // y solo se mostrará una vez en tabla.
        if (!this.checkIfExists(keys, element.push_id)) {
          keys.push(element.push_id);
          element.numberOfRecievers = 1;
          notifications.push(element);
          position++;
        } else {
          notifications[position].numberOfRecievers++;
        }
      }
    });

    notifications.forEach(element => {
      let data_split = element.created_at.split(' ');
      let date = moment(data_split[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '');
      let time = data_split[1].slice(0, 5)
      element.date = date;
      element.time = time;

      if (element.user_type === null || element.user_type === undefined) {
        element.sent_to = 'Sin datos'
      } else {
        element.sent_to = this.userCat.getUserCategory(Number(element.user_type));
      }

      if (element.numberOfRecievers > 1) {
        element.sent_to = element.user_info.user_type_description
      } else {
        if (element.user_info.last_name === null) {
          element.sent_to = element.user_info.name
        } else {
          element.sent_to = element.user_info.name + ' ' + element.user_info.last_name
        }
      }

    });

    this.notifications = notifications;
    if (this.notifications.length >= 1) {
      this.hasData = true;
    } else {
      this.hasData = false;
    }

    this.ngxService.stop();
  }

  checkIfExists(arr, val) {
    return arr.some(function (arrVal) {
      return val === arrVal;
    });
  }

  getNotificationsError() {
    this.openDialogMessage(false);
  }



  // openSendNotification() Abre la imagen seleccionada en la tabla
  openSendNotification(): void {
    const dialogRef = this.dialog.open(ModalSendNotificationComponent, {
      panelClass: 'modal-send-notification'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined) {
        this.openDialogMessage(result)
      }
    });
  }

  openDialogMessage(success?): void {
    let modal_success,
      modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al enviar la notificación. Intenta de nuevo más tarde'
    }

    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }

    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent, {
      panelClass: 'modal-send-notification',
      disableClose: true,
      data: {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      }
    });
  }
}

import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormControl} from '@angular/forms';
import { ProfileService } from '../../services/users/profile.service';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-editar-cliente',
  templateUrl: './editar-cliente.component.html',
  styleUrls: ['./editar-cliente.component.css']
})


export class EditarClienteComponent implements OnInit, OnDestroy {
  clientData: any;
  idCompany;
  selected = new FormControl(0);
  editCompanyData;
  reloadData;

  constructor(private router: Router,
              private profileService: ProfileService,
              private ngxService: NgxUiLoaderService) {} 

  ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem('AdminCliente'));
    console.log(this.clientData);
    this.idCompany = this.clientData.companyId;
    this.dataForServices( this.clientData );
    // Guarda la ultima posición del mat-tab
    if (localStorage.getItem('EditarCliente-TabLocation')) {
      let index = localStorage.getItem('EditarCliente-TabLocation') // get stored number or zero if there is nothing stored
      this.selected.setValue(index); 
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('newCompanyName');
    localStorage.removeItem('AdminCliente');
  }

  changeButton(a){ 
    localStorage.setItem('EditarCliente-TabLocation', a.index);
  }

  // Back button
  goToAdminCliente() {
    localStorage.removeItem('AdminCliente');
    this.router.navigate(['/usersClientes']);
  }

  // Gets the data arranged for each service
  dataForServices( data ) {
    console.log(data);
    this.editCompanyData = {
      companyId: data.companyId,
      userId: data.userId,
      email: data.email,
      companyName: data.name,
      vehicles_limit: data.vehicles_limit
    }
  }

  // Loads all the database 
  public loadMembers() {
    this.ngxService.start();
    this.profileService.getPremiumCompanies().subscribe(
        response => { 
          this.ngxService.stop(); 
          this.loadMembersSuccess( response ); 
        //  this.spinner.hide(); 
        },
        error => { 
          this.ngxService.stop();
          this.loadMembersError( error ); 
        //  this.spinner.hide(); 
        }
    );
  }

  /*
  loadMembers() Callbacks
*/
private loadMembersSuccess( response: any ) {
  let code = response.success;
  let data = response.data;
  let users = [];
  let headers;
  let companyId = JSON.parse(localStorage.getItem('AdminCliente')).companyId;
  let email = JSON.parse(localStorage.getItem('AdminCliente')).email;
  let userId = JSON.parse(localStorage.getItem('AdminCliente')).userId
  let companyName;
  if (localStorage.getItem('newCompanyName')) {
    companyName = localStorage.getItem('newCompanyName');
  } else {
    companyName = JSON.parse(localStorage.getItem('AdminCliente')).name;
  }
  if (code != 1) {} else {
      companyId = data[companyName][0].id_company;
      users = data[companyName][0].users;
      headers = Object.keys(users);

      console.log(data[companyName]);
      // console.log(companyId);
      // console.log(users[headers[0]][0].email);
      // console.log(headers);
      let element = {
        companyId: companyId,
        name: companyName, 
        email: users[headers[0]][0].email,
        userId: users[headers[0]][0].id,
        vehicles_limit: data[companyName][0].limits.vehicles_limit
      }
      console.log(element);
      localStorage.setItem('AdminCliente', JSON.stringify(element));
      this.clientData = element;
      this.dataForServices( this.clientData );
    }
  }

  private loadMembersError( error: any ) {
    console.error( error );
  }

}

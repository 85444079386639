import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharingServiceService {
  
  private data: any = undefined;

  setData(data: any) {
    this.data = data;
  }

  getData():any {
    return this.data;
  }

  resetData() {
    this.data = undefined;
    console.log('The value is ' + this.data );
  }
  
}

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Gauge } from 'gaugeJS';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class GaugeService {
  userType = Number(localStorage.getItem('userType'));
  // Configuración para crear las gráficas
  private opts: any = {
    angle: 0.09,
    lineWidth: 0.45,
    radiusScale: 0.9,
    pointer: {
      length: 0.5,
      strokeWidth: 0.02,
      color: '#0a0b09'
    },
    limitMax: false,
    limitMin: false,
    colorStart: '#f99046',
    colorStop: '#f99046',
    strokeColor: '#ededed',
    generateGradient: true,
    highDpiSupport: true,
    renderTicks: {
      divisions: 6,
      divWidth: 1.5,
      divLength: 0.25,
      divColor: '#333333',
      subDivisions: 3,
      subLength: 0.2,
      subWidth: 0.6,
      subColor: '#666666'
    },
    // Esta configuración es innecesaria por diseño
    // staticLabels: {
    //   font: "20px Helvetica",  // Specifies font
    //   labels: [ 1 ],  // Print labels at these values
    //   color: "#f99046",  // Optional: Label text color
    //   percentage: 1,
    //   fractionDigits: 0
    // },
  }


  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  setupGauge(gaugeData) {
    const user_type: number = 1;
    let data: any = gaugeData;
    let target, gauge, gauge1, gauge2, gauge3;
    console.log(data);
    let customersData;
    let vehiclesData;
    let supervisionData;
    let totalSupervisedData;
    // Customers gauge
    // Esta parte define que tipo de gráfica es de acuerdo a la información que contiene la variable 'data'
    // Este if construye una gráfica opcional que solo se muestra cuando el tipo de usuario autentificado = 1
    if (data.customers != undefined) {
      if (data.period === 'current' || data.period === 'lastMonth') {
        customersData = {
          total: data.customers.total_new,
          hired: data.customers.customers_hired
        }
      } else {
        customersData = {
          total: data.customers.total,
          hired: data.customers.customers_hired
        }
      }
    }

    if (data.period === 'current' || data.period === 'lastMonth') {

      vehiclesData = {
        total: data.vehicles.total_new,
        install: data.vehicles.install_new
      }
      supervisionData = {
        total: data.supervision.checked,
        damage: data.supervision.damage
      }
      totalSupervisedData = {
        total: data.total_supervised.total_supervised,
        damage: data.total_supervised.supervised_with_damage
      }
    } else {
      vehiclesData = {
        total: data.vehicles.total,
        install: data.vehicles.install_total
      }
      supervisionData = {
        total: data.supervision.checked,
        damage: data.supervision.damage
      }
      totalSupervisedData = {
        total: data.total_supervised.total_supervised,
        damage: data.total_supervised.supervised_with_damage
      }
    }

    // Una vez procesada la información, se construyen las gráficas
    console.log(data.customers);
    if (this.userType !== 2 && this.userType !== 3 && this.userType !== 9) {
      target = document.getElementById('gaugeClientes');
      gauge = new Gauge(target).setOptions(this.opts);
      gauge.maxValue = customersData.total;
      if (gauge.maxValue <= 0 || gauge.maxValue === undefined) {
        gauge.maxValue = 1;
      }
      gauge.set(customersData.hired);
    }

    const target2 = document.getElementById('gaugeVehicles');
    gauge1 = new Gauge(target2).setOptions(this.opts);
    gauge1.maxValue = vehiclesData.total;
    if (gauge1.maxValue <= 0 || gauge1.maxValue === undefined) {
      gauge1.maxValue = 1;
    }
    gauge1.set(vehiclesData.install);

    const target3 = document.getElementById('gaugeSupervision');
    gauge2 = new Gauge(target3).setOptions(this.opts);
    gauge2.maxValue = supervisionData.total;
    console.log(gauge2.maxValue);
    if (gauge2.maxValue <= 0 || gauge2.maxValue === undefined) {
      gauge2.maxValue = 1;
    }
    gauge2.set(supervisionData.damage);

    const target4 = document.getElementById('gaugeTotalSupervised');
    gauge3 = new Gauge(target4).setOptions(this.opts);
    gauge3.maxValue = totalSupervisedData.total;
    if (gauge3.maxValue <= 0 || gauge3.maxValue === undefined) {
      gauge3.maxValue = 1;
    }
    gauge3.set(totalSupervisedData.damage);
  }

  getPercentage(percentFor, percentOf) {
    if (percentFor === 0 || percentOf === 0) {
      return 0;
    }
    let percentage = Math.floor((Number(percentFor) / Number(percentOf)) * 100);
    if (!percentage || percentage === NaN || percentage === Infinity) {
      return 0;
    }

    console.log(percentage);
    return percentage <= 100 ? percentage : 100;
  }

  getDashboard(period) {
    const access_token: string = this.cookieService.get('access_token');
    const token_type: string = this.cookieService.get('token_type');
    const body = period;
    //  console.log(body);
    const headers = new Headers({
      'Accept': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${token_type} ${access_token}`
    });

    const url = `${GLOBAL_URL}/api/staff/dashboard_opt`;
    return this.http.post(url, body, { headers })
      .map((response: Response) => response.json());

  }
  getDashboardNew() {
    const access_token: string = this.cookieService.get('access_token');
    const token_type: string = this.cookieService.get('token_type');
    const headers = new Headers({
      'Accept': 'application/json',
      'lang': 'es_mx',
      'Authorization': `${token_type} ${access_token}`
    });

    const url = `${GLOBAL_URL}/api/staff/dashboard`;
    return this.http.get(url, { headers })
      .map((response: Response) => response.json());

  }
}
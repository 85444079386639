import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

// Componentes para Vistas
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { RecoverpasswordComponent } from './components/recoverpassword/recoverpassword.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AdminIndependienteComponent } from './components/admin-independiente/admin-independiente.component';
import { AdminClienteComponent } from './components/admin-cliente/admin-cliente.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AdminIndVehiculosRegistradosTableComponent } from './shared/tables/admin-ind-vehiculos-registrados-table/admin-ind-vehiculos-registrados-table.component';
import { AltaClientesComponent } from './components/alta-clientes/alta-clientes.component';
import { AltaNuevoClienteComponent } from './components/alta-nuevo-cliente/alta-nuevo-cliente.component';
import { EditarClienteComponent } from './components/editar-cliente/editar-cliente.component';
import { CatalogoVehiculosTableComponent } from './shared/tables/catalogo-vehiculos-table/catalogo-vehiculos-table.component';
import { ChoferesComponent } from './components/choferes/choferes.component';
import { EquipoSoltekComponent } from './components/equipo-soltek/equipo-soltek.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones.component';
import { AdminClienteDetalleClienteComponent } from './components/admin-cliente-detalle-cliente/admin-cliente-detalle-cliente.component';
import { FichaTecnicaTableComponent } from './shared/tables/ficha-tecnica-table/ficha-tecnica-table.component';
import { VehiculosTableComponent } from './shared/tables/vehiculos-table/vehiculos-table.component';
import { VehiculosDetalleTableComponent } from './shared/tables/vehiculos-detalle-table/vehiculos-detalle-table.component';
import { DetallePuntoRevisionComponent } from './components/detalle-punto-revision/detalle-punto-revision.component';
import { VehiculosClienteComponent } from './components/vehiculos-cliente/vehiculos-cliente.component';
import { ChoferesClienteComponent } from './components/choferes-cliente/choferes-cliente.component';
import { DriverDamagedListComponent } from './components/driver-damaged-list/driver-damaged-list.component';
import { EquipoClienteComponent } from './components/equipo-cliente/equipo-cliente.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';
import { TestingGroundsComponent } from './components/testing-grounds/testing-grounds.component';

const APP_ROUTES: Routes = [

    // Esta vista se ocupa para probar nuevos features
    {path: 'testing-grounds', component: TestingGroundsComponent},

    // Esta es la vista para autentificarte como usuario
    { path: 'login', component: LoginComponent },

    // Esta vista se ocupa para recuperar la contraseña de un usuario existente
    { path: 'password-recovery', component: RecoverpasswordComponent },

    // Esta vista es únicamente accesible mediante una liga con un token único, contenida en un correo de recuperación de contraseña
    { path: 'reset-password/:id', component: ResetPasswordComponent },

    // Esta es la vista del aviso de privacidad
    { path: 'privacidad', component: AvisoPrivacidadComponent },
    
    // Esta es la vista del Dashboard informativo y sirve como el Home cuando un usuario se autentificó correctamente
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

    // Usuarios PREMIUM
    // Esta es la ruta para visualizar la tabla con usuarios PREMIUM
    { path: 'usersClientes', canActivate: [AuthGuard],
        children: [
            { path: '', component: AdminClienteComponent },

            // Esta ruta permite dar de alta un nuevo usuario tipo PREMIUM
            { path: 'alta-nuevo-cliente', component: AltaNuevoClienteComponent },
            { path: 'editar-cliente/:id', component: EditarClienteComponent },
        ]
    },

    // Usuarios FREEMIUM
    // Esta es la ruta para visualizar la tabla con usuarios FREEMIUM
    { path: 'users', canActivate: [AuthGuard],
    children: [
        { path: '', component: AdminIndependienteComponent },

        // Esta es la ruta donde se hace el cambio de un usuario FREEMIUM a uno PREMIUM
        { path: 'alta-cliente/:id', component: AltaNuevoClienteComponent }
    ]
    },

    // Detalles Usuarios PREMIUM
    // Es una vista exclusiva para visualizar los datos de un usuario tipo PREMIUM
    { path: 'detalle-cliente', component: AdminClienteDetalleClienteComponent, canActivate: [AuthGuard] },

    // Vehículos Usuarios FREEMIUM
    // Es una vista exclusiva para visualizar los vehículos de un usuario tipo FREEMIUM
    { path: 'vehiculos-cliente', component: AdminIndVehiculosRegistradosTableComponent , canActivate: [AuthGuard]},
    
    // Pantalla donde se muestra la ficha técnica de un vehículo, así como sus datos principales
    { path: 'ficha-tecnica', component: FichaTecnicaTableComponent, canActivate: [AuthGuard] },

    // Pantalla donde se muestra el detalle de un punto instalado a un vehículo, así como los datos principales de este vehículo
    { path: 'detalle-punto-revision', component: DetallePuntoRevisionComponent, canActivate: [AuthGuard] },

    // Lista de vehiculos
    // Muestra todos los vehículos registrados en base de datos, separados en marca y modelo
    { path: 'vehiculos', canActivate: [AuthGuard],
    children: [
        { path: '', component: VehiculosTableComponent },
        
        // Muestra todos los vehículos registrados en base de datos de la misma marca y modelo seleccionada en la pantalla anterior
        { path: 'detalle-vehiculo', component: VehiculosDetalleTableComponent },
    ] 
    },

    // Vista que muestra la lista de marcas y modelos dentro de la base de datos
    // Cuenta con Modal que permite agregar nuevos Marcas y modelos
    { path: 'catalogo-vehiculos', component: CatalogoVehiculosTableComponent, canActivate: [AuthGuard] },
    
    // Vista que muestra lista de compañias y choferes que pertenecen a cada una, gestión de choferes
    // Cuenta con modal para agregar choferes a compañías
    { path: 'choferes', component: ChoferesComponent, canActivate: [AuthGuard] },

    // Esta vista es exclusiva con usuarios autentificados con USER TYPE = 2
    // Muestra a todos lo choferes registrados en esta compañía
    { path: 'choferes-clientes', component: ChoferesClienteComponent, canActivate: [AuthGuard] },     

    // Esta vista es exclusiva con usuarios autentificados con USER TYPE = 2
    // Lista de vehiculos con daño por un chofer
    { path: 'vehiculos-danados-choferes', component: DriverDamagedListComponent, canActivate: [AuthGuard] },

    // Esta vista es exclusiva con usuarios autentificados con USER TYPE = 2
    // Lista de vehiculos registrados a esa compañía
    { path: 'vehiculos-clientes', component: VehiculosClienteComponent, canActivate: [AuthGuard] },  

    // Esta vista es exclusiva con usuarios autentificados con USER TYPE = 2
    // Lista de usuarios registrados a esa compañía
    { path: 'mi-equipo', component: EquipoClienteComponent, canActivate: [AuthGuard] },  


    // Esta vista permite enviar notificaciones a los usuarios registrados
    { path: 'notificaciones', component: NotificacionesComponent, canActivate: [AuthGuard] },

    // Esta vista es exclusiva para el Admin Soltek
    // Muestra a los usuarios registrados en la compañía Soltek
    { path: 'equipo-soltek', component: EquipoSoltekComponent, canActivate: [AuthGuard] },

    // Esta vista muestra el perfil del usuario autenticado
    { path: 'perfil', component: ProfileComponent, canActivate: [AuthGuard] },

    // Esta ruta debe borrarse posteriormente
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },  

    // Ruta default
    { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },

   
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, {useHash: true});


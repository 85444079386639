import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MatExpansionModule, MatIcon} from '@angular/material';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ModalAltaChoferesComponent } from '../../shared/modals/modal-alta-choferes/modal-alta-choferes.component'; 
import { ProfileService } from '../../services/users/profile.service'
import { UtilsService } from '../../app.utils';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';

  export class companiesDriversList {
    company: string
    company_id: number
    drivers: []
  }

@Component({
  selector: 'app-choferes',
  templateUrl: './choferes.component.html',
  styleUrls: ['./choferes.component.css']
})
export class ChoferesComponent implements OnInit, OnChanges, OnDestroy {
  showTableAlert;
  alertClass = true; // Se usa para estilizar los colores de la alerta; true = green, false = red
  alertMessage = {
    success: 'Se agregó exitosamente el chofer.',
    fail: 'Hubo un error al agregar el chofer.'
  }
  userFilter: any = { company: '' }; // This restricts the data filtered in the search box
  @Input() expanded: any
  getCompaniesDrivers: companiesDriversList[];
  companiesDrivers: companiesDriversList[];
  companieSelected;
  displayDrivers = false;
  driversTableData;
  expand: number;
  hasData: boolean;
  dataSource;

  constructor(public dialog: MatDialog,
              private profileService: ProfileService,
              private utils: UtilsService,
              private ngxService: NgxUiLoaderService
              ) { 
              }

  ngOnInit() {
    this.loadCompanies();
  }

  ngOnChanges( changes: SimpleChanges) {
    // this.expand = this.getLastPanelOpenend();
    // console.log(this.expand);
  }

  // Se remueve la información del panel abierto
  ngOnDestroy() {
    localStorage.removeItem('panelOpen');
  }

  // Abre el modal para dar de alta choferes
  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAltaChoferesComponent,{
      panelClass: 'modal-send-notification',
    });
    dialogRef.afterClosed().subscribe(data => {
      console.log( data );
      if (data.result) {
        this.showTableAlert = true;
        this.afterEditUser();
      }
    });
  }

  // Guarda en localStorage el panel actual abierto, si la vista se destruye y vuelve a iniciarse
  // el último panel abierto se mostrará por default
  openedPanel(openPanelPosition){
    console.log(openPanelPosition);
    localStorage.setItem('panelOpen',  openPanelPosition);
  }

  // Esta función se ocupa despues de haber editado un chofer, se recarga
  // la vista y se muestra el último elemento del acordeon que fue abierto abierto 
  afterEditUser(){    
    this.ngxService.start();
    let driverList;
    Promise.all([ 
      this.loadCompanies( false )
    ]).then((value) => {
      this.expand = -1;
      console.log(this.userFilter);
      if(this.userFilter.company != '') {
        this.userFilter.company = ''
      }
      else {
        this.expand = parseInt(localStorage.getItem('panelOpen'));
      }
      driverList = this.companiesDrivers[this.expand].drivers;
      driverList.forEach(element => {
        element.company_name = this.companieSelected;
      });
      console.log( driverList );
      this.driversTableData = driverList;
      this.hasData = true;
      console.log(this.expand);
      this.ngxService.stop();
    }, ( error ) => {
      this.ngxService.stop();
    })
  }

  // Esta función se ocupa despues de haber borrado un chofer, se recarga
  // la vista y se muestra el último elemento del acordeon que fue abierto abierto 
  afterDeleteUser(){
    let driverList;
    Promise.all([ 
      this.loadCompanies( false )
    ]).then((value) => {
      this.expand = -1;
      this.expand = parseInt(localStorage.getItem('panelOpen'));
      driverList = this.companiesDrivers[this.expand].drivers;
      driverList.forEach(element => {
        element.company_name = this.companieSelected;
      });
      console.log( driverList );
      this.driversTableData = driverList;
      console.log(this.expand);
    }, function ( error ){})  
  }

  // Cierra Alert Table
  closeTableAlert(data){
    this.showTableAlert = false;
  }

  // Muestra a los choferes de una compañía cuando se clickea el nombre de la misma
  showDrivers(companyId,company, position?) {
    console.log(companyId);
    console.log(position);
    console.log(company);
    this.companieSelected = company;
    this.displayDrivers = true;
    for(let i = 0; i < this.companiesDrivers.length ; i++ ) {
      if(this.companiesDrivers[i].company === company) {
        let drivers: any = this.companiesDrivers[i].drivers;
        drivers.forEach(element => {
          element.company_name = company;
        });
        this.driversTableData = drivers;
      }
    }
    console.log(this.driversTableData);
  }



  // Carga todas las compañias
  // Si el parámetro 'startLoader'  no es false, se muestra el loader
  public loadCompanies(startLoader?) {
    return new Promise ((resolve, reject) => {
      if (typeof startLoader === 'undefined' || startLoader === true){
        this.ngxService.start();
      }
      this.profileService.getCompaniesDrivers().subscribe(
          response => { 
            console.log(response);
            let code = response.success;
            if (code != 1) {
                this.utils.errorManagement( response );
            } else {
              this.companiesDrivers = response.data;
              // Esta parte filtra a los usuarios con status 3 (eliminado) de la vista
              this.companiesDrivers.forEach(element => {
                console.log(element);
                for (var item in element.drivers) { 
                  let num = item;
                  if (element.drivers[num].status === 3) {
                    console.log(element.drivers[item]);
                    element.drivers.splice(Number(num),1);
                  }
                 }
              });
              this.dataSource = new MatTableDataSource( this.companiesDrivers);
              this.hasData = true;        
              resolve( true );
            }
            this.ngxService.stop();
          },
          error => { 
            console.log(error);
            this.ngxService.stop();
            this.hasData = false;            
            reject( error );
          } 
      );
    })
    
  }

  onSearchChange(event) {
    console.log(event);
    console.log(this.companiesDrivers);  
    console.log( this.dataSource );    
    console.log(this.userFilter);
  }

}

import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDatosUsuario]'
})
export class DatosUsuarioDirective {

  constructor(private el: ElementRef) { 
    this.setupDatosUsuario();
  }

  setupDatosUsuario() {
    const parent = this.el.nativeElement.parentNode;
    const element = document.getElementById('datos-usuario-directive');
    element.innerHTML = `
      <div class="datos-usuario-box p-3">
          <div class="row">
              <div class="col-12 col-md-4 d-flex flex-row">
                  <div class="icon mr-2">
                      <img class="img-fluid" src="../../../../assets/images/icn-independiente.png" alt="Icon">
                  </div>
                  <div>
                      <p class="text-title">Admin Independiente</p>
                      <p class="pt-1">Kokonut Studio</p>
                      <p>coreo@mail.com</p>
                  </div>
              </div>
              <div class="col-12 col-md-8 p-3 p-md-0">
                  <hr class="hr-vertical d-none d-md-block">
                  <div class="col-12 d-flex flex-row px-0">
                      <div class="icon mr-2">
                          <img class="img-fluid" src="../../../../assets/images/icn-vehiculo.png" alt="Icon">
                      </div>
                      <div style="width:100%;">
                          <p class="text-title">Vehículo</p>
                          <div class="pt-1 row">
                              <div class="col-4">
                                  <p><b>Marca:</b> Freightliner </p>
                                  <p><b>Modelo:</b> Columbia </p>
                              </div>
                              <div class="col-4">
                                  <p><b>No. Tanques:</b> 4 </p>
                              </div>
                              <div class="col-4">
                                  <p><b>No. Económico:</b> 1234 </p>
                                  <p><b>No. de serie:</b> 0123 4567 </p>
                              </div>
                          </div>
                      </div>
  
                  </div>
  
              </div>
          </div>
      </div>
    `;
    parent.appendChild(element);

  }

}

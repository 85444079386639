import { Directive, EventEmitter, ElementRef, HostListener, Input, Output } from '@angular/core';
import { FileItem } from './file-item';
import { fileURLToPath } from 'url';

@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirectiveDirective {
  @Input() expectedFileType: string;
  @Input() files: FileItem[] = [];
  @Output() mouseOver: EventEmitter<boolean> = new EventEmitter();
  @Output() hasImage = new EventEmitter();
  @Output() file = new EventEmitter();

  constructor() { }

  @HostListener('dragover', ['$event'])
  public onDragEnter( event:any ){
      this._preventOpen( event );

      this.mouseOver.emit( true );
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave( event:any ){
      this._preventOpen( event );

      this.mouseOver.emit( false );
  }

  @HostListener('drop', ['$event'])
  public onDrop( event:any ){
      const transfer = this._getTransfer( event );
      if( !transfer ){
        return;
      }
      this._preventOpen( event );
      this._extractFiles( transfer.files );
      this.mouseOver.emit( false );
  }

  private _getTransfer( event:any ){
    return  event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer
  }

  private _extractFiles( fileList: FileList ){
      console.log(fileList);
      for ( const property in Object.getOwnPropertyNames( fileList )) {
        const tempFile = fileList[property];
        if ( this._validFile( tempFile )){
          this.hasImage.emit( true );
          const newFile = new FileItem( tempFile );
          this.files.push( newFile );
          this.mouseOver.emit( true );
          console.log(newFile);
          this.file.emit( newFile );
        } else {
          alert('El tipo de archivo no es válido');
        }
      }


  }

  //Validations

  private _validFile( file: File ): boolean{
    switch (this.expectedFileType) {
      case 'image':
        if ( this._isValid( file.type, this.expectedFileType )){
          return true;
        } else {
          return false;
        }
        break;
      case 'xlsx':
        const fileExtension = file.name.split('.');
        if ( this._isValid( fileExtension[1], this.expectedFileType )){
          return true;
        } else {
          return false;
        }
        break;
    
      default:
        return false;
        break;
    }
    
  }

  private _preventOpen( event ) {
    event.preventDefault();
    event.stopPropagation();
  }
  
  private _isValid ( fileType: string, expectedFiletype: string ): boolean{
      return (fileType === '' || fileType === undefined) ? false : fileType.startsWith(expectedFiletype);
  }

}

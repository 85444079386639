import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { CustomValidators } from 'ng4-validators';
import { ValidateEmptySpaces } from '../../../validators/additional_validators';
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ModalCancelarAltaClienteComponent } from '../../../shared/modals/modal-cancelar-alta-cliente/modal-cancelar-alta-cliente.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-form-alta-bases',
  templateUrl: './form-alta-bases.component.html',
  styleUrls: ['./form-alta-bases.component.css']
})
export class FormAltaBasesComponent implements OnInit {
  @Output() nextStep: EventEmitter<boolean>;
  @Output() goBack: EventEmitter<boolean>;
  success: boolean = null;
  message: string = null;
  form: FormGroup;
  baseData: FormArray;
  base_errors: any = ValidationMessages.commonMessages.generic_errors;
  companyData;

  constructor(
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) { 
    this.form = this.fb.group({
      baseData: this.fb.array([this.createForm()])
    }) 
    this.nextStep = new EventEmitter();
    this.goBack = new EventEmitter();
    this.companyData = JSON.parse(localStorage.getItem('NewCompany'));

  }

  ngOnInit() {
    this.baseData = this.form.get('baseData') as FormArray;
    this.checkFormChanges();
  }

  createForm(): FormGroup {
    return this.fb.group ({
      // fk_company : new FormControl( clientData.companyId ),
      baseName: ['',[Validators.required, ValidateEmptySpaces]]
    })
  }

  get baseDataFormGroup() {
    return this.form.get('baseData') as FormArray;
  }

  removeInput(position) {
      this.baseData.removeAt(position);
  }

  addData(index, $event) {
    $event.preventDefault();
    this.form.statusChanges;
    this.baseData.push(this.createForm());
  }


  checkFormChanges() {
    this.form.valueChanges.subscribe( data => {
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
          if (controls.baseData.invalid){
            console.log('baseData invalid')
          }
      }
      console.log(invalid);

      Promise.all([ this.checkBases(),])
      .then((values) => { 
        if(values){
          console.log('Válido?: ' + this.form.valid)
        } 
      }, function() {
      });
    })
  }

  checkBases() {
    return new Promise((resolve, reject)=> {
      const bases = this.baseData.value;
      let baseList = [];
      let thisOne;
      let testObject = {};
      let seenDuplicate = false;
      console.log(bases);
      let i;
      for ( i=0; bases[i]; i++ ) {
      // console.log(bases[i]);
        thisOne = {
          baseNum: i,
          value: bases[i].baseName
        }
        baseList.push(thisOne)
      }
      console.log(baseList);
      baseList.map(function(item) {
        let itemPropertyName = item['value'];    
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
          // console.log(item); 
        }
        else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      });   
      let duplicates = [];
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate === true ) {
              duplicates.push(  baseList[i]  );
          }
        }
      if ( seenDuplicate ) {
        console.log(duplicates);
    //    console.log(this.baseData['controls'][0]['controls'].baseName);
        let i;
        for (i = 0; duplicates[i] ; i++) { 
          console.log(duplicates[i]);
          this.baseData['controls'][duplicates[i].baseNum]['controls']['baseName'].setErrors({'duplicate': true});
          // this.baseData[i]['controls'].baseName.setErrors({'duplicate': true} );
        }
        reject('Hay bases duplicadas');
      } else {
        console.log(baseList);
        let i;
        for (i = 0; baseList[i] ; i++) {
          if ( baseList[i].duplicate != true ) {
            this.baseData['controls'][baseList[i].baseNum]['controls']['baseName'].setErrors(null);
          }
        }
        console.log(this.baseData);
        resolve(true);
      }
    })
  }

  createBasesAll() {
    // let responseValues;
    this.ngxService.start();
    let baseData = this.baseData.value;
    let promises = [];
    const companyId = this.companyData.id_company;
    let vehicles = this.vehiclesService;

    baseData.forEach(element => {
      // console.log(element);
      promises.push( {
        baseName: element.baseName,
        companyId: companyId,
        vehicles: vehicles
      })
    })
    console.log(promises);
    Promise.all(promises.map(this.createBases))
      .then(response => {
        // console.log(response);
        this.ngxService.stop();
        let successList = [];
        let successString:string;

        let failList = [];
        let failString:string;
        response.forEach(element => {
          let data: any = element;
          if ( data.success ) {
            successList.push(data.baseName)
            this.success = false;
          } else {
            failList.push(data.baseName)
          }
        });

        if (successList.length >= 1) {
          successString = successList.toString().replace(/,/g, ", ");
        }
        if (failList.length >= 1) {
          failString = failList.toString().replace(/,/g, ", ");
        }

        if (successList.length  >= 1 && (failList === undefined || failList.length === 0 ||  failList === null)){
          this.message = `Las bases: <br><b>` + successList + `</b><br> fueron creadas correctamente.`;
          this.success = true;
        }

        if (successList.length  >= 1 &&  failList.length > 0 ){
          this.message = `Las bases: <br><b>` + successList + `</b><br> fueron creadas correctamente.<br><br> Hubo un problema al crear las siguientes bases: <br><b>` + failString +  `</b>. <br> Revisa que no existan bases con ese nombre.`;
          this.success = false;
        }

        if (( successList === undefined || successList.length === 0 ||  successList === null) &&  failList.length > 0 ){
          this.message =  
          `Hubo un problema al crear las siguientes bases: <br> <b>`
            + failString + 
          `</b>. <br> Revisa que no existan  bases con ese nombre.`;
          this.success = false;
        }
        this.openDialog(this.success, this.message);
        // console.log(successList);
        // console.log(failString);
    })
  }

  createBases( baseData ) {

    return new Promise((resolve, reject)=> {
      // console.log(element.baseName);
        const data = {
          base : baseData.baseName,
          company_id: baseData.companyId
        }
        baseData.vehicles.createBases( data ).subscribe(
          response => { 
            let responseData;
          // console.log(response);  
          // If base already exists or some service related error
            if (response.success === 0) {
              responseData = {
                success: false,
                message : response.message,
                baseName : baseData.baseName,
              }
              // this.success = false;
              // this.message = response.message
            } else {
              responseData = {
                success: true,
                message : response.message,
                baseName : baseData.baseName,
              }
              
              // this.success = true;

            }
            resolve(responseData);
            // this.spinner.hide();    
          },
          error => { 
            // if service can't be reached
            let responseData = {
              success: false,
              message : 'Hubo un error con el servicio',
              baseName : baseData.baseName,
            }
            reject(responseData);
            // console.log(error); 
            // this.spinner.hide(); 
          }
        );
    })
  }

  openDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.nextStep.emit( true );
      }
    });
  }

  openCancelDialog(): void {
    const companyId = JSON.parse(localStorage.getItem('NewCompany')).company_name;
    const dialogRef = this.dialog.open(ModalCancelarAltaClienteComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        user_name: companyId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.goBack.emit( true );
      }
    });
  }

}

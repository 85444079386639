import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm, FormsModule, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as ValidationMessages from '../../../validators/validation-messages';
import { ValidateEmptySpaces } from '../../../validators/additional_validators';

import { InputPasswordService } from '../../../services/input-password.service';
import { CustomValidators } from 'ng4-validators';
import { ProfileService } from '../../../services/users/profile.service';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-form-alta-nuevo-cliente',
  templateUrl: './form-alta-nuevo-cliente.component.html',
  styleUrls: ['./form-alta-nuevo-cliente.component.css']
})
export class FormAltaNuevoClienteComponent implements OnInit {
  @Output() nextStep: EventEmitter<boolean>;
  @Output() goBack: EventEmitter<boolean>;
  success: boolean = null;
  message: string = null;
  formDataCompany: FormGroup;
  name_errors: any = ValidationMessages.staffMessages.name_errors;
  email_errors: any = ValidationMessages.staffMessages.email_errors;
  pass_errors: any = ValidationMessages.staffMessages.pass_errors;
  passc_errors: any = ValidationMessages.staffMessages.passc_errors;
  password_errors: any = ValidationMessages.passwordMessages.generic_errors;
  regex = RegExp("^(?=.*[A-Z])(?=.{5,})");
  password = new FormControl('',  [      
      Validators.pattern(this.regex),
      Validators.minLength(6),
      Validators.maxLength(12)
  ]);
  password_confirmation = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.password)
  ]);
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;

  constructor(
    private profileService: ProfileService,
    private inputPasswordService: InputPasswordService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService
  ) {
    this.nextStep = new EventEmitter();
    this.goBack = new EventEmitter();
   }

  ngOnInit() {
    this.formDataCompany = this.createFormCompany();
    this.checkFormChanges();
  }

  createFormCompany() {
    return new FormGroup({
      'company' : new FormControl('', [
                  Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*'),
                  ValidateEmptySpaces
                ]),
      'name' : new FormControl('', [
                  Validators.pattern('[a-zA-ZÀ-ÿ \u00f1\u00d1]*'),
                  ValidateEmptySpaces
                ]),
      'email' : new FormControl('', [
              Validators.required, 
              Validators.email,
              CustomValidators.email
      ]),
      'password': this.password,
      'password_confirmation': this.password_confirmation
    })
  }

  submitForm() {
    this.ngxService.start();
    // First the customer and company gets created
    this.createCustomer().then((response:any) => {
      console.log(response);
      if (response.success === 0) {
        this.createCustomerError( response ); 
      } else {
        console.log(response);
        let companyData = {
          id_company : response.data.id_company,
          company_name : response.data.company_name,
          id_user : response.data.id_user,
          vehicle_limit :response.data.vehicle_limit
        }
        localStorage.setItem('NewCompany', JSON.stringify(companyData));
        this.createCustomerSuccess( response );
      }
    }, function (error) {
      this.ngxService.stop();
        this.createCustomerError( error ); 
    })
  }

  createCustomer() {
    return new Promise((resolve, reject)=> {
      const form = this.formDataCompany;
      console.log(form.value);
      if (form.valid) {
          console.log(form.value);
          this.profileService.addNewPremiumCompany( form.value ).subscribe(
            response => { 
              this.ngxService.stop(); 
              resolve( response );
              
            },
            error => { 
              this.ngxService.stop(); 
              reject( error );
              // this.createCustomerError( error ); 
            }
        );
      }
    })
  }

  createCustomerSuccess( response ) {
    console.log( response );
    this.success = true;
    if (response.message === "" || response.message === null) {
      this.message = "El usuario fue dado de alta correctamente";
    } else {
      this.message = response.message;
    }
    this.ngxService.stop();
    this.openDialog(this.success, this.message);
  }
  
  createCustomerError( response ) {
    console.log( response );
    this.success = false;
    if (response.message === "" || response.message === null) {
      this.message = "Hubo un error";
    } else {
      this.message = response.message;
    }
    this.ngxService.stop();
    this.openDialog(this.success, this.message);
  }
  
  loadMembers() {
    return new Promise((resolve, reject)=> {
      this.profileService.getFreemiumCompanies().subscribe(
          response => { 
            console.log(response);
            let code = response.success;
            let data = response.data;
            const companyName = this.formDataCompany.value.company;
            console.log(data);
            if (code != 1) {
              console.log( response );
              reject( response )
            } else {
              let userNum = Number(Object.keys(data[companyName][0].users)[0]);
              console.log(data[companyName][0].users[userNum][0].id);
              let companyData = {
                id_company : data[companyName][0].id_company,
                company_name : companyName,
                id_user : data[companyName][0].users[userNum][0].id,
                vehicle_limit : data[companyName][0].limits.vehicles_limit
              }
              
              localStorage.setItem('NewCompany', JSON.stringify(companyData));
              resolve( response );
            }
          },
          error => { 
            console.log( error );
            reject( error );
          }
      );
    })
}

serviceUpdateUser() {
    const userId = JSON.parse(localStorage.getItem('NewCompany'));
    const data = {
      user_id : userId.id_user,
      name_company: userId.company_name
    }
    this.profileService.convertUser( data ).subscribe(
      response => { 
        console.log(response);
    },
        error => { 
        console.log(error);
    });
}


  randomPassword( e ) {
  //  console.log(e.controls.password);
    this.inputPasswordService.randomPassword( e ); 
  }

  openDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.nextStep.emit( true );
      }
    });
  }

  back(){
      this.goBack.emit( true );
  }

  checkFormChanges() {
    this.formDataCompany.valueChanges.subscribe( data => {
        console.log(this.formDataCompany);
    })
  }


}

import { Component, OnInit, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { GetTeamSoltek } from '../../interfaces/get-team-soltek';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalAltaVehiculoNewComponent } from '../../shared/modals/modal-alta-vehiculo-new/modal-alta-vehiculo-new.component'
import { ModalAltaInstaladorSupervisorComponent } from '../../shared/modals/modal-alta-instalador-supervisor/modal-alta-instalador-supervisor.component'
import { ModalAltaVehiculoComponent } from '../../shared/modals/modal-alta-vehiculo/modal-alta-vehiculo.component';
import { TablaAdminIndependiente } from '../../interfaces/user.interface';
import {FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { SharingServiceService } from "../../services/sharing-service.service";
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';

@Component({
  selector: 'app-vehiculos-cliente',
  templateUrl: './vehiculos-cliente.component.html',
  styleUrls: ['./vehiculos-cliente.component.css']
})
export class VehiculosClienteComponent implements OnInit {
  selected = new FormControl(0);
  tabs = ['Vehículos registrados'];
  detailClient: GetTeamSoltek[];
  id;
  user;
  TypeOfUser;
  addUser = false;
  reloadVehicleTable = false;
  data;
  showTableAlert:boolean;
  alertClass:boolean = true;
  addSuccessMessage: 'El usuario fue agregado correctamente.'
  alertMessage:any = {
    success: '',
    fail: 'Hubo un error al agregar el usuario.'
  } 

  
  constructor(
      public dialog: MatDialog,     
      private router: Router,
      private sharingService:SharingServiceService,
    ) { 
      this.data = this.sharingService.getData();
      console.log(this.data);
  }

  ngOnInit() {
 
  }
  ngOnChanges() {}

  ngOnDestroy() {
    localStorage.removeItem('AdminClienteDetalleCliente-TabLocation');
  }

  openDialogVehicle(): void {
    let dialogRef = this.dialog.open(ModalAltaVehiculoComponent,{
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log( 'Vehiculo dado de alta ' + result )
      console.log('The dialog was closed');
      this.reloadVehicleTable = result;
      console.log(this.reloadVehicleTable);
      setTimeout(() => {
        this.reloadVehicleTable = false;
      }, 300);
    });
  }

  openAlertModal( success ): void {
    let modal_success, modal_message;
    if ( success || success === undefined ) {
      modal_success = true;
      modal_message = 'El usuario fue dado de alta correctamente'
    } else {
      modal_success = false;
      modal_message = 'Hubo un error al crear el usuario'
    }
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.addUser = true;
      }
    });
  }


}

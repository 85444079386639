import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections'; // Usado para el table select
import { UtilsService } from '../../../app.utils';
import { ModalEditarEquipoSoltekComponent } from '../../../shared/modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component'; 
import { ModalSendNotificationClientesComponent } from '../../../shared/modals/modal-send-notification-clientes/modal-send-notification-clientes.component';
import { ModalSendNotificationIndComponent } from '../../../shared/modals/modal-send-notification-ind/modal-send-notification-ind.component';
import { SharingServiceService } from "../../../services/sharing-service.service";
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';

// #Services
import { ProfileService } from '../../../services/users/profile.service';
import { SweetAlertService } from 'angular-sweetalert-service';


// #Interfaces
import { TablaAdminIndependiente } from '../../../interfaces/user.interface';
import { Router } from '@angular/router';

import noInternet from 'no-internet';
import { ModalEliminarCompaniaComponent } from '../../modals/modal-eliminar-compania/modal-eliminar-compania.component';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { FormControl } from '@angular/forms';
import { PaginatorService } from "../../../services/paginator.service";


const initialSelection = [];
const allowMultiSelect = true;
 
@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.css']
})

export class AdminTableComponent implements OnInit, OnDestroy {
  @Input() clientType:number;
  @Input() statusClient:number;
  GroupAdminInd;
  @Input() members: TablaAdminIndependiente[];
  @Output() memberSelected: EventEmitter<TablaAdminIndependiente> = new EventEmitter();
  route = this.router.url;
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  companiesList;
  @Input() showTableAlert:boolean;
  @Input() alertClass = true;
  alertMessage =  {
    success: 'El usuario fue editado correctamente.',
    fail: 'Hubo un error al actualizar el usuario.'
  };
  masterLoader: Loader;
  @Output() toggleChange: EventEmitter<void> 
  @Input() checked: Boolean;
  toggleUserStatus = new FormControl();
  hasData: boolean = false;
  
  /** Opciones de la alerta*/
private disable_options = {
  title: '¿Estás seguro de eliminar este administrador independiente?',
  confirmButtonText: 'Si, estoy seguro',
  cancelButtonText: '<span style="color:#ff6602">No, cancelar<span>',
  confirmButtonClass: 'btn-soltek btn-orange mr-1', 
  cancelButtonClass: 'btn-soltek btn-orange-inverse ml-1',
  buttonsStyling: false,
  width: 597,
  imageUrl: '../../../../assets/images/icn-eliminar.png'
};

private enable_options = {
    text: '¿Desea re-activar al usuario?',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    focusCancel: true
};
private premium_options = {
  title: '¿Estás seguro de cambiar este usuario a Premium?',
  text: 'Al cambiarlo a premium este usuario se borrará de la lista de Administradores Independientes y se pasará a la lista de Administradores clientes',
  confirmButtonText: 'Aceptar',
  cancelButtonText: '<span style="color:#ff6602">No, cancelar<span>',
  confirmButtonClass: 'btn-soltek btn-orange mr-1', 
  cancelButtonClass: 'btn-soltek btn-orange-inverse ml-1',
  buttonsStyling: false,
  width: 597,
  imageUrl: '../../../../assets/images/icn-modal-upgrade.png'
};

  selection = new SelectionModel<TablaAdminIndependiente>(allowMultiSelect, initialSelection);

  @Input() displayedColumns: string[];
  dataSource = new MatTableDataSource(this.members);
  // Define si el total de elementos está seleccionado
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    if ( numSelected > 1  ){
      $('.table-group-actions-box').addClass('fadeInUp').removeClass('invisible fadeOut');
    }
    if ( numSelected <= 1  ){
      $('.table-group-actions-box').removeClass('fadeInUp').addClass('fadeOut');
    }
    return numSelected === numRows;
  }
  
  // Selecciona todas las filas si no están seleccionadas; de otra forma, quita la selección a todas
  masterToggle() {
    this.isAllSelected() ?
      this.clearSelection(): 
          this.dataSource.data.forEach(row =>this.selection.select(row));          
  }


  clearSelection(){
    $('.table-group-actions-box').removeClass('fadeInUp').addClass('fadeOut');
    this.selection.clear();
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatPaginator) paginatorBottom: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort ;
  paginatorId = 'pAt'
  paginatorChosenValue: number;

  constructor(private profileService: ProfileService,
    private utils: UtilsService,
    private router: Router,
    private alertService: SweetAlertService,
    public dialog: MatDialog,
    private sharingService: SharingServiceService,
    private ngxService: NgxUiLoaderService,
    private paginatorService: PaginatorService
    ) { 
      this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
      this.masterLoader = this.ngxService.getLoader();
    }
 

  ngOnInit() {
    this.loadMembers(); 
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );
  }

  ngOnBeforeDestroy() {
    this.ngxService.stop();
  }

  ngOnDestroy() { 
  }

  ngAfterViewInit() {
  }
  
  applyDatasource(): void {
    setTimeout( (event) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }, 1000);
  }

  // Obtiene posición del arreglo del usuario seleccionado
  altaCliente(idx: number){
    console.log(idx + 1);
    this.router.navigate(['/users/alta-cliente/',(idx + 1)]);
  }

  // Obtiene posición del arreglo del usuario seleccionado
  editarCliente(idx: number, element){
    console.log(element);
    let users = element.users;
    let headers;
    console.log(users);
    headers = Object.keys(users);
    element = {
      companyId: element.companyId,
      name: element.name, 
      email: element.email,
      userId: users[headers[0]][0].id,
      vehicles_limit: element.vehicles_limit
    }
    localStorage.setItem('AdminCliente', JSON.stringify(element));
    this.router.navigate(['/usersClientes/editar-cliente/',( idx + 1)]);
  }


// Alerta para confirmar cambio de Admin Ind a Admin cliente
premiumAlert( idx: number, company) {
  console.log(company);
  localStorage.setItem('upgradeUser', JSON.stringify(company));
  this.alertService.confirm(this.premium_options)
  .then((result) => {
    this.altaCliente( idx );
      if (result.value) {
          noInternet().then(offline => {
              if (offline) {
                  this.utils.alertError('Verifica tu conexión');
              } else {
                  this.altaCliente( idx );
              }
          });
      } else if (result.dismiss === 'cancel') { }

  })
  .catch(() => console.log('canceled'))
}

// Abre el modal para editar a un usuario
openEditDialog(name, last_name, email, user_id, user_type_id): void { 
  const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
    panelClass: 'modal-send-notification',
    data: {
        name: name,
        last_name: last_name,
        email: email,
        user_id: user_id,
        user_type_id: user_type_id
      }
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
    if (result) {
      this.showTableAlert = true;
      this.loadMembers();
    } else {
      this.showTableAlert = false;
    }
  });
}

openDeleteDialog(company_id): void { 
  console.log(company_id);
  const dialogRef = this.dialog.open(ModalEliminarCompaniaComponent,{
    panelClass: 'modal-send-notification',
    data: {
      company: company_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    
    console.log( result );
    if (result === true) {
      this.openMessageDialog( true, 'El usuario fue borrado.' )
    } 
    if  (result === false) {
      this.openMessageDialog( false, 'Hubo un error al borrar al usuario.' )
    } 
  });
}


  // Obtiene todas las compaías registradas en el cms
  public loadMembers(startLoader?) {
    if (typeof startLoader === 'undefined' || startLoader === true){
      this.ngxService.startLoader( this.masterLoader.loaderId )
    }
    this.profileService.getAdmins().subscribe(
        response => { 
          console.log(response);
          this.ngxService.stop();
          // Dependiendo la ruta en la que se encuentra la vista mostrará clientes freemium o premium
          if ( this.route === '/users') {
            this.loadMembersSuccessAdminInd( response ); 
          }
          if ( this.route === '/usersClientes') {
            this.loadMembersSuccessAdminClientes( response ); 
          }
        },
        error => { 
          this.ngxService.stop();
        }
    );
}

// Clientes Freemium = Admin Independiente
private loadMembersSuccessAdminInd( response: any ) {
  console.log(this.displayedColumns);
  let code = Number(response.success);
  let data = response.data;
  let headers;
  let keys = [];
  let adminsInd = [];
  if (code != 1) {
      this.utils.errorManagement( response );
  } else {
    headers = Object.keys(data);
    console.log(headers);
    headers.forEach(element => {
      if ( this.re.test(String(element).toLowerCase()) ) {
          keys.push(element);
          // console.log(keys);
      }  
    });
    this.companiesList = headers;
    let i;
    for (i = 0; i < keys.length; i++) { 
      let key = keys[i];
      let Users = Object.keys(data[key][0].users);
      console.log( Users );
      let companyId =  data[key][0].id_company;
      let companyStatus = Number(data[key][0].active);
      let vehicleData = data[key][0].vehicles;
        let userData = [data[key][0].users];
        console.log(userData);
        userData.forEach(element => {
            let i;
            for (i=0; i < Users.length ; i++) {
              const userTypeId =element[Number(Users[i])][0].user_type_id
              if (Number(userTypeId) === 3) {
                let userData = element[Number(Users[i])][0];
                let toggleUserStatus:boolean;
                if (Number(userData.user_status_id) === 1){
                  toggleUserStatus = true;
                } 
                if (Number(userData.user_status_id) === 2) {
                  toggleUserStatus = false;
                }
                let client;
                if ( userData.last_name === 'null' || userData.last_name === null ) {
                  client = userData.name;
                } else {
                  client = userData.name + ' ' + userData.last_name;
                }
                let newUser = {
                  created_at: userData.created_at,
                  email: userData.email,
                  id: userData.id,
                  last_name: userData.last_name,
                  name: userData.name,
                  client: client,
                  updated_at: userData.updated_at,
                  user_status_description: userData.user_status_description,
                  user_status_id: Number(userData.user_status_id),
                  user_type: Number(userData.user_type),
                  user_type_id: Number(userData.user_type_id),
                  incidents: Number(vehicleData.incidents),
                  total_vehicles: Number(vehicleData.total_vehicles),
                  vehicles_with_kits: Number(vehicleData.vehicles_with_kits),
                  vehicles_checked: Number(vehicleData.vehicles_checked),
                  companyId: companyId,
                  companyStatus: Number(userData.user_status_id),
                  toggleUserStatus: toggleUserStatus
                }
                // Si el 'user_status_id' es 3 significa que el usuario se 'borró'
                if (newUser.user_status_id != 3) {
                  adminsInd.push( newUser );
                }
              }
            }
            
        });
       
        if (adminsInd.length > 0) {
          this.createTable(adminsInd);
        }
        else {
          this.hasData = false;
        }
      
      
    }
   
  }
}

// Clientes Premium = Admin Clientes
private loadMembersSuccessAdminClientes( response: any ) {
  let code = Number(response.success);
  let data = response.data;
  console.log(this.displayedColumns);
 

  let headers;
  let keys = [];
  let adminsClientes = [];
  if (code != 1) {
      this.utils.errorManagement( response );
  } else {
    headers = Object.keys(data);
    headers.forEach(element => {
      if ( !this.re.test(String(element).toLowerCase()) ) {
          keys.push(element);
      }  
    });
    let i;
    for (i = 0; i < keys.length; i++) { 
      let key = keys[i];
      let Users = Object.keys(data[key][0].users);
      let companyName = keys[i];
      let companyId =  data[key][0].id_company;
      let companyStatus = Number(data[key][0].active);
      let vehicleData = data[key][0].vehicles;
      let limits = data[key][0].limits;
      let userData = [data[key][0].users];
      console.log(userData);
      userData.forEach(element => {
        // console.log(element);
            let i;
            for (i=0; i < Users.length ; i++) {
              // console.log(Users[i]);
              const userTypeId = element[ Number(Users[i])][0].user_type_id
              if ( Number(userTypeId) === 2) {
                console.log(element);
                let userData = element[Number(Users[i])][0];
                let toggleUserStatus:boolean;
                if (companyStatus === 1){
                  toggleUserStatus = true;
                } 
                if (companyStatus === 0) {
                  toggleUserStatus = false;
                }
                // En el caso de usuarios Premium, los parámetros user_status_id, id, toggleUserStatus pertenecen a la compañía, no a un usuario
                let newUser = {
                  created_at: userData.created_at,
                  email: userData.email,
                  id: userData.id,
                  last_name: '',
                  name: companyName,
                  client: companyName,
                  updated_at: userData.updated_at,
                  user_status_description: userData.user_status_description,
                  user_status_id:  Number(userData.user_status_id),
                  user_type: userData.user_type,
                  user_type_id: Number(userData.user_type_id),
                  incidents: Number(vehicleData.incidents),
                  total_vehicles: vehicleData.total_vehicles,
                  vehicles_checked: Number(vehicleData.vehicles_checked),
                  companyId: companyId,
                  companyStatus: Number(companyStatus),
                  users_limit: Number(limits.users_limit),
                  vehicles_limit: Number(limits.vehicles_limit),
                  vehicles_with_kits: Number(vehicleData.vehicles_with_kits),
                  users: data[key][0].users,
                  toggleUserStatus: toggleUserStatus,
                }
                console.log(newUser);
                // 'user_status_id' === 3 significa un usuario con status 'borrado' que no debe salir en vista
                if (newUser.user_status_id != 3) {
                  adminsClientes.push( newUser );
                  break;
                }
              }
            }
            
        });
        console.log(adminsClientes);
        if (adminsClientes.length >= 1) {
         // console.log(adminsClientes);
         this.createTable(adminsClientes);
        } else {
          this.hasData = false;
        }
      
    }
   
  }
}

// Crea la tabla con los datos que le pasa
createTable( data ) {
  console.log(data);
  this.dataSource = new MatTableDataSource(data);
  this.dataSource.paginator = this.paginator;
  console.log(this.paginator);
  this.dataSource.filterPredicate = (data, filter: string) => {
    console.log(data);
    return data.email.toLowerCase().includes(filter) 
    || data.client.toLowerCase().includes(filter);
   };
  this.hasData = true;
  this.applyDatasource();

}

applyFilter(filterValue: string) {
  filterValue = filterValue.trim(); 
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
}


refresh(){
  window.location.reload();
}

// Maneja status de usuarios (block, unblocks y borra)
public manageUser(user_id, status, name) {
  let new_status:number;
  if (status === 1) {
    new_status = 2;
  }
  if (status === 2)  {
    new_status = 1;
  }
  const data = {
    user_id: user_id,
    status: new_status
  }
  console.log(data);
  this.ngxService.startLoader('loader-toggle-'+ name);
  // Status 1 = Active || 2 = Supended || 3 = Delete
  this.profileService.manageUser(data).subscribe(
    response => { 
      this.manageUserSuccess();
     },
    error => { 
      this.ngxService.stopLoader('loader-toggle-'+ name);
      this.manageUserError();
     }
  )
}

// Maneja status de Compañías (block, unblocks y borra)
  public manageCompany(company_id, status, name) {
    let new_status:number;
    if (status === 1) {
      new_status = 0;
    }
    if (status === 0)  {
      new_status = 1;
    }
    const data = {
      company: company_id,
      status: new_status
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-'+ name);
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageCompany(data).subscribe(
      response => { 
        // this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserError();
       }
    )
  }
  
  manageUserSuccess() {
    this.loadMembers(false);
  }
  
  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(ModalSendNotificationClientesComponent,{
      panelClass: 'modal-send-notification'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  // Lleva a la vista de la información del usuario seleccionado
  // Dpendiendo de si es un usuario Freemium o Premium envía a vistas diferentes
  goToDetalleCliente(element) {
    console.log(element);
    element = {
      companyId: element.companyId,
      userId: element.id,
      last_name: element.last_name, 
      name: element.name, 
      email: element.email,
    }

    localStorage.setItem('AdminCliente', JSON.stringify(element));
    const route = this.router.url // === '/login'
    if ( route === '/users') {
      this.sharingService.setData(element);
      localStorage.setItem('ViewingUserType', 'free')
      this.router.navigate(['/vehiculos-cliente']);
    }

    if ( route === '/usersClientes') {
      this.sharingService.setData(element);
      localStorage.setItem('ViewingUserType', 'premium')
      this.router.navigate(['/detalle-cliente']);
    }
  }

  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }

  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.loadMembers();
    });
  }
  
   // openSendNotification() Abre la imagen seleccionada en la tabla
   openSendNotification(element): void {
    const dialogRef = this.dialog.open(ModalSendNotificationClientesComponent,{
      panelClass: 'modal-send-notification',
      data : element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != undefined){
        this.openDialogMessage( result )
      }
    });
  }

   // openSendNotification() Abre la imagen seleccionada en la tabla
   openSendNotificationInd(element): void {
    const dialogRef = this.dialog.open(ModalSendNotificationIndComponent,{
      panelClass: 'modal-send-notification',
      data : element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != undefined){
        this.openDialogMessage( result )
      }
    });
  }

  openDialogMessage( success? ): void {
    let modal_success,
    modal_message;
    if (!success) {
      modal_success = false;
      modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
    }

    if (success) {
      modal_success = true;
      modal_message = 'La notificación ha sido enviada'
    }
    
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (!result) {
        this.router.navigate(['/dashboard']);
      } 
     

    });
  }

  syncPrimaryPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDeleteService } from '../../services/filter-delete.service';
@Component({
  selector: 'app-admin-cliente',
  templateUrl: './admin-cliente.component.html',
  styleUrls: ['./admin-cliente.component.css']
})
export class AdminClienteComponent implements OnInit {
  displayColumns = [
    // 'select', 
    'number',
    'administrador', 
    'contacto', 
    'vehiculos', 
    'vehiculos_con_kit',
    'vehiculosSupervisados', 
    'incidencia', 
    'status', 
    'acciones', 
    'next'
  ];
  clientType = 2;
  constructor(private router: Router, public FilterDelete: FilterDeleteService) { }

  ngOnInit() {
    this.FilterDelete.deleteFilters([
      'sort-detalle-cliente', 'folio-/detalle-cliente',  'dataSource-filter-detalle-cliente'
    ]);
  }

  altaCliente(){
    this.router.navigate(['/usersClientes/alta-nuevo-cliente/']);
  }

}

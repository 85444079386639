import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MatExpansionModule, MatIcon} from '@angular/material';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ModalAltaChoferesClienteComponent } from '../../shared/modals/modal-alta-choferes-cliente/modal-alta-choferes-cliente.component'; 
import { ProfileService } from '../../services/users/profile.service'
import { UtilsService } from '../../app.utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
export class companiesDriversList {
  company: string
  company_id: number
  drivers: []
}
@Component({
  selector: 'app-choferes-cliente',
  templateUrl: './choferes-cliente.component.html',
  styleUrls: ['./choferes-cliente.component.css']
})
export class ChoferesClienteComponent implements OnInit {
  createTable: boolean = false;
  showTableAlert; 
  alertClass = true; // Acepta booleans; true = green, false = red.
  alertMessage = {
    success: 'Se agregó exitosamente el chofer.',
    fail: 'Hubo un error al agregar el chofer.'
  }
  userFilter: any = { company: '' };
  @Input() expanded: any
  getCompaniesDrivers: companiesDriversList[];
  companiesDrivers: companiesDriversList[];
  companieSelected;
  displayDrivers = false;
  driversTableData;
  expand: number;
  dataSource = new MatTableDataSource(this.getCompaniesDrivers);
  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private utils: UtilsService,
    private ngxService: NgxUiLoaderService) {      
  }

  ngOnInit() {
    this.loadCompanies();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAltaChoferesClienteComponent,{
      panelClass: 'modal-send-notification',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log( result );
      if (result) {
        this.showTableAlert = true;
        this.afterEditUser();
      }
    });
  }

  afterEditUser(){
    this.driversTableData = null;
    console.log(this.driversTableData);
    this.loadCompanies( false ) ;
  }

   // Cierra la Alert Table
   closeTableAlert(data){
    this.showTableAlert = false;
  }


   // Obtiene los choferes de todas las compañias y realiza un filtrado a partir del item de local storage 'AdminCliente', 
   // usando el parámetro 'name'para mostrar solo los choferes de esa compañía
   public loadCompanies(startLoader?) {
    return new Promise ((resolve, reject) => {
      if (typeof startLoader === 'undefined' || startLoader === true){
        this.ngxService.start();
      }
      this.profileService.getCompaniesDrivers().subscribe(
          response => { 
            console.log(response);
            let code = response.success;
            if (code != 1) {
                this.utils.errorManagement( response );
            } else {
              this.companiesDrivers = response.data;
              console.log(this.companiesDrivers);
              this.displayDrivers = true;
              const company = JSON.parse(localStorage.getItem('AdminCliente')).name;
              for(let i = 0; i < this.companiesDrivers.length ; i++ ) {
                if(this.companiesDrivers[i].company === company) {
                  this.driversTableData = this.companiesDrivers[i].drivers;
                }
              }
              this.driversTableData.forEach(element => {
                element.company_name = company;
              });
              console.log(this.driversTableData);
              this.createTable = true;
              resolve( true );
            }
            this.ngxService.stop();
          },
          error => { 
            console.log(error);
            this.ngxService.stop();
            reject( error );
          } 
      );
    })
    
  }


}

import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectModule, MatFormFieldModule} from '@angular/material';
import { FullImageComponent } from '../../../shared/modals/full-image/full-image.component';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { FichaTecnicaInterface } from '../../../interfaces/ficha-tecnica-interface'
import { SharingServiceService } from "../../../services/sharing-service.service";
import { VehiclesService } from '../../../services/vehicles/vehicles.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import * as moment from 'moment';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';

@Component({
  selector: 'app-detalle-punto-revision-table',
  templateUrl: './detalle-punto-revision-table.component.html',
  styleUrls: ['./detalle-punto-revision-table.component.css']
})


export class DetallePuntoRevisionTableComponent implements OnInit, OnDestroy{
  @Input() data;
  calendarFilter;
  @Input() displayedColumns: string[] = ['ultimaFoto', 'status', 'folios', 'fecha', 'hora'];
  @Output() reload: EventEmitter< object >;

  pointId;
  dataSource;

  constructor(
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    public vehiclesService: VehiclesService
    ) {
      this.reload = new EventEmitter();
    }



  ngOnInit() {
    console.log(this.data);
    let data_split;
    if (this.data === undefined || this.data === null) { } else {
      let date;
      let time;
      if ( this.data.updated_at !== null) { 
        data_split = this.data.updated_at.split(' '); 
      } 
      else { 
        data_split = this.data.created_at.split(' '); 
       }
       date = moment(data_split[0], "YYYY-MM-DD", 'es', true).format("DD/MMM/YY").replace('.', '');
       time = data_split[1].slice(0,5)
      if (this.data.movement === 'Instalación'){
        this.data.movement = "Registro"
      }
      if (this.data.movement === 'Supervisión' && this.data.supervisor.comment !== null) {
        this.displayedColumns.push('comentarios');
      }
      this.data.date = date;
      this.data.time = time;
      this.dataSource = new MatTableDataSource([this.data]);
    }
    
  }

  ngOnDestroy() {}

  // openDialog() Abre la imagen seleccionada en la tabla
  openDialog(data): void {
    let imgLink = data;
    console.log(imgLink);
    const dialogRef = this.dialog.open(FullImageComponent,{
      data: imgLink,
      panelClass: 'modal-full-image'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // Se usa para marcar un punto con supervisión y status dañado
  // EL administrador determina si la falla es o no justificada, por default todas las fallas son No justificadas
  changeJustifiedStatus( id ) {
    console.log(id);
    const data = {
      installation_point_id: id
    }
    this.ngxService.startLoader('loader-toggle-' + id);
    this.vehiclesService.justifiedFail(data).subscribe(
      response => {
        console.log(response);
        this.ngxService.stopLoader('loader-toggle-' + id);
        if ( response.success !== 1 ) {
          this.changeJustifiedStatusError( response );

        } else {
          this.changeJustifiedStatusSuccess();
        }
      },
      error => {
        console.log(error);
        this.ngxService.stopLoader('loader-toggle-' + id);
        this.changeJustifiedStatusError( error );
      }
    )
  }

  // Recarga la vista para reflejar los cambios realizados
  changeJustifiedStatusSuccess() {
    this.reload.emit( {
      reload: true,
      toggleUserStatus: true
    });
  }
  
  // Abre un alerta en un modal
  changeJustifiedStatusError( data ) {
    this.openMessageDialog( false, data.message );
  }

  changeStatus(status: number, id: number): void {
    const data = {
      id,
      status,
    }
    console.log(data);
      this.ngxService.startLoader('loader-toggle-status' + id);
      this.vehiclesService.changeStatusPointInstallation(data).subscribe(
        response => {
         this.changeJustifiedStatusSuccess();
         this.ngxService.stopLoader('loader-toggle-status' + id);
        },
        error => {
        this.ngxService.stopLoader('loader-toggle-status' + id);
        this.changeJustifiedStatusError( error );
        }
      );
  }

  openMessageDialog( success, message ): void {
    let data = {
      message : message,
      success : success
    }
    console.log(data);
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

}

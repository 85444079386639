import { Injectable } from '@angular/core';
import { Http, Headers, Response, CookieXSRFStrategy } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GLOBAL_URL } from './global-urls';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  private access_token: string = this.cookieService.get('access_token');
  private token_type: string = this.cookieService.get('token_type');

  constructor(private http: Http,
    private httpClient: HttpClient,
    private cookieService: CookieService) { }

  // #RESET PASSWORD
  resetpassword( value: any) {
    const body = JSON.stringify( value );
    const headers = new Headers({
        'Content-Type': 'application/json',
        'lang': 'es_mx',
        'Authorization': `${ this.token_type } ${ this.access_token }`
    });

    const url = `${ GLOBAL_URL }/api/reset_password`;

    return this.http.post( url, body, { headers }).map( (response: Response) => response.json() );
}

}

import { Component, OnInit, Inject, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-modal-carga-puntos-revision',
  templateUrl: './modal-carga-puntos-revision.component.html',
  styleUrls: ['./modal-carga-puntos-revision.component.css']
})
export class ModalCargaPuntosRevisionComponent implements OnInit {
  tabs = ['Carga Masiva'];
  tab_selected = new FormControl(0);
  DragAndDropFileOperation = 4; // 0 = 'choferes', 1= 'vehiculos', 2 ='bases', 3 = 'equipo soltek', 4 = 'puntos de revisión'
  DragAndDropFileType = 'xlsx'; // 'image', 'xlsx', etc

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalCargaPuntosRevisionComponent>,
    private profileService: ProfileService,
    private utils: UtilsService,
    private router: Router,
    private ngxService: NgxUiLoaderService
  ) { }

  
  ngOnInit() {
    console.log(this.data);
  }
  
  refresh(){
    window.location.reload();
  }

  onNoClick( value? ): void {
    this.dialogRef.close( value );
  }
}

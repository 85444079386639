import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, PageEvent} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ModalEliminarComponent } from '../../../shared/modals/modal-eliminar/modal-eliminar.component'; 
import { GetTeamSoltek } from '../../../interfaces/get-team-soltek';
import { ModalEditarEquipoSoltekComponent } from '../../modals/modal-editar-equipo-soltek/modal-editar-equipo-soltek.component';
import { ModalErroresEditarClientesComponent } from '../../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { ProfileService } from '../../../services/users/profile.service';
import { UtilsService } from '../../../app.utils';
import { NgxUiLoaderService, Loader} from 'ngx-ui-loader';
import { FormControl } from '@angular/forms';
import { PaginatorService } from "../../../services/paginator.service";
import { ModalSendNotificationIndComponent } from '../../../shared/modals/modal-send-notification-ind/modal-send-notification-ind.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-cliente-usuarios-table',
  templateUrl: './admin-cliente-usuarios-table.component.html',
  styleUrls: ['./admin-cliente-usuarios-table.component.css']
})
export class AdminClienteUsuariosTableComponent implements OnInit, OnChanges {
  @Input() addedNewUser;
  @Input() TypeOfUser;
  @Input() displayedColumns: string[] = ['name', 'email', 'user_status_id', 'acciones'];
  @ViewChild(MatSort) sort: MatSort;
  activeSort = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() toggleChange: EventEmitter<void> 
  @Input() checked: Boolean;
  @Input() idCompany: string;
  paginatorId = 'pAcUt'
  paginatorChosenValue: number;
  toggleUserStatus = new FormControl();
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  dataSource;
  filterPlaceholder = "¿Qué quieres buscar?";
  Instalador:GetTeamSoltek[] = [];
  Supervisor:GetTeamSoltek[] = [];
  Administrador:GetTeamSoltek[] = [];
  Moderador:GetTeamSoltek[] = [];
  Vigilante:GetTeamSoltek[] = [];
  data;
  hasData: boolean = false;
  @Input() showTableAlert:boolean;
  @Input() alertClass = true;
  alertMessage =  {
    success: 'El usuario fue editado correctamente.',
    fail: 'Hubo un error al actualizar el usuario.'
  };

  constructor(public dialog: MatDialog,
              private profileService: ProfileService,
              private utils: UtilsService,
              private ngxService: NgxUiLoaderService,
              private paginatorService: PaginatorService,
              private router: Router,
             ) { 
      this.paginatorChosenValue = this.paginatorService.setPaginator( this.paginatorId );
      this.showTableAlert = false;
  }
 
  ngOnInit() {
    console.log(this.idCompany);
    this.addedNewUser = false;
    // console.log(this.addedNewUser);
    const isSortActive = sessionStorage.getItem('sort-detalle-cliente' + this.TypeOfUser);
     if (isSortActive) {
       this.activeSort = JSON.parse(isSortActive);
     } else {
       this.activeSort = {
         active : 'name',
         direction : 'asc'
       }
     }
    this.loadMembers();
    this.paginatorService.savePaginator( this.paginator, this.paginatorId );

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.addedNewUser) {
      this.Instalador = [];
      this.Supervisor = [];
      this.Vigilante = [];
      this.Administrador = [];
      this.Moderador = [];
      this.loadMembers();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    sessionStorage.setItem('dataSource-filter-detalle-cliente' + this.TypeOfUser, this.dataSource.filter);
  }


  openDeleteDialog(user_id): void { 
    console.log(user_id);
    const dialogRef = this.dialog.open(ModalEliminarComponent,{
      panelClass: 'modal-send-notification',
      data: {
        user_id: user_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
  
      console.log( result );
      if (result[0] === true) {
        this.openMessageDialog( true, 'El usuario fue borrado.' )
      } 
      if  (result[0] === false) {
        this.openMessageDialog( false, 'Hubo un error al borrar al usuario.' )
      } 
    });
  }

  openEditDialog(name, last_name, email, user_id): void { 
    const dialogRef = this.dialog.open(ModalEditarEquipoSoltekComponent,{
      panelClass: 'modal-send-notification',
      data: {
          name: name,
          last_name: last_name,
          email: email,
          user_id: user_id
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === true) {
        this.showTableAlert = true;
        this.alertClass = true;
        this.loadMembers();
      }
    });
  }

  // #Get Admins Ind
public loadMembers(startLoader?) {
  if (typeof startLoader === 'undefined' || startLoader === true){
    this.ngxService.start();
  }
  this.profileService.getCompanyMembers( this.idCompany ).subscribe(
      response => { 
        this.ngxService.stop();
        console.log(response);
        this.loadMembersSuccess( response ); 
      },
      error => { 
        this.ngxService.stop();
        console.log(error);
        this.loadMembersError( error ); 
      }
  );
}
/*
  Load Members Callbacks
*/
private loadMembersSuccess( response: any ) {
  let code = response.success;
  let data = response.data;
  let users = response.data.users;
  let headers;
  let keys = [];
  let supervisores = [];
  let instaladores = [];
  let administradores = [];
  let moderadores = [];
  let vigilantes = [];
  let companyId = JSON.parse(localStorage.getItem('AdminCliente')).companyId;
  let companyName = JSON.parse(localStorage.getItem('AdminCliente')).name;
  if (code != 1) {
    this.hasData = false;
    // this.utils.errorManagement( response );
  } else {
    headers = Object.keys(users);
    console.log(headers);
    users.forEach(user => {
      switch (Number(user.user_type_id)) {
          // user_type_id = 6 === Supervisor
          // user_type_id = 7 === Instalador
          // user_type_id = 2 === Administrador
          // user_type_id = 9 === Moderador
          case 6:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            supervisores.push(user);
            return; 
          case 7:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            instaladores.push(user);
            return; 
          case 2:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            administradores.push(user);
            return; 
          case 9:
            user.toggleUserStatus = this.setUserStatusId(Number(user.user_status_id))
            moderadores.push(user);
            return; 
          default:
            return;
      }
    });
    console.log(this.TypeOfUser);
    switch (this.TypeOfUser) {
      case 'Instalador':
        this.data = instaladores;
        break;
      case 'Supervisor':
        this.data = supervisores;
        break;
      case 'Administrador':
        this.data = administradores;
        break;
      case 'Moderador':
        this.data = moderadores;
        break;
      default:
        this.data = instaladores;
    }
   console.log(this.data);
    // This reverses the array so it shows the last users created first
    let reverseData = this.data.reverse();
    this.dataSource = new MatTableDataSource(reverseData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if (this.data.length <= 0 || this.data.length === null || this.data.length === undefined) {
      this.hasData = false;
    } else {
      this.hasData = true;
    }
    if (sessionStorage.getItem('dataSource-filter-detalle-cliente' + this.TypeOfUser)) {
      this.dataSource.filter = sessionStorage.getItem('dataSource-filter-detalle-cliente' + this.TypeOfUser);
      this.filterPlaceholder = 'Filtro activado: "' + this.dataSource.filter +'"';
    }
  }
}

setUserStatusId(user_status_id) {
  // El retorno es el valor que se asigna a 'toggleUserStatus'
  switch (user_status_id) {
    case 1:
      return true;
    case 2:
      return false;
  }
};

private loadMembersError( error: any ) {
  console.error( error );
}

refresh(){
  window.location.reload();
}



    // Manage User (block, unblocks and erases users)
  public manageUser(user_id, status, name) {
    let new_status:number;
    if (Number(status) === 1) {
      new_status = 2;
    }
    if (Number(status) === 2) {
      new_status = 1;
    }
    const data = {
      user_id: user_id,
      status: new_status
    }
    console.log(data);
    this.ngxService.startLoader('loader-toggle-'+ name);
    // Status 1 = Active || 2 = Supended || 3 = Delete
    this.profileService.manageUser(data).subscribe(
      response => { 
        // this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserSuccess();
       },
      error => { 
        this.ngxService.stopLoader('loader-toggle-'+ name);
        this.manageUserError();
       }
    )
  }

  manageUserSuccess() {
    this.loadMembers(false);
  }

  manageUserError() {
    this.openMessageDialog( false, 'Ocurrió un error al actualizar el usuario, intente de nuevo más tarde.' );
  }


  openMessageDialog( success, message ): void {
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: message,
        success: success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.loadMembers( result );
    });
  }

  hideTableAlert(e) {
    console.log(e);
    if (e) {
      this.showTableAlert = false;
    }
  }

    // openSendNotification() Abre la imagen seleccionada en la tabla
    openSendNotificationInd(element): void {
      const dialogRef = this.dialog.open(ModalSendNotificationIndComponent,{
        panelClass: 'modal-send-notification',
        data : element
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result != undefined){
          this.openDialogMessage( result )
        }
      });
    }
  
    openDialogMessage( success? ): void {
      let modal_success,
      modal_message;
      if (!success) {
        modal_success = false;
        modal_message = 'Hubo un error al consultar la información. Intenta de nuevo más tarde'
      }
      if (success) {
        modal_success = true;
        modal_message = 'La notificación ha sido enviada'
      }
      const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
        panelClass: 'modal-send-notification',
        disableClose: true,
        data : {
          message: modal_message,
          success: modal_success
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!result) {
          this.router.navigate(['/dashboard']);
        } 
      });
    }

    sortData(event) {
      console.log(event);
      this.activeSort = event;
      sessionStorage.setItem('sort-detalle-cliente' + this.TypeOfUser, JSON.stringify(event));
    }
  
    sortActive(event) {
      console.log(event);
    }

    syncPrimaryPaginator(event: PageEvent) {
      this.paginator.pageIndex = event.pageIndex;
      this.paginator.pageSize = event.pageSize;
      this.paginator.page.emit(event);
    }
  
}

import { Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import { Router, Routes } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ProfileService } from '../../services/users/profile.service';
import { VehiclesService } from '../../services/vehicles/vehicles.service';
import { CookieService } from 'ngx-cookie-service';
import { SweetAlertService } from 'angular-sweetalert-service';
import { CustomValidators } from 'ng4-validators';
import * as ValidationMessages from '../../validators/validation-messages';
import { UtilsService } from 'src/app/app.utils';
import { ModalErroresEditarClientesComponent } from '../../shared/modals/modal-errores-editar-clientes/modal-errores-editar-clientes.component';
import { AuthService } from '../../services/auth.service';
import { InputPasswordService } from '../../services/input-password.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  member: any = {
    username: '',
    password: ''
  };

  // Error Messages
  user_errors: any = ValidationMessages.loginMessages.user_errors;
  pass_errors: any = ValidationMessages.loginMessages.pass_errors;

  constructor( 
               private loginService: LoginService,
               private utils: UtilsService,
               private router: Router,
               private cookieService: CookieService,
               private alertService: SweetAlertService,
               private profileService: ProfileService,
               public dialog: MatDialog, 
               private authService: AuthService,
               private inputPasswordService: InputPasswordService,
               ) { 
                let active = this.isActive();
                if (active){
                  this.router.navigate(['dashboard']);
                }
                this.loginForm =  new FormGroup({
                  'username': new FormControl('', [
                      Validators.required,
                      CustomValidators.email
                  ]),
                  'password': new FormControl('', Validators.required)
              });

                }

  ngOnInit() {}

  onLogin(member: any){
    if(this.loginForm.valid) {
    this.member.username = member.username;
    this.member.password = member.password;
    this.loginService.login(this.member)
    .subscribe(
      response => {
        this.accessSuccess( response );
      },
      error => { 
        this.accessError ( error ); 
      }
    );
    }
  }

  private accessSuccess( response: any ) {
        let code = response.success;
        if (code != 1) {
          console.log(response);
          this.openAlertModal( false, response.message );
        } else {
            this.utils.tokenAssign( response );
            console.log(response);
            localStorage.setItem('userType', response.data.user_type);
            const userType = Number(response.data.user_type);

            // Referencias de tipos de usuarios
            // 1	Super Admin
            // 2	Admin Cliente
            // 3	Admin Independiente
            // 4	Supervisor Soltek
            // 5	Instalador Soltek
            // 6	Supervisor
            // 7	Instalador
            // 8	Chofer
            // 9	Moderador
            // 10	Moderador Soltek

            setTimeout(() => {
              // Si el tipo de usuario no es alguno de los siguientes no se permite el acceso al cms
              if (
                userType === 1 || 
                userType === 2 || 
                userType === 3 || 
                userType === 9 || 
                userType === 10
              ){
                if ( userType != 1){
                  this.profileService.getProfile().subscribe(
                    response =>{
                      console.log(response.data[0].company_id);
                      let data = {
                          companyId: response.data[0].company_id,
                          last_name: response.data[0].last_name,
                          name: response.data[0].company_name,
                          email:response.data[0].email
                        }
                      console.log(data);
                      localStorage.setItem('AdminCliente', JSON.stringify(data));
                      setTimeout(() => {
                        this.router.navigate(['/dashboard']);
                      }, 1000);
                    },
                    error => {
                      this.openAlertModal( false, error.message );
                    }
                  )
                } else {
                  setTimeout(() => {
                    this.router.navigate(['/dashboard']);
                  }, 1000);
                }
              } else {
                this.openAlertModal( false, 'Tu usuario no puede acceder a este recurso' );
                localStorage.clear();
                this.cookieService.deleteAll();
              }
            }, 1000);
        }
}

private accessError( error: any ) {
    let e_body = JSON.parse(error._body);
    let message: string = e_body.message;
    this.openAlertModal( false, 'Los datos ingresados no son los correctos' );
}

  openAlertModal( success, message ): void {
    let modal_success = success;
    let modal_message = message
   
    const dialogRef = this.dialog.open(ModalErroresEditarClientesComponent,{
      panelClass: 'modal-send-notification',
      disableClose: true,
      data : {
        message: modal_message,
        success: modal_success
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  // Ocupa el servicio para checar si el usuario está autentificado
  isActive() {
    return this.authService.isAuthenticated();
  }
}
